import {
  ActionButton,
  ActionsGroup,
  DisplayData,
  formatUtils
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';

class DisplayIdentificacaoRequisicao extends React.Component {
  static propTypes = {
    position: PropTypes.number,
    pessoa: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    vendedor: PropTypes.bool,
    showActionButtons: PropTypes.bool,
    onViewPessoa: PropTypes.func,
    onCopyPercent: PropTypes.func
  };

  static defaultProps = {
    vendedor: false,
    showActionButtons: false
  };

  handleCopyPercent = () => {
    const { pessoa, position, onCopyPercent } = this.props;

    onCopyPercent(pessoa, position);
  };

  handleDelete = () => {
    const { position, onDelete } = this.props;

    onDelete(position);
  };

  handleEdit = () => {
    const { pessoa, onEdit, position } = this.props;

    onEdit(pessoa, position);
  };

  render() {
    const {
      pessoa,
      vendedor,
      showActionButtons,
      onViewPessoa,
      isServidor
    } = this.props;

    return (
      <div key={pessoa.cpf}>
        <div className="row" tabIndex="0" autoFocus>
          {isServidor && (
            <div className="col-sm-3">
              <DisplayData title="Vínculo">
                {pessoa.vinculo ? pessoa.vinculo.descricao : ''}
              </DisplayData>
            </div>
          )}
          <div className="col-sm-5">
            <DisplayData title="Nome">
              {`${formatUtils.formatCpfCnpj(pessoa.cpfCnpj)} - ${pessoa.nome}`}
            </DisplayData>
          </div>
          <div className="col-sm-2">
            <DisplayData title="Percentual">
              <NumberFormat
                value={pessoa.percentual}
                displayType={'text'}
                decimalSeparator={','}
                suffix={'%'}
              />
            </DisplayData>
          </div>
          {vendedor && (
            <div className="col-sm-2">
              <DisplayData title="Perc. Venda">
                <NumberFormat
                  value={pessoa.percentualVenda}
                  displayType={'text'}
                  decimalSeparator={','}
                  suffix={'%'}
                />
              </DisplayData>
            </div>
          )}
          <ActionsGroup>
            <ActionButton
              data-test-id="buttonView"
              key="view-button"
              icon="eye"
              label="Visualizar"
              onClick={() => onViewPessoa(pessoa)}
            />
            {showActionButtons && (
              <React.Fragment>
                <ActionButton
                  data-test-id="buttonEditar"
                  key="editar"
                  icon="pencil-alt"
                  label="Editar"
                  onClick={this.handleEdit}
                />
                <ActionButton
                  data-test-id="buttonRemover"
                  key="editexcluirar"
                  icon="trash-alt"
                  label="Excluir"
                  onClick={this.handleDelete}
                />
                {isServidor && vendedor && (
                  <ActionButton
                    data-test-id="buttonClonarPercentuais"
                    key="editeclonarpercentuais"
                    icon="copy"
                    label="Copiar Percentuais"
                    onClick={this.handleCopyPercent}
                  />
                )}
              </React.Fragment>
            )}
          </ActionsGroup>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    vinculos: state.proprietario.vinculos
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  null
)(DisplayIdentificacaoRequisicao);

export { ConnectedComponent as default, DisplayIdentificacaoRequisicao };
