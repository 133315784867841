import { Alert, NotificationActions } from '@elotech/components';
import { LaudoService } from 'itbi-common/service';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import LaudoList from './LaudoList';
import { getFields } from './SearchFieldsLaudo';

class LaudoListPage extends React.Component {
  static propTypes = {
    showNotification: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
  }

  state = {
    laudos: [],
    pagination: undefined,
    searchText: '',
    loadingLaudos: false,
    empty: undefined,
    searchFields: []
  };

  componentDidMount() {
    this.updateSearchFields(this.props.listRequerentes);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.listRequerentes !== this.props.listRequerentes) {
      this.updateSearchFields(this.props.listRequerentes);
    }
  }

  updateSearchFields = listaRequerentes =>
    this.setState({ searchFields: getFields(listaRequerentes) });

  onCreate = () => {
    this.props.history.push('/laudos/novo');
  };

  onSeeLaudo = laudo => () => {
    this.props.history.push(`/laudos/${laudo.id}`);
  };

  onPrintBoleto = laudo => () => {
    window.open(laudo.urlBoleto, '_blank');
  };

  onSearch = (searchParam = this.state.searchText, page) => {
    this.setState({ searchText: searchParam, loadingLaudos: true });
    return LaudoService.searchLaudos(searchParam, page)
      .then(response => {
        const {
          content,
          number,
          totalPages,
          first,
          last,
          numberOfElements,
          size
        } = response.data;

        this.setState(state => ({
          loadingLaudos: false,
          laudos: content,
          pagination: {
            number,
            totalPages,
            first,
            last,
            numberOfElements,
            size
          },
          empty:
            state.empty === undefined && searchParam === ''
              ? numberOfElements === 0
              : state.empty
        }));

        return response.data;
      })
      .catch(error => {
        this.setState({ loadingLaudos: false });
        Alert.error(
          { title: 'Erro ao fazer a busca de avaliações de laudos' },
          error
        );
      });
  };

  render() {
    const {
      loadingLaudos,
      laudos,
      pagination,
      empty,
      searchText,
      searchFields
    } = this.state;
    const { tipoPessoa } = this.props;

    if (empty && !searchText) {
      this.buttonRef.current && this.buttonRef.current.focus();
    }

    return (
      <LaudoList
        laudos={laudos}
        loading={loadingLaudos}
        pagination={pagination}
        onSearch={this.onSearch}
        onCreate={this.onCreate}
        onViewLaudo={this.onSeeLaudo}
        onDownloadBoleto={this.onPrintBoleto}
        showDetailsPrimaryButton={empty}
        buttonRef={this.buttonRef}
        tipoPessoa={tipoPessoa}
        searchFields={searchFields}
      />
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const mapStateToProps = state => {
  const listRequerentes =
    (state.user.currentUser && state.user.currentUser.usuariosProcurador) || [];
  return {
    page: state.requerimento.requerimentos,
    listRequerentes: [state.user.currentUser, ...listRequerentes],
    tipoPessoa: state.user.currentUser.tipoPessoa
  };
};

export { LaudoListPage };

export default connect(mapStateToProps, mapDispatchToProps)(LaudoListPage);
