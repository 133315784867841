import {
  Alert,
  BasicInput,
  FormikSelect,
  Loading,
  Masks,
  Row,
  SectionTitle
} from '@elotech/components';
import { connect } from 'formik';
import { FormikContext } from 'formik/dist/types';
import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';

import { CepService, UserService } from '../../service';
import { LoteLaudo } from '../../type';
import { Constantes } from '../../utils';
import { validateCpfCnpj } from '../../utils/ValidationUtils';

type Props = {
  onChangeCpf: (event: React.ChangeEvent<any>) => void;
  onChangeValueWithoutMask: (event: React.ChangeEvent<any>) => void;
  onChangeCep: (event: React.ChangeEvent<any>) => void;
  userTributos: any;
};

type PropsWithFormik = Props & {
  formik: FormikContext<LoteLaudo>;
};
const SeletorRequerenteServidor: React.FC<PropsWithFormik> = ({
  onChangeCpf,
  onChangeValueWithoutMask,
  onChangeCep,
  userTributos
}) => {
  return (
    <>
      <SectionTitle>Requerente</SectionTitle>
      <Row>
        <BasicInput
          name="requerenteCpfCnpj"
          label={'CPF'}
          render={({ field }) => (
            <MaskedInput
              {...field}
              id="requerenteCpfCnpj"
              value={field.value}
              onChange={onChangeCpf}
              autoFocus
              mask={Masks.MASK_CPF}
            />
          )}
        />
        <BasicInput
          name={'requerenteNome'}
          label={'Nome'}
          size={3}
          disabled={userTributos?.nome}
        />
        <BasicInput
          name={'requerenteEmail'}
          label={'Email'}
          size={3}
          disabled={userTributos?.email}
        />
        <BasicInput
          name="requerenteTelefone"
          label="Telefone"
          size={3}
          disabled={userTributos?.telefone}
          render={({ field }) => (
            <MaskedInput
              {...field}
              id="requerenteTelefone"
              guide
              showMask
              onChange={onChangeValueWithoutMask}
              mask={Masks.MASK_TELEFONE}
            />
          )}
        />
      </Row>
      <Row>
        <BasicInput
          label="CEP"
          name="requerenteCep"
          disabled={userTributos?.cep}
          render={({ field }) => (
            <MaskedInput
              {...field}
              id="requerenteCep"
              guide
              onChange={onChangeCep}
              mask={Masks.MASK_CEP}
            />
          )}
        />
        <BasicInput
          label={'Endereço'}
          name={'requerenteLogradouro'}
          size={5}
          disabled={userTributos?.logradouro}
        />
        <BasicInput
          label={'Número'}
          name={'requerenteNumero'}
          size={2}
          disabled={userTributos?.numero}
        />
      </Row>
      <Row>
        <BasicInput
          label="Bairro"
          name={'requerenteBairro'}
          size={5}
          disabled={userTributos?.bairro}
        />
        <BasicInput
          label={'Cidade'}
          name={'requerenteCidade'}
          size={5}
          disabled={userTributos?.cidade}
        />
        <FormikSelect
          label={'Estado'}
          name={'requerenteUF'}
          options={Constantes.optionsEstados}
          getOptionLabel={value => value.label}
          getOptionValue={value => value.key}
          size={2}
          disabled={userTributos?.uf}
        />
      </Row>
    </>
  );
};

export default connect<Props, LoteLaudo>(SeletorRequerenteServidor);
