import * as Types from './types';

const handleloadDeclaracoes = (state, action) => ({
  ...state,
  requerimentos: action.payload
});

const handlers = {
  [Types.LOAD_REQUERIMENTOS]: handleloadDeclaracoes
};

const RequerimentoReducer = (state = {}, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};

export default RequerimentoReducer;
