import {
  FormattedCurrency,
  FormattedDate,
  FormattedDecimal,
  QuickView
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

class AvaliacaoQuickView extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    ultimasAvaliacoes: PropTypes.array.isRequired
  };

  renderAvaliacoes = avaliacao => {
    return (
      <React.Fragment key={avaliacao.id}>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label className="label">Data</label>
              <FormattedDate
                value={avaliacao.dataLancamento}
                timeZone={'UTC'}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="label">Valor Avaliado</label>
              <FormattedCurrency value={avaliacao.valorTransacaoAvaliacao} />
            </div>
          </div>
          {avaliacao.valorTransacaoItbi > 0 && (
            <React.Fragment>
              <div className="col-md-2">
                <div className="form-group">
                  <label className="label">Alíquota</label>
                  <FormattedDecimal value={avaliacao.aliquotaTransacao} />%
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="label">Valor ITBI</label>
                  <FormattedCurrency value={avaliacao.valorTransacaoItbi} />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        {avaliacao.valorFinanciadoAvaliacao > 0 && (
          <div className="row">
            <div className="col-md-4" />
            <div className="col-md-3">
              <div className="form-group">
                <label className="label">Valor Financiado/Anuência</label>
                {avaliacao.valorFinanciadoAvaliacao}
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label className="label">Alíquota</label>
                {avaliacao.aliquotaFinanciado}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="label">Valor ITBI</label>
                <FormattedCurrency value={avaliacao.valorFinanciadoItbi} />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { onClose, ultimasAvaliacoes } = this.props;

    return (
      <QuickView width={550} onClose={onClose} className="fixed">
        <React.Fragment>
          <div className="quick-header">Últimas Avaliações</div>
          {ultimasAvaliacoes.map(this.renderAvaliacoes)}
        </React.Fragment>
      </QuickView>
    );
  }
}

export default AvaliacaoQuickView;
