import {
  Col,
  Field,
  Filters,
  Row,
  SearchInput,
  SearchPanel
} from '@elotech/components';
import React, { ChangeEvent } from 'react';

type Props = {
  fields: Field[];
  search(search: object): void;
};

type State = {
  selectedField?: Field;
  filters: Filters;
};

class SearchFilterCadastro extends React.Component<Props, State> {
  searchValueRef = React.createRef<any>();

  constructor(props: Props) {
    super(props);
    const { fields } = this.props;

    if (fields.length > 0) {
      const firstField = fields[0];

      this.state = {
        selectedField: firstField,
        filters: {}
      };
    }
  }

  onFilter = () => {
    const { search } = this.props;
    const { selectedField } = this.state;
    const value = this.searchValueRef.current.getValue();
    const newFilter = {
      field: selectedField,
      value: value
    };
    search(newFilter);
  };

  handleFieldsChange = (event: ChangeEvent<any>) => {
    const selectedField = this.props.fields.find(
      f => f.name === event.target.value
    );
    this.setState({ selectedField }, () => {
      this.searchValueRef.current.focus();
    });
  };

  render() {
    const { fields } = this.props;
    const { selectedField } = this.state;

    return (
      <SearchPanel>
        <Row>
          <Col sm={3}>
            <div className="form-group">
              <select
                data-testid="selectField"
                name="filter-fields"
                style={{ height: 28 }}
                onChange={this.handleFieldsChange}
              >
                {fields.map(field => (
                  <option key={field.label} value={field.name}>
                    {field.label}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col sm={9}>
            <div className="form-group">
              <SearchInput
                ref={this.searchValueRef}
                onEnter={this.onFilter}
                field={selectedField!}
              />
              <button
                data-test-id="buttonAdicionarFiltro"
                className="btn filter"
                type="button"
                onClick={this.onFilter}
              >
                Filtrar
              </button>
            </div>
          </Col>
        </Row>
      </SearchPanel>
    );
  }
}

export default SearchFilterCadastro;
