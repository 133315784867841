import React from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
  SyntheticInputEvent
} from 'react-number-format';

export type InputIntegerProps = {
  allowNegative?: boolean;
  onValueChange?: (values: NumberFormatValues, e: SyntheticInputEvent) => void;
} & NumberFormatProps;

const InputInteger: React.FC<InputIntegerProps> = props => (
  <NumberFormat
    decimalScale={0}
    allowNegative={false}
    autoComplete="off"
    {...props}
  />
);

InputInteger.displayName = 'InputInteger';
export { InputInteger };
