import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  FAB,
  Field,
  FormattedDate,
  Loading,
  NotificationActions,
  PagedResponse,
  Panel,
  SearchFilter,
  SearchPagination,
  Table
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import classnames from 'classnames';
import { History } from 'history';
import { ParametroService, ParcelamentoService } from 'itbi-common/service';
import React from 'react';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';

import { Parcelamento, PermissaoParcelamentoDTO } from '../../types';
import InstrucaoParcelamento from './InstrucaoParcelamento';

type Props = {
  history: Pick<History, 'push'>;
  cpfCnpjRequerente: string[];
  showNotification(notification: Notification): void;
};

type State = {
  searchParam: string;
  parcelamentos: Parcelamento[];
  pagination?: any;
  loading: boolean;
  loadingParcelamentos: boolean;
  permissaoParcelamento: PermissaoParcelamentoDTO;
  empty?: boolean;
};

const searchFields: Field[] = [
  {
    label: 'Nº Contrato',
    name: 'numeroContrato',
    type: 'NUMBER'
  },
  {
    label: 'Ano Contrato',
    name: 'anoContrato',
    type: 'NUMBER'
  },
  {
    label: 'Tipo',
    name: 'tipo',
    type: 'ENUM',
    options: [
      {
        name: 'P',
        descricao: 'Parcelamento'
      },
      {
        name: 'R',
        descricao: 'Reparcelamento'
      }
    ]
  },
  {
    label: 'Data Contrato',
    name: 'dataParcelamento',
    type: 'DATE'
  },
  {
    label: 'Requerente',
    name: 'requerente.nome',
    type: 'STRING'
  },
  {
    label: 'Situação',
    name: 'situacao.descricao',
    type: 'ENUM',
    options: [
      {
        name: 'ABERTO',
        descricao: 'Aberto'
      },
      {
        name: 'RESCINDIDO',
        descricao: 'Rescindido'
      },
      {
        name: 'ESTORNADO',
        descricao: 'Estornado'
      },
      {
        name: 'QUITADO',
        descricao: 'Quitado'
      }
    ]
  },
  {
    label: 'Cadastro Geral',
    name: 'cadastroGeral.cadastroGeral',
    type: 'NUMBER'
  },
  {
    label: 'Tipo Cadastro',
    name: 'cadastroGeral.tipoCadastro',
    type: 'NUMBER'
  }
];

class ParcelamentoListPage extends React.Component<Props, State> {
  state: State = {
    searchParam: '',
    parcelamentos: [],
    pagination: undefined,
    loading: false,
    loadingParcelamentos: false,
    permissaoParcelamento: {
      isAutorizadoParcelamento: false,
      instrucaoAcessoParcelamento: ''
    },
    empty: undefined
  };

  componentDidMount = () => {
    this.getPermissaoParcelamento();
  };

  getPermissaoParcelamento = () => {
    this.setState({ loading: true });
    return ParametroService.loadPermissaoParcelamento()
      .then(response => {
        this.setState({ permissaoParcelamento: response.data });
      })
      .catch(error => {
        this.setState({ loading: false });
        Alert.error(
          {
            title: 'Erro ao buscar permissão do parcelamento.'
          },
          error
        );
      })
      .finally(() => this.setState({ loading: false }));
  };

  onSearch = (searchParam: string = this.state.searchParam, page?: any) => {
    const { cpfCnpjRequerente } = this.props;
    if (cpfCnpjRequerente) {
      this.setState(
        { loadingParcelamentos: true, searchParam: searchParam },
        () => {
          const cpfCnpjSeparadosPorVirgula = cpfCnpjRequerente.join(',');
          const filterCpfCnpjRequerente = `requerente.cnpjCpf=in=(${cpfCnpjSeparadosPorVirgula})`;
          return ParcelamentoService.findAll(
            searchParam
              ? `${searchParam} and ${filterCpfCnpjRequerente}`
              : filterCpfCnpjRequerente,
            page
          )
            .then(this.onSearchSuccess)
            .catch(error => {
              this.setState({ loadingParcelamentos: false });
              Alert.error(
                {
                  title: 'Erro ao carregar contratos de parcelamento'
                },
                error
              );
            })
            .finally(() => this.setState({ loadingParcelamentos: false }));
        }
      );
    }
  };

  onSearchSuccess = (response: AxiosResponse<PagedResponse<Parcelamento>>) => {
    const {
      content,
      number,
      totalPages,
      first,
      last,
      numberOfElements,
      size
    } = response.data;
    this.setState({
      empty:
        this.state.empty === undefined && this.state.searchParam === ''
          ? numberOfElements === 0
          : this.state.empty
    });
    if (content && content.length > 0) {
      return this.setState({
        parcelamentos: content,
        pagination: {
          number,
          totalPages,
          first,
          last,
          numberOfElements,
          size
        }
      });
    } else {
      this.props.showNotification({
        level: 'warning',
        message:
          'Não foi possível encontrar nenhum contrato parcelamento com o filtro informado.'
      });
      return this.setState({
        parcelamentos: [],
        pagination: undefined
      });
    }
  };

  onView = (parcelamento: Parcelamento) => {
    this.props.history.push(`/parcelamentos/${parcelamento.id}/resumo`);
  };

  paginationSearch = (page: Parcelamento) => this.onSearch(undefined, page);

  render() {
    const {
      parcelamentos,
      loading,
      loadingParcelamentos,
      pagination,
      permissaoParcelamento,
      empty
    } = this.state;

    return (
      <Container title="Parcelamentos" icon="file-contract">
        <Loading loading={loading} />
        {permissaoParcelamento.isAutorizadoParcelamento ? (
          <Panel isTable>
            <SearchFilter fields={searchFields} search={this.onSearch} />
            <Table values={parcelamentos} loading={loadingParcelamentos}>
              <Table.Column<Parcelamento>
                header="Nº Contrato"
                value={item => item.numeroContrato}
              />
              <Table.Column<Parcelamento>
                header="Ano do Contrato"
                value={item => item.anoContrato}
              />
              <Table.Column<Parcelamento>
                header="Tipo"
                value={item =>
                  item.tipo === 'P' ? 'Parcelamento' : 'Reparcelamento'
                }
              />
              <Table.Column<Parcelamento>
                header="Data Contrato"
                value={item => (
                  <FormattedDate value={item.dataParcelamento} timeZone="UTC" />
                )}
              />
              <Table.Column<Parcelamento>
                header="Tipo Cadastro"
                value={item => item.cadastroGeralResumido.tipoCadastro}
              />
              <Table.Column<Parcelamento>
                header="Cadastro Geral"
                value={item => item.cadastroGeralResumido.cadastroGeral}
              />
              <Table.Column<Parcelamento>
                header="Requerente"
                value={item =>
                  item.requerente && item.requerente.nome
                    ? item.requerente.nome
                    : ''
                }
              />
              <Table.Column<Parcelamento>
                header="Situação"
                value={item =>
                  item.situacao && item.situacao.descricao
                    ? item.situacao.descricao
                    : ''
                }
              />
              <Table.Column<Parcelamento>
                header=""
                name="actions-buttons"
                value={item => (
                  <ActionsGroup>
                    <ActionButton
                      key="view-button"
                      icon="eye"
                      label="Visualizar"
                      onClick={() => this.onView(item)}
                    />
                  </ActionsGroup>
                )}
              />
            </Table>
            {pagination && (
              <SearchPagination
                page={pagination}
                searchWithPage={this.paginationSearch}
              />
            )}
            <div
              className={classnames('btn-save has-details', {
                show: empty
              })}
            >
              <FAB
                icon="plus"
                title="Novo Parcelamento"
                path="/parcelamentos/novo"
                showHintOnFocus
              />
              <div className="details">
                <h1>Novo por aqui?</h1>
                <p className="mt-xs">
                  Clique neste botão para solicitar seu <b>Parcelamento</b>, é{' '}
                  <b> simples </b> e <b>rápido</b>.
                </p>
              </div>
            </div>
          </Panel>
        ) : (
          permissaoParcelamento.instrucaoAcessoParcelamento && (
            <InstrucaoParcelamento
              instrucaoAcessoParcelamento={
                permissaoParcelamento.instrucaoAcessoParcelamento
              }
            />
          )
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  const listRequerentes =
    (state.user.currentUser && state.user.currentUser.usuariosProcurador) || [];
  return {
    cpfCnpjRequerente: [state.user.currentUser, ...listRequerentes].map(
      user => user.cpfCnpj
    )
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  showNotification: NotificationActions.showNotification
})(ParcelamentoListPage);

export { ConnectedComponent as default, ParcelamentoListPage };
