import React from 'react';

type Props = {
  value: string;
  checked: boolean;
  children: React.ReactNode;
  onChange(value: string): void;
};

const FloatSidebarItem: React.FC<Props> = ({
  value,
  checked,
  children,
  onChange
}) => {
  return (
    <div className="row" key={value}>
      <div className="col-md-12 select-list-item ">
        <div className="col-md-2 selection">
          <div className="form-group">
            <input
              type="radio"
              aria-label="Radio button para selecionar"
              id={value}
              data-testid={value}
              name="radio"
              className="input-radio"
              value={value}
              onChange={() => onChange(value)}
              checked={checked}
            />
            <label htmlFor={value}></label>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default FloatSidebarItem;
