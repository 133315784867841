import { FieldProps } from 'formik';
import React, { FC } from 'react';
import { NumberFormatProps } from 'react-number-format';

import { EditableInputProps } from '../../editableInput/EditableInput';
import EditableInputInteger from '../../editableInput/EditableInputInteger';
import { FormikEditableInput } from '../..';

export type FormikEditableInputProps = Partial<EditableInputProps<number>> & {
  fast?: boolean;
  allowNegative?: boolean;
} & NumberFormatProps;

const FormikEditableInputInteger: FC<FormikEditableInputProps> = props => (
  <FormikEditableInput<number>
    {...props}
    render={({ field, form }: FieldProps) => {
      return (
        <EditableInputInteger
          {...field}
          initialValue={field.value}
          onConfirmChange={(value: number, name: string, fullPath: string) => {
            form.setFieldValue(fullPath, value);
            form.setFieldTouched(fullPath);
            props.onConfirmChange &&
              props.onConfirmChange(value, name, fullPath);
          }}
          allowNegative={props.allowNegative}
          defaultOpened={props.defaultOpened}
          readOnly={props.readOnly}
        />
      );
    }}
  />
);

export default FormikEditableInputInteger;
