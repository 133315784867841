import { ReactComponent as AdministrativoIcon } from '@elotech/arc/src/assets/img/modules/oxy-administrativo.svg';
import { ReactComponent as ArrecadacaoIcon } from '@elotech/arc/src/assets/img/modules/oxy-arrecadacao.svg';
import { ReactComponent as ContabilIcon } from '@elotech/arc/src/assets/img/modules/oxy-contabil.svg';
import { ReactComponent as EducacionalIcon } from '@elotech/arc/src/assets/img/modules/oxy-educacional.svg';
import { ReactComponent as ComumIcon } from '@elotech/arc/src/assets/img/modules/oxy-logo.svg';
import { ReactComponent as RecursosHumanosIcon } from '@elotech/arc/src/assets/img/modules/oxy-rh.svg';
import { ReactComponent as SocialIcon } from '@elotech/arc/src/assets/img/modules/oxy-social.svg';
import classNames from 'classnames';
import React from 'react';

import { AreaAgrupada } from '../../type';

const AreaIcons = {
  ADMINISTRATIVO: <AdministrativoIcon className={'icon'} />,
  ARRECADACAO: <ArrecadacaoIcon className={'icon'} />,
  CONTABIL: <ContabilIcon className={'icon'} />,
  EDUCACIONAL: <EducacionalIcon className={'icon'} />,
  RECURSOS_HUMANOS: <RecursosHumanosIcon className={'icon'} />,
  SOCIAL: <SocialIcon className={'icon'} />,
  COMUM: <ComumIcon className={'icon'} />
};
const buildModuloUrl = (moduloUri: string): string => {
  return `${window.location.origin}/${moduloUri}`;
};

type AreaItemProps = {
  identificador: string;
  area: AreaAgrupada;
  open: boolean;
  hideTitle?: boolean;
  onClick: (area: string) => void;
};
const AreaItem: React.FC<AreaItemProps> = ({
  area,
  identificador,
  open,
  onClick,
  hideTitle = false
}) => {
  const ulClasses = classNames('module-list no-logo expansible', {
    open: open
  });
  return (
    <ul className={ulClasses} key={identificador}>
      <li className="features-section" onClick={() => onClick(identificador)}>
        {AreaIcons[identificador]}
        {!hideTitle && area.descricao}
      </li>
      {area.modulos.map(modulo => (
        <li className="sisters" key={modulo.valor}>
          <a href={buildModuloUrl(modulo.uri)} target={'_blank'}>
            <span>{modulo.descricao}</span>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default AreaItem;
