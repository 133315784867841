import { ActionButton, ActionsGroup } from '@elotech/components/src';
import React, { useState } from 'react';

import { Laudo } from '../../type';
import { LotePropriedadeQuickView } from '../index';
import LaudoPropriedadeList from './LaudoPropriedadeList';

type Props = {
  propriedades: Laudo[];
};

const LaudoPropriedadeResumo: React.FC<Props> = ({ propriedades }) => {
  const [propriedadeQuickView, setPropriedadeQuickView] = useState<Laudo>();

  return (
    <>
      <LaudoPropriedadeList
        propriedades={propriedades}
        renderButton={item => (
          <ActionsGroup>
            <ActionButton
              key="view-button"
              icon="eye"
              label="Propriedades"
              onClick={() => setPropriedadeQuickView(item)}
            />
          </ActionsGroup>
        )}
      />
      <LotePropriedadeQuickView
        laudo={propriedadeQuickView}
        onClose={() => setPropriedadeQuickView(undefined)}
      />
    </>
  );
};

export default LaudoPropriedadeResumo;
