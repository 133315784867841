import {
  ActionButton,
  ActionsGroup,
  FormattedCurrency,
  FormattedDate,
  Panel,
  PanelFooter,
  PanelFooterItem,
  SectionTitle,
  Table
} from '@elotech/components';
import React from 'react';

import { ParcelamentoDebitoParcelasDTO } from '../../types';

type Props = {
  parcelas: ParcelamentoDebitoParcelasDTO[];
  onPrintBoleto(parcela: number): void;
  isEstornado: boolean;
};

const Footer: React.FC<{ total: number }> = ({ total }) => (
  <PanelFooter>
    <PanelFooterItem
      align="right"
      inline
      label="Total"
      value={<FormattedCurrency value={total} />}
    />
  </PanelFooter>
);

const getTotal = (parcelas: ParcelamentoDebitoParcelasDTO[]) =>
  parcelas.map(item => item.valor).reduce((acc, curr) => acc + curr, 0);

const ParcelasList: React.FC<Props> = ({
  parcelas,
  onPrintBoleto,
  isEstornado
}) => {
  const vencidoEAberto = (parcela: ParcelamentoDebitoParcelasDTO) =>
    !!parcela.vencido && !!parcela.aberto;

  return (
    <>
      <SectionTitle>Parcelas</SectionTitle>
      <Panel isTable footer={<Footer total={getTotal(parcelas)} />}>
        <Table
          data-test-id="table-parcelamento-parcelas"
          values={parcelas}
          fixed
          keyExtractor={item => item.parcela}
          highlightError={vencidoEAberto}
        >
          <Table.Column<ParcelamentoDebitoParcelasDTO>
            header="Parcela"
            value={item => item.parcela}
          />
          <Table.Column<ParcelamentoDebitoParcelasDTO>
            header="Data Vencimento"
            value={item => (
              <FormattedDate value={item.dataVencimento} timeZone="UTC" />
            )}
          />
          <Table.Column<ParcelamentoDebitoParcelasDTO>
            header="Situação"
            value={item => item.situacao}
          />
          <Table.Column<ParcelamentoDebitoParcelasDTO>
            headerClassName="right"
            className="right"
            header="Valor total"
            value={item => <FormattedCurrency value={item.valorTotal} />}
          />
          <Table.Column<ParcelamentoDebitoParcelasDTO>
            header=""
            value={(item: ParcelamentoDebitoParcelasDTO) =>
              !isEstornado &&
              item.aberto && (
                <ActionsGroup>
                  <ActionButton
                    data-test-id="print-button-boleto"
                    key="print-button"
                    icon="print"
                    label="Imprimir Boleto"
                    onClick={() => onPrintBoleto(item.parcela)}
                  />
                </ActionsGroup>
              )
            }
          />
        </Table>
      </Panel>
    </>
  );
};

export { ParcelasList as default, Footer };
