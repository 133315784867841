import * as Types from './types';

const handleGetCidadeLogada = (state, action) => ({
  ...state,
  cidadeLogada: action.payload.cidadeAise,
  idCidadeGateway: action.payload.idCidadeGateway
});

const handlers = {
  [Types.LOAD_CIDADE_LOGADA]: handleGetCidadeLogada
};

const initialState = {
  cidadeLogada: {},
  idCidadeGateway: undefined
};

const CidadeReducer = (state = initialState, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};

export default CidadeReducer;
