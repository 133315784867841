import React from 'react';

type Props = {
  instrucaoAcessoParcelamento: string;
};

const InstrucaoParcelamento: React.FC<Props> = ({
  instrucaoAcessoParcelamento
}) => (
  <div
    className="typography-size-16px ql-editor"
    dangerouslySetInnerHTML={{ __html: instrucaoAcessoParcelamento }}
  />
);

export default InstrucaoParcelamento;
