import classNames from 'classnames';

export const getTabItemClassName = (
  props: { badgeCount?: number },
  active: boolean
): string => {
  return classNames('tab-item', {
    active: active,
    'has-badge': shouldRenderBadge(props.badgeCount)
  });
};

export const shouldRenderBadge = (badgeCount?: number): boolean =>
  badgeCount !== undefined && badgeCount > 0;
