import React, { FC } from 'react';

import FormattedDate from '../formatted/FormattedDate';
import EditableInput, { EditableInputProps } from './EditableInput';
import { InputDate } from '..';

type Props = EditableInputProps<string>;

const EditableInputDate: FC<Props> = props => {
  return (
    <EditableInput
      {...props}
      renderEditComponent={({ value, onBlur, onChange, onKeyDown }) => (
        <InputDate
          autoFocus={props.autoFocus}
          name={props.name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      )}
      renderVisualization={value => <FormattedDate value={value} />}
    />
  );
};

export default EditableInputDate;
