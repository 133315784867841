import { AxiosPromise } from 'axios';
import { FormikProps } from 'formik';
import React, { ReactNode } from 'react';

import AutoCompleteMultiple from '../../inputs/AutoCompleteMultiple';
import BasicInput, { Props } from '../BasicInput';

type AutocompleteMultipleProps<T> = Props & {
  onSearch(search: string): AxiosPromise<any>;
  getOptionLabel: (option: T) => string;
  getDetailOptionLabel?: (option: T) => ReactNode;
  getOptionValue?: (option: T) => any;
  filter?: (value: string) => string;
  onItemSelected?: (form: FormikProps<any>, value: any) => void;
  onItemRemoved?: (form: FormikProps<any>, value: any) => void;
  autoFocus?: boolean;
  placeholder?: string;
  newValueLabel?: string;
  onCreateValue?: (texto: string) => AxiosPromise<T>;
};

class FormikAutocompleteMultiple<T> extends React.Component<
  AutocompleteMultipleProps<T>
> {
  static defaultProps = {
    getOptionLabel: (option: any) => option.descricao,
    getOptionValue: (option: any) => option.id
  };

  onSelect = (
    form: FormikProps<any>,
    name: string,
    value: T | undefined,
    values: T[]
  ) => {
    form.setFieldValue(name, values);
    form.setFieldTouched(name);
    this.props.onItemSelected?.(form, value);
  };

  onRemoveItem = (
    form: FormikProps<any>,
    name: string,
    value: T,
    values: T[]
  ) => {
    form.setFieldValue(name, values);
    this.props.onItemRemoved?.(form, value);
  };

  render() {
    const {
      onSearch,
      getOptionLabel,
      getDetailOptionLabel,
      getOptionValue,
      filter,
      onItemSelected,
      autoFocus,
      placeholder,
      newValueLabel,
      onCreateValue,
      ...rest
    } = this.props;

    return (
      <BasicInput
        label="label"
        {...rest}
        render={({
          field: { onChange, disabled, value, name, ...fieldsProps },
          form
        }) => (
          <AutoCompleteMultiple
            {...fieldsProps}
            values={value}
            name={name}
            onSearch={onSearch}
            onSelect={(name: string, value: T | undefined, values: T[]) =>
              this.onSelect(form, name, value, values)
            }
            onRemoveItem={(value: T, values: T[]) => {
              this.onRemoveItem(form, name, value, values);
            }}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            getDetailOptionLabel={getDetailOptionLabel}
            isDisabled={disabled}
            filter={filter}
            autoFocus={autoFocus}
            placeholder={placeholder}
            newValueLabel={newValueLabel}
            onCreateValue={onCreateValue}
          />
        )}
      />
    );
  }
}

export default FormikAutocompleteMultiple;
