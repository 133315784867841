import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classnames from 'classnames';
import React from 'react';

import Icon from '../icons/Icon';

type ChipProps = {
  color?: 'module-color' | 'negative' | 'positive' | 'warning' | 'neutral';
  value: React.ReactNode;
  outlined?: boolean;
  interactive?: boolean;
  iconLeft?: IconProp;
  iconRight?: IconProp;
  iconLeftCustom?: any;
  inline?: boolean;
  className?: string;
  onClick?(event: React.MouseEvent): void;
};

const Chip: React.FunctionComponent<ChipProps> = ({
  color = 'module-color',
  iconLeft,
  iconLeftCustom,
  iconRight,
  value,
  interactive = false,
  outlined = true,
  inline = false,
  onClick,
  className = ''
}) => {
  const conteinerClassName = classnames('chip-container', {
    outlined,
    interactive: interactive && !!onClick,
    'pull-left mr-xs': inline,
    [color]: color !== 'neutral',
    [className]: className !== ''
  });

  return (
    <div className={conteinerClassName} onClick={onClick}>
      {iconLeft && (
        <Icon darkPrimary icon={iconLeft} className={`chip-icon-left`} />
      )}
      {iconLeftCustom}
      <p className="chip-text">{value}</p>
      {iconRight && (
        <Icon darkPrimary icon={iconRight} className={`chip-icon-right`} />
      )}
    </div>
  );
};

export default Chip;
