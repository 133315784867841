import React, { useContext } from 'react';

import {
  NotificationContext,
  NotificationContextProps
} from '../providers/NotificationProvider';
import Header from './Header';
import NotificationDialog from './NotificationDialog';

export type NotificationContentProps = {
  onView?(notification: any): void;
  onShowView?(notification: any): boolean;
};

const NotificationContent: React.FC<NotificationContentProps> = ({
  onShowView,
  onView
}) => {
  const { toggleNotification } = useContext(Header.HeaderContext);
  const context: NotificationContextProps = useContext(NotificationContext);

  const handleOnView = (notification: any) => {
    toggleNotification();
    onView && onView(notification);
  };

  return (
    <NotificationDialog
      count={context.countNaoLidas}
      notifications={context.notifications}
      autoHeight
      hasReadOptions
      reduced
      onRead={context.read}
      onReadAll={context.readAll}
      loadMoreNotifications={context.loadMore}
      loadingNotifications={context.loading}
      showButtonMore={context.hasMore}
      onView={handleOnView}
      onShowView={onShowView}
    />
  );
};

export default NotificationContent;
