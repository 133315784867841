import { ActionButton, ActionsGroup } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const DocumentoAvulsoListItem = ({
  documento,
  showVisibleColumn,
  onRemove,
  onDownload
}) => {
  return (
    <tr>
      <td>{documento.nome}</td>
      <td>{documento.descricao}</td>
      {showVisibleColumn && <td>{documento.visivelCidadao ? 'Sim' : 'Não'}</td>}
      <td>
        <ActionsGroup>
          {onDownload && (
            <ActionButton
              icon={'file-download'}
              data-test-id="button-download"
              key="download-button"
              label="Baixar arquivo"
              onClick={() => onDownload(documento)}
            />
          )}
          {onRemove && (
            <ActionButton
              data-test-id="button-remove"
              key="remove-button"
              icon="trash-alt"
              label="Remover"
              onClick={() => onRemove(documento)}
            />
          )}
        </ActionsGroup>
      </td>
    </tr>
  );
};

DocumentoAvulsoListItem.propTypes = {
  documento: PropTypes.object.isRequired,
  showVisibleColumn: PropTypes.bool,
  onRemove: PropTypes.func,
  onDownload: PropTypes.func
};

DocumentoAvulsoListItem.defaultProps = {
  showVisibleColumn: false
};

export default DocumentoAvulsoListItem;
