import classnames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
  hasInput?: boolean;
};

/**
 * Componente de rodapé alternativo para ser usado dentro do footer do [[Panel]]
 * */
const PanelFooter: React.FC<Props> = ({
  children,
  hasInput = false,
  className = ''
}) => (
  <div
    className={classnames('altfooter-container', {
      hasInput: !!hasInput,
      [className]: className
    })}
  >
    {children}
  </div>
);

export default PanelFooter;
