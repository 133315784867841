import React from 'react';
import { FormattedNumber } from 'react-intl';

import { FormattedDecimal } from '../index';

const FormattedCurrency = ({ value, hideSymbol = undefined }) =>
  hideSymbol ? (
    <FormattedDecimal minimumFractionDigits={2} value={value}>
      {number => number}
    </FormattedDecimal>
  ) : (
    <FormattedNumber
      value={value || 0}
      style="currency" // eslint-disable-line
      currency="BRL"
      currencyDisplay="symbol"
    >
      {number => number}
    </FormattedNumber>
  );

export default FormattedCurrency;
