import React from 'react';

type Props = {
  icon: string;
  title: string;
};

export const TitleDash: React.FC<Props> = ({ icon, title }) => (
  <div className="title-dash">
    <i className={icon} />
    <h2>{title}</h2>
  </div>
);
