import {
  Alert,
  Container,
  Loading,
  Wizard,
  Yup,
  extractErrorText,
  hasVisibleErrors,
  useShowNotification
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import {
  CadastroRuralService,
  CepService,
  ImobiliarioService,
  LaudoService,
  ParametroService,
  UserService
} from '../../service';
import {
  Laudo,
  LoteLaudo,
  TIPO_IMOVEL_RURAL,
  TIPO_IMOVEL_URBANO,
  Usuario
} from '../../type';
import { ObjectUtils } from '../../utils';
import { validateCpfCnpj } from '../../utils/ValidationUtils';
import LaudoPagamentoSection from './LaudoPagamentoSection';
import LaudoPropriedadeResumo from './LaudoPropriedadeResumo';
import PropriedadeSection from './PropriedadeSection';
import SeletorRequerenteCidadao from './SeletorRequerenteCidadao';
import SeletorRequerenteServidor from './SeletorRequerenteServidor';

type Props = {
  afterSubmit: (valor: LoteLaudo) => void;
  servidor: boolean;
};

type FormValue = Partial<LoteLaudo>;
const buildInitialValue = (isServidor: boolean): FormValue => ({
  requerente: undefined,
  propriedades: [],
  origemServidor: isServidor
});

const validationSchemaCidadao = Yup.object().shape({
  requerente: Yup.object()
    .required()
    .shape({
      cpfCnpj: Yup.string()
        .required()
        .label('Requerente')
    })
    .label('Requerente'),
  propriedades: Yup.array()
    .label('Propriedades')
    .min(1)
});

const validationSchemaServidor = Yup.object().shape({
  requerenteTipoPessoa: Yup.string().label('Tipo de Pessoa'),
  requerenteCpfCnpj: Yup.string()
    .label('CPF/CNPJ')
    .required()
    .test('cpfCnpjValido', 'CPF/CNPJ inválido', value =>
      validateCpfCnpj(value)
    ),
  requerenteNome: Yup.string()
    .label('Nome')
    .required(),
  requerenteTelefone: Yup.string().label('Telefone'),
  requerenteCep: Yup.string()
    .label('CEP')
    .required(),
  requerenteLogradouro: Yup.string()
    .label('Endereço')
    .required(),
  requerenteNumero: Yup.string()
    .label('Número')
    .max(10)
    .required(),
  requerenteBairro: Yup.string()
    .label('Bairro')
    .required(),
  requerenteCidade: Yup.string()
    .label('Cidade')
    .required(),
  requerenteUF: Yup.string()
    .label('Estado')
    .required(),
  propriedades: Yup.array()
    .label('Propriedades')
    .min(1)
    .test(
      '',
      'Há propriedades com valor estimado menor ou igual a zero',
      function(propriedades) {
        return !propriedades.some(
          (propriedade: Laudo) => propriedade.valorEstimado <= 0
        );
      }
    )
});

const LaudoForm: React.FC<Props> = ({ afterSubmit, servidor = false }) => {
  const history = useHistory();
  const [dadosValores, setDadosValores] = useState<LoteLaudo>();
  const showNotification = useShowNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [permiteItbiRural, setPermiteItbiRural] = useState<boolean | undefined>(
    undefined
  );
  const [userTributos, setUserTributos] = useState<any>(undefined);
  const [cadastroGeralInicial, setCadastroGeralInicial] = useState<string>('');
  const [tipoImovelInicial, setTipoImovelInicial] = useState<string>('');
  const [openFirstPropriedadeForm, setOpenFirstPropriedadeForm] = useState<
    boolean
  >(false);

  const onChangeCpf = (event: React.ChangeEvent<any>) => {
    onChangeValueWithoutMask(event);
    const { value } = event.target;
    const valueSemMascara = value.replace(/\D/g, '');
    loadCpf(valueSemMascara);
  };

  const onChangeCep = (event: React.ChangeEvent<any>) => {
    onChangeValueWithoutMask(event);
    const valueSemMascara = event.target.value.replace(/\D/g, '');
    if (valueSemMascara.length === 8) {
      setLoading(true);
      CepService.buscaCep(valueSemMascara)
        .then(response => {
          const { logradouro, bairro, cidade, uf } = response.data;

          formRef.current!.setFieldValue(
            'requerenteLogradouro',
            logradouro,
            false
          );
          formRef.current!.setFieldValue('requerenteBairro', bairro, false);
          formRef.current!.setFieldValue('requerenteCidade', cidade, false);
          formRef.current!.setFieldValue('requerenteUF', uf, false);
          formRef.current!.setFieldValue(
            'requerenteCep',
            valueSemMascara,
            true
          );
        })
        .catch(error => {
          Alert.error(
            { title: 'Não foi possível encontrar o CEP informado' },
            error
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const findByCadastroImobiliarioComValorAvaliado = (cadastro: string) => {
    setLoading(true);
    ImobiliarioService.findByCadastroComValorAvaliado(cadastro)
      .then(response => {
        formRef.current!.setFieldValue(
          'propriedades',
          [
            {
              id: undefined,
              cadastro: response.data.cadastro,
              cadastroImobiliario: response.data,
              tipoImovel: TIPO_IMOVEL_URBANO,
              valorEstimado: response.data?.valorAvaliado!
            }
          ],
          true
        );
        setOpenFirstPropriedadeForm(true);
      })
      .finally(() => setLoading(false));
  };

  const findByCadastroRuralComValorAvaliado = (cadastro: string) => {
    setLoading(true);
    CadastroRuralService.findByCadastroComValorAvaliado(cadastro)
      .then(response => {
        formRef.current!.setFieldValue(
          'propriedades',
          [
            {
              id: undefined,
              cadastro: response.data?.cadastro,
              cadastroRural: response.data,
              tipoImovel: TIPO_IMOVEL_RURAL,
              valorEstimado: response.data?.valorAvaliado!
            }
          ],
          true
        );
        setOpenFirstPropriedadeForm(true);
      })
      .finally(() => setLoading(false));
  };

  const onChangeValueWithoutMask = (event: React.ChangeEvent<any>) => {
    const { value, name } = event.target;
    const newValue = value.replace(/\D/g, '');
    formRef.current!.setFieldValue(name, newValue);
  };

  const loadParametroPermiteItbiRural = () => {
    setLoading(true);
    ParametroService.loadAllParametros()
      .then(response => {
        setPermiteItbiRural(response.data.utilizaCadastroRural);
      })
      .catch(error =>
        Alert.error(
          { title: 'Não foi possível consultar os parâmetros para o laudo' },
          error
        )
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (permiteItbiRural && cadastroGeralInicial && tipoImovelInicial) {
      tipoImovelInicial === TIPO_IMOVEL_RURAL && permiteItbiRural
        ? findByCadastroRuralComValorAvaliado(cadastroGeralInicial)
        : findByCadastroImobiliarioComValorAvaliado(cadastroGeralInicial);
    }
  }, [permiteItbiRural, tipoImovelInicial, cadastroGeralInicial]);

  useEffect(() => {
    if (servidor) {
      loadParametroPermiteItbiRural();
      if (history.location?.search) {
        const searchParams = Object.fromEntries(
          new URLSearchParams(history.location.search)
        );
        formRef.current!.setFieldValue(
          'requerenteCpfCnpj',
          searchParams.cpfCnpj,
          true
        );
        formRef.current!.setFieldValue(
          'requerenteNome',
          searchParams.nome,
          true
        );
        formRef.current!.setFieldTouched('requerenteCpfCnpj', true, true);
        formRef.current!.setFieldTouched('requerenteNome', true, true);
        history.replace('/laudos/novo');
        loadCpf(searchParams.cpfCnpj);
        setCadastroGeralInicial(searchParams.cadastroGeral);
        setTipoImovelInicial(searchParams.tipoImovel);
      }
    }
  }, [servidor, history.location?.search]);

  const onSubmit = (laudo: FormValue) => {
    setLoading(true);
    LaudoService.save(laudo)
      .then(response => {
        gerarDebitos(response.data);
      })
      .catch(error => {
        setLoading(false);
        Alert.error({ title: 'Erro ao criar laudo de avaliação.' }, error);
      });
  };

  const gerarDebitos = (laudo: any) => {
    LaudoService.gerarDebitos(laudo.id)
      .then(response => {
        showNotification({
          level: 'success',
          message: 'Laudo de Avaliação gerado com sucesso'
        });
        afterSubmit(response.data);
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao criar laudo de avaliação.' }, error);
        afterSubmit(laudo);
      })
      .finally(() => setLoading(false));
  };

  const validateChangeStep = async (oldStep: any, newStep: any) => {
    if (oldStep.index >= newStep.index) {
      return true;
    }

    const errors = await formRef.current!.getFormikActions().validateForm();

    const isValid = ObjectUtils.isEmptyObject(errors);

    if (!isValid) {
      if (servidor) {
        formRef.current!.setFieldTouched('requerenteCpfCnpj', true, true);
        formRef.current!.setFieldTouched('requerenteNome', true, true);
        formRef.current!.setFieldTouched('requerenteTelefone', true, true);
        formRef.current!.setFieldTouched('requerenteCep', true, true);
        formRef.current!.setFieldTouched('requerenteLogradouro', true, true);
        formRef.current!.setFieldTouched('requerenteNumero', true, true);
        formRef.current!.setFieldTouched('requerenteBairro', true, true);
        formRef.current!.setFieldTouched('requerenteCidade', true, true);
        formRef.current!.setFieldTouched('requerenteUF', true, true);
      } else {
        formRef.current!.setFieldTouched('requerente.cpfCnpj', true, true);
      }
      formRef.current!.setFieldTouched('propriedades', true, true);
      return false;
    }

    setLoading(true);
    const { values } = formRef.current!.getFormikBag();
    return LaudoService.calcularValorLaudo(values)
      .then(response => {
        setDadosValores(response.data);
        return true;
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao calcular o valor do laudo' }, error);
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadCpf = (cpfCnpj: string) => {
    if (!validateCpfCnpj(cpfCnpj)) {
      return;
    }

    setLoading(true);
    UserService.getUsuarioTributosByCpf(cpfCnpj)
      .then(response => {
        setUserTributos(response.data);
        if (response.data) {
          formRef.current!.setFieldValue(
            'requerenteTipoPessoa',
            response.data.tipoPessoa,
            false
          );
          formRef.current!.setFieldValue(
            'requerenteCpfCnpj',
            response.data.cpfCnpj,
            false
          );
          formRef.current!.setFieldValue(
            'requerenteNome',
            response.data.nome,
            false
          );
          formRef.current!.setFieldValue(
            'requerenteEmail',
            response.data.email,
            false
          );
          formRef.current!.setFieldValue(
            'requerenteTelefone',
            response.data.telefone,
            false
          );
          formRef.current!.setFieldValue(
            'requerenteLogradouro',
            response.data.logradouro,
            false
          );
          formRef.current!.setFieldValue(
            'requerenteNumero',
            response.data.numero,
            false
          );
          formRef.current!.setFieldValue(
            'requerenteBairro',
            response.data.bairro,
            false
          );
          formRef.current!.setFieldValue(
            'requerenteCidade',
            response.data.cidade,
            false
          );
          formRef.current!.setFieldValue(
            'requerenteUF',
            response.data.uf,
            false
          );
          formRef.current!.setFieldValue(
            'requerenteCep',
            response.data.cep,
            true
          );
        } else {
          setUserTributos(false);
          formRef.current!.setFieldValue('requerenteTipoPessoa', 'F', false);
          formRef.current!.setFieldValue('requerenteCpfCnpj', cpfCnpj, false);
          formRef.current!.setFieldValue('requerenteNome', '', false);
          formRef.current!.setFieldValue('requerenteEmail', '', false);
          formRef.current!.setFieldValue('requerenteTelefone', '', false);
          formRef.current!.setFieldValue('requerenteLogradouro', '', false);
          formRef.current!.setFieldValue('requerenteNumero', '', false);
          formRef.current!.setFieldValue('requerenteBairro', '', false);
          formRef.current!.setFieldValue('requerenteCidade', '', false);
          formRef.current!.setFieldValue('requerenteUF', '', false);
          formRef.current!.setFieldValue('requerenteCep', '', true);
        }
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao buscar a pessoa' }, error);
        setUserTributos(false);
      })
      .finally(() => setLoading(false));
  };

  const validationSchema = servidor
    ? validationSchemaServidor
    : validationSchemaCidadao;
  const formRef = useRef<Formik<FormValue>>(null);

  const onSelectRequerenteCidadao = (
    requerente: Usuario,
    formProps: FormikProps<FormValue>
  ) => {
    formProps.setFieldValue('requerenteNome', requerente.nome);
    formProps.setFieldValue('requerenteTipoPessoa', requerente.tipoPessoa);
    formProps.setFieldValue('requerenteCpfCnpj', requerente.cpfCnpj);
    formProps.setFieldValue('requerenteEmail', requerente.email);
    formProps.setFieldValue('requerenteTelefone', requerente.telefone);
    formProps.setFieldValue('requerenteLogradouro', requerente.logradouro);
    formProps.setFieldValue('requerenteNumero', requerente.numero);
    formProps.setFieldValue('requerenteBairro', requerente.bairro);
    formProps.setFieldValue('requerenteCidade', requerente.cidade);
    formProps.setFieldValue('requerenteUF', requerente.uf);
    formProps.setFieldValue('requerenteCep', requerente.cep);
  };

  return (
    <Container title="Laudo de Avaliação" icon="paste">
      <Loading loading={loading} />
      <Formik<FormValue>
        ref={formRef}
        initialValues={buildInitialValue(servidor)}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {formProps => (
          <Wizard
            finishButtonOnlyOnLastStep
            allowInvalidChange={false}
            shouldChange={validateChangeStep}
            onFinish={formProps.submitForm}
          >
            <Wizard.Step
              stepId={'1'}
              label="Propriedades"
              icon="fa-building"
              valid={!hasVisibleErrors(formProps)}
              errorMessage={extractErrorText(
                formProps,
                Object.keys(validationSchema.describe().fields)
              )}
            >
              <>
                {servidor ? (
                  <SeletorRequerenteServidor
                    onChangeCpf={onChangeCpf}
                    onChangeValueWithoutMask={onChangeValueWithoutMask}
                    onChangeCep={onChangeCep}
                    userTributos={userTributos}
                  />
                ) : (
                  <SeletorRequerenteCidadao
                    onSelect={(value: Usuario) =>
                      onSelectRequerenteCidadao(value, formProps)
                    }
                  />
                )}
                <PropriedadeSection
                  openFirstPropriedadeForm={openFirstPropriedadeForm}
                  isOrigemServidor={servidor}
                  permiteItbiRural={permiteItbiRural}
                  propriedades={formProps.values.propriedades!}
                  onChange={(values: Laudo[]) =>
                    formProps.setFieldValue('propriedades', values, true)
                  }
                />
              </>
            </Wizard.Step>
            <Wizard.Step stepId="2" label="Pagamento" icon="fa-barcode">
              <>
                <LaudoPagamentoSection valores={dadosValores} />
                <LaudoPropriedadeResumo
                  propriedades={formProps.values.propriedades!}
                />
              </>
            </Wizard.Step>
          </Wizard>
        )}
      </Formik>
    </Container>
  );
};

export default LaudoForm;
