import {
  DisplayDataGrid,
  DisplayDataItem,
  FormattedCurrency,
  FormattedDate,
  Row
} from '@elotech/components';
import React from 'react';

import { ParcelamentoDTO } from '../../types';

type Props = {
  parcelamento?: ParcelamentoDTO;
};

const ParcelamentoViewResumo: React.FunctionComponent<Props> = ({
  parcelamento
}) => {
  if (!parcelamento) {
    return null;
  }
  return (
    <DisplayDataGrid>
      <Row>
        <DisplayDataItem title="Nº Contrato/Ano" sm={3}>
          {`${parcelamento.numeroContrato}/${parcelamento.anoContrato}`}
        </DisplayDataItem>
        <DisplayDataItem title="Tipo Cadastro" sm={3}>
          {parcelamento.tipoCadastro}
        </DisplayDataItem>
        <DisplayDataItem title="Cadastro Geral" sm={3}>
          {parcelamento.cadastroGeral}
        </DisplayDataItem>
        <DisplayDataItem title="Situação" sm={3}>
          {parcelamento.situacaoDescricao}
        </DisplayDataItem>
      </Row>
      <Row>
        <DisplayDataItem title="Nº de Parcelas" sm={3}>
          {parcelamento.numeroParcela}
        </DisplayDataItem>
        <DisplayDataItem title="Data Entrada" sm={3}>
          <FormattedDate value={parcelamento.dataEntrada} />
        </DisplayDataItem>
        <DisplayDataItem title="Valor Entrada" sm={3}>
          <FormattedCurrency value={parcelamento.valorEntrada} />
        </DisplayDataItem>
        <DisplayDataItem title="Data Parcelamento" sm={3}>
          <FormattedDate value={parcelamento.dataParcelamento} />
        </DisplayDataItem>
      </Row>
      <Row>
        <DisplayDataItem title="Inscrição Estadual" sm={3}>
          {parcelamento.requerente.inscricaoEstadual}
        </DisplayDataItem>
        <DisplayDataItem title="CPF/CNPJ" sm={3}>
          {parcelamento.requerente.cnpjCpf}
        </DisplayDataItem>
        <DisplayDataItem title="RG" sm={3}>
          {parcelamento.requerente.rg}
        </DisplayDataItem>
      </Row>
      <Row>
        <DisplayDataItem title="Nº Processo/Ano" sm={6}>
          {parcelamento.numeroProcessoPrazo &&
            `${parcelamento.numeroProcessoPrazo}/${parcelamento.anoProcessoPrazo}`}
        </DisplayDataItem>
        <DisplayDataItem title="Desc. Parametro" sm={6}>
          {parcelamento.descricaoParametroParcelamento}
        </DisplayDataItem>
      </Row>
      <Row>
        <DisplayDataItem title="Requerente" sm={6}>
          {parcelamento.requerente.id} - {parcelamento.requerente.nome}
        </DisplayDataItem>
        <DisplayDataItem title="Nome Fantasia" sm={6}>
          {parcelamento.requerente.nomeFantasia}
        </DisplayDataItem>
      </Row>
      <Row>
        <DisplayDataItem title="Atividade Principal" sm={12}>
          {parcelamento.requerente.atividadePrincipal}
        </DisplayDataItem>
      </Row>
      {parcelamento.situacaoDescricao === 'ESTORNADO' && (
        <Row>
          <DisplayDataItem title="Motivo" sm={12}>
            {parcelamento.observacao}
          </DisplayDataItem>
        </Row>
      )}
    </DisplayDataGrid>
  );
};

export default ParcelamentoViewResumo;
