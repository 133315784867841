import React from 'react';

type Props = {
  title: string;
  children: React.ReactNode;
  titleStyle?: React.CSSProperties;
};

const DisplayData: React.FunctionComponent<Props> = ({
  title,
  children,
  titleStyle,
  ...rest
}) => {
  return (
    <React.Fragment>
      <b className="dd-title" style={titleStyle}>
        {title}
      </b>
      <div {...rest} className="dd-content">
        {children}
      </div>
    </React.Fragment>
  );
};

export default DisplayData;
