import { FormikContext, FormikProps, connect } from 'formik';
import React from 'react';

type FormikEffectOnChange<T> = (
  newProps: FormikProps<T>,
  oldProps: FormikProps<T>
) => void;

type Props<T> = {
  onChange: FormikEffectOnChange<T>;
};

type PropsWithFormik<T> = Props<T> & { formik: FormikContext<T> };
class FormikEffect<T> extends React.Component<PropsWithFormik<T>, {}> {
  componentDidUpdate(
    prevProps: Readonly<PropsWithFormik<T>>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (this.props.formik !== prevProps.formik) {
      this.props.onChange(this.props.formik, prevProps.formik);
    }
  }

  render() {
    return null;
  }
}

const ConnectedComponent = connect<Props<any>, any>(FormikEffect);

export { ConnectedComponent as default, FormikEffect };
