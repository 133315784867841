import classNames from 'classnames';
import React from 'react';

type Props = {
  children: React.ReactNode;
  iconPosition?: 'left' | 'right';
  position?: 'left' | 'right';
  text?: boolean;
  color?: 'module-color' | 'positive' | 'negative' | 'neutral' | 'warning';
  type?: 'submit' | 'reset' | 'button';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FunctionComponent<Props> = ({
  className = '',
  children,
  iconPosition,
  position,
  type = 'button',
  color = 'module-color',
  text = false,
  ...rest
}) => {
  const classes = classNames({
    btn: true,
    [`icon-${iconPosition}`]: !!iconPosition,
    [position ?? '']: !!position,
    text: text,
    [color]: true
  });

  return (
    <button
      data-testid={rest.id ?? 'elo-button'}
      className={`${classes} ${className}`}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
