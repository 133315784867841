import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { NavLink, withRouter } from 'react-router-dom';

import { useHover } from '../hooks';
import { stripAccents } from '../utils/StringUtils';
import { Consumer } from './Sidebar';

export const canShow = (title, searchValue, visible) => {
  const normalizedTitle = stripAccents(title.toUpperCase());
  const normalizedSearchValue = stripAccents(searchValue.toUpperCase());

  return (
    (visible === undefined || !!visible) &&
    (searchValue === '' || normalizedTitle.includes(normalizedSearchValue))
  );
};

export const MenuItem = ({
  to,
  icon,
  title,
  count,
  searchValue = '',
  visible,
  setActiveMenu,
  setHover,
  setFocusedSearchInput,
  showBadge,
  target = '_self'
}) => {
  const [refHover, isHover] = useHover();
  const location = useLocation();

  useEffect(() => {
    setHover(isHover);
  }, [refHover.current, isHover]);

  const onClickMenuItem = () => {
    setActiveMenu('');
    setFocusedSearchInput(false);
  };

  const isMenuActive = () => {
    const [pathname] = (typeof to === 'object' ? to.pathname : to).split('?');
    return location.pathname === pathname;
  };

  const classes = classnames({
    on: isMenuActive()
  });

  if (canShow(title, searchValue, visible)) {
    return (
      <li className={classes} ref={refHover}>
        <NavLink
          to={to}
          onClick={onClickMenuItem}
          title={title}
          target={target}
        >
          <i className={icon} />
          {(showBadge || count) && <span className="count">{count}</span>}
          <b>{title}</b>
        </NavLink>
      </li>
    );
  }
  return null;
};

const SideBarConsumer = ({ ...props }) => (
  <Consumer>
    {({ searchValue, setHover, setFocusedSearchInput, setActiveMenu }) => (
      <MenuItem
        searchValue={searchValue}
        setHover={setHover}
        setFocusedSearchInput={setFocusedSearchInput}
        setActiveMenu={setActiveMenu}
        {...props}
      />
    )}
  </Consumer>
);

MenuItem.propTypes = {
  to: PropTypes.any,
  icon: PropTypes.any,
  title: PropTypes.string,
  count: PropTypes.number,
  location: PropTypes.object,
  visible: PropTypes.bool,
  showBadge: PropTypes.bool
};

export default withRouter(SideBarConsumer);
