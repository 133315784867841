import classNames from 'classnames';
import {
  addMonths,
  addYears,
  endOfMonth,
  getDate,
  getDay,
  getMonth,
  getYear,
  startOfMonth,
  subDays,
  subMonths,
  subYears
} from 'date-fns';

import { convertStringToDate } from '../utils/DateUtils';
import {
  DatePickerDateConfig,
  InternalDatePickerDateConfig
} from './DatePicker';
import { formatUtils } from '..';

export type ENUM_OPERACAO =
  | 'INCREMENTA_MES'
  | 'DECREMENTA_MES'
  | 'INCREMENTA_ANO'
  | 'DECREMENTA_ANO';

export const getNewArray = (size: number): number[] => {
  return [...Array.from(new Array(size), (x, i) => i + 1)];
};

export const decrementaAno = (data: Date) => {
  return subYears(data, 1);
};

export const decrementaMes = (data: Date) => {
  return subMonths(data, 1);
};

export const incrementaAno = (data: Date) => {
  return addYears(data, 1);
};

export const incrementaMes = (data: Date) => {
  return addMonths(data, 1);
};

export const getListaDiasConfig = (novaData: Date): DatePickerDateConfig[] => {
  return getNewArray(getDate(endOfMonth(novaData))).map(item => ({
    data: new Date(getYear(novaData), getMonth(novaData), item),
    marked: false
  }));
};

export const getListaLimpa = (listaDias: InternalDatePickerDateConfig[]) => {
  return listaDias.map(item => ({
    ...item,
    marked: false,
    dragging: false,
    interval: false,
    initial: false,
    final: false
  }));
};

export const getDiasCalendario = (
  data: Date,
  listaDiasConfig: DatePickerDateConfig[] = []
) => {
  const listaDiasInicial: InternalDatePickerDateConfig[] = getListaDiasIniciais(
    data
  );

  const listaDiasMesAtual: InternalDatePickerDateConfig[] = getListaDiasMesAtual(
    data,
    listaDiasConfig
  );

  const listaDiasFinais: InternalDatePickerDateConfig[] = getListaDiasFimMes(
    data,
    listaDiasInicial.length,
    listaDiasMesAtual.length
  );

  return [...listaDiasInicial, ...listaDiasMesAtual, ...listaDiasFinais];
};

export const getClassName = (config: InternalDatePickerDateConfig) => {
  return classNames({
    negative: !config.marked && config.option && config.option!.id === 'red',
    selected: config.marked,
    dragging: config.dragging,
    interval: config.interval,
    initial: config.initial,
    final: config.final,
    neutral: !config.marked && config.option && config.option!.id === 'black',
    ['not-this-month blocked']: !config.available
  });
};

export const getClickCalendarDateList = (
  item: InternalDatePickerDateConfig,
  lista: InternalDatePickerDateConfig[]
): InternalDatePickerDateConfig[] => {
  const canSelect = item.initial || item.final || item.interval;

  return canSelect ? getListaLimpa(lista) : lista;
};

export const getConfigDiaSelecionado = (
  data: string | undefined,
  listaDias: InternalDatePickerDateConfig[]
): InternalDatePickerDateConfig | undefined => {
  if (data) {
    const dataConvertida = convertStringToDate(data);

    return listaDias.find(
      item =>
        item.available &&
        !!dataConvertida &&
        item.data.getTime() === dataConvertida.getTime() &&
        (!item.option || item.option!.canSelect)
    );
  }
  return undefined;
};

const getDiaSemanaInicioMes = (dataReferencia: Date) => {
  const ultimoDiaMesAnterior = subDays(startOfMonth(dataReferencia), 1);
  return getDay(ultimoDiaMesAnterior) + 1;
};

const getUltimoDiaMesAnterior = (dataReferencia: Date) => {
  const ultimoDiaMesAnterior = subDays(startOfMonth(dataReferencia), 1);
  return getDate(ultimoDiaMesAnterior);
};

const getListaDiasIniciais = (data: Date) => {
  const listaDias: number[] =
    getDay(startOfMonth(data)) === 0
      ? []
      : [
          ...Array.from(
            new Array(getDiaSemanaInicioMes(data)),
            (x, i) => getUltimoDiaMesAnterior(data) - i
          ).reverse()
        ];

  const mesAnterior = getMonth(subDays(startOfMonth(data), 1));

  return listaDias.map(item => ({
    data: new Date(getYear(data), mesAnterior, item),
    available: false
  }));
};

const getDiasCalendarioRender = (
  listaDias: InternalDatePickerDateConfig[],
  listaDiasConfig: DatePickerDateConfig[]
) => {
  let diasCalendario = listaDias;

  listaDiasConfig!.forEach(config => {
    const index = diasCalendario.findIndex(
      item =>
        formatUtils.getFormattedUTCDateWithZeroTime(new Date(item.data)) ===
        formatUtils.getFormattedUTCDateWithZeroTime(config.data)
    );

    if (index >= 0) {
      diasCalendario = [
        ...diasCalendario.slice(0, index),
        {
          ...diasCalendario[index],
          available: true,
          data: config.data,
          option: config.option
        },
        ...diasCalendario.slice(index + 1)
      ];
    }
  });

  return diasCalendario;
};

const getListaDiasMesAtual = (
  dataAtual: Date,
  listaDiasConfig: DatePickerDateConfig[]
) => {
  const listaDias: number[] = getNewArray(getDate(endOfMonth(dataAtual)));

  const listaDiasMesAtual = listaDias.map(item => {
    return {
      data: new Date(getYear(dataAtual), getMonth(dataAtual), item),
      available: true
    };
  });

  return getDiasCalendarioRender(listaDiasMesAtual, listaDiasConfig);
};

const getListaDiasFimMes = (
  data: Date,
  tamanhoListaInicial: number,
  tamanhoListaFinal: number
) => {
  const listaDias: number[] = getNewArray(
    42 - (tamanhoListaInicial + tamanhoListaFinal)
  );
  const mesPosterior = getMonth(addMonths(startOfMonth(data), 1));

  return listaDias.map(item => ({
    data: new Date(getYear(data), mesPosterior, item),
    available: false
  }));
};
