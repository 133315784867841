import PropTypes from 'prop-types';
import React from 'react';

import { Col } from '../grid';

const TableReportCol = props => {
  const { children, header, ...rest } = props;
  return (
    <Col {...rest} className="group-column" data-header={header}>
      <p>{children}</p>
    </Col>
  );
};

TableReportCol.propTypes = {
  header: PropTypes.string
};

export default TableReportCol;
