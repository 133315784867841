import { FormikProps } from 'formik';
import React, { ChangeEvent } from 'react';
import MaskedInput from 'react-text-mask';

import BasicInput, { Props as BasicInputProps } from './BasicInput';
import { Masks } from '..';

type Props = BasicInputProps & { valueWithMask?: boolean };

const getMaskCpfCnpjByLength = (value: string) => {
  return value.replace(/\D/g, '').length <= 11
    ? Masks.MASK_CPF
    : Masks.MASK_CNPJ;
};

const FormikInputCpfCnpj: React.FC<Props> = props => {
  const propsOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    return props.onChange && props.onChange(event);
  };

  const formikValueWithoutMask = (
    form: FormikProps<any>,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    propsOnChange(event);
    form.setFieldValue(props.name, event.target.value.replace(/\D/g, ''));
  };

  return (
    <BasicInput
      type="text"
      size={props.size}
      name={props.name}
      label={props.label}
      loading={props.loading}
      disabled={props.disabled}
      render={({ field, form }) => (
        <MaskedInput
          {...field}
          mask={getMaskCpfCnpjByLength}
          id={field.name}
          onChange={event =>
            !props.valueWithMask
              ? formikValueWithoutMask(form, event)
              : propsOnChange(event) || field.onChange(event)
          }
          className={`${field.className} ${props.loading ? 'loading' : ''}`}
          value={!field.value ? '' : field.value}
        />
      )}
    />
  );
};

export default FormikInputCpfCnpj;
