import {
  FormattedCurrency,
  FormattedDate,
  Widget,
  WidgetDisplay
} from '@elotech/components';
import { FunctionUtils } from 'itbi-common/utils';
import React from 'react';

import { DocumentoAutenticado } from '../../types';

type Props = {
  documento: DocumentoAutenticado;
};

const InformacaoBoleto: React.FC<Props> = ({ documento }) => {
  return (
    <Widget title="Informações do Documento" icon="fab fa-wpforms">
      <article>
        <WidgetDisplay
          label="Tipo do documento"
          content={documento.descricao}
          hideHorizontalRule
        />
        <WidgetDisplay label="Nosso Número" content={documento.documento} />
        <WidgetDisplay
          label="Código de Barras"
          content={documento.codigoBarras}
        />
        {documento.dataEmissao && (
          <WidgetDisplay
            label="Data de emissão"
            content={
              <FormattedDate value={documento.dataEmissao} timeZone={'UTC'} />
            }
          />
        )}
        {documento.dataValidade && (
          <WidgetDisplay
            label="Data de vencimento"
            content={
              <FormattedDate value={documento.dataValidade} timeZone={'UTC'} />
            }
          />
        )}
        {documento.finalidade && (
          <WidgetDisplay label="Finalidade" content={documento.finalidade} />
        )}
        {documento.subTipo && (
          <WidgetDisplay
            label="Situação"
            content={FunctionUtils.getSubTipoDocumento(documento.subTipo)}
          />
        )}
        {documento.valorBloqueto && (
          <WidgetDisplay
            label="Valor"
            content={<FormattedCurrency value={documento.valorBloqueto} />}
          />
        )}
      </article>
    </Widget>
  );
};

export default InformacaoBoleto;
