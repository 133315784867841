import { FormikContext, connect } from 'formik';
import React, { useEffect } from 'react';
import { PromptProps } from 'react-router';
import { Prompt } from 'react-router-dom';

type Props = {
  message?: PromptProps['message'];
};

type PropsWithFormik = Props & { formik: FormikContext<any> };

const FormikBlockRoute: React.FC<PropsWithFormik> = ({
  formik,
  message = 'É possível que as alterações feitas não sejam salvas.'
}) => {
  const { dirty } = formik;
  useEffect(() => {
    const listener = (event: BeforeUnloadEvent) => {
      if (dirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', listener);

    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [dirty]);

  return <Prompt when={dirty} message={message}></Prompt>;
};

const ConnectedComponent = connect<Props, any>(FormikBlockRoute);

export { ConnectedComponent as default, FormikBlockRoute };
