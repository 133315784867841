import { Container } from '@elotech/components';
import { MenuItbiService } from 'itbi-common/service';
import { MenuCidadao } from 'itbi-common/type';
import permissaoCidadaoKeys from 'itbi-common/utils/Permissoes';
import React, { useEffect, useState } from 'react';

import { TipoDocumentoEnum, getTipoDocumentoByCodigo } from '../../enums';
import CertidaoNegativaDebitoForm from './CertidaoNegativaDebitoForm';
import DocumentoGenericoForm from './DocumentoGenericoForm';

type Props = {};

const EmissaoCertidaoFormPage: React.FC<Props> = () => {
  const [certidao, setCertidao] = useState('');
  const [certidoes, setCertidoes] = useState<MenuCidadao[]>([]);

  useEffect(() => {
    MenuItbiService.getMenuItbiAll().then(response => {
      const menu: MenuCidadao[] = response.data;
      setCertidoes(
        menu.filter(
          value =>
            value.ativo &&
            (value.key === permissaoCidadaoKeys.certidaoNegativaDebito ||
              value.key === permissaoCidadaoKeys.certidaoComprobatoria ||
              value.key === permissaoCidadaoKeys.certidaoNumeracao)
        )
      );
    });
  }, []);

  return (
    <Container title="Emissão de Certidões" icon="id-card">
      <label className="label" htmlFor="certidao">
        Tipo de Emissão de Certidão
      </label>
      <select
        id="certidao"
        name="certidao"
        value={certidao}
        onChange={event => setCertidao(event.target.value)}
        style={{ marginBottom: 20 }}
      >
        <option>Selecione</option>
        {certidoes.map((option, index) => (
          <option data-testid={option.key} key={index} value={option.key}>
            {option.funcionalidade}
          </option>
        ))}
      </select>
      {certidao === permissaoCidadaoKeys.certidaoNegativaDebito && (
        <CertidaoNegativaDebitoForm />
      )}
      {certidao === permissaoCidadaoKeys.certidaoComprobatoria && (
        <DocumentoGenericoForm
          title={
            getTipoDocumentoByCodigo(TipoDocumentoEnum.certidaoComprobatoria)
              .title
          }
          tipoDocumento={
            getTipoDocumentoByCodigo(TipoDocumentoEnum.certidaoComprobatoria)
              .codigo
          }
        />
      )}
      {certidao === permissaoCidadaoKeys.certidaoNumeracao && (
        <DocumentoGenericoForm
          title={
            getTipoDocumentoByCodigo(TipoDocumentoEnum.certidaoNumeracao).title
          }
          tipoDocumento={
            getTipoDocumentoByCodigo(TipoDocumentoEnum.certidaoNumeracao).codigo
          }
        />
      )}
    </Container>
  );
};

export default EmissaoCertidaoFormPage;
