//@ts-ignore
import steps from '@elotech/arc/src/assets/components/onboarding/oxy.js';
import React, { useContext, useEffect } from 'react';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';

import { useAuth } from '../hooks';
import AuthService from '../service/AuthService';

type Props = {
  children: React.ReactNode;
  shouldStartTour?: boolean;
};

const OnboardingConsumer: React.FC<Props> = ({ children }) => {
  const tour = useContext(ShepherdTourContext);
  const { userProfile } = useAuth();
  const primeiroAcesso =
    userProfile?.attributes?.primeiro_acesso?.[0] ?? 'TRUE';

  const updateUserAttributes = () => {
    const attributes = {
      ...userProfile.attributes,
      primeiro_acesso: ['FALSE']
    };

    return AuthService.updateUserAttributes(attributes);
  };

  useEffect(() => {
    if (primeiroAcesso === 'TRUE') {
      updateUserAttributes();
      tour?.start();
    }
  }, []);

  return <>{children}</>;
};

const Onboarding: React.FC<Props> = ({ children, shouldStartTour = true }) => {
  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true
      }
    },
    exitOnEsc: true,
    useModalOverlay: true
  };

  return shouldStartTour ? (
    <ShepherdTour steps={steps} tourOptions={tourOptions}>
      <OnboardingConsumer>{children}</OnboardingConsumer>
    </ShepherdTour>
  ) : (
    <>{children}</>
  );
};

export default Onboarding;
