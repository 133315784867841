import PropTypes from 'prop-types';
import React from 'react';

const Checkbox = props => {
  const { hintClass, hintText, blocked, ...otherProps } = props;
  return (
    <div className={`checkbox-container ${props.className}`}>
      {blocked ? (
        <em className="fa fa-ban" title={props.title} />
      ) : (
        <input
          id={props.id}
          type="checkbox"
          disabled={props.disabled}
          checked={props.checked}
          onChange={props.onChange}
          name={props.name}
          {...otherProps}
          className={`checkbox-item-input input-checkbox ${props.className}`}
        />
      )}
      <label htmlFor={props.id} title={props.title}>
        {props.label}
      </label>
      {hintText && (
        <div className={`hint ${hintClass}`}>
          <div className="hint-content">{hintText}</div>
        </div>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.any,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  hintClass: PropTypes.string,
  hintText: PropTypes.string,
  blocked: PropTypes.bool,
  title: PropTypes.string
};

Checkbox.defaultProps = {
  label: '',
  className: '',
  disabled: false,
  checked: false,
  hintClass: 'inline module-color fa-question-circle sm clean'
};

export default Checkbox;
