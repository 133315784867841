import { useContext } from 'react';

import {
  SidebarToggleContext,
  SidebarToggleContextType
} from '../sidebar/SidebarToggle';

export default function useFullScreen(): [boolean, () => void] {
  const { fullScreen, toggleFullScreen }: SidebarToggleContextType = useContext(
    SidebarToggleContext
  );

  return [fullScreen, toggleFullScreen];
}
