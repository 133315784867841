import imgModuleNewTheme from '@elotech/arc/src/assets/img/modules/oxy-arrecadacao.svg';
import { Sidebar, withAuth } from '@elotech/components';
import imgModule from '@elotech/components/assets/img/modules/arrecadacao-portalcontribuinte.png';
import imgModuleMain from '@elotech/components/assets/img/modules/arrecadacao-small.png';
import { MenuItbiService } from 'itbi-common/service';
import { withService } from 'itbi-common/service';
import { permissaoCidadaoKeys } from 'itbi-common/utils/Permissoes';
import PropTypes from 'prop-types';
import React from 'react';

class AppSideBar extends React.Component {
  static propTypes = {
    toggleSideBar: PropTypes.func,
    toggledSidebar: PropTypes.bool,
    showMenu: PropTypes.bool,
    auth: PropTypes.shape({
      user: PropTypes.object.isRequired
    }).isRequired
  };

  state = {
    menuAtivo: []
  };

  canShowMenu = key => {
    const menu = this.state.menuAtivo.find(m => m.key === key);
    return menu === undefined ? false : menu.ativo;
  };

  componentDidMount() {
    this.props.menuItbiService.getMenuItbiAll().then(response => {
      this.setState({ menuAtivo: response.data });
    });
  }

  render() {
    return this.props.showMenu ? (
      <Sidebar
        title="Atendimento Cidadão"
        imgModuleMain={imgModuleMain}
        imgModule={imgModule}
        imgModuleNewTheme={imgModuleNewTheme}
      >
        <Sidebar.Item title="Início" to="/" icon="fa fa-home" />

        <Sidebar.Item
          title="Cadastros"
          to="/propriedades"
          icon="fa fa-building"
          visible={this.canShowMenu(permissaoCidadaoKeys.propriedades)}
        />

        <Sidebar.Item
          title="Carnê"
          to="/carne"
          icon="fa fa-building"
          visible={this.canShowMenu(permissaoCidadaoKeys.propriedades)}
        />

        <Sidebar.Group title="ITBI" icon="fa fa-paste">
          <Sidebar.Item
            title="Declaração ITBI"
            to="/declaracoes-itbi"
            icon="fa fa-file-signature"
            visible={this.canShowMenu(permissaoCidadaoKeys.declaracaoItbi)}
          />

          <Sidebar.Item
            title="Laudos"
            to="/laudos"
            icon="fa fa-paste"
            visible={this.canShowMenu(permissaoCidadaoKeys.laudo)}
          />
        </Sidebar.Group>

        <Sidebar.Item
          title="Parcelamentos"
          to="/parcelamentos"
          icon="fa fa-file-contract"
          visible={this.canShowMenu(permissaoCidadaoKeys.parcelamento)}
        />

        <Sidebar.Item
          title="Emissão de Certidões"
          to="/emissao-certidoes"
          icon="fa fa-id-card"
          visible={
            this.canShowMenu(permissaoCidadaoKeys.certidaoNegativaDebito) ||
            this.canShowMenu(permissaoCidadaoKeys.certidaoComprobatoria) ||
            this.canShowMenu(permissaoCidadaoKeys.certidaoNumeracao)
          }
        />

        <Sidebar.Group title="Documentos" icon="fa fa-file-alt">
          <Sidebar.Item
            title="Autenticar Documento"
            to="/autenticar-documento"
            icon="fa fa-check-circle"
            visible={this.canShowMenu(permissaoCidadaoKeys.autenticarDocumento)}
          />
          <Sidebar.Item
            title="Documentos Emitidos"
            to="/documentos-emitidos"
            icon="fa fa-file-alt"
            visible={this.canShowMenu(permissaoCidadaoKeys.documentoEmitido)}
          />
        </Sidebar.Group>
        {this.props.auth?.user?.tipoPessoa === 'FISICA' && (
          <Sidebar.Item
            title="Nota PG"
            to="/concurso-premiado"
            icon="fa fa-trophy"
            visible={this.canShowMenu(permissaoCidadaoKeys.concursoPremiado)}
          />
        )}
        <Sidebar.Item
          to="/processos"
          icon="fa fa-list"
          title="Processos"
          visible={this.canShowMenu(permissaoCidadaoKeys.processos)}
        />
      </Sidebar>
    ) : null;
  }
}

const ComponentWithService = withService({
  menuItbiService: MenuItbiService
})(AppSideBar);

const ComponentWithAuth = withAuth(ComponentWithService);

export default ComponentWithAuth;
