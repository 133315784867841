import { ReactComponent as Logo } from '@elotech/arc/src/assets/img/modules/oxy-arrecadacao.svg';
import { ApplicationContext, Header } from '@elotech/components';
import { MenuItbiService, withService } from 'itbi-common/service';
import { permissaoCidadaoKeys } from 'itbi-common/utils/Permissoes';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export class AppHeader extends React.Component {
  static contextType = ApplicationContext;

  state = {
    menuAtivo: []
  };

  canShowMenu = key => {
    const menu = this.state.menuAtivo.find(m => m.key === key);

    return menu === undefined ? false : menu.ativo;
  };

  componentDidMount() {
    this.props.menuItbiService.getMenuItbiAll().then(response => {
      this.setState({ menuAtivo: response.data });
    });
  }

  onMyAccount = () => {
    this.props.history.push('/minhaconta');
  };

  onView = notification => {
    if (notification.metadata) {
      const { tipoPedido, idPedido } = notification.metadata;

      if (tipoPedido === 'ITBI') {
        this.props.history.replace(`/declaracoes-itbi/${idPedido}/resumo`);
      } else if (tipoPedido === 'LAUDO') {
        this.props.history.replace(`/laudos/${idPedido}`);
      }
    }
  };

  onShowView = notification => {
    if (notification.metadata) {
      const { tipoPedido } = notification.metadata;
      return tipoPedido === 'ITBI' || tipoPedido === 'LAUDO';
    }
    return false;
  };

  onContactUs = () => {
    this.props.history.push('/faleconosco');
  };

  onClickFaq = () => {
    this.props.history.push('/faq');
  };

  openNovidadesVersao = () => {
    window.open(
      'https://elotech.movidesk.com/kb/category/oxy-atendimento-cidadao',
      '_blank'
    );
  };
  render() {
    const { showMenu, idCidadeGateway } = this.props;
    const { entidade } = this.context;
    const showFaleConosco = this.canShowMenu(permissaoCidadaoKeys.faleConosco);
    const showFaq = this.canShowMenu(permissaoCidadaoKeys.faq);

    return (
      <Header
        title={entidade?.nome ?? ''}
        showToggleButton={showMenu}
        logoModulo={Logo}
        idCidadeGateway={idCidadeGateway}
        brasao={entidade?.brasao}
        permiteTrocarEntidade={false}
      >
        <Header.Features>
          <Header.Features.AccessibilityButton />
          <Header.Features.NotificationButton />
          <Header.Features.AjustesButton />
        </Header.Features>
        <Header.FeaturesContent>
          <Header.FeaturesContent.AccessibilityContent />
          <Header.FeaturesContent.NotificationContent
            onView={this.onView}
            onShowView={this.onShowView}
          />
          <Header.FeaturesContent.AjustesContent
            onMyAccount={this.onMyAccount}
            onContactUs={showFaleConosco ? this.onContactUs : undefined}
            onClickFaq={showFaq ? this.onClickFaq : undefined}
            showNovidadesVersao
            onClickNovidadesVersao={this.openNovidadesVersao}
          />
        </Header.FeaturesContent>
      </Header>
    );
  }
}

AppHeader.propTypes = {
  showMenu: PropTypes.bool
};

const mapStateToProps = state => ({
  cidadeLogada: state.cidade.cidadeLogada,
  idCidadeGateway: state.cidade.idCidadeGateway
});

const connectedComponent = connect(mapStateToProps)(AppHeader);

const AppHeaderWithService = withService({
  menuItbiService: MenuItbiService
})(connectedComponent);

export default withRouter(AppHeaderWithService);
