import React from 'react';

import useAuth, { UseAuthData } from '../hooks/useAuth';

type WithAuthProps = {
  auth: UseAuthData;
};

const withAuth = <P extends WithAuthProps>(
  Component: React.ComponentType<P>
) => {
  const name = Component.displayName || Component.name || 'Component';
  function WrappedComponent(props: P) {
    const auth = useAuth();
    return <Component auth={auth} {...props} />;
  }

  WrappedComponent.displayName = `withAuth(${name})`;
  return WrappedComponent;
};

export default withAuth;
