import PropTypes from 'prop-types';
import React from 'react';

import DadosSectionForm from './DadosSectionForm';
import EnderecoSectionForm from './EnderecoSectionForm';
import VinculoSectionForm from './VinculoSectionForm';

const exigeRg = (tipoProprietario, tipoPessoa) =>
  tipoProprietario === 'comprador' && tipoPessoa === 'FISICA';

class Form extends React.Component {
  static propTypes = {
    cancelar: PropTypes.func.isRequired,
    vinculos: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    tipoProprietario: PropTypes.oneOf(['comprador', 'vendedor', 'anuente']),
    cepService: PropTypes.shape({
      buscaCep: PropTypes.func.isRequired
    }).isRequired,
    values: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired
  };

  classError = name => {
    const { errors, touched } = this.props;

    return errors[name] && touched[name] && 'error';
  };

  onCancel = () => {
    const { cancelar, resetForm } = this.props;
    cancelar();
    resetForm();
  };

  render() {
    const {
      title,
      vinculos,
      values,
      errors,
      touched,
      setFieldValue,
      setValues,
      tipoProprietario,
      cepService,
      handleSubmit,
      isServidor
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <DadosSectionForm
          title={title}
          classError={this.classError}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          exigeRg={exigeRg(tipoProprietario, values.tipoPessoa)}
          isServidor={isServidor}
        />
        <div className="mt-xs" />
        <EnderecoSectionForm
          title={title}
          classError={this.classError}
          errors={errors}
          touched={touched}
          cepService={cepService}
          setFieldValue={setFieldValue}
          setValues={setValues}
          values={values}
        />
        <div className="mt-xs" />
        <VinculoSectionForm
          title={title}
          vinculos={vinculos}
          tipoProprietario={tipoProprietario}
          setFieldValue={setFieldValue}
          classError={this.classError}
          isServidor={isServidor}
        />
        <button
          data-test-id="buttonAdicionar"
          data-cy="buttonSalvar"
          className="btn positive inline mt-xs"
          type="submit"
        >
          salvar
        </button>
        <button
          data-test-id="buttonCancelar"
          data-cy="buttonCancelar"
          className="btn negative inline mt-xs"
          onClick={this.onCancel}
        >
          cancelar
        </button>
      </form>
    );
  }
}

export { Form as default, exigeRg };
