import React from 'react';

import AccessibilityButton from './AccessibilityButton';
import AjustesButton from './AjustesButton';
import ModulosButton from './ModulosButton';
import NotificationButton from './NotificationButton';

export const FEATURE_ACESSIBILIDADE = 'acessibilidade';
export const FEATURE_NOTIFICATION = 'notification';
export const FEATURE_AJUSTES = 'ajustes';
export const FEATURE_AJUSTES__SOBRE_SISTEMA = 'ajustes.sobre-sistema';
export const FEATURE_MODULOS = 'modulos';
export const FEATURE_ENTIDADE = 'entidade';

interface StaticComponents {
  AccessibilityButton: typeof AccessibilityButton;
  AjustesButton: typeof AjustesButton;
  NotificationButton: typeof NotificationButton;
  ModulosButton: typeof ModulosButton;
}

export type FeaturesProps = {
  children: React.ReactNode;
};

const Features: React.FC<FeaturesProps> & StaticComponents = ({ children }) => (
  <ul className="header-features">{children}</ul>
);
Features.AccessibilityButton = AccessibilityButton;
Features.ModulosButton = ModulosButton;
Features.AjustesButton = AjustesButton;
Features.NotificationButton = NotificationButton;

export default Features;
