import {
  BasicInput,
  FormikInputNumber,
  Row,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

class VinculoSectionForm extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    vinculos: PropTypes.array.isRequired,
    tipoProprietario: PropTypes.oneOf(['comprador', 'vendedor', 'anuente']),
    setFieldValue: PropTypes.func.isRequired,
    classError: PropTypes.func.isRequired,
    isServidor: PropTypes.bool.isRequired
  };

  onChangePercentual = ({ floatValue }, event) => {
    this.props.setFieldValue(event.target.name, floatValue);
  };

  onChangeInputValueVinculo = ({ target }) => {
    const { vinculos, setFieldValue } = this.props;
    const vinculoSelected = vinculos.find(
      vinculo => vinculo.id === target.value
    );

    setFieldValue(target.name, vinculoSelected);
  };

  getPercentualLabel = () => {
    switch (this.props.tipoProprietario) {
      case 'comprador':
        return 'Percentual de Compra';
      case 'vendedor':
        return 'Percentual de Propriedade';
      default:
        return 'Percentual';
    }
  };

  render() {
    const {
      title,
      vinculos,
      tipoProprietario,
      classError,
      isServidor
    } = this.props;

    return (
      <React.Fragment>
        <SectionTitle>Vínculo do {title}</SectionTitle>
        <Row>
          {isServidor && (
            <BasicInput
              name="vinculo"
              label="Vínculo"
              size={4}
              render={({ field }) => (
                <select
                  {...field}
                  data-testid="select-vinculo"
                  className={classError('vinculo')}
                  onChange={this.onChangeInputValueVinculo}
                  value={field.value && field.value.id}
                >
                  <option value="">Selecione</option>
                  {vinculos.map(vinculo => (
                    <option key={vinculo.id} value={vinculo.id}>
                      {vinculo.descricao}
                    </option>
                  ))}
                </select>
              )}
            />
          )}

          <FormikInputNumber
            name="percentual"
            label={this.getPercentualLabel()}
            size={4}
            decimalScale={2}
            allowNegative={false}
            onValueChange={this.onChangePercentual}
            maxLength="6"
            className={classError('percentual')}
          />
          {tipoProprietario === 'vendedor' && (
            <FormikInputNumber
              name="percentualVenda"
              label="Percentual de Venda"
              size={4}
              decimalSeparator=","
              decimalScale={2}
              allowNegative={false}
              onValueChange={this.onChangePercentual}
              maxLength="6"
              className={classError('percentualVenda')}
            />
          )}
        </Row>
      </React.Fragment>
    );
  }
}

export default VinculoSectionForm;
