const extractErroBlob = (error: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    try {
      reader.readAsText(error);
    } catch (error) {
      reject(error);
    }

    reader.onload = () => {
      resolve(JSON.parse(reader.result as any));
    };
    reader.onerror = () => {
      reader.abort();
      reject('Não foi possível extrair a mensagem de erro');
    };
  });
};

export const getFormatedMessageViolations = (violations: any) => {
  return violations && violations.length > 0
    ? violations
        .filter((element: any) => element)
        .map((violation: any) => {
          const field = violation.field ?? '';
          const message = violation.message ?? '';
          return field + ' ' + message;
        })
        .join('<br>')
    : '';
};

export const getErrorMessage = async (error: any) => {
  let errorMessage = !!error.message ? error.message : error;

  if (error.response && error.response.data) {
    const messageViolations = getFormatedMessageViolations(
      error.response.data.violations
    );
    errorMessage =
      messageViolations ||
      error.response.data.message ||
      error.response.data.detail ||
      error.response.data.error ||
      errorMessage;

    if (error.response.data instanceof Blob) {
      await extractErroBlob(error.response.data)
        .then((result: any) => {
          if (result && result.message) {
            errorMessage = result.message;
          }
        })
        .catch(e => console.log(e));
    }
  }
  return errorMessage;
};
