import classnames from 'classnames';
import React, { useContext } from 'react';

import Icon from '../icons/Icon';
import { HeaderContext } from './Header';

const AccessibilityButton: React.FC = () => {
  const headerContext = useContext(HeaderContext);
  const className = classnames({
    active: headerContext.activeAcessibilidade
  });

  return (
    <li
      className={className}
      title="Acessibilidade"
      onClick={headerContext.toggleAcessibilidade}
    >
      <Icon icon="wheelchair" className={'icon'} />
      <small className="hidden-xs">Acessibilidade</small>
    </li>
  );
};

export default AccessibilityButton;
