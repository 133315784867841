import axios, { AxiosPromise } from 'axios';
import { CadastroGeralAcessoWebDTO } from 'itbi-cidadao/src/types/CadastroGeralAcessoWebDTO';

export const getCadastroGeral = (
  tipoCadastro: number,
  cadastroGeral: number
): AxiosPromise<CadastroGeralAcessoWebDTO> =>
  axios.get(
    `/api/cadastro-geral?tipo-cadastro=${tipoCadastro}&cadastro-geral=${cadastroGeral}`
  );

export const isInadimplente = (
  tipoCadastro: number,
  cadastroGeral: number
): AxiosPromise<Boolean> =>
  axios.get(
    `/api/cadastro-geral/is-inadimplente?tipo-cadastro=${tipoCadastro}&cadastro-geral=${cadastroGeral}`
  );
