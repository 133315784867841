import { PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CuponsParticipanteFilter } from '../../itbi-cidadao/src/pages/concurso-premiado/ConcursoPremiadoCuponsPage';
import { ConcursoPremiadoResumoPorCpf, CuponsParticipante } from '../type';

const ENDPOINT_CONCURSOS_PREMIADOS = 'concursos-premiados';

export const getConcursoByCpf = (
  tenant: string,
  cpf: string,
  status: boolean
): AxiosPromise<ConcursoPremiadoResumoPorCpf> => {
  return axios.get(
    `/gateway/${ENDPOINT_CONCURSOS_PREMIADOS}/${cpf}/${
      status ? 'status' : 'resumo'
    }`,
    {
      headers: { cidade: tenant }
    }
  );
};

export const aderirConcurso = (
  tenant: string,
  idConcurso: number,
  idUsuarioCidadao: number
) => {
  return axios.post(
    `/gateway/${ENDPOINT_CONCURSOS_PREMIADOS}/${idConcurso}/aderir/${idUsuarioCidadao}`,
    undefined,
    {
      headers: { cidade: tenant }
    }
  );
};

export const downloadRegulamento = (
  tenant: string,
  idConcurso: number
): AxiosPromise<any> =>
  axios.get(
    `/gateway/${ENDPOINT_CONCURSOS_PREMIADOS}/${idConcurso}/regulamento`,
    {
      responseType: 'blob',
      headers: { cidade: tenant }
    }
  );

export const getCupons = (
  search: CuponsParticipanteFilter,
  page?: object
): AxiosPromise<PagedResponse<CuponsParticipante>> => {
  return axios.get(
    `/gateway/${ENDPOINT_CONCURSOS_PREMIADOS}/${search.idConcurso}/cupons/${search.cpf}`,
    {
      params: { somenteSorteados: search.somenteSorteados, ...page },
      headers: { cidade: search.cidade }
    }
  );
};
