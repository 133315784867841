import PropTypes from 'prop-types';
import React from 'react';

const HistoryTable = ({ children }) => {
  return (
    <div className="panel table table-responsive">
      <div className="panel-body">
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <thead>
              <tr>
                <th>Data</th>
                <th>Responsável</th>
                <th>Tipo de Alteração</th>
                <th />
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

HistoryTable.propTypes = {
  children: PropTypes.node.isRequired
};

export default HistoryTable;
