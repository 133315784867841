import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  FAB,
  Field,
  FormattedDate,
  Panel,
  SearchFilter,
  SearchPagination,
  Table,
  usePagedQuery,
  useShowNotification
} from '@elotech/components';
import { ProcessoService } from 'itbi-common/service';
import React from 'react';
import { useState } from 'react';

import { ProcessoProtocolo } from '../../types';

const searchFields: Field[] = [
  {
    label: 'Número',
    name: 'id.numero',
    type: 'NUMBER'
  },
  {
    label: 'Ano',
    name: 'id.ano',
    type: 'NUMBER'
  },
  {
    label: 'Tipo',
    name: 'id.tipo.descricao',
    type: 'STRING'
  },
  {
    label: 'Assunto',
    name: 'assunto.descricao',
    type: 'STRING'
  },
  {
    label: 'Data',
    name: 'dataProcesso',
    type: 'DATE'
  }
];

const ProcessoListPage = () => {
  const showNotification = useShowNotification();
  const [isLoading, setIsLoading] = useState(false);
  const {
    doSearch,
    doPagedSearch,
    loading,
    pagination,
    values,
    lastSearch
  } = usePagedQuery({
    search: ProcessoService.search,
    onError: error => {
      Alert.error({ title: 'Ocorreu um erro ao buscar os processos' }, error);
    }
  });

  const toParam = (processo: ProcessoProtocolo): string => {
    return `${processo.id}&entidade=${processo.entidade.id}`.replace('?', '');
  };

  const parecerValidator = (value: string) => {
    return new Promise(resolve => {
      if (!value) {
        return resolve('Informe o motivo da reabertura do processo');
      }

      resolve(false);
    });
  };

  const onReabrir = async (id: string) => {
    const result = await Alert.question({
      title: 'Deseja realmente reabrir este processo?',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: true,
      input: 'textarea',
      inputPlaceholder: 'Informe o motivo da reabertura do processo',
      inputValidator: parecerValidator
    });

    if (result.value) {
      setIsLoading(true);

      return ProcessoService.reabrirProcesso(id, result.value)
        .then(() => {
          showNotification({
            level: 'success',
            title: 'Processo foi reaberto com sucesso!'
          });
          return doSearch(lastSearch);
        })
        .catch(error => {
          Alert.error(
            { title: 'Ocorreu um erro ao reabrir o processo' },
            error
          );
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <Container title="Processos" icon="list">
      <Panel isTable>
        <SearchFilter fields={searchFields} search={doSearch} />
        <Table
          values={values}
          loading={loading || isLoading}
          keyExtractor={p => `${p.numero}-${p.ano}-${p.tipo?.id}`}
        >
          <Table.Column
            header="Número"
            value={(processo: ProcessoProtocolo) => processo.numero}
          />
          <Table.Column
            header="Ano"
            value={(processo: ProcessoProtocolo) => processo.ano}
          />
          <Table.Column
            header="Tipo"
            value={(processo: ProcessoProtocolo) =>
              `${processo.tipo?.id} - ${processo.tipo?.descricao}`
            }
          />
          <Table.Column
            header="Assunto"
            value={(processo: ProcessoProtocolo) =>
              `${processo.assunto?.id} - ${processo.assunto?.descricao}`
            }
          />
          <Table.Column
            header="Data"
            value={(processo: ProcessoProtocolo) => (
              <FormattedDate value={processo.dataProcesso} />
            )}
          />
          <Table.Column
            header="Situação"
            value={(processo: ProcessoProtocolo) => processo.funcao}
          />
          <Table.Column
            header=""
            value={(processo: ProcessoProtocolo) => (
              <ActionsGroup>
                {processo.funcao === 'ARQUIVADO' && (
                  <>
                    <ActionButton
                      icon="star"
                      label="Avaliar"
                      path={`/processos/${toParam(processo)}/avaliar`}
                    />
                    <ActionButton
                      icon="paper-plane"
                      label="Reabrir"
                      onClick={() => onReabrir(toParam(processo))}
                    />
                  </>
                )}
                <ActionButton
                  icon="eye"
                  label="Visualizar"
                  path={`/processos/${toParam(processo)}/resumo`}
                />
              </ActionsGroup>
            )}
          />
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
        <div className="btn-save">
          <FAB icon="plus" title="Novo Processo" path="/processos/novo" />
        </div>
      </Panel>
    </Container>
  );
};

export default ProcessoListPage;
