import React from 'react';
import { FormattedDate as Date } from 'react-intl';

const FormattedDate = ({ value, ...props }: any) => {
  if (!value) {
    return null;
  }

  return <Date value={value} timeZone="UTC" {...props} />;
};

export default FormattedDate;
