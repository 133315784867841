import { PageRequest, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';
import {
  AssuntoProtocolo,
  AvaliacaoProcesso,
  ProcessoProtocolo,
  TipoProcessoProtocolo
} from 'itbi-cidadao/src/types';

export const save = (
  processo: ProcessoProtocolo
): AxiosPromise<ProcessoProtocolo> => {
  return axios.post('/gateway/processos', processo);
};

export const getTipos = (
  search?: string
): AxiosPromise<TipoProcessoProtocolo[]> => {
  return axios.get('/gateway/processos/tipos', {
    params: {
      search
    }
  });
};

export const getAssuntos = (
  search?: string
): AxiosPromise<AssuntoProtocolo[]> => {
  return axios.get('/gateway/processos/assuntos', {
    params: {
      texto: search,
      page: 0,
      size: 999
    }
  });
};

export const salvarArquivo = (
  processoId: string,
  arquivo: any
): AxiosPromise<ProcessoProtocolo> => {
  return axios.post(`/gateway/processos/arquivo/${processoId}`, arquivo);
};

export const salvarArquivoComDocumento = (
  processoId: string,
  documentoId: number,
  arquivo: any
): AxiosPromise<ProcessoProtocolo> => {
  return axios.post(
    `/gateway/processos/arquivo/${processoId}&documento=${documentoId}`,
    arquivo
  );
};

export const downloadArquivo = (
  identificador: string,
  nome: string
): AxiosPromise<any> => {
  return axios.get(`/gateway/processos/arquivo/${identificador}`, {
    responseType: 'blob',
    params: {
      nome
    }
  });
};

export const search = (
  search: string,
  page?: PageRequest
): AxiosPromise<PagedResponse<ProcessoProtocolo>> => {
  return axios.get('/gateway/processos', {
    params: {
      search,
      ...page
    }
  });
};

export const getProcesso = (id: string): AxiosPromise<ProcessoProtocolo> => {
  return axios.get(`/gateway/processos/?${id}`);
};

export const avaliarProcesso = (
  id: string,
  avaliacao: AvaliacaoProcesso
): AxiosPromise<void> => {
  return axios.put(`/gateway/processos/avaliar?${id}`, avaliacao);
};

export const reabrirProcesso = (
  id: string,
  parecer: string
): AxiosPromise<void> => {
  return axios.put(`/gateway/processos/reabrir?${id}`, parecer, {
    headers: { 'Content-Type': 'text/plain' }
  });
};
