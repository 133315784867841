import { FormattedCurrency, Panel, Table } from '@elotech/components';
import { LaudoUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';

const ListPropriedadesLaudo = props => {
  const { renderButtons, loteLaudo } = props;

  return (
    <Panel isTable>
      <Table
        values={loteLaudo?.propriedades ?? []}
        loading={false}
        keyExtractor={item => item.cadastro}
      >
        <Table.Column header="Cadastro" value={item => item.cadastro} />
        <Table.Column
          header="Endereço"
          value={item => LaudoUtils.buildEnderecoCadastro(item)}
        />
        <Table.Column
          header="Valor Estimado"
          value={item => <FormattedCurrency value={item.valorEstimado} />}
        />
        {loteLaudo?.situacaoLaudo?.nome === 'LIBERADO' &&
          loteLaudo?.pago === true && (
            <Table.Column
              header="Valor Avaliado"
              value={item => <FormattedCurrency value={item.valorAvaliado} />}
            />
          )}
        <Table.Column
          header=""
          value={(item, index) => renderButtons(index, item)}
        />
      </Table>
    </Panel>
  );
};

ListPropriedadesLaudo.propTypes = {
  loteLaudo: PropTypes.object.isRequired,
  renderButtons: PropTypes.func
};

export default ListPropriedadesLaudo;
