import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
interface Props {
  classes: string;
  children: React.ReactNode;
  isTable?: boolean;
  className?: string;
}

const HintContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const HintRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;

  &&& span {
    padding-left: 5px;
    color: white;
  }
`;

const getClassName = (classes: string, isTable: boolean): string => {
  return classNames(
    {
      [classes]: classes,
      'inline clean module-color mobile sm': isTable
    },
    'hint no-print'
  );
};

const Hint: React.FC<Props> = ({
  classes,
  className = '',
  children,
  isTable = false
}) => (
  <div className={getClassName(`${classes} ${className}`, isTable)}>
    <div className="hint-content">{children}</div>
  </div>
);

export { HintContent, HintRow };
export default Hint;
