import {
  Alert,
  Col,
  Container,
  ErrorText,
  FAB,
  Info,
  NotificationActions,
  Row,
  TextArea
} from '@elotech/components';
import {
  EmailService,
  FaleConoscoService,
  ReportarProblemaService,
  withService
} from 'itbi-common/service';
import React from 'react';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';

type Props = {
  reportarService: typeof ReportarProblemaService;
  faleConoscoService: typeof FaleConoscoService;
  emailService: typeof EmailService;
  showNotification(notification: Notification): void;
};

type State = {
  settings: {
    mensagemSucesso: string;
    emailDestino: string;
  };
  message: string;
  error: { [key: string]: string };
  loading: boolean;
};

class ContactPage extends React.Component<Props, State> {
  readonly state: State = {
    settings: {
      mensagemSucesso: '',
      emailDestino: ''
    },
    message: '',
    error: {},
    loading: false
  };

  componentDidMount() {
    this.props.faleConoscoService
      .getSettings()
      .then(response => this.setState({ settings: response.data }));
  }

  onSendMessageClick = async () => {
    const { settings, message } = this.state;

    try {
      if (message.length >= 5 && message.length <= 500) {
        this.setState({ loading: true });
        await this.props.reportarService.reportar(message);
        await this.props.emailService.send({
          to: settings.emailDestino,
          title: settings.mensagemSucesso,
          text: message
        });

        this.setState({ message: '' });
        Alert.success({ title: 'Mensagem enviada com sucesso' });
      } else if (message.length >= 500) {
        const messageError = 'A mensagem deve conter menos de 500 caracteres';
        this.props.showNotification({
          level: 'error',
          message: messageError
        });
        this.setState({ error: { message: messageError } });
      } else {
        const messageError = 'A mensagem deve conter mais de 5 caracteres';
        this.props.showNotification({
          level: 'error',
          message: messageError
        });
        this.setState({ error: { message: messageError } });
      }
    } catch (error) {
      Alert.error({ title: 'Não foi possível enviar a mensagem', error });
    } finally {
      this.setState({ loading: false });
    }
  };

  onMessageChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    this.setState({ message: event.currentTarget.value, error: {} });
  };

  render() {
    const { message, loading, error } = this.state;
    return (
      <Container title="Fale conosco" icon="check-circle">
        <Info classes="mb-xs">
          Sinta-se a vontade para fazer qualquer sugestão, reclamação ou pedido
          de ajuda.
        </Info>

        <form className="form">
          <Row>
            <Col md={12}>
              <label htmlFor="mensagemLabel">Mensagem</label>
              <TextArea
                resize="vertical"
                name="mensagem"
                value={message}
                onChange={this.onMessageChange}
                className={error.message ? 'error' : ''}
              />
              <ErrorText>{error.message}</ErrorText>
            </Col>
          </Row>

          <div className="btn-save">
            <FAB
              icon="envelope"
              iconColor="white"
              title="Enviar mensagem"
              onClick={this.onSendMessageClick}
              loading={loading}
              disabled={!message}
            />
          </div>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentUser } = state.user;

  return {
    user: currentUser
  };
};

const WithServiceComponent = withService({
  reportarService: ReportarProblemaService,
  faleConoscoService: FaleConoscoService,
  emailService: EmailService
})(ContactPage);

const ConnectedComponent = connect(mapStateToProps, {
  showNotification: NotificationActions.showNotification
})(WithServiceComponent);

export { ConnectedComponent as default, ContactPage };
