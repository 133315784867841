import { FormikProps } from 'formik';
import React from 'react';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';

import { showNotification } from '../../notification/state/actions';
import { isEmptyObject } from '../../utils/ObjectUtils';
import { FormikEffect, scrollToElementByName } from '../..';
import { extractFirstError } from '../validate';

type Props = {
  showNotification(notification: Notification): void;
};

class FormikErrorOnSubmit extends React.Component<Props> {
  validateError = (
    currentProps: FormikProps<any>,
    oldProps: FormikProps<any>
  ) => {
    const temErroDeValidacao =
      oldProps.isSubmitting &&
      !currentProps.isSubmitting &&
      !isEmptyObject(currentProps.errors);
    if (temErroDeValidacao) {
      this.props.showNotification({
        level: 'error',
        message: extractFirstError(currentProps.errors)
      });
      scrollToElementByName(Object.keys(currentProps.errors)[0]);
    }
  };

  render() {
    return <FormikEffect onChange={this.validateError} />;
  }
}

const connectedComponent = connect(null, { showNotification })(
  FormikErrorOnSubmit
);

export { connectedComponent as default, FormikErrorOnSubmit };
