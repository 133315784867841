import { Container } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DocumentoEmitidoList from './DocumentoEmitidoList';
import DocumentoEmitidoQuickView from './DocumentoEmitidoQuickView';

class DocumentoEmitidoListPage extends Component {
  static propTypes = {
    listUsuarios: PropTypes.array.isRequired
  };

  state = {
    usuario: undefined,
    showQuickView: false,
    documentoSelecionado: undefined
  };

  onChangeSelect = ({ target: { value } }) => {
    const { listUsuarios = [] } = this.props;

    const usuario = listUsuarios.find(item => item.cpfCnpj === value);

    this.setState({ usuario });
  };

  onPrintDocumento = documento => window.open(documento.url, '_blank');

  onShowDetail = documento => {
    this.setState({ showQuickView: true, documentoSelecionado: documento });
  };

  onCloseQuickView = () => {
    this.setState({ showQuickView: false });
  };

  render() {
    const { usuario, documentoSelecionado, showQuickView } = this.state;
    const { listUsuarios } = this.props;
    return (
      <Container title="Documentos Emitidos" icon="file-alt">
        {showQuickView && (
          <DocumentoEmitidoQuickView
            onClose={this.onCloseQuickView}
            documentoSelecionado={documentoSelecionado}
          />
        )}
        <div className="row mb-xs">
          <div className="form-group col-md-6">
            <label htmlFor="usuario">Usuário</label>
            <select
              data-test-id="select-usuario"
              name="usuario"
              onChange={this.onChangeSelect}
              value={usuario && usuario.cpfCnpj}
            >
              <option value="">Selecione</option>
              {listUsuarios.map(item => (
                <option key={item.cpfCnpj} value={item.cpfCnpj}>
                  {item.nome}
                </option>
              ))}
            </select>
          </div>
        </div>

        <DocumentoEmitidoList
          usuario={usuario}
          onPrintDocumento={this.onPrintDocumento}
          onShowDetail={this.onShowDetail}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const listUsuarios = state.user.currentUser.usuariosProcurador || [];
  if (
    state.user.currentUser.id &&
    !listUsuarios.some(p => p.id === state.user.currentUser.id)
  ) {
    listUsuarios.unshift(state.user.currentUser);
  }

  return { listUsuarios };
};

const ConnectedComponent = connect(
  mapStateToProps,
  null
)(DocumentoEmitidoListPage);

export { ConnectedComponent as default, DocumentoEmitidoListPage };
