import FileSaver from 'file-saver';
import React from 'react';

type Props = {
  file: any;
  onPreview?: (file: any) => void;
  onDownload?: (file: any) => void;
  onRemoveFile?: () => void;
  canDelete?: boolean;
  sizeDivFile?: number;
};

const DragDropItem: React.FunctionComponent<Props> = ({
  file,
  canDelete,
  sizeDivFile,
  onRemoveFile,
  onPreview,
  onDownload
}) => {
  const formattedName = () => {
    let formattedName = file.name;

    if (formattedName.length > 47) {
      const fileName = file.name.split('.');
      const extension = fileName[fileName.length - 1];
      const name = formattedName.substr(0, 38 - extension.length) + '...';
      formattedName = name + extension;
    }

    return <div className="name">{formattedName}</div>;
  };

  const download = () => {
    if (onDownload) {
      return onDownload(file);
    }

    const urlFile = file.url ? file.url : URL.createObjectURL(file);
    FileSaver.saveAs(urlFile, file.name);
  };

  const preview = () => {
    if (onPreview) {
      return onPreview(file);
    }

    const urlFile = file.url ? file.url : URL.createObjectURL(file);
    return window.open(urlFile);
  };

  return (
    <li key={file.name} className={`col-md-${sizeDivFile}`}>
      <div className="dragdrop-item">
        {formattedName()}
        <div className="buttons">
          <button type="button" className="btn circle" onClick={preview}>
            <i className="fa fa-eye" />
            <div className="btn-actions-label">Visualizar</div>
          </button>
          <button type="button" className="btn circle" onClick={download}>
            <i className="fa fa-download" />
            <div className="btn-actions-label">Download</div>
          </button>
          {canDelete && (
            <button
              type="button"
              className="btn circle"
              onClick={onRemoveFile}
              id="btn-remove"
            >
              <i className="fa fa-trash" />
              <div className="btn-actions-label">Excluir</div>
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

export default DragDropItem;
