import { Col, Row } from '@elotech/components';
import React from 'react';

import { CadastroRural } from '../../type';

type Props = {
  cadastroRural: CadastroRural;
};

const PropriedadeRuralQuickView: React.FC<Props> = ({ cadastroRural }) => {
  return (
    <>
      <div className="quick-header">Propriedade</div>
      <Row>
        <Col md={12}>
          <div className="form-group">
            <label className="label">Nome</label>
            {cadastroRural?.nome}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="form-group">
            <label className="label">Endereço Descrito</label>
            {cadastroRural?.enderecoDescrito}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <div className="form-group">
            <label className="label">Logradouro</label>
            {cadastroRural?.logradouro}
          </div>
        </Col>
        <Col md={4}>
          <div className="form-group">
            <label className="label">Bairro</label>
            {cadastroRural?.bairro}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <label className="label">Complemento</label>
            {cadastroRural?.complemento}
          </div>
        </Col>
        <Col md={4}>
          <div className="form-group">
            <label className="label">Distrito</label>
            {cadastroRural?.distrito}
          </div>
        </Col>
        <Col md={4}>
          <div className="form-group">
            <label className="label">Setor</label>
            {cadastroRural?.setor}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="form-group">
            <label className="label">NIRF</label>
            {cadastroRural?.numeroReceita}
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <label className="label">Número no INCRA</label>
            {cadastroRural?.numeroIncra}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <label className="label">Qtd. Alqueires</label>
            {cadastroRural?.alqueires}
          </div>
        </Col>
        <Col md={4}>
          <div className="form-group">
            <label className="label">Qtd. Hectares</label>
            {cadastroRural?.hectares}
          </div>
        </Col>
        <Col md={4}>
          <div className="form-group">
            <label className="label">Área Total</label>
            {cadastroRural?.areaTotal ? `${cadastroRural?.areaTotal} m²` : ''}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PropriedadeRuralQuickView;
