import React from 'react';

const DisplayValue = ({ size, label, value }) => (
  <div
    className={`form-group col-sm-${size}`}
    style={{ display: 'flex', flexDirection: 'column' }}
  >
    <label className="label">{label}</label>
    <div>{value}</div>
  </div>
);

export default DisplayValue;
