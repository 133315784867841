import classnames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';

import ActionButton from '../buttons/ActionButtons';
import ActionsGroup from '../buttons/Actions';
import { useOnClickOutside } from '../hooks';
import Checkbox from '../inputs/Checkbox';

type Field = {
  label: string;
  name: string;
  checked: boolean;
};

type Props = {
  fields: Field[];
  visibleFields: string[];
  onClick(event: React.ChangeEvent<HTMLInputElement>): void;
  scrollHorizontal: boolean;
};

const SelectorColumn: React.FC<Props> = ({
  fields,
  visibleFields,
  onClick,
  scrollHorizontal
}) => {
  const divRef = useRef(null);

  const [active, setActive] = useState(false);
  const [empty, setEmpty] = useState(false);

  useOnClickOutside(divRef, () => setActive(false));

  const handleOnClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isEmpty = visibleFields.length === 1 && !event.target.checked;

      setEmpty(isEmpty);

      if (isEmpty) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        onClick(event);
      }
    },
    [visibleFields, onClick]
  );

  return (
    <div ref={divRef}>
      {scrollHorizontal ? (
        <ActionsGroup>
          <ActionButton
            key="column-selector-button"
            icon="columns"
            label="Seleção de Colunas"
            onClick={() => setActive(true)}
          />
        </ActionsGroup>
      ) : (
        <div
          className="hint fa-columns clean module-color right top-right no-print"
          onClick={() => setActive(true)}
        >
          <div className="hint-content">Seleção de Colunas</div>
        </div>
      )}
      <div
        data-testid="column-selector-options"
        className={classnames('options', { actived: active })}
      >
        {fields.map(value => (
          <li key={value.name}>
            <div className="form-group">
              <Checkbox
                id={value.name}
                name={value.name}
                checked={value.checked}
                onChange={handleOnClick}
                label={value.label}
              />
              {value.checked && empty ? (
                <p className="help-message">
                  Ao menos um campo deve permanecer selecionado!
                </p>
              ) : (
                undefined
              )}
            </div>
          </li>
        ))}
      </div>
    </div>
  );
};

export default SelectorColumn;
