export * from './Pagination';
export * from './PagedResponse';
export * from './Theme';
export * from './Sort';
export * from '../notification/state/actions';
export * from './BreadCrumb';
export * from './Facet';
export * from './Fields';
export * from './EnumTypeDialog';
export * from './Tenant';
export * from './Entidade';
export * from './Modulo';
export * from './ActuatorInfo';
export * from './CamposDinamicos';
export * from './enum';
export * from './Notification';

export type FieldsOfType<T, F> = {
  [Key in keyof T]: T[Key] extends F ? Key : never;
}[keyof T];

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type PartialPick<T, K extends keyof T> = {
  [P in keyof T]: P extends K ? T[P] | undefined : T[P];
};
export type Optional<T> = T | undefined;
type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
// Ou Exclusivo: Deve conter atributo de apenas um dos tipos.
// Exemplo: type Props = Exclusive<TipoA, TipoB>;
export type Exclusive<T, U> = (Without<T, U> & U) | (Without<U, T> & T);
