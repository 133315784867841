import { useCallback } from 'react';
import { Notification } from 'react-notification-system';
import { useDispatch } from 'react-redux';

import { showNotification } from '../type';

const useShowNotification = () => {
  const dispatch = useDispatch();
  return useCallback(
    (notification: Notification) => dispatch(showNotification(notification)),
    [dispatch]
  );
};

export default useShowNotification;
