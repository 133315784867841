import {
  DisplayData,
  DisplayDataGrid,
  DisplayDataItem,
  FormattedCurrency,
  Info,
  Row,
  SectionTitle
} from '@elotech/components';
import React from 'react';

import { LoteLaudo } from '../../type';

type Props = {
  valores?: LoteLaudo;
};

const LaudoPagamentoSection: React.FC<Props> = ({ valores }) => {
  if (!valores) {
    return (
      <Info classes="negative mt-xs mb-xs">
        Ocorreu um erro ao gerar o débito
      </Info>
    );
  }
  return (
    <>
      <SectionTitle>À pagar</SectionTitle>
      <DisplayDataGrid className={'mb-xs'}>
        <Row>
          <DisplayDataItem sm={3} title={'Requerente'}>
            {valores.requerente?.nome}
          </DisplayDataItem>
          <DisplayDataItem sm={3} title={'Quantidade Laudo'}>
            {valores.qtdImoveis}
          </DisplayDataItem>
          <DisplayDataItem sm={3} title={'Valor Unitário'}>
            <FormattedCurrency value={valores.valorUnitario} />
          </DisplayDataItem>
          <DisplayDataItem sm={3} title={'Valor Total'}>
            <FormattedCurrency value={valores.valorTotal} />
          </DisplayDataItem>
        </Row>
      </DisplayDataGrid>
    </>
  );
};

export default LaudoPagamentoSection;
