import {
  Alert,
  Container,
  ForbiddenPage,
  Loading,
  useAuth
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { ConcursoPremiadoService } from 'itbi-common/service';
import { ConcursoPremiadoResumoPorCpf } from 'itbi-common/type';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import ConcursoPremiadoAdesaoEncerrada from './ConcursoPremiadoAdesaoEncerrada';
import ConcursoPremiadoAdesaoPage from './ConcursoPremiadoAdesaoPage';
import ConcursoPremiadoContaCorrentePage from './ConcursoPremiadoContaCorrentePage';
import ConcursoPremiadoExclusaoPage from './ConcursoPremiadoExclusaoPage';

const Content = styled.div`
  display: block;
  padding: 0px 20px;
  line-height: 1.5;
`;

type Props = {};

const ConcursoPremiadoFormPage: React.FC<Props> = () => {
  const history = useHistory();
  const { user, tenantIdentifier } = useAuth();
  const [loading, setLoading] = useState(false);
  const [concurso, setConcurso] = useState<ConcursoPremiadoResumoPorCpf>();

  const isPessoaFisica = () => user.tipoPessoa === 'FISICA';

  const onGetConcurso = useCallback(
    (status: boolean) => {
      setLoading(true);

      return ConcursoPremiadoService.getConcursoByCpf(
        tenantIdentifier,
        user.cpfCnpj,
        status
      )
        .then(response => {
          setConcurso(response.data);
          if (status) {
            const retorno = response.data;
            if (retorno?.aderiu && !retorno?.excluido) {
              onGetConcurso(false);
            }
          }
        })
        .catch(async error => {
          setLoading(false);
          await Alert.error(
            { title: 'Erro ao buscar as informações do concurso' },
            error
          );
          history.goBack();
        })
        .finally(() => setLoading(false));
    },
    [tenantIdentifier, user.cpfCnpj, history]
  );

  useEffect(() => {
    onGetConcurso(true);
  }, [onGetConcurso]);

  const onAderirConcurso = async () => {
    const result = await Alert.question({
      title: 'Deseja realmente aderir a este concurso?',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: true
    });

    if (result.value) {
      setLoading(true);
      ConcursoPremiadoService.aderirConcurso(
        tenantIdentifier,
        concurso?.idConcurso!,
        user.id
      )
        .then(async response => {
          await onGetConcurso(false);
        })
        .catch(error => {
          Alert.error(
            {
              title:
                'Não foi possível realizar a ação, tente novamente mais tarde.'
            },
            error
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const onPrintRegulamento = () => {
    setLoading(true);

    return ConcursoPremiadoService.downloadRegulamento(
      tenantIdentifier,
      concurso?.idConcurso!
    )
      .then((response: AxiosResponse<any>) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window && window.open(fileURL);
      })
      .catch(error => {
        Alert.error({ title: `Erro ao imprimir o regulamento` }, error);
      })
      .finally(() => setLoading(false));
  };

  const renderRegulamentoText = () => (
    <>
      {' '}
      <button key={`regulamento`} onClick={onPrintRegulamento}>
        <b className={'primary-color'}>Regulamento do Sorteio</b>
      </button>
    </>
  );

  return !isPessoaFisica() ? (
    <ForbiddenPage />
  ) : (
    <Container breadcrumb>
      <Loading loading={loading} />
      <Content>
        {concurso &&
          (concurso.excluido ? (
            <ConcursoPremiadoExclusaoPage
              concurso={concurso}
              renderRegulamentoText={renderRegulamentoText}
            />
          ) : !concurso.aderiu ? (
            concurso.situacao === 'VIGENTE' ? (
              <ConcursoPremiadoAdesaoPage
                concurso={concurso!}
                renderRegulamentoText={renderRegulamentoText}
                onClickAceite={onAderirConcurso}
              />
            ) : (
              <ConcursoPremiadoAdesaoEncerrada concurso={concurso!} />
            )
          ) : (
            <ConcursoPremiadoContaCorrentePage
              concurso={concurso}
              renderRegulamentoText={renderRegulamentoText}
            />
          ))}
      </Content>
    </Container>
  );
};

export default ConcursoPremiadoFormPage;
