import axios, { AxiosPromise } from 'axios';
import { TipoCadastroDTO } from 'itbi-cidadao/src/types/CadastroGeral';

export const loadDocumentoTipo = (): AxiosPromise<any> =>
  axios.get(`/api/documentos-tipos`);

export const findTiposCadastroByTipoDocumento = (
  tipoDocumento: number
): AxiosPromise<TipoCadastroDTO[]> =>
  axios.get(`/api/documentos-tipos/${tipoDocumento}/tipos-cadastro`);
