import { AxiosPromise } from 'axios';
import React, { useCallback, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router';

import { PageRequest, PagedResponse, PaginationData, Sort } from '../type';

type PagedQueryState<Value, Query> = {
  loading: boolean;
  values: Value[];
  pagination?: PaginationData;
  error?: any;
  lastSearch: Query;
  lastSort?: Sort;
  initialPageRequest?: PageRequest;
};

type LoadingAction<Query> = {
  type: 'START_QUERY';
  payload: {
    lastSearch: Query;
    lastSort?: Sort;
  };
};

type ErrorAction = {
  type: 'ERROR';
  payload: any;
};

type SuccessAction<Value> = {
  type: 'SUCCESS';
  payload: {
    value: Value[];
    pagination: PaginationData;
  };
};

type ResetAction = {
  type: 'RESET';
};

type PagedQueryAction<Value, Query> =
  | LoadingAction<Query>
  | ErrorAction
  | SuccessAction<Value>
  | ResetAction;

type PagedQueryReducer<Value, Query> = React.Reducer<
  PagedQueryState<Value, Query>,
  PagedQueryAction<Value, Query>
>;

const reducer = <Value, Query>(
  state: PagedQueryState<Value, Query>,
  action: PagedQueryAction<Value, Query>
): PagedQueryState<Value, Query> => {
  switch (action.type) {
    case 'START_QUERY': {
      return {
        ...state,
        loading: true,
        lastSearch: action.payload.lastSearch,
        lastSort: action.payload.lastSort
      };
    }
    case 'ERROR': {
      return { ...state, loading: false, error: action.payload };
    }
    case 'SUCCESS': {
      return {
        ...state,
        loading: false,
        pagination: action.payload.pagination,
        values: action.payload.value
      };
    }
    case 'RESET': {
      return {
        ...state,
        loading: false,
        values: [],
        pagination: undefined
      };
    }
    default:
      return state;
  }
};

type UsePagedQueryProps<Value, Query> = {
  search: (
    search: Query,
    pagination?: PageRequest,
    sort?: Sort
  ) => AxiosPromise<PagedResponse<Value>>;
  onError?: (error: any) => void;
  onEmptyResult?: () => void;
  disableQueryPagination?: boolean;
};

const initialState: PagedQueryState<any, any> = {
  loading: false,
  lastSearch: undefined,
  values: [],
  pagination: undefined
};

const usePagedQuery = <Value, Query = string>(
  props: UsePagedQueryProps<Value, Query>
) => {
  const history = useHistory();

  const { search, onEmptyResult, onError, disableQueryPagination } = props;

  const getPageRequest = () => {
    const urlSearchParams = new URLSearchParams(history?.location?.search!);

    const page = parseInt(urlSearchParams.get('page') || '');
    const size = parseInt(urlSearchParams.get('size') || '');

    if (page && size) {
      return { page: page - 1, size };
    }
  };

  const onInitialize = (state: PagedQueryState<Value, Query>) => {
    if (!disableQueryPagination || history?.location) {
      state.initialPageRequest = getPageRequest();
    }

    return state;
  };

  const [state, dispatch] = useReducer<
    PagedQueryReducer<Value, Query>,
    PagedQueryState<Value, Query>
  >(reducer, initialState, onInitialize);

  useEffect(() => {
    if (disableQueryPagination || !history?.location) {
      return;
    }

    const pageRequest = getPageRequest();

    if (!pageRequest || !state.pagination) {
      return;
    }

    if (
      pageRequest?.page !== state.pagination?.number ||
      pageRequest?.size !== state.pagination?.size
    ) {
      onPaginationSearch(pageRequest);
    }
  }, [history?.location]);

  const updatePageRequestParams = (
    pageRequest: PageRequest,
    createNewState: boolean
  ) => {
    if (disableQueryPagination || !history || !history.location) {
      return;
    }

    const currentSearch = history?.location.search;
    let search = currentSearch;

    ['page', 'size'].forEach(param => {
      let value = pageRequest?.[param];

      if (param === 'page' && value >= 0) {
        value++;
      }

      // Regex para encontrar o parâmetro e seu valor
      // pois ao utilizar SearchParams ele encoda os valores
      // e remove o igual duplo que vem do SearchFilter
      const regex = new RegExp(`((?:[?&]|^)\\b${param}=)[\\d]*\\b`, 'i');

      if (search.match(regex)) {
        search = search.replace(regex, (_, rep) =>
          value ? `${rep}${value}` : ''
        );
      } else {
        search += `${search ? '&' : '?'}${param}=${value}`;
      }
    });

    (createNewState ? history.push : history.replace)({
      search,
      state: history.location.state
    });
  };

  const onSearch = useCallback(
    (
      searchParam: Query = state.lastSearch,
      pageRequest?: PageRequest,
      sort: Sort = state.lastSort!
    ) => {
      dispatch({
        type: 'START_QUERY',
        payload: {
          lastSearch: searchParam,
          lastSort: sort
        }
      });

      const createNewState = searchParam === state.lastSearch;

      if (
        !disableQueryPagination &&
        !pageRequest &&
        state.lastSearch === undefined
      ) {
        pageRequest = state.initialPageRequest;
      }

      return search(searchParam, pageRequest, sort)
        .then(response => {
          const {
            content,
            number,
            totalPages,
            first,
            last,
            numberOfElements,
            size,
            totalElements
          } = response.data;
          dispatch({
            type: 'SUCCESS',
            payload: {
              value: content,
              pagination: {
                number,
                totalPages,
                first,
                last,
                numberOfElements,
                size,
                totalElements
              }
            }
          });

          updatePageRequestParams({ page: number, size }, createNewState);

          if (content.length === 0) {
            onEmptyResult && onEmptyResult();
          }
        })
        .catch((error: any) => {
          onError && onError(error);
          dispatch({
            type: 'ERROR',
            payload: error
          });
        });
    },
    [dispatch, search, onEmptyResult, onError, state.lastSearch, state.lastSort]
  );

  const resetValues = () => {
    dispatch({
      type: 'RESET'
    });
  };

  const onPaginationSearch = (page: PageRequest, sort?: Sort) =>
    onSearch(undefined, page, sort || state.lastSort);

  const paginationToPageRequest = (
    pagination: PaginationData
  ): PageRequest => ({
    page: pagination?.number || 0,
    size: pagination?.size || 20
  });

  const onSortChange = (sort?: Sort) => {
    const lastPage = paginationToPageRequest(state.pagination!);
    return onSearch(undefined, lastPage, sort ?? state.lastSort);
  };

  return {
    loading: state.loading,
    lastSearch: state.lastSearch,
    values: state.values,
    pagination: state.pagination,
    error: state.error,
    lastSort: state.lastSort,
    doSearch: onSearch,
    doPagedSearch: onPaginationSearch,
    doSortChange: onSortChange,
    resetValues
  };
};

export default usePagedQuery;
