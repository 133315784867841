import { Col, ErrorText, Row } from '@elotech/components';
import React from 'react';
import { FormGroup } from 'react-bootstrap';

import {
  ArquivoProtocolo,
  AssuntoDocumento,
  DocumentoProtocolo
} from '../../types';

type Props = {
  hasError?: boolean;
  onlyView?: boolean;
  arquivos: ArquivoProtocolo[];
  documentos: AssuntoDocumento[];
  onChangeArquivo?: (file: any, documento: DocumentoProtocolo) => void;
};

const DocumentosExigidosList = ({
  documentos = [],
  arquivos = [],
  hasError = false,
  onlyView = false,
  onChangeArquivo
}: Props) => {
  const getArquivoByDocumento = (documento: DocumentoProtocolo) => {
    return arquivos.find(a => a.documento?.id === documento.id);
  };

  return (
    <>
      {documentos.map(({ documento }) => {
        const arquivo = getArquivoByDocumento(documento!);

        return (
          <Row key={documento?.id}>
            <Col md={12}>
              <FormGroup>
                <label htmlFor={`${documento?.id}`}>
                  {documento?.descricao}
                </label>

                <div className="file-uploader">
                  <input
                    type="file"
                    disabled={onlyView}
                    id={`${documento?.id}`}
                    className={`file-uploader-input ${
                      hasError && !arquivo ? 'error' : ''
                    }`}
                    onChange={e =>
                      onChangeArquivo?.(e.target.files?.[0], documento!)
                    }
                    title="Clique ou arraste para anexar"
                  />

                  <label
                    className="input"
                    htmlFor={`${documento?.id}`}
                    data-title={
                      arquivo
                        ? arquivo.name || arquivo.nome
                        : 'Clique ou arraste para anexar'
                    }
                  />

                  <label
                    htmlFor={`${documento?.id}`}
                    className="file-uploader-icon"
                  />

                  {hasError && !arquivo && (
                    <ErrorText>{documento?.descricao} é obrigatório</ErrorText>
                  )}
                </div>
              </FormGroup>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default DocumentosExigidosList;
