const getFields = listRequerentes => [
  {
    label: 'Número do Pedido',
    name: 'numero',
    type: 'NUMBER'
  },
  {
    label: 'Número do Cadastro Imobiliário',
    name: 'laudos.cadastroImobiliario.cadastro',
    type: 'NUMBER'
  },
  {
    label: 'Data Lançamento',
    name: 'dataLancamento',
    type: 'DATE'
  },
  {
    label: 'Situação',
    name: 'situacao',
    type: 'ENUM',
    options: [
      {
        name: 'AGUARDANDO_ANALISE',
        descricao: 'Aguardando Análise'
      },
      {
        name: 'LIBERADO',
        descricao: 'Liberado'
      }
    ]
  },
  {
    label: 'Pago',
    name: 'pago',
    type: 'BOOLEAN'
  },
  {
    label: 'Requerente',
    name: 'requerente',
    type: 'AUTOCOMPLETE',
    loadOptions: searchParameter => {
      const data = listRequerentes.filter(requerente =>
        requerente.nome.includes(searchParameter)
      );

      return Promise.resolve({ data });
    },
    getOptionValue: option => option.id,
    getOptionLabel: option => option.nome,
    options: listRequerentes
  }
];

export { getFields };
