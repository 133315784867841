import classnames from 'classnames';
import React from 'react';

type Props = {
  label: string;
  size?: number;
  value: React.ReactNode;
  children: React.ReactNode;
  hasBorder: boolean;
  align: 'left' | 'center' | 'right';
  flex: boolean;
  fixed: boolean;
  inline?: boolean;
};
/**
 * Componente para utilizar dentro do [[PanelFooter]]
 *
 * COMO USAR:
 * ```
  <PanelFooterItem
    label="Valor total:"
    value={<FormattedCurrency value={15000.5} />}
  />
  ```
  * ou
  * ```
  <PanelFooterItem hasBorder flex>
    <BasicInput name="observacao" label="Motivo" />
  </PanelFooterItem>
  ```
  *
  * Caso necessário borda, deve-se usar o atributo `hasBorder`;
  * Caso necessário alinhamento diferente de centralizado, deve-se usar o atributo `align` com valor `'left' | 'center' | 'right'`
 */
const PanelFooterItem: React.FC<Partial<Props>> = ({
  align = 'center',
  ...props
}) => {
  const containerClass = classnames(
    'altfooter-container-item',
    {
      'side-border': !!props.hasBorder,
      flex: !!props.flex,
      fixed: !!props.fixed
    },
    props.size ? `col-md-${props.size}` : ''
  );

  const itemClass = classnames('altfooter-item-textcomplex', {
    inline: !!props.inline
  });

  return (
    <div className={containerClass}>
      {props.children || (
        <div className={align}>
          <div className={itemClass}>
            <span>{props.label}</span>
            <p>{props.value}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PanelFooterItem;
