import { AxiosRequestConfig } from 'axios';

export const configureBaseUrl = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  if (process.env.PUBLIC_URL || process.env.REACT_APP_BASE_URL) {
    if (config.url?.startsWith('/api') || config.url?.startsWith('/actuator')) {
      config.baseURL =
        process.env.REACT_APP_BASE_URL ?? `${process.env.PUBLIC_URL}-api`;
    } else if (config.url?.startsWith('/admin')) {
      config.url = config.url.replace(/^\/admin/, '');
      config.baseURL = `/admin-api/api`;
    } else if (config.url?.startsWith('/gateway')) {
      config.url = config.url.replace(/^\/gateway/, '');
      config.baseURL = `/gateway-api`;
    } else if (config.url?.startsWith('/iss-admin')) {
      config.url = config.url.replace(/^\/iss-admin/, '');
      config.baseURL = `/iss-admin-api`;
    } else if (config.url?.startsWith('/iss-fiscalizacao')) {
      config.url = config.url.replace(/^\/iss-fiscalizacao/, '');
      config.baseURL = `/iss-fiscalizacao-api`;
    }
  }
  config.headers['x-tenant-host'] = window.location.host;
  return config;
};
