import classnames from 'classnames';
import React from 'react';

type Props = {
  percentualProgresso: number;
  title?: string;
  color?: string;
  format?: 'circle';
  size?: 'small' | 'medium' | 'big';
};

const ProgressBar: React.FC<Props> = props => {
  const { percentualProgresso, title, color, format, size } = props;

  const inProgress = percentualProgresso < 100;

  const styleBarraDeProgressoFormat = classnames(
    `reach-${percentualProgresso} progressbar-${format}-reacher`,
    {
      striped: inProgress,
      animated: inProgress
    }
  );

  const styleBarraDeProgresso = classnames(
    `reach-${percentualProgresso} progressbar-reacher ${color}`,
    {
      striped: inProgress,
      animated: inProgress
    }
  );

  const styleFormat = `progressbar-${format}`;

  if (format === 'circle') {
    return (
      <>
        <div className={`${size} ${styleFormat} ${color}`}>
          <div className={styleBarraDeProgressoFormat}></div>
          <div className={`${styleFormat}-content-outer`}>
            <div className={`${styleFormat}-content`}>
              <h2 className={`${styleFormat}-title`}>{percentualProgresso}%</h2>
            </div>
          </div>
        </div>
        {title && (
          <small className="col-md-12 center form-group">{title}</small>
        )}
      </>
    );
  }
  return (
    <>
      <div className={`${size} progressbar`}>
        <div className={styleBarraDeProgresso}></div>
      </div>
      {title && <small className="col-md-12 center form-group">{title}</small>}
    </>
  );
};

export default ProgressBar;
