import {
  Alert,
  Container,
  Loading,
  NotificationActions,
  PageRequest,
  PagedResponse,
  Wizard
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { History } from 'history';
import {
  DebitoService,
  ParametroService,
  ParcelamentoService
} from 'itbi-common/service';
import React from 'react';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';

import {
  DebitoParcelamentoTotalizador,
  DebitoPassivelParcelamentoDTO,
  DebitosEmAbertoComTotalizadorDTO,
  ParametroParcelamento,
  ParcelamentoCadastroDTO,
  PermissaoParcelamentoDTO,
  SimulacaoDTO,
  User
} from '../../../types';
import InstrucaoParcelamento from '../InstrucaoParcelamento';
import DadosGeraisStep from './DadosGeraisStep';
import DebitosStep from './DebitosStep';
import LeisStep from './LeisStep';
import ResumoStep from './ResumoStep';
import SimulacoesStep from './SimulacoesStep';

export type Requerente = Pick<User, 'id' | 'nome' | 'cpfCnpj'>;

type Props = {
  history: History;
  showNotification(notification: Notification): void;
  requerente?: Requerente;
};

type State = {
  loading: boolean;
  cadastroSelecionado?: ParcelamentoCadastroDTO;
  leiSelecionada?: ParametroParcelamento;
  loadingCadastros: boolean;
  cadastrosRequerente: ParcelamentoCadastroDTO[];
  errors: { [index: string]: string };
  checkedTermoConfissaoDivida: boolean;
  permissaoParcelamento: PermissaoParcelamentoDTO;
  debitosEmAberto?: DebitosEmAbertoComTotalizadorDTO;
  loadingDebitos: boolean;
  debitosPassiveisParcelamento: {
    [index: number]: DebitoPassivelParcelamentoDTO[];
  };
  debitosSelecionados: number[];
  leis: ParametroParcelamento[];
  simulacoes: SimulacaoDTO[];
  simulacaoSelecionada?: SimulacaoDTO;
  totalizadorDebitosSelecionados?: DebitoParcelamentoTotalizador;
  searchParam: string;
  pagination?: any;
};

class ParcelamentoFormPage extends React.Component<Props, State> {
  state: State = {
    cadastroSelecionado: undefined,
    leiSelecionada: undefined,
    loading: false,
    loadingCadastros: false,
    cadastrosRequerente: [],
    errors: {
      stepDadosGerais: '',
      stepDebitos: '',
      stepResumo: ''
    },
    checkedTermoConfissaoDivida: false,
    permissaoParcelamento: {
      isAutorizadoParcelamento: false,
      instrucaoAcessoParcelamento: ''
    },
    debitosPassiveisParcelamento: {},
    loadingDebitos: false,
    leis: [],
    debitosSelecionados: [],
    simulacoes: [],
    searchParam: '',
    pagination: undefined
  };

  componentDidMount() {
    this.validaParametroAndUsuarioAcessoCidadao();
  }

  validaParametroAndUsuarioAcessoCidadao = () => {
    this.setState({ loading: true });
    return ParametroService.loadPermissaoParcelamento()
      .then(response => {
        this.setState({ permissaoParcelamento: response.data });
        if (response.data.isAutorizadoParcelamento) {
          return this.searchLei();
        }
      })
      .catch(error => {
        Alert.error(
          { title: 'Erro ao buscar permissão do parcelamento.' },
          error
        );
      })
      .finally(() => this.setState({ loading: false }));
  };

  onValidaFormulario = (): boolean => {
    const errorMessageDadosGerais = this.onValidateDadosGeraisStep(this.state);
    const errorMessageResumo = this.onValidateResumoStep(this.state);

    this.setState({
      errors: {
        stepDadosGerais: errorMessageDadosGerais,
        stepResumo: errorMessageResumo
      }
    });

    const primeiroErro = errorMessageDadosGerais || errorMessageResumo;

    if (primeiroErro) {
      this.props.showNotification({
        message: primeiroErro,
        level: 'error'
      });
    }

    return !primeiroErro;
  };

  onSubmit = () => {
    if (this.onValidaFormulario()) {
      this.setState({ loading: true }, () => {
        const { simulacaoSelecionada } = this.state;

        return ParcelamentoService.gerarParcelamento(simulacaoSelecionada)
          .then(response => {
            const { idParcelamento } = response.data;
            this.props.history.replace(
              `/parcelamentos/${idParcelamento}/resumo`,
              { showDestaque: true }
            );
          })
          .catch(error => {
            this.setState({ loading: false });
            Alert.error({ title: 'Erro ao gerar o parcelamento' }, error);
          });
      });
    }
  };

  searchCadastros = (
    searchParam: string = this.state.searchParam,
    page?: PageRequest
  ) => {
    const { requerente } = this.props;
    const { cadastroSelecionado } = this.state;
    this.onChangeCadastroGeral(cadastroSelecionado);

    this.setState({ searchParam: searchParam, loadingCadastros: true });

    if (requerente) {
      ParcelamentoService.loadCadastrosByCpfCnpj(
        requerente!.cpfCnpj,
        searchParam,
        page
      )
        .then(this.onSearchSuccess)
        .catch(error => {
          Alert.error(
            { title: 'Erro ao buscar cadastros do requerente ' },
            error
          );
        })
        .finally(() => this.setState({ loadingCadastros: false }));
    }
  };

  onSearchSuccess = (
    response: AxiosResponse<PagedResponse<ParcelamentoCadastroDTO>>
  ) => {
    const {
      content,
      number,
      totalPages,
      first,
      last,
      numberOfElements,
      size
    } = response.data;

    if (content && content.length > 0) {
      return this.setState({
        cadastrosRequerente: content,
        loading: false,
        pagination: {
          number,
          totalPages,
          first,
          last,
          numberOfElements,
          size
        }
      });
    }

    this.props.showNotification({
      level: 'warning',
      message:
        'Não foi possível encontrar nenhum cadastro com o filtro informado.'
    });

    return this.setState({
      loading: false,
      cadastrosRequerente: [],
      pagination: undefined
    });
  };

  paginationSearch = (page: PageRequest) =>
    this.searchCadastros(undefined, page);

  searchLei = () => {
    this.setState({ loading: true });
    ParcelamentoService.loadLeis()
      .then(response => {
        this.setState({ leis: response.data });
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao buscar as leis' }, error);
      })
      .finally(() => this.setState({ loading: false }));
  };
  onChangeCadastroGeral = (cadastro?: ParcelamentoCadastroDTO) => {
    this.setState(
      {
        cadastroSelecionado: cadastro,
        debitosEmAberto: undefined,
        checkedTermoConfissaoDivida: false,
        debitosPassiveisParcelamento: {},
        debitosSelecionados: [],
        leiSelecionada: undefined,
        simulacaoSelecionada: undefined,
        totalizadorDebitosSelecionados: undefined
      },
      () => {
        if (cadastro) {
          this.getDebitosEmAberto(cadastro);
          this.getDebitosPassiveisParcelamento(cadastro, this.state.leis);
        }
      }
    );
  };

  getDebitosEmAberto = (cadastro: ParcelamentoCadastroDTO) => {
    this.setState({ loadingDebitos: true });
    DebitoService.getDebitosEmAberto(
      cadastro?.tipoCadastro,
      cadastro?.cadastroGeral
    )
      .then(response => this.setState({ debitosEmAberto: response.data }))
      .catch(error => {
        Alert.error(
          { title: 'Erro ao buscar os débitos em aberto do cadastro' },
          error
        );
      })
      .finally(() => this.setState({ loadingDebitos: false }));
  };

  getDebitosPassiveisParcelamento = (
    cadastro: ParcelamentoCadastroDTO,
    leis: ParametroParcelamento[]
  ) => {
    leis.forEach(lei => {
      ParcelamentoService.getDebitosPassiveisParcelamento(
        cadastro.tipoCadastro,
        cadastro.cadastroGeral,
        lei.id
      )
        .then(response => {
          this.setState(oldState => ({
            debitosPassiveisParcelamento: {
              ...oldState.debitosPassiveisParcelamento,
              [lei.id]: response.data
            }
          }));
        })
        .catch(error => {
          Alert.error(
            {
              title: `Erro ao buscar os débitos passíveis de parcelamento da lei ${lei.descricao}.`
            },
            error
          );
        });
    });
  };

  getConcedeDescontoNoPagamento = () => {
    return (
      Object.keys(this.state.debitosPassiveisParcelamento)
        .flatMap(key => this.state.debitosPassiveisParcelamento[key])
        .findIndex(debito => debito.concedeDescontoNoPagamento === true) >= 0
    );
  };

  onChangeLeiSelecionada = (
    leiSelecionada: ParametroParcelamento,
    debitosSelecionados: number[]
  ) => {
    this.setState(oldState => {
      let valorSelecionado = 0;
      let totalDebitos = 0;
      if (this.getConcedeDescontoNoPagamento() === true) {
        valorSelecionado =
          oldState.debitosPassiveisParcelamento[leiSelecionada.id]
            ?.filter(debito => debitosSelecionados.includes(debito.iddebito))
            ?.reduce((result, current) => result + current.valordebito, 0) ?? 0;

        totalDebitos =
          Object.keys(oldState.debitosPassiveisParcelamento)
            .map(key => oldState.debitosPassiveisParcelamento[key])
            .reduce((result, current) => result + current.valordebito, 0) ?? 0;
      } else {
        valorSelecionado =
          oldState.debitosEmAberto?.debitos
            ?.filter(debito => debitosSelecionados.includes(debito.idDebito))
            ?.reduce((result, current) => result + current.valorTotal, 0) ?? 0;

        totalDebitos = oldState.debitosEmAberto?.valorTotal!;
      }
      const totalizador: DebitoParcelamentoTotalizador = {
        quantidadeDebitosSelecionados: debitosSelecionados.length,
        totalDebitos,
        totalDebitosSelecionados: valorSelecionado,
        totalDebitosPendentes: totalDebitos - valorSelecionado
      };
      return {
        leiSelecionada,
        debitosSelecionados,
        totalizadorDebitosSelecionados: totalizador,
        simulacaoSelecionada: undefined,
        simulacoes: []
      };
    });
  };

  onCheckTermoConfissao = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checkedTermoConfissaoDivida: event.target.checked });
  };

  onValidateDadosGeraisStep = ({ cadastroSelecionado }: State): string => {
    if (!this.props.requerente) {
      return 'Requerente é obrigatório';
    }
    if (!cadastroSelecionado) {
      return 'Selecione um cadastro geral';
    }

    return '';
  };

  onValidateResumoStep = ({ checkedTermoConfissaoDivida }: State): string => {
    if (!checkedTermoConfissaoDivida) {
      return 'Leia e aceite o termo de confissão de dívida';
    }
    return '';
  };

  beforeChangeStep = async (oldStepData: any, newStepData: any) => {
    const stepDadosGerais = 'stepDadosGerais';
    const stepDebitos = 'stepDebitos';
    const stepSimulacoes = 'stepSimulacoes';

    let errorMessage: string = '';
    this.setState({ errors: {} });

    if (newStepData.index < oldStepData.index) {
      return { oldStepData, newStepData };
    }

    const {
      debitosEmAberto,
      debitosSelecionados,
      simulacaoSelecionada
    } = this.state;

    if (oldStepData.stepId === stepDadosGerais) {
      errorMessage = this.onValidateDadosGeraisStep(this.state);
    } else if (oldStepData.stepId === stepDebitos) {
      if (debitosEmAberto?.debitos.length === 0) {
        errorMessage = 'Esse cadastro não possui débitos em aberto ';
      }
    } else if (oldStepData.stepId === 'stepLeis') {
      if (debitosSelecionados.length === 0) {
        errorMessage = 'Selecione pelo menos um débito para parcelar';
      }
    } else if (oldStepData.stepId === stepSimulacoes) {
      if (!simulacaoSelecionada) {
        errorMessage = 'Selecione uma simulação';
      }
    }

    if (errorMessage) {
      this.props.showNotification({
        message: errorMessage,
        level: 'error'
      });
    }

    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [oldStepData.stepId]: errorMessage
      }
    }));

    return {
      oldStepData: { ...oldStepData, valid: !errorMessage },
      newStepData
    };
  };

  limparSimulacao = () =>
    this.setState({ simulacoes: [], simulacaoSelecionada: undefined });

  selectSimulacao = (simulacao: SimulacaoDTO) =>
    this.setState({ simulacaoSelecionada: simulacao });

  gerarSimulacao = (lei: ParametroParcelamento, numeroParcelas: number) => {
    this.setState({ loading: true }, () => {
      const {
        cadastroSelecionado,
        debitosSelecionados,
        totalizadorDebitosSelecionados
      } = this.state;
      if (!cadastroSelecionado) {
        return;
      }
      ParcelamentoService.gerarSimulacao({
        tipoCadastro: cadastroSelecionado.tipoCadastro,
        cadastroGeral: cadastroSelecionado.cadastroGeral,
        idParamParcelamento: lei.id,
        numeroParcela: numeroParcelas,
        requerente: cadastroSelecionado.codigoPessoa,
        idDebitos: debitosSelecionados,
        valorTotal:
          totalizadorDebitosSelecionados?.totalDebitosSelecionados ?? 0
      })
        .then(response => {
          this.setState(oldState => {
            if (
              oldState.simulacoes.find(
                simulacao => simulacao.hash === response.data.hash
              )
            ) {
              return null;
            }
            return {
              simulacoes: [...oldState.simulacoes, response.data],
              simulacaoSelecionada: response.data
            };
          });
        })
        .catch(error => {
          Alert.error({ title: 'Erro ao gerar a simulação' }, error);
        })
        .finally(() => this.setState({ loading: false }));
    });
  };

  getDebitosSelecionados = () => {
    const { debitosEmAberto, debitosSelecionados } = this.state;
    return (
      debitosEmAberto?.debitos.filter(debito =>
        debitosSelecionados.includes(debito.idDebito)
      ) ?? []
    );
  };

  render() {
    const {
      loading,
      cadastroSelecionado,
      errors,
      loadingCadastros,
      cadastrosRequerente,
      leiSelecionada,
      checkedTermoConfissaoDivida,
      permissaoParcelamento,
      debitosEmAberto,
      loadingDebitos,
      leis,
      debitosPassiveisParcelamento,
      debitosSelecionados,
      simulacoes,
      simulacaoSelecionada,
      totalizadorDebitosSelecionados,
      pagination
    } = this.state;

    const { requerente } = this.props;

    return (
      <Container title="Parcelamentos" icon="file-contract">
        <Loading loading={loading} />

        {permissaoParcelamento.isAutorizadoParcelamento ? (
          <Wizard
            finishButtonOnlyOnLastStep
            onFinish={this.onSubmit}
            beforeChange={this.beforeChangeStep}
            allowIconNavigation={false}
          >
            <Wizard.Step
              stepId="stepDadosGerais"
              label="Dados Gerais"
              icon="fas fa-file-alt"
              errorMessage={errors.stepDadosGerais}
              valid={!errors.stepDadosGerais}
            >
              <DadosGeraisStep
                loading={loadingCadastros}
                select={this.onChangeCadastroGeral}
                cadastros={cadastrosRequerente}
                cadastroSelecionado={cadastroSelecionado}
                searchCadastros={this.searchCadastros}
                paginationSearch={this.paginationSearch}
                pagination={pagination}
              />
            </Wizard.Step>
            <Wizard.Step
              stepId="stepDebitos"
              label="Débitos"
              icon="fas fa-dollar-sign"
            >
              <DebitosStep
                requerente={requerente!}
                cadastro={cadastroSelecionado!}
                debitosEmAberto={debitosEmAberto}
                loading={loadingDebitos}
              />
            </Wizard.Step>
            <Wizard.Step
              stepId="stepLeis"
              label="Leis"
              icon="fas fa-gavel"
              errorMessage={errors.stepLeis}
              valid={!errors.stepLeis}
            >
              <LeisStep
                cadastro={cadastroSelecionado!}
                requerente={requerente!}
                leis={leis}
                debitosPassiveisParcelamento={debitosPassiveisParcelamento}
                idDebitosSelecionados={debitosSelecionados}
                onSelect={this.onChangeLeiSelecionada}
                idLeiSelecionada={leiSelecionada?.id}
                valorTotalDebitosAbertos={debitosEmAberto?.valorTotal}
              />
            </Wizard.Step>
            <Wizard.Step
              stepId="stepSimulacoes"
              label="Simulações"
              icon="fas fa-calculator"
              errorMessage={errors.stepSimulacoes}
              valid={!errors.stepSimulacoes}
            >
              <SimulacoesStep
                cadastro={cadastroSelecionado!}
                requerente={requerente!}
                totalizador={totalizadorDebitosSelecionados!}
                lei={leiSelecionada!}
                simulacoes={simulacoes}
                simulacaoSelecionada={simulacaoSelecionada}
                onLimpar={this.limparSimulacao}
                onSimular={this.gerarSimulacao}
                onSelect={this.selectSimulacao}
              />
            </Wizard.Step>
            <Wizard.Step
              stepId="stepResumo"
              label="Resumo"
              icon="fas fa-check"
              errorMessage={errors.stepResumo}
              valid={!errors.stepResumo}
            >
              <ResumoStep
                requerente={requerente!}
                cadastro={cadastroSelecionado!}
                leiSelecionada={leiSelecionada!}
                debitosSelecionados={this.getDebitosSelecionados()}
                totalizador={totalizadorDebitosSelecionados!}
                simulacaoSelecionada={simulacaoSelecionada!}
                onCheckTermoConfissao={this.onCheckTermoConfissao}
                checkedTermoConfissaoDivida={checkedTermoConfissaoDivida}
                concedeDescontoNoPagamento={this.getConcedeDescontoNoPagamento()}
                debitosPassiveisParcelamento={debitosPassiveisParcelamento}
              />
            </Wizard.Step>
          </Wizard>
        ) : (
          permissaoParcelamento.instrucaoAcessoParcelamento &&
          !loading && (
            <InstrucaoParcelamento
              instrucaoAcessoParcelamento={
                permissaoParcelamento.instrucaoAcessoParcelamento
              }
            />
          )
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => ({
  requerente: state.user.currentUser
});

const ConnectedComponent = connect(mapStateToProps, {
  showNotification: NotificationActions.showNotification
})(ParcelamentoFormPage);

export { ConnectedComponent as default, ParcelamentoFormPage };
