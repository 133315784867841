import {
  Alert,
  Container,
  Loading,
  Panel,
  SearchPagination,
  useAuth,
  usePagedQuery
} from '@elotech/components';
import { PropriedadeService } from 'itbi-common/service';
import {
  CadastroGeralCidadaoFiltroDTO,
  CadastroGeralCidadaoMobileDTO
} from 'itbi-common/type';
import React, { useEffect, useState } from 'react';

import CadastrosFilter from './CadastrosFilter';
import CadastrosList from './CadastrosList';

const initialFilter: CadastroGeralCidadaoFiltroDTO = {
  cpfCnpj: ''
};

const CadastrosPage = () => {
  const { user } = useAuth();
  const [listProprietarios, setListProprietarios] = useState<any[]>([]);
  const [updateListProprietarios, setUpdateListProprietarios] = useState<
    boolean
  >(true);
  const [filtro, setFiltro] = useState<CadastroGeralCidadaoFiltroDTO>(
    initialFilter
  );

  const {
    loading,
    values,
    pagination,
    doSearch,
    doSortChange,
    doPagedSearch
  } = usePagedQuery<
    CadastroGeralCidadaoMobileDTO,
    CadastroGeralCidadaoFiltroDTO
  >({
    search: PropriedadeService.findCadastrosByProprietario,
    onError: (error: any) => {
      Alert.error(
        {
          title: 'Não foi possível consultar os cadastros'
        },
        error
      );
    }
  });

  const onChangeSelect = (target: any) => {
    const proprietarioSelected = listProprietarios.find(
      (item: { cpfCnpj: any }) => item.cpfCnpj === target.currentTarget.value
    );
    if (proprietarioSelected) {
      setFiltro({ cpfCnpj: proprietarioSelected.cpfCnpj });
      doSearch({ cpfCnpj: proprietarioSelected.cpfCnpj });
    } else {
      setFiltro(initialFilter);
    }
  };

  useEffect(() => {
    if (updateListProprietarios) {
      setListProprietarios(user.usuariosProcurador || []);
      if (user.id && !listProprietarios.some(p => p.id === user.id)) {
        setListProprietarios([...listProprietarios, user]);
      }
      setUpdateListProprietarios(false);
    }
  }, [listProprietarios, user, updateListProprietarios]);
  return (
    <Container title="Propriedades" icon="building">
      <Loading loading={loading} />
      <div className="row mb-xs">
        <div className="form-group col-md-6">
          <label htmlFor="proprietario">Proprietário</label>
          <select
            data-test-id="select-proprietario"
            name="proprietario"
            onChange={onChangeSelect}
          >
            <option value="">Selecione</option>
            {listProprietarios.map(item => (
              <option key={item.cpfCnpj} value={item.cpfCnpj}>
                {item.nome}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Panel isTable>
        {filtro.cpfCnpj.length > 0 && (
          <CadastrosFilter
            filtro={filtro}
            onSearch={doSearch}
            isFiltroCarne={false}
          />
        )}
        <CadastrosList
          loading={loading}
          cadastros={values}
          onSortSearch={doSortChange}
        />
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
    </Container>
  );
};

export default CadastrosPage;
