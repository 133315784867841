import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

type InitialValue<T> = T | (() => T);

function usePreviousState<S>(
  initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>, S];

function usePreviousState<S = undefined>(): [
  S | undefined,
  Dispatch<SetStateAction<S | undefined>>,
  S | undefined
];

function usePreviousState<Value = undefined>(
  initialValue?: InitialValue<Value>
) {
  const [value, setValue] = useState<Value>(initialValue!);
  const previousValue = useRef<Value>(value);

  useEffect(() => {
    previousValue.current = value;
  }, [value]);

  return [value, setValue, previousValue.current];
}

export default usePreviousState;
