import {
  Alert,
  Button,
  Checkbox,
  Col,
  Container,
  DateUtils,
  FormattedCurrency,
  FormattedDate,
  Hint,
  Icon,
  InputDate,
  Loading,
  Panel,
  PanelFooter,
  PanelFooterItem,
  Row,
  Table
} from '@elotech/components';
import { BloquetoService, ExtratoDebitoService } from 'itbi-common/service';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { DebitoDTO } from '../../../types';

type Props = {};

type Params = {
  tipoCadastro: string;
  cadastroGeral: string;
};

type CodigoDeBarras = {
  codigoBarras: string;
  codigoBarrasImagem: string;
  dataVencimento: string;
  valorBloqueto: number;
  urlImpressao: string;
  textoQrCodePix: string;
  qrCodePixImagem: string;
};

const ExtratoListPage: React.FC<Props> = () => {
  const params = useParams<Params>();
  const [loading, setLoading] = useState(false);
  const [debitos, setDebitos] = useState<DebitoDTO[]>([]);
  const [selecionados, setSelecionados] = useState<Record<string, boolean>>({});
  const [codigoDeBarra, setCodigoDeBarra] = useState<CodigoDeBarras>();
  const [dataReferencia, setDataReferencia] = useState<string>(
    DateUtils.getTodayDate()
  );

  useEffect(() => {
    setLoading(true);
    ExtratoDebitoService.search(params.tipoCadastro, params.cadastroGeral)
      .then(response => {
        setDebitos(response.data);
        setSelecionados({});
        setLoading(false);
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao buscar debitos' }, error);
      })
      .finally(() => setLoading(false));
  }, [params]);

  const keyExtractor = (item: DebitoDTO) => `${item.idDebito}-${item.parcela}`;

  const allChecked = useMemo(
    () =>
      debitos.length > 0 &&
      Object.values(selecionados).filter(Boolean).length ===
        debitos.filter(debito => debito.emiteBloqueto).length,
    [selecionados, debitos]
  );

  const mudaValor = (item: DebitoDTO) => {
    const key = keyExtractor(item);

    setSelecionados(oldSelecionados => ({
      ...oldSelecionados,
      [key]: !oldSelecionados[key]
    }));
  };

  const checkAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const novosSelecionados = checked
      ? debitos
          .filter(debito => debito.emiteBloqueto)
          .reduce((acc, curr) => ({ ...acc, [keyExtractor(curr)]: true }), {})
      : {};

    setSelecionados(novosSelecionados);
  };

  const visualizarCodigoBarras = (dataReferencia: string) => {
    setLoading(true);
    let debitosSelecionado = debitos
      .filter(item => selecionados[keyExtractor(item)])
      .flatMap(debito => debito.debitos);

    if (debitosSelecionado.length === 0) {
      Alert.warning({
        title: 'Selecione ao menos um debito antes de realizar a consulta'
      });
      setCodigoDeBarra(undefined);
      setLoading(false);
    } else {
      BloquetoService.visualizarCodigoDeBarras(
        debitosSelecionado,
        dataReferencia
      )
        .then(response => {
          setCodigoDeBarra(response.data);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }
  };

  const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
  };

  return (
    <Container title="Extratos de debitos" icon="list">
      <Loading loading={loading} />
      <Panel
        isTable
        footer={
          <PanelFooter>
            <Col md={3}>
              <Button
                type="submit"
                color="positive"
                className="mt-xs"
                onClick={() => visualizarCodigoBarras(dataReferencia)}
              >
                Gerar Boleto
              </Button>
            </Col>
            <Col md={3}>
              <PanelFooterItem
                label="Total"
                value={
                  <FormattedCurrency
                    value={debitos
                      .map(deb => deb.valor)
                      .reduce((temp, value) => temp + value, 0)}
                  />
                }
              />
            </Col>
            <Col md={3}>
              <PanelFooterItem
                label="Total selecionados"
                value={
                  <FormattedCurrency
                    value={debitos
                      .filter(item => selecionados[keyExtractor(item)])
                      .map(deb => deb.valor)
                      .reduce((temp, value) => temp + value, 0)}
                  />
                }
              />
            </Col>
          </PanelFooter>
        }
      >
        <Table values={debitos} keyExtractor={keyExtractor}>
          <Table.Column
            headerClassName="column-checkbox no-print"
            className="column-checkbox no-print"
            name="select-all-debitos"
            header={
              <div className="hidden-xs">
                <Checkbox
                  id="checkAll"
                  checked={allChecked}
                  onChange={checkAll}
                />
              </div>
            }
            value={(item: DebitoDTO, index) =>
              renderizaCheckBoxEmissaoBoleto(item, index)
            }
          />
          <Table.Column<DebitoDTO>
            header="Exercício"
            value={item => item.exercicio}
          />
          <Table.Column<DebitoDTO>
            header="Divida"
            value={item => `${item.guiaRecolhimento} - ${item.descricaoGuia}`}
          />
          <Table.Column<DebitoDTO>
            header="Subdivida"
            value={item => item.subDivida}
          />
          <Table.Column<DebitoDTO>
            header="Parcela"
            value={item => item.parcela}
          />
          <Table.Column<DebitoDTO>
            header="Situação"
            value={item => (
              <>
                <span className="no-print">{item.situacao}</span>
                <Hint classes="inline clean module-color fa-exclamation-circle mobile sm bottom-right">
                  {item.descricaoSituacao}
                </Hint>
              </>
            )}
          />
          <Table.Column<DebitoDTO>
            header="Valor"
            value={item => <FormattedCurrency value={item.valor} />}
          />
          <Table.Column<DebitoDTO>
            header="Data Vencimento"
            value={item => <FormattedDate value={item.vencimento} />}
          />
        </Table>
      </Panel>

      {codigoDeBarra && (
        <Panel>
          <div className="col-md-8 left">
            <div className="form-group left">
              <label className="label">Valor total</label>
              <FormattedCurrency value={codigoDeBarra.valorBloqueto} />
            </div>
          </div>

          <div className="col-md-4 rigth">
            <div className="form-group center ">
              <label className="label">Data Pagamento</label>
              <InputDate
                name="dataPagamento"
                data-testid="dataPagamento"
                value={dataReferencia}
                min={DateUtils.getTodayDate()}
                onChange={(event: React.ChangeEvent<any>) => {
                  visualizarCodigoBarras(event.target.value);
                  setDataReferencia(event.target.value);
                }}
              />
            </div>
          </div>

          <div className="col-md-12 center">Código de Barras</div>

          <div className="col-md-12 center">{codigoDeBarra.codigoBarras}</div>

          <div className="col-md-12 center">
            <img
              alt={'Código de Barras'}
              src={`data:image/*;base64,${codigoDeBarra.codigoBarrasImagem}`}
            />
          </div>

          {codigoDeBarra.qrCodePixImagem && (
            <>
              <div className="col-md-12 center">QRCode</div>

              <div className="col-md-12 center">
                <img
                  alt={'QR code'}
                  src={`data:image/*;base64,${codigoDeBarra.qrCodePixImagem}`}
                />
              </div>
            </>
          )}

          <Row className="center">
            <Button
              type="submit"
              color="positive"
              className="mt-xs inline center"
              onClick={() => {
                window.open(codigoDeBarra.urlImpressao, '_blank');
              }}
            >
              Imprimir Boleto
            </Button>

            <Button
              type="submit"
              color="positive"
              className="mt-xs inline center"
              onClick={() => copyToClipboard(codigoDeBarra.textoQrCodePix)}
            >
              Copiar Código Pix
            </Button>

            <Button
              type="submit"
              color="positive"
              className="mt-xs inline center"
              onClick={() => copyToClipboard(codigoDeBarra.codigoBarras)}
            >
              Copiar Código Boleto
            </Button>
          </Row>
        </Panel>
      )}
    </Container>
  );

  function renderizaCheckBoxEmissaoBoleto(
    item: DebitoDTO,
    index: number
  ): React.ReactNode {
    return !item.emiteBloqueto ? (
      <Icon
        data-testid={`${index}-bloqueio-geracao-boleto`}
        className="icon negative"
        icon="ban"
        color="red"
      />
    ) : (
      <Checkbox
        id={`checkbox-${index}`}
        checked={selecionados[keyExtractor(item)]}
        onChange={() => mudaValor(item)}
      />
    );
  }
};

export default ExtratoListPage;
