import * as Types from './types';

const handleLoadVinculos = (state, action) => ({
  ...state,
  vinculos: action.payload.reduce(
    (acumulado, atual) => ({ ...acumulado, [atual.name]: atual.descricao }),
    {}
  )
});

const handlers = {
  [Types.LOAD_VINCULOS]: handleLoadVinculos
};

const ProprietarioReducer = (state = {}, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};

export default ProprietarioReducer;
