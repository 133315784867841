import { TextAlignProperty } from 'csstype';
import React, { ChangeEvent, FC } from 'react';
import {
  NumberFormatProps,
  NumberFormatValues,
  SyntheticInputEvent
} from 'react-number-format';

import EditableInput, { EditableInputProps } from './EditableInput';
import { InputInteger } from '..';

type Props = EditableInputProps<number> & {
  allowNegative?: boolean;
  align?: TextAlignProperty;
} & NumberFormatProps;

const normalizedChange = (onChange: (e: React.ChangeEvent<any>) => void) => (
  { floatValue }: NumberFormatValues,
  { target: { name } }: SyntheticInputEvent
) => {
  const value = floatValue === undefined ? '' : floatValue;
  onChange({ target: { name, value: value } } as ChangeEvent<any>);
};

const EditableInputInteger: FC<Props> = props => {
  return (
    <EditableInput<number>
      {...props}
      renderEditComponent={({ value, onBlur, onChange, onKeyDown }) => (
        <InputInteger
          autoFocus={!props.defaultOpened}
          name={name}
          value={value}
          onBlur={onBlur}
          onValueChange={normalizedChange(onChange)}
          onKeyDown={onKeyDown}
          style={props.align ? { textAlign: props.align } : {}}
        />
      )}
    />
  );
};

export default EditableInputInteger;
