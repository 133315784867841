import classnames from 'classnames';
import React, { ReactNode } from 'react';

import { Spinner } from '../icons/Spinner';

type Props = {
  xs?: number;
  sm?: number;
  md?: number;
  icon: string;
  number?: ReactNode;
  title: ReactNode;
  description?: ReactNode;
  onClick?(): void;
  error?: boolean;
  alignItem?: 'left' | 'center';
  className?: string;
  children?: ReactNode;
  loading?: boolean;
};

const BlockDash: React.FC<Props> = ({
  xs = 12,
  sm = 12,
  md = 12,
  icon,
  number,
  title,
  description,
  onClick,
  error,
  alignItem,
  className,
  children,
  loading = false
}) => {
  const classesBlockDash = classnames('block-dash', className, {
    error,
    [`${alignItem}`]: !!alignItem,
    list: !!children
  });

  return (
    <div className={`col-md-${md} col-sm-${sm} col-xs-${xs}`}>
      <div className={classesBlockDash} onClick={onClick}>
        <i className={icon} />
        {loading ? (
          <Spinner />
        ) : (
          number && <div className="number">{number}</div>
        )}
        <div className="title">{title}</div>
        {description && <div className="description">{description}</div>}
        {children}
      </div>
    </div>
  );
};

export default BlockDash;
