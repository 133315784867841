import axios from 'axios';

import { UserInfo } from '../providers/KeycloakProvider';
import KeycloakService from './KeycloakService';
import UnicoService from './UnicoService';

type LogoutOptions = {
  redirectUri?: string;
};

const refreshToken = async (opts: {}): Promise<string | undefined> => {
  if (KeycloakService.isInitialized()) {
    return KeycloakService.refreshToken(opts).then(() =>
      KeycloakService.getToken()
    );
  }
  if (UnicoService.isInitialized()) {
    return Promise.resolve(UnicoService.getToken());
  }
  throw new Error('Nenhum service de autenticação foi inicializado');
};

const isLogged = (): boolean => {
  if (KeycloakService.isInitialized()) {
    return KeycloakService.getInstance().authenticated ?? false;
  }
  if (UnicoService.isInitialized()) {
    return UnicoService.isLogged();
  }
  throw new Error('Nenhum service de autenticação foi inicializado');
};

const logout = (opts?: LogoutOptions): Promise<void> => {
  if (KeycloakService.isInitialized()) {
    return new Promise((resolve, reject) =>
      KeycloakService.logout(opts)
        .success(() => resolve())
        .error(error => reject(error))
    );
  }
  if (UnicoService.isInitialized()) {
    UnicoService.logout();
    UnicoService.redirectToLoginPage();
    return Promise.resolve();
  }
  throw new Error('Nenhum service de autenticação foi inicializado');
};

const updateUserAttributes = (
  attributes: Record<string, string[]>
): Promise<UserInfo> => {
  if (KeycloakService.isInitialized()) {
    return axios
      .put<UserInfo>(
        `${KeycloakService.getUrl()}/usuario/me/attributes`,
        attributes,
        {
          headers: {
            Authorization: `Bearer ${KeycloakService.getToken()}`,
            'x-tenant-host': window.location.host
          }
        }
      )
      .then(response => response.data);
  }
  if (UnicoService.isInitialized()) {
    return Promise.resolve({} as UserInfo);
  }
  throw new Error('Nenhum service de autenticação foi inicializado');
};

export type AuthType = 'KEYCLOAK' | 'UNICO';

const getAuthType = (): AuthType => {
  if (KeycloakService.isInitialized()) {
    return 'KEYCLOAK';
  }
  if (UnicoService.isInitialized()) {
    return 'UNICO';
  }
  throw new Error('Nenhum service de autenticação foi inicializado');
};
export default {
  refreshToken,
  isLogged,
  logout,
  updateUserAttributes,
  getAuthType
};
