import {
  FormattedCurrency,
  Panel,
  PanelFooter,
  PanelFooterItem,
  SectionTitle,
  Table
} from '@elotech/components';
import React from 'react';

import { ParcelamentoDebitoDTO } from '../../types';

type Props = {
  debitos: ParcelamentoDebitoDTO[];
};

const Footer: React.FC<{ total: number }> = ({ total }) => (
  <PanelFooter>
    <PanelFooterItem
      align="right"
      inline
      label="Total"
      value={<FormattedCurrency value={total} />}
    />
  </PanelFooter>
);

const getTotal = (debitos: ParcelamentoDebitoDTO[]) =>
  debitos.map(item => item.valor).reduce((acc, curr) => acc + curr, 0);

const DebitosList: React.FC<Props> = ({ debitos }) => {
  return (
    <>
      <SectionTitle>Débitos Origem</SectionTitle>
      <Panel isTable footer={<Footer total={getTotal(debitos)} />}>
        <Table values={debitos} keyExtractor={item => item.idDebito} fixed>
          <Table.Column<ParcelamentoDebitoDTO>
            header="Ano"
            value={item => item.exercicio}
          />
          <Table.Column<ParcelamentoDebitoDTO>
            header="Dívida"
            value={item => item.guiaRecolhimento}
          />
          <Table.Column<ParcelamentoDebitoDTO>
            header="Subdivida"
            value={item => item.subDivida}
          />
          <Table.Column<ParcelamentoDebitoDTO>
            header="Valor"
            headerClassName="right"
            className="right"
            value={item => <FormattedCurrency value={item.valor} />}
          />
        </Table>
      </Panel>
    </>
  );
};

export { DebitosList as default, Footer };
