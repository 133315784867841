import { PageRequest, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';
import { TermoResponsabilidade } from 'itbi-servidor/src/types';

export const defaultSort = { sort: 'dataCriacao,desc' };

export const getTermo = (): AxiosPromise<any> =>
  axios.get(`/api/termoresponsabilidade`);

export const searchTermos = (
  searchParams: string,
  pagination?: PageRequest
): AxiosPromise<PagedResponse<TermoResponsabilidade>> =>
  axios.get(`/api/termoresponsabilidade/search`, {
    params: { ...defaultSort, ...pagination, search: searchParams }
  });

export const remove = (id: string): AxiosPromise<TermoResponsabilidade> =>
  axios.delete(`/api/termoresponsabilidade/${id}`);

export const save = (
  termoResponsabilidade: TermoResponsabilidade,
  id: string
): AxiosPromise<TermoResponsabilidade> =>
  id && id !== 'novo'
    ? axios.put(`/api/termoresponsabilidade/${id}`, termoResponsabilidade)
    : axios.post('/api/termoresponsabilidade', termoResponsabilidade);

export const findOne = (id: string): AxiosPromise<TermoResponsabilidade> =>
  axios.get(`/api/termoresponsabilidade/${id}`);
