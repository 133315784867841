import axios from 'axios';
import Cookies from 'js-cookie';

import { UserInfo } from '../providers/KeycloakProvider';

let url = '';

const TOKEN_NAME = 'authorization';

const USER_INFO_COOKIE_NAME = 'authorization_usuarioinfo_unico';

const isLogged = (): boolean => {
  return getToken() !== undefined;
};

const getToken = (): string | undefined => {
  return Cookies.get(TOKEN_NAME);
};

const redirectToLoginPage = () => {
  const currentLocation = window.location.href;
  window.location.href = `${url}/login/auth?redirect=${currentLocation}`;
};

const setLoginData = (token: string) => {
  Cookies.set(TOKEN_NAME, token);
};

const logout = () => {
  Cookies.remove(TOKEN_NAME);
};

const setUrl = (currentUrl: string) => {
  url = currentUrl;
};

const isInitialized = (): boolean => url !== '';

const getUserInfo = (): Promise<UserInfo> => {
  const userData = Cookies.getJSON(USER_INFO_COOKIE_NAME);
  return axios
    .get(`${url}/api/usuarios/byname/${userData.username}`)
    .then(({ data }) => ({
      groups: [],
      email: data.email,
      nome: data.nome,
      username: data.usuario,
      id: data.id,
      sub: data.id,
      name: data.nome
    }));
};

export default {
  isLogged,
  setLoginData,
  logout,
  setUrl,
  redirectToLoginPage,
  isInitialized,
  getToken,
  getUserInfo
};
