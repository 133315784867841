import {
  Button,
  Col,
  FormattedCurrency,
  FormattedDate,
  Hint,
  HintContent,
  HintRow,
  Panel,
  RadioButton,
  Row,
  SectionTitle,
  Table
} from '@elotech/components';
import React, { useState } from 'react';

import {
  DebitoParcelamentoTotalizador,
  ParametroParcelamento,
  ParcelamentoCadastroDTO,
  SimulacaoDTO
} from '../../../types';
import { Requerente } from './ParcelamentoFormPage';
import ParcelamentoIdentificacao from './ParcelamentoIdentificacao';

type Props = {
  cadastro: ParcelamentoCadastroDTO;
  requerente: Requerente;
  totalizador: DebitoParcelamentoTotalizador;
  lei: ParametroParcelamento;
  simulacoes: SimulacaoDTO[];
  simulacaoSelecionada?: SimulacaoDTO;
  onSimular: (lei: ParametroParcelamento, numeroParcelas: number) => void;
  onSelect: (index: SimulacaoDTO) => void;
  onLimpar: () => void;
};

const SimulacoesStep: React.FC<Props> = ({
  cadastro,
  requerente,
  totalizador,
  lei,
  simulacoes,
  simulacaoSelecionada,
  onSimular,
  onSelect,
  onLimpar
}) => {
  const [numeroParcelas, setNumeroParcelas] = useState<number>(1);

  return (
    <>
      <ParcelamentoIdentificacao
        cadastro={cadastro}
        requerente={requerente}
        totalizador={totalizador}
        lei={lei}
      />
      <Row className={'mt-xs'}>
        <Col md={2}>
          <label htmlFor="numeroParcelas" className="label">
            Número de Parcelas
          </label>
          <select
            value={numeroParcelas}
            onChange={event => setNumeroParcelas(+event.target.value)}
            id={'numeroParcelas'}
          >
            {Array(lei.numeroParcela)
              .fill('')
              .map((item, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
          </select>
        </Col>
        <Col md={2}>
          <label className="label" />
          <Button onClick={() => onSimular(lei, numeroParcelas)}>
            Simular
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={simulacaoSelecionada ? 8 : 12}>
          <SectionTitle hasButton>
            Simulações Realizadas
            {simulacoes.length > 0 && (
              <Button onClick={onLimpar}>Limpar simulações</Button>
            )}
          </SectionTitle>
          <Panel isTable>
            <Table
              values={simulacoes}
              fixed
              onClickRow={item => onSelect(item)}
              keyExtractor={item => item.hash}
            >
              <Table.Column
                header=""
                name="check-simulacao-realizada"
                value={(item: SimulacaoDTO) => (
                  <RadioButton
                    options={item}
                    selectedValue={item.hash === simulacaoSelecionada?.hash}
                    name="atendimento-contratos-parcelamento-novo"
                    id={item.numeroParcelas}
                  />
                )}
              />
              <Table.Column
                header="Qtd. Parcelas"
                value={(item: SimulacaoDTO) => item.numeroParcelas}
              />
              <Table.Column
                header="Valor Entrada"
                className={'right'}
                headerClassName={'right'}
                value={(item: SimulacaoDTO) => (
                  <FormattedCurrency value={item.valorEntrada} />
                )}
              />
              <Table.Column
                header="Valor Parcelas"
                className={'right'}
                headerClassName={'right'}
                value={(item: SimulacaoDTO) => (
                  <FormattedCurrency value={item.valorDemaisParcelas} />
                )}
              />
              <Table.Column
                header="Valor Desconto"
                className={'right'}
                headerClassName={'right'}
                style={{ whiteSpace: 'nowrap' }}
                value={(item: SimulacaoDTO) => (
                  <>
                    <FormattedCurrency value={item.valorDescontos} />
                    <Hint
                      classes={`inline clean module-color fa-exclamation-circle mobile sm bottom-right`}
                    >
                      <HintContent>
                        <HintRow>
                          Principal:
                          <FormattedCurrency
                            value={item.valorDescontoPrincipal}
                          />
                        </HintRow>
                        <HintRow>
                          Juros:
                          <FormattedCurrency value={item.valorDescontoJuros} />
                        </HintRow>
                        <HintRow>
                          Multa:
                          <FormattedCurrency value={item.valorDescontoMulta} />
                        </HintRow>
                        <HintRow>
                          Correção:
                          <FormattedCurrency
                            value={item.valorDescontoCorrecao}
                          />
                        </HintRow>
                      </HintContent>
                    </Hint>
                  </>
                )}
              />
              <Table.Column
                header="Valor Total"
                className={'right'}
                headerClassName={'right'}
                value={(item: SimulacaoDTO) => (
                  <FormattedCurrency value={item.valorTotal} />
                )}
              />
            </Table>
          </Panel>
        </Col>
        {simulacaoSelecionada && (
          <Col sm={4}>
            <SectionTitle>Parcelas</SectionTitle>
            <Panel isTable>
              <Table
                footer={
                  <tfoot>
                    <tr>
                      <td colSpan={2} className="right hidden-xs">
                        <b>Total</b>
                      </td>
                      <td className="right">
                        <b className="td-label">Total</b>
                        <span className="td-content">
                          <b>
                            <FormattedCurrency
                              value={simulacaoSelecionada?.valorTotal}
                            />
                          </b>
                        </span>
                      </td>
                      {simulacaoSelecionada?.concedeDescontoNoPagamento ? (
                        <td className="right">
                          <b className="td-label">Total no Vencimento</b>
                          <span className="td-content">
                            <b>
                              <FormattedCurrency
                                value={simulacaoSelecionada?.valorTotalAVista}
                              />
                            </b>
                          </span>
                        </td>
                      ) : (
                        undefined
                      )}
                    </tr>
                  </tfoot>
                }
                values={simulacaoSelecionada.debitosParcelaTributoParcelamento}
                fixed
                keyExtractor={item => item.parcela}
              >
                <Table.Column
                  header="Parcela"
                  value={(item: any) => item.parcela}
                />
                <Table.Column
                  header="Vencimento"
                  value={(item: any) => (
                    <FormattedDate value={item.dataVencimento} timeZone="UTC" />
                  )}
                />
                <Table.Column
                  header="Valor total"
                  headerClassName="right"
                  className="right"
                  value={(item: any) => (
                    <FormattedCurrency value={item.valorTotal} />
                  )}
                />
                {simulacaoSelecionada?.concedeDescontoNoPagamento ? (
                  <Table.Column
                    header="Valor no Vencimento"
                    headerClassName="right"
                    className="right"
                    value={(item: any) => (
                      <FormattedCurrency value={item.valorAVista} />
                    )}
                  />
                ) : (
                  undefined
                )}
              </Table>
            </Panel>
          </Col>
        )}
      </Row>
    </>
  );
};

export default SimulacoesStep;
