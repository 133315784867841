import { Container, Loading, NotificationActions } from '@elotech/components';
import { DeclaracaoItbiForm } from 'itbi-common/components';
import { ParametroService } from 'itbi-common/service';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class DeclaracaoForm extends Component {
  state = {
    permiteItbiRural: false,
    loading: true
  };

  componentDidMount() {
    ParametroService.loadAllParametros()
      .then(response => {
        this.setState({ permiteItbiRural: response.data.utilizaCadastroRural });
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const {
      match,
      history,
      showNotification,
      listRequerentes,
      user
    } = this.props;
    const { permiteItbiRural, loading } = this.state;

    return (
      <Container title="Declaração ITBI" icon="paste">
        <Loading loading={loading} />
        {!loading && (
          <DeclaracaoItbiForm
            showNotification={showNotification}
            history={history}
            listRequerentes={listRequerentes}
            user={user}
            match={match}
            isServidor={false}
            permiteItbiRural={permiteItbiRural}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const requerentes = state.user.currentUser.usuariosProcurador || [];

  const listRequerentes = [...requerentes];
  if (
    state.user.currentUser.id &&
    !listRequerentes.some(p => p.id === state.user.currentUser.id)
  ) {
    listRequerentes.unshift(state.user.currentUser);
  }

  return {
    user: state.user.currentUser,
    listRequerentes
  };
};

const ConnectedComponent = connect(mapStateToProps, {
  showNotification: NotificationActions.showNotification
})(DeclaracaoForm);

export { ConnectedComponent as default, DeclaracaoForm };
