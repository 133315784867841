import React from 'react';
type Props = {
  message: string;
};
export const UnknownErrorPage: React.FunctionComponent<Props> = ({
  message
}) => {
  return (
    <div className="not-found" style={{ maxWidth: '500px' }}>
      <h1>
        <em className="far fa-frown" />
      </h1>
      <h2>Desculpe, ocorreu um erro inesperado.</h2>
      <small>{message}</small>
      <a href={document.baseURI}>
        <button
          className="btn module-color icon-right center mt-xs"
          type="button"
        >
          Volte ao Início <em className="fa fa-home" />
        </button>
      </a>
    </div>
  );
};

export default UnknownErrorPage;
