export const sumNumericStrings = (...values: string[]): number => {
  return values
    .map(value => Number(value.replace(',', '.')))
    .reduce((acc, current) => acc + current, 0);
};

export const stripAccents = (value: string) =>
  value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const somenteNumeros = (value: string) =>
  value?.toString().replace(/\D/g, '') ?? '';

export const removePontosBarrasTracos = (value: string) =>
  value?.toString().replace(/[.\-/]/g, '');
