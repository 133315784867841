import {
  BasicInput,
  Loading,
  Masks,
  Row,
  SectionTitle
} from '@elotech/components';
import { Constantes } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

class EnderecoSectionForm extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    cepService: PropTypes.shape({
      buscaCep: PropTypes.func.isRequired
    }).isRequired,
    classError: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired
  };

  state = {
    loadingCep: false
  };

  onChangeCepValue = ({ target }) => {
    const { cepService, setFieldValue, setValues } = this.props;

    const newValue = target.value.replace(/\D/g, '');

    setFieldValue(target.name, newValue);

    if (newValue.length === 0) {
      setValues({
        ...this.props.values,
        bairro: '',
        cep: '',
        cidade: '',
        logradouro: '',
        numero: '',
        uf: '',
        complemento: '',
        cepValido: false
      });
    } else if (newValue.length >= 8) {
      this.setState({ loadingCep: true }, () => {
        cepService
          .buscaCep(newValue)
          .then(response => {
            const endereco = response.data;

            setValues({
              ...this.props.values,
              bairro: endereco.bairro,
              cep: endereco.cep,
              cidade: endereco.cidade,
              logradouro: endereco.logradouro,
              uf: endereco.uf,
              ibge: endereco.ibge,
              cepValido: true
            });
          })
          .catch(() => {
            setValues({
              ...this.props.values,
              cepValido: false
            });
          })
          .finally(() => {
            this.setState({ loadingCep: false });
          });
      });
    }
  };

  render() {
    const { title, errors, touched, classError } = this.props;

    const { loadingCep } = this.state;

    return (
      <React.Fragment>
        <SectionTitle>Endereço do {title}</SectionTitle>
        <Row>
          {loadingCep && <Loading loading={loadingCep} />}
          <BasicInput
            name="cep"
            label="CEP"
            data-test-id="input-cep"
            render={({ field }) => (
              <MaskedInput
                id="cep"
                {...field}
                onChange={this.onChangeCepValue}
                mask={Masks.MASK_CEP}
                className={classError('cep')}
              />
            )}
          />
          <BasicInput
            name="logradouro"
            label="Endereço"
            size={5}
            errors={errors}
            touched={touched}
          />
          <BasicInput
            name="numero"
            label="Número"
            errors={errors}
            touched={touched}
          />
          <BasicInput
            name="complemento"
            label={
              <React.Fragment>
                Complemento <span className="optional">Opcional</span>
              </React.Fragment>
            }
            size={3}
            errors={errors}
            touched={touched}
          />
        </Row>

        <Row>
          <BasicInput
            label="Bairro"
            name="bairro"
            size={5}
            errors={errors}
            touched={touched}
          />
          <BasicInput
            label="Cidade"
            name="cidade"
            size={5}
            errors={errors}
            touched={touched}
          />
          <BasicInput
            label={'Estado'}
            name="uf"
            size={2}
            render={({ field }) => (
              <select
                data-cy={'selectEstadoEndereco'}
                className={classError('uf')}
                {...field}
                data-testid="select-uf"
              >
                <option value="">Selecione</option>
                {Constantes.optionsEstados.map(item => (
                  <option key={item.key} value={item.key}>
                    {item.label}
                  </option>
                ))}
              </select>
            )}
          />
        </Row>
      </React.Fragment>
    );
  }
}

export default EnderecoSectionForm;
