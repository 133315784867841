import { ConcursoPremiadoResumoPorCpf } from 'itbi-common/type/ConcursoPremiado';
import React from 'react';

import { ReactComponent as ImagemAdesao } from '../../assets/img/iss_premiado.svg';

type Props = {
  concurso: ConcursoPremiadoResumoPorCpf;
};

const MENSAGENS_STATUS_CONCURSO = {
  VIGENTE: 'Concurso em fase de apuração',
  APURANDO: 'Concurso em fase de apuração',
  CUPONS_GERADOS: 'Concurso em fase de apuração',
  PUBLICADO: 'Concurso em fase de apuração',
  ENCERRADO: 'Concurso encerrado'
};

const ConcursoPremiadoAdesaoEncerrada: React.FC<Props> = ({ concurso }) => {
  return (
    <>
      <div className={'mt-xs'} style={{ textAlign: 'center' }}>
        <div>
          <ImagemAdesao
            className="module-color"
            style={{
              width: '535px',
              maxWidth: '100%'
            }}
          />
        </div>
        <p className={'typography-size-20px'}>
          {MENSAGENS_STATUS_CONCURSO[concurso.situacao]}
        </p>
      </div>
    </>
  );
};

export default ConcursoPremiadoAdesaoEncerrada;
