import classnames from 'classnames';
import React, { ReactElement, ReactNode } from 'react';

import ActionButton from '../buttons/ActionButtons';
import ActionsGroup from '../buttons/Actions';
import EmptyRow from '../text/EmptyRow';
import { Direction, Sort, SortState } from '../type/Sort';
import Column, { Props as ColumnProps } from './Column';
import Data from './Data';
import Header from './Header';
import SelectorColumn from './SelectorColumn';

type Props<T> = {
  loading?: boolean;
  values: T[];
  children:
    | ReactElement<ColumnProps<T>>
    | (ReactElement<ColumnProps<T>> | undefined)[];
  messageEmpty?: string;
  keyExtractor(item: T, index?: number): string | number;
  onClickRow(item: T, index: number): void;
  onDoubleClickRow?(item: T, index: number): void;
  highlight(item: T): boolean;
  highlightError?(item: T): boolean;
  highlightWarning?(item: T): boolean;
  highlightSuccess?(item: T): boolean;
  rowSelected?(item: T): boolean;
  renderInnerComponent?(item: T, index: number): React.ReactNode;
  reduced: boolean;
  tableClassName?: string;
  fixed: boolean;
  footer?: ReactNode;
  columnSelector?: boolean;
  striped?: boolean;
  renderFooter?: (renderFooterProps: RenderFooterProps) => ReactNode;
  sortOnHeaderColumnClick?: (sort: Sort) => void;
  defaultSort?: SortState;
  onPrint?: (options: TablePrintOptions) => void;
  scrollHorizontal?: boolean;
  subtitles?: SubtitlesProps;
  headerClassName?: string;
};
type TablePrintOptions = {
  fields: string;
};

type RenderFooterProps = {
  numeroColunasVisiveis: number;
};

type SubtitlesProps = {
  default?: string;
  positive?: string;
  negative?: string;
  highlight?: string;
};

type State = {
  visible: {
    [columnName: string]: boolean;
  };
  labels: {
    [columnName: string]: string;
  };
};

/*
 * Funciona só dentro de panel:
 * Utilizar com
 *   <Panel isTable></Panel>
 **/
class Table<T> extends React.Component<Props<T>, State> {
  static Column = Column;
  static SelectorColumn = SelectorColumn;

  static defaultProps = {
    keyExtractor: (item: any) => item.id,
    onClickRow: () => {},
    highlight: () => false,
    reduced: true,
    tableClassName: '',
    fixed: false
  };

  state: State = {
    visible: {},
    labels: {}
  };
  sortState: SortState = {
    name: this.props.defaultSort ? this.props.defaultSort.name : '',
    direction: this.props.defaultSort ? this.props.defaultSort.direction : ''
  };

  componentDidMount() {
    const newState = React.Children.toArray(this.props.children)
      .filter(
        (item, index, items) =>
          item !== undefined &&
          typeof item.props.header === 'string' &&
          item.props.header.length > 0
      )
      .reduce(
        (accumulator, current) => {
          const { name, header, defaultHidden } = current!.props;
          const columnName = name || header;
          if (typeof columnName !== 'string') {
            return accumulator;
          }
          return {
            visible: {
              ...accumulator.visible,
              [columnName]: !defaultHidden
            },
            labels: {
              ...accumulator.labels,
              [columnName]: header
            }
          };
        },
        { visible: {}, labels: {} }
      );

    this.setState(newState);
  }

  onColumnClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = { [event.target.name]: event.target.checked };
    this.setState(state => ({
      visible: { ...state.visible, ...newState }
    }));
  };

  shouldRender = (element: ReactElement<ColumnProps<T>>) => {
    const { name, header } = element.props;
    const columnName = name || header;
    return !(
      typeof columnName == 'string' && this.state.visible[columnName] === false
    );
  };

  lastColumn = (index: number, array: Array<any>) => {
    return index === array.length - 1;
  };

  onHeaderColumnClick = (name: string, direction: Direction) => {
    if (this.props.sortOnHeaderColumnClick && name) {
      this.sortState = { name, direction };
      this.props.sortOnHeaderColumnClick({ sort: `${name},${direction}` });
    }
  };

  onPrintClick = () => {
    const { onPrint } = this.props;
    const fields = this.getVisibleFields().join(',');
    onPrint && onPrint({ fields });
  };

  getVisibleFields = () => {
    return Object.entries(this.state.visible)
      .filter(([k, v]) => !!v)
      .map(([k, v]) => k);
  };

  isEmptyColumn = (child: ReactElement) => {
    const { scrollHorizontal } = this.props;
    return (
      scrollHorizontal &&
      typeof child.props.header === 'string' &&
      child.props.header.length === 0
    );
  };

  renderFooter = (displayedColumns: Array<any>) => {
    const { renderFooter, footer } = this.props;
    if (renderFooter) {
      return renderFooter({ numeroColunasVisiveis: displayedColumns.length });
    } else return footer;
  };

  renderSubtitles = () => {
    const { subtitles } = this.props;

    if (subtitles) {
      const subtitleEntries = Object.entries(subtitles);

      return (
        <div className="subtitles">
          {subtitleEntries.map(([type, subtitle]) => (
            <div
              key={type}
              className={classnames('subtitle', type !== 'default' && type)}
            >
              {subtitle}
            </div>
          ))}
        </div>
      );
    } else return null;
  };

  render() {
    const {
      values,
      children,
      messageEmpty,
      keyExtractor,
      reduced,
      highlight,
      highlightError,
      highlightWarning,
      highlightSuccess,
      tableClassName,
      headerClassName,
      fixed,
      footer,
      onClickRow,
      onDoubleClickRow,
      onPrint,
      renderInnerComponent,
      columnSelector,
      scrollHorizontal,
      striped = true,
      rowSelected,
      loading,
      defaultSort,
      sortOnHeaderColumnClick,
      ...rest
    } = this.props;

    const classesTable = classnames({
      'panel-table': true,
      striped: striped,
      fancy: true,
      fixed: fixed && values.length > 7,
      'tfoot-fixed': footer && fixed && values.length > 7,
      'placeholder-loader': loading
    });

    const displayedColumns = React.Children.toArray(children)
      .filter(child => child !== undefined)
      .filter(child => this.shouldRender(child!)) as ReactElement<
      ColumnProps<T>
    >[];

    const renderPrintButton = !!onPrint;

    const visibleFields = this.getVisibleFields();

    if (renderPrintButton && scrollHorizontal) {
      const actionsColumn = (
        <Table.Column
          headerClassName="many-header-buttons"
          headerOnly
          header={
            <>
              <i className="fa fa-ellipsis-v many-header-buttons-icon"></i>
              <div className="many-header-buttons-options">
                <div className="header-button ">
                  <ActionsGroup>
                    <ActionButton
                      key="print-button"
                      icon="print"
                      label="Imprimir"
                      onClick={this.onPrintClick}
                    />
                  </ActionsGroup>
                </div>
                {columnSelector && (
                  <div className="column-selector">
                    <Table.SelectorColumn
                      visibleFields={visibleFields}
                      fields={Object.keys(this.state.visible).map(key => ({
                        name: key,
                        label: this.state.labels[key] || key,
                        checked: this.state.visible[key]
                      }))}
                      scrollHorizontal
                      onClick={this.onColumnClick}
                    />
                  </div>
                )}
              </div>
            </>
          }
          value={item => undefined}
        />
      );
      displayedColumns.push(actionsColumn);
    } else if (renderPrintButton) {
      const actionsColumn = (
        <Table.Column
          headerClassName="header-button"
          headerOnly={scrollHorizontal}
          header={
            <ActionsGroup>
              <ActionButton
                key="print-button"
                icon="print"
                label="Imprimir"
                onClick={this.onPrintClick}
              />
            </ActionsGroup>
          }
          value={item => undefined}
        />
      );
      displayedColumns.splice(displayedColumns.length - 1, 0, actionsColumn);
    }

    return (
      <>
        <div
          className={classnames({
            'panel-table-outer': true,
            'has-column-selector': columnSelector,
            'has-header-button': renderPrintButton
          })}
        >
          <table className={`${classesTable} ${tableClassName}`} {...rest}>
            <thead className={headerClassName}>
              <tr>
                {displayedColumns.map((child: ReactElement, index: number) => {
                  const headerClasses = classnames(
                    child.props.headerClassName,
                    {
                      [this.sortState.direction]:
                        child.props.name === this.sortState.name,
                      [child.props.align]: child.props.align
                    }
                  );
                  return (
                    <Header
                      name={child.props.name}
                      sortable={child.props.sortable}
                      onHeaderClick={this.onHeaderColumnClick}
                      initialDirection={this.sortState.direction}
                      className={classnames(headerClasses, {
                        'empty-column': this.isEmptyColumn(child)
                      })}
                      key={child.props.name || child.props.header}
                      header={child.props.header}
                      columnSelector={
                        columnSelector &&
                        !scrollHorizontal &&
                        this.lastColumn(index, displayedColumns) ? (
                          <Table.SelectorColumn
                            visibleFields={visibleFields}
                            fields={Object.keys(this.state.visible).map(
                              key => ({
                                name: key,
                                label: this.state.labels[key] || key,
                                checked: this.state.visible[key]
                              })
                            )}
                            scrollHorizontal={false}
                            onClick={this.onColumnClick}
                          />
                        ) : (
                          undefined
                        )
                      }
                    />
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  {React.Children.toArray(children)
                    .filter(column => !!column)
                    .map((_, index) => (
                      <td key={index} />
                    ))}
                </tr>
              ) : (
                <React.Fragment>
                  {values.map((value, index) => (
                    <React.Fragment key={keyExtractor(value, index)}>
                      <tr
                        className={classnames({
                          reduced,
                          highlight: highlight && highlight(value),
                          rowSelected: rowSelected && rowSelected(value),
                          success: highlightSuccess && highlightSuccess(value),
                          error: highlightError && highlightError(value),
                          warning: highlightWarning && highlightWarning(value)
                        })}
                        onClick={() => onClickRow(value, index)}
                        onDoubleClick={() => onDoubleClickRow?.(value, index)}
                      >
                        {displayedColumns.map((child: ReactElement) => {
                          const {
                            name,
                            header,
                            headerOnly,
                            value: getValue,
                            style,
                            className,
                            ...childProps
                          } = child.props;

                          if (headerOnly) {
                            return null;
                          }
                          return (
                            <Data
                              key={name || header}
                              value={getValue(value, index)}
                              header={header}
                              className={classnames(className, {
                                'empty-column': this.isEmptyColumn(child)
                              })}
                              style={
                                this.isEmptyColumn(child) && index > 0
                                  ? { borderTop: '1px solid #DDDDDD' }
                                  : style
                              }
                              {...childProps}
                            />
                          );
                        })}
                      </tr>
                      {renderInnerComponent &&
                        renderInnerComponent(value, index)}
                    </React.Fragment>
                  ))}
                  <EmptyRow
                    show={!values || values.length === 0}
                    colSpan={React.Children.count(children)}
                    message={messageEmpty}
                  />
                </React.Fragment>
              )}
            </tbody>
            {this.renderFooter(displayedColumns)}
          </table>
        </div>
        {this.renderSubtitles()}
      </>
    );
  }
}

export default Table;
