const BASE_HREF_ATENDIMENTO_CIDADAO = '/cidadao';
const PREFIX_URL_LOGIN_ISS_MARINGA_PROD = 'ecity';
const PREFIX_URL_LOGIN_ISS_MARINGA_DEV = 'hom-ecity';

const LoginIssMaringaEnum = {
  prod: 'isse-maringa',
  dev: 'isse-teste'
};

export const getIdpHint = (): string => {
  const { hostname, pathname } = window.location;
  if (
    hostname.match(/^maringa\.cidadao\.oxy/) ||
    (hostname.match(/^maringa\.oxy/) &&
      pathname.startsWith(BASE_HREF_ATENDIMENTO_CIDADAO))
  ) {
    return LoginIssMaringaEnum.prod;
  } else if (
    hostname.match(/^\w*maringa\.(dev|qa)/) &&
    pathname.startsWith(BASE_HREF_ATENDIMENTO_CIDADAO)
  ) {
    return LoginIssMaringaEnum.dev;
  }

  return '';
};

const getAcessoCidadaoLogoutUrl = (
  ambiente: string,
  redirectUrl: string
): string =>
  `https://${ambiente}.maringa.pr.gov.br/auth/logout?return_to=${redirectUrl}`;

export const getLogoutUrl = (): string => {
  const redirectUrl = window.location.origin;
  const redirectUrlWithBaseHref = `${redirectUrl}${BASE_HREF_ATENDIMENTO_CIDADAO}`;

  switch (getIdpHint()) {
    case LoginIssMaringaEnum.dev:
      return getAcessoCidadaoLogoutUrl(
        PREFIX_URL_LOGIN_ISS_MARINGA_DEV,
        redirectUrlWithBaseHref
      );
    case LoginIssMaringaEnum.prod:
      return getAcessoCidadaoLogoutUrl(
        PREFIX_URL_LOGIN_ISS_MARINGA_PROD,
        redirectUrlWithBaseHref
      );
    default:
      return '';
  }
};
