import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useState
} from 'react';

enum Key {
  ENTER = 'Enter',
  ESC = 'Escape'
}

export type RenderEditComponentProps<T> = {
  value?: T;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  onBlur(): void;
  onKeyDown(event: KeyboardEvent<HTMLInputElement>): void;
};

export type EditableInputProps<T> = {
  name: string;
  hintPosition?: string;
  maxLength?: number;
  initialValue?: T;
  defaultOpened?: boolean;
  autoFocus?: boolean;
  clickOnValue?: boolean;
  onCancelChange?: (value: T, name: string, fullPath: string) => void;
  onConfirmChange: (value: T, name: string, fullPath: string) => void;
  renderEditComponent?: (props: RenderEditComponentProps<T>) => any;
  renderVisualization?: (value?: T | string) => any;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
};

const pathToProp = (path: string): string => {
  const [propName]: string[] = path.split('.').reverse();
  return propName;
};

const EditableInput = <T extends {}>({
  name,
  maxLength,
  initialValue,
  onCancelChange,
  onConfirmChange,
  defaultOpened = false,
  autoFocus = false,
  clickOnValue = false,
  hintPosition = 'center-right',
  renderEditComponent,
  renderVisualization,
  onFocus,
  readOnly = false
}: EditableInputProps<T>) => {
  const editableRef = React.createRef<any>();

  const [isEditing, setIsEditing] = useState<any>(false);
  const [previousValue, setPreviousValue] = useState<any>();
  const [currentValue, setCurrentValue] = useState();

  useEffect(() => {
    setPreviousValue(initialValue);
    setCurrentValue(initialValue);
  }, [initialValue]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target.value);
  };

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(true);
    setPreviousValue(currentValue);
  };

  const onBlur = () => {
    setIsEditing(false);
    setPreviousValue(initialValue);

    if (currentValue !== initialValue) {
      onConfirmChange(currentValue, pathToProp(name), name);
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Key.ENTER) {
      onBlur();
    }

    if (event.key === Key.ESC) {
      setIsEditing(false);
      setCurrentValue(previousValue);

      if (onCancelChange) {
        onCancelChange(previousValue, pathToProp(name), name);
      }
    }
  };

  const doRenderVisualization = (value: string): any => {
    if (renderVisualization) return renderVisualization(currentValue);

    return value;
  };

  const renderInput = () => {
    return (
      <input
        ref={editableRef}
        name={name}
        value={currentValue}
        autoFocus={autoFocus}
        maxLength={maxLength}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        type="text"
        onFocus={onFocus}
        readOnly={readOnly}
      />
    );
  };

  const doRenderEditComponent = () => {
    if (renderEditComponent)
      return renderEditComponent({
        value: currentValue,
        onChange,
        onBlur,
        onKeyDown,
        onFocus
      });

    return renderInput();
  };

  return (
    <>
      <div className="editable">
        {isEditing || defaultOpened ? (
          doRenderEditComponent()
        ) : (
          <>
            <span
              className="td-content"
              onClick={!readOnly && clickOnValue ? onClick : undefined}
            >
              {doRenderVisualization(currentValue)}
            </span>
            {!readOnly && (
              <div
                className={`hint clean inline module-color fa-pencil-alt sm ${hintPosition}`}
                onClick={onClick}
              >
                <div className="hint-content">Editar</div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default EditableInput;
