import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../icons/Icon';

const TableReportTitle = props => {
  return (
    <div className="title-dash">
      <Icon icon={props.icon} size="lg" primary />
      <h2>
        {props.title}
        <span>{props.subtitle}</span>
      </h2>
    </div>
  );
};

TableReportTitle.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default TableReportTitle;
