import { Col, Row } from '@elotech/components';
import React from 'react';

import { CadastroImobiliario } from '../../type';

type Props = {
  cadastroImobiliario: CadastroImobiliario;
  desconsideraAreaConstruida?: boolean;
};

const PropriedadeUrbanoQuickView: React.FC<Props> = ({
  cadastroImobiliario,
  desconsideraAreaConstruida
}) => {
  return (
    <>
      <div className="quick-header">Propriedade</div>
      <Row>
        <Col md={12}>
          <div className="form-group">
            <label className="label">Proprietário</label>
            {cadastroImobiliario?.proprietario}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <label className="label">Zona</label>
            {cadastroImobiliario?.zona}
          </div>
        </Col>
        <Col md={4}>
          <div className="form-group">
            <label className="label">Quadra</label>
            {cadastroImobiliario?.quadra}
          </div>
        </Col>
        <Col md={4}>
          <div className="form-group">
            <label className="label">Data/Lote</label>
            {cadastroImobiliario?.lote}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <label className="label">Área Terreno</label>
            {cadastroImobiliario?.areaTerreno
              ? `${cadastroImobiliario?.areaTerreno} m²`
              : ''}
          </div>
        </Col>
        <Col md={4}>
          <div className="form-group">
            <label className="label">Área Construída</label>
            {cadastroImobiliario?.areaConstruida &&
            desconsideraAreaConstruida !== true
              ? `${cadastroImobiliario?.areaConstruida} m²`
              : ''}
          </div>
        </Col>
      </Row>
      {cadastroImobiliario?.condominio && (
        <Row>
          <Col md={12}>
            <div className="form-group">
              <label className="label">Condomínio</label>
              {`${cadastroImobiliario.condominio} - ${cadastroImobiliario.descricaoCondominio}`}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PropriedadeUrbanoQuickView;
