import { Col, ErrorText, Hint, Row } from '@elotech/components';
import classnames from 'classnames';
import React from 'react';
import NumberFormat from 'react-number-format';

import { CadastroRural } from '../../../type';

type Props = {
  cadastro?: CadastroRural;
  onChangeCadastro: (event: React.ChangeEvent<any>) => void;
  onSearch: (cadastro?: number) => void;
  error: Record<string, boolean>;
  errorMessages: Record<string, string>;
  loading: boolean;
  notFound: boolean;
};

const LocalizacaoImovelRural: React.FC<Props> = ({
  cadastro,
  onChangeCadastro,
  loading,
  onSearch,
  notFound,
  error,
  errorMessages
}) => {
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch(cadastro?.cadastro);
    }
  };

  const inputCadastroClassName = classnames({
    loading,
    error: notFound || error.cadastroRural || error.cadastroInadimplente
  });
  return (
    <>
      <Row>
        <Col md={3} className="form-group">
          <label htmlFor="cadastroRural">Cadastro Rural</label>
          <NumberFormat
            id="cadastroRural"
            name="cadastro"
            value={cadastro?.cadastro}
            onChange={onChangeCadastro}
            onKeyDown={onKeyDown}
            onBlur={() => onSearch(cadastro?.cadastro)}
            maxLength={10}
            decimalScale={0}
            allowNegative={false}
            placeholder="Digite o código do cadastro"
            autoComplete="off"
            className={inputCadastroClassName}
          />
          {loading && <i className="fa fa-spinner spinner" />}
          {notFound && (
            <div>
              <ErrorText>
                Não foi possível localizar o cadastro rural!
              </ErrorText>
            </div>
          )}
          {!notFound && error.cadastroRural && (
            <ErrorText>{errorMessages.cadastroRural}</ErrorText>
          )}
          {error.cadastroInadimplente && (
            <ErrorText>{errorMessages.cadastroInadimplente}</ErrorText>
          )}
        </Col>
        <Col md={4} className={'form-group'}>
          <label htmlFor="nome">Nome</label>
          <input
            id="nome"
            type="text"
            name="nome"
            value={cadastro?.nome ?? ''}
            disabled
          />
        </Col>
        <Col md={5} className={'form-group'}>
          <label htmlFor="enderecoDescrito">Endereço Descrito</label>
          <input
            id="enderecoDescrito"
            type="text"
            name="enderecoDescrito"
            value={cadastro?.enderecoDescrito ?? ''}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} className={'form-group'}>
          <label htmlFor="logradouro">Logradouro</label>
          <input
            id="logradouro"
            type="text"
            name="logradouro"
            value={cadastro?.logradouro ?? ''}
            disabled
          />
        </Col>
        <Col md={2} className={'form-group'}>
          <label htmlFor="bairro">Bairro</label>
          <input
            id="bairro"
            type="text"
            name="bairro"
            value={cadastro?.bairro ?? ''}
            disabled
          />
        </Col>
        <Col md={2} className={'form-group'}>
          <label htmlFor="complemento">Complemento</label>
          <input
            id="complemento"
            type="text"
            name="complemento"
            value={cadastro?.complemento ?? ''}
            disabled
          />
        </Col>
        <Col md={2} className={'form-group'}>
          <label htmlFor="distrito">Distrito</label>
          <input
            id="distrito"
            type="text"
            name="distrito"
            value={cadastro?.distrito ?? ''}
            disabled
          />
        </Col>
        <Col md={2} className={'form-group'}>
          <label htmlFor="setor">Setor</label>
          <input
            id="setor"
            type="text"
            name="setor"
            value={cadastro?.setor ?? ''}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col md={2} className={'form-group'}>
          <label htmlFor="numeroReceita">
            NIRF
            <Hint
              classes={`inline clean module-color fa-exclamation-circle mobile sm`}
            >
              Número do Imóvel na Receita Federal
            </Hint>
          </label>
          <input
            id="numeroReceita"
            type="text"
            name="numeroReceita"
            value={cadastro?.numeroReceita ?? ''}
            disabled
          />
        </Col>
        <Col md={2} className={'form-group'}>
          <label htmlFor="numeroIncra">Número no INCRA</label>
          <input
            id="numeroIncra"
            type="text"
            name="numeroIncra"
            value={cadastro?.numeroIncra ?? ''}
            disabled
          />
        </Col>
        <Col md={2} className={'form-group'}>
          <label htmlFor="alqueires">Qtd. Alqueires</label>
          <input
            id="alqueires"
            type="text"
            name="alqueires"
            value={cadastro?.alqueires ?? ''}
            disabled
          />
        </Col>
        <Col md={2} className={'form-group'}>
          <label htmlFor="hectares">Qtd. Hectares</label>
          <input
            id="hectares"
            type="text"
            name="hectares"
            value={cadastro?.hectares ?? ''}
            disabled
          />
        </Col>
        <Col md={2} className={'form-group'}>
          <label htmlFor="areaTotal">Área total</label>
          <input
            id="areaTotal"
            type="text"
            name="areaTotal"
            value={cadastro?.areaTotal ?? ''}
            disabled
          />
        </Col>
      </Row>
    </>
  );
};

export default LocalizacaoImovelRural;
