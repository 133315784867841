const validate = (
  getValidationSchema,
  list,
  vendedores,
  compradores,
  tipoProprietario,
  position,
  isServidor,
  validaRgComprador
) => formValues => {
  try {
    const values = { ...formValues };

    values.percentual =
      values.percentual === ''
        ? 0
        : parseFloat(`${values.percentual || '0'}`.replace(',', '.'));

    values.percentualVenda =
      values.percentualVenda === ''
        ? 0
        : parseFloat(`${values.percentualVenda || '0'}`.replace(',', '.'));

    getValidationSchema(
      values,
      list,
      vendedores,
      compradores,
      tipoProprietario,
      position,
      isServidor,
      validaRgComprador
    ).validateSync(values, { abortEarly: false });

    return {};
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
};

function getErrorsFromValidationError(validationError) {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
}
export { validate };
