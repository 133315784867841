import { FormattedDate } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import CollapseRow from '../containers/CollapseRow';
import DiffTable from './DiffTable';

const TdBorderLeftNone = styled.td`
  border-left: none !important;
`;

const defaultFieldParser = (fieldName, showNew, showRemoved) => (
  diff,
  objectParser,
  originalPath
) => {
  if (diff.kind === 'N' && !showNew) {
    return '';
  }
  if (diff.kind === 'D' && !showRemoved) {
    return '';
  }
  const diffValue = diff[fieldName];
  if (typeof diffValue === 'object') {
    return objectParser(diffValue, originalPath);
  }
  if (`${diffValue}`.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return <FormattedDate value={diffValue} timeZone={'UTC'} />;
  }
  if (typeof diffValue === 'boolean') {
    return diffValue ? 'Sim' : 'Não';
  }
  return String(diffValue);
};

const convertKind = diff => {
  switch (diff.kind) {
    case 'E':
      return 'Atualizado';
    case 'N':
      return 'Novo';
    case 'D':
      return 'Deletado';
    case 'A':
      return convertKind(diff.item);
    default:
      return diff.kind;
  }
};

const DiffRow = props => {
  const {
    diff,
    path,
    isNested,
    nameDictionary,
    oldValueParser,
    newValueParser,
    objectParser,
    parentField
  } = props;

  const currentPath = [...path, ...diff.path];
  const fieldName = nameDictionary(currentPath);
  if (fieldName === undefined) {
    return null;
  }
  const field = `${parentField ? parentField : ''} ${fieldName}`;

  const nested = isNested ? isNested(currentPath) : false;

  if (diff.kind === 'A') {
    return (
      <CollapseRow
        buttonLabel={`Modificações ${field}`}
        renderHeader={() => (
          <React.Fragment>
            <td data-header="Alteração">
              <b className="td-label">Alteração</b>
              {convertKind(diff)}
            </td>
            <td data-header="Campo">
              <b className="td-label">Campo</b>
              {field}
            </td>
            <TdBorderLeftNone data-header="Antes" />
            <TdBorderLeftNone data-header="Depois" />
          </React.Fragment>
        )}
      >
        <DiffTable>
          {diff.itemDiff.map((diffItem, index) => (
            <DiffRow
              key={index}
              diff={diffItem}
              path={diff.path}
              nameDictionary={nameDictionary}
              objectParser={objectParser}
              newValueParser={newValueParser}
              oldValueParser={oldValueParser}
              isNested={isNested}
            />
          ))}
        </DiffTable>
      </CollapseRow>
    );
  }
  if (diff.kind === 'N' && typeof diff.rhs === 'object' && !nested) {
    return (
      <CollapseRow
        buttonLabel={`Modificações ${field}`}
        renderHeader={() => (
          <React.Fragment>
            <td data-header="Alteração">
              <b className="td-label">Alteração</b>
              {convertKind(diff)}
            </td>
            <td data-header="Campo">
              <b className="td-label">Campo</b>
              {field}
            </td>
            <TdBorderLeftNone data-header="Antes" />
            <TdBorderLeftNone data-header="Depois" />
          </React.Fragment>
        )}
      >
        <DiffTable>
          {Object.entries(diff.rhs).map(([key, value], index) => (
            <DiffRow
              key={index}
              diff={{ rhs: value, path: [key], kind: 'N' }}
              path={diff.path}
              nameDictionary={nameDictionary}
              objectParser={objectParser}
              newValueParser={newValueParser}
              oldValueParser={oldValueParser}
              parentField={field}
              isNested={isNested}
            />
          ))}
        </DiffTable>
      </CollapseRow>
    );
  }
  if (diff.kind === 'D' && typeof diff.lhs === 'object' && !nested) {
    return (
      <CollapseRow
        buttonLabel={`Modificações ${field}`}
        renderHeader={() => (
          <React.Fragment>
            <td data-header="Alteração">
              <b className="td-label">Alteração</b>
              {convertKind(diff)}
            </td>
            <td data-header="Campo">
              <b className="td-label">Campo</b>
              {field}
            </td>
            <TdBorderLeftNone data-header="Antes" />
            <TdBorderLeftNone data-header="Depois" />
          </React.Fragment>
        )}
      >
        <DiffTable>
          {Object.entries(diff.lhs).map(([key, value], index) => (
            <DiffRow
              key={index}
              diff={{ lhs: value, path: [key], kind: 'D' }}
              path={diff.path}
              nameDictionary={nameDictionary}
              objectParser={objectParser}
              newValueParser={newValueParser}
              oldValueParser={oldValueParser}
              parentField={field}
              isNested={isNested}
            />
          ))}
        </DiffTable>
      </CollapseRow>
    );
  }
  return (
    <tr>
      <td data-header="Alteração">
        <b className="td-label">Alteração</b>
        {convertKind(diff)}
      </td>
      <td data-header="Campo">
        <b className="td-label">Campo</b>
        {field}
      </td>
      <td data-header="Antes">
        <b className="td-label">Antes</b>
        {oldValueParser(diff, objectParser, currentPath)}
      </td>
      <td data-header="Depois" colSpan={2}>
        <b className="td-label">Depois</b>
        {newValueParser(diff, objectParser, currentPath)}
      </td>
    </tr>
  );
};

DiffRow.propTypes = {
  diff: PropTypes.object.isRequired,
  path: PropTypes.array,
  isNested: PropTypes.func,
  nameDictionary: PropTypes.func.isRequired,
  oldValueParser: PropTypes.func,
  newValueParser: PropTypes.func,
  objectParser: PropTypes.func
};

DiffRow.defaultProps = {
  path: [],
  oldValueParser: defaultFieldParser('lhs', false, true),
  newValueParser: defaultFieldParser('rhs', true, false),
  objectParser: value => JSON.stringify(value)
};

export default DiffRow;
