import React, { FunctionComponent } from 'react';
import { ActionsGroup, ActionButton } from '@elotech/components';
import * as UploadService from '../../service/UploadService';

type CampoDocumentoRowProps = {
  campo: any;
};

type DocumentoRowProps = {
  uploadService: typeof UploadService;
  documento: any;
  idDeclaracao: string;
};

type DocumentoRowState = {
  showCampos: boolean;
};

const CampoDocumentoRow: FunctionComponent<CampoDocumentoRowProps> = ({
  campo
}) => (
  <tr>
    <td>{campo.campoDocumento.label}</td>
    <td>{campo.valor}</td>
  </tr>
);

const CampoDocumentoTable: FunctionComponent<{ campos: any[] }> = ({
  campos
}) => {
  return (
    <div className="panel table" style={{ marginBottom: '0px' }}>
      <div className="panel-body table table-responsive">
        <div className="panel-table-outer">
          <table className="panel-table border fancy">
            <thead>
              <tr>
                <th>Campo</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {campos.map(campo => (
                <CampoDocumentoRow key={campo.id} campo={campo} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

class DocumentoRow extends React.Component<
  DocumentoRowProps,
  DocumentoRowState
> {
  state = {
    showCampos: false
  };

  onViewCampos = () => {
    this.setState(state => ({ showCampos: !state.showCampos }));
  };

  onDownload = (documento: any) => {
    const { idDeclaracao, uploadService } = this.props;
    uploadService
      .getUrlDownloadS3Declaracao({
        declaracao: idDeclaracao,
        documento: documento.documentoItbi.id,
        arquivo: documento.valor
      })
      .then(response => {
        window.open(response.data.url, '_blank');
      });
  };

  render() {
    const { documento } = this.props;
    const { showCampos } = this.state;

    return (
      <React.Fragment>
        <tr key={documento.id}>
          <td>{documento.documentoItbi.nome}</td>
          <td>
            <ActionsGroup>
              {documento.valor && (
                <ActionButton
                  data-test-id={`download-button-${documento.id}`}
                  key={`download-button-${documento.id}`}
                  icon="download"
                  label={`Download ${documento.documentoItbi.nome}`}
                  onClick={() => this.onDownload(documento)}
                />
              )}
              {documento.campos && (
                <ActionButton
                  data-test-id={`view-button-${documento.id}`}
                  key={`view-button-${documento.id}`}
                  icon={showCampos ? 'chevron-up' : 'chevron-down'}
                  label={
                    showCampos
                      ? 'Minimizar campos do documento'
                      : 'Visualizar campos do documento'
                  }
                  onClick={this.onViewCampos}
                />
              )}
            </ActionsGroup>
          </td>
        </tr>
        {showCampos && (
          <tr style={{ backgroundColor: '#eaf1f9' }}>
            <td colSpan={2}>
              <CampoDocumentoTable campos={documento.campos} />
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }
}

export default DocumentoRow;
