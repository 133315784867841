import classNames from 'classnames';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Panel } from '../index';
import { getTabItemClassName, shouldRenderBadge } from './TabUtils';

type TabProps = {
  header: ReactNode;
  badgeCount?: number;
  children?: ReactNode;
  hidden?: boolean;
};

const Tab: React.FC<TabProps> = () => null;

type ChildType = ReactElement<TabProps>;

type Props = {
  children: ChildType | ChildType[];
  initialTab?: number;
  panelBodyClassName?: string;
  onChangeTab?(newTab: number): boolean;
  renderAllContents?: boolean;
} & Omit<React.AllHTMLAttributes<HTMLDivElement>, 'children' | 'className'>;

const Tabs: React.FC<Props> & { Tab: typeof Tab } = ({
  children,
  initialTab = 0,
  panelBodyClassName,
  onChangeTab,
  renderAllContents,
  ...divProps
}: Props) => {
  const [activeTab, setActiveTab] = useState(initialTab);
  let childrenArray = React.Children.toArray(children as ChildType);

  useEffect(() => {
    if (initialTab > childrenArray.length || initialTab < 0) {
      setActiveTab(0);
    } else {
      setActiveTab(initialTab);
    }
  }, [initialTab]);

  const doChangeTab = (newTab: number) =>
    !!onChangeTab
      ? onChangeTab(newTab) && setActiveTab(newTab)
      : setActiveTab(newTab);

  const activeChildren = childrenArray[activeTab]?.props?.hidden
    ? childrenArray[childrenArray.findIndex(a => !a.props.hidden)]
    : childrenArray[activeTab];

  return (
    <Panel>
      <div className="panel-tab" {...divProps}>
        <div className="panel-tab-selector">
          {React.Children.map(children, (child, index) =>
            !child.props.hidden ? (
              <div
                className={getTabItemClassName(
                  child.props,
                  index === activeTab
                )}
                data-position={index}
                key={index}
                onClick={() => doChangeTab(index)}
              >
                {child.props.header}
                {shouldRenderBadge(child.props.badgeCount) && (
                  <div className="badge">{child.props.badgeCount}</div>
                )}
              </div>
            ) : null
          )}
        </div>
        <div className="panel-tab-content">
          {renderAllContents ? (
            React.Children.map(children, (child, index) => (
              <div
                className={`tab-item ${index === activeTab &&
                  'active'} ${panelBodyClassName}`}
              >
                {child.props.children}
              </div>
            ))
          ) : (
            <div className={`tab-item active ${panelBodyClassName}`}>
              {activeChildren && activeChildren.props.children}
            </div>
          )}
        </div>
      </div>
    </Panel>
  );
};

Tabs.Tab = Tab;

export default Tabs;
