import axios, { AxiosPromise } from 'axios';
import { DebitoAgrupadoPorFormaPagamentoDTO } from 'itbi-cidadao/src/types';
import { CadastroGeralAcessoWebDTO } from 'itbi-cidadao/src/types/CadastroGeralAcessoWebDTO';

type ImpressaoDTO = {
  id: string;
  url: string;
};

export const getCadastrosComCarneByCpfCnpj = (
  cpfCnpj: string
): AxiosPromise<Array<CadastroGeralAcessoWebDTO>> =>
  axios.get(`/api/cadastro-geral/cadastros-com-carnes?cpfCnpj=${cpfCnpj}`);

export const getDebitosFormaPagamento = (
  tipoCadastro: number,
  cadastroGeral: number
): AxiosPromise<Array<DebitoAgrupadoPorFormaPagamentoDTO>> =>
  axios.get(`/api/debitoscarne/agrupado-forma-pagamento`, {
    params: { tipoCadastro, cadastroGeral }
  });

export const imprimirFormaPagamento = (debito: {
  idDebito: number;
  formaPagamento: number;
}): AxiosPromise<ImpressaoDTO> =>
  axios.post('/api/carne/gera-carne-forma-pagamento', debito);
