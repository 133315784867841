import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';

const FormattedDateTime = ({ value }: any) => {
  if (!value) {
    return null;
  }

  return (
    <>
      <FormattedDate value={value}/>{' '}
      <FormattedTime value={value} />
    </>
  );
};

export default FormattedDateTime;
