import classnames from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';

import { Props as ColumnProps } from './Column';

export type Props<T> = Pick<
  ColumnProps<T>,
  'header' | 'className' | 'style' | 'align'
> & { value: ReactNode };

class Data<T> extends React.Component<Props<T>> {
  render() {
    const { value, header, className = '', style = {}, align } = this.props;

    const classes = classnames(className, {
      [`${align}`]: align
    });

    if (!header) {
      return (
        <td className={classes} style={style}>
          {value}
        </td>
      );
    }

    return (
      <td className={classes} style={style}>
        <b className="td-label">{header}</b>
        <span className="td-content">{value}</span>
      </td>
    );
  }
}

export default Data;
