import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';

// https://usehooks.com/useLocalStorage/
export default function useLocalStorage<S>(
  key: string,
  initialValue: S
): [S, Dispatch<SetStateAction<S>>] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const readValue = useCallback(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  }, [key]);
  const [storedValue, setStoredValue] = useState<S>(readValue);

  useEffect(() => {
    setStoredValue(readValue());
  }, [readValue]);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: Dispatch<SetStateAction<S>> = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore: S =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));

      return valueToStore;
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}
