import { ErrorPage, ForbiddenPage, NotFoundPage } from '@elotech/components';

import DeclaracaoListPage from '../../itbi-cidadao/src/pages/declaracao-itbi/DeclaracaoListPage';
import PedidoActionButton from './buttons/PedidoActionButton';
import ContestacaoSectionForm from './declaracao-itbi/contestacao/ContestacaoSectionForm';
import DeclaracaoItbiForm from './declaracao-itbi/DeclaracaoItbiForm';
import DeclaracaoItbiRequerente from './declaracao-itbi/imovel/Requerente';
import DocumentosAvulsosForm from './documentacao/avulsos/DocumentosAvulsosForm';
import DocumentosAvulsosList from './documentacao/avulsos/DocumentosAvulsosList';
import DocumentacaoList from './documentacao/DocumentacaoList';
import EnderecoForm from './endereco/EnderecoForm';
import * as icons from './icons/IconsLibrary';
import AvaliacaoQuickView from './quickView/AvaliacaoQuickView';
import LotePropriedadeQuickView from './quickView/LotePropriedadeQuickView';
import PessoasQuickView from './quickView/PessoasQuickView';
import RouteWithRedirect from './routes/RouteWithRedirect';
import SearchFilterCadastro from './searchfilter-cadastro/SearchFilterCadastro';

const IconLibrary = Object.values(icons);

export {
  AvaliacaoQuickView,
  DeclaracaoItbiForm,
  ContestacaoSectionForm,
  DeclaracaoItbiRequerente,
  DeclaracaoListPage,
  DocumentacaoList,
  DocumentosAvulsosList,
  DocumentosAvulsosForm,
  EnderecoForm,
  ErrorPage,
  ForbiddenPage,
  IconLibrary,
  NotFoundPage,
  LotePropriedadeQuickView,
  PedidoActionButton,
  RouteWithRedirect,
  PessoasQuickView,
  SearchFilterCadastro
};
