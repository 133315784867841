import swal from 'sweetalert2';

import { getErrorMessage } from '../utils/ErrorMessageUtils';

const Alert = {
  error: async (config, error = '') => {
    const errorMessage = await getErrorMessage(error);

    return swal({
      type: 'error',
      html: `${errorMessage}`,
      showCancelButton: false,
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      heightAuto: false,
      onOpen: () => {
        swal.getConfirmButton().focus();
      },
      ...config
    });
  },

  question: config =>
    swal({
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#659937',
      cancelButtonText: 'Não',
      cancelButtonColor: '#c7382d',
      allowOutsideClick: false,
      allowEscapeKey: true,
      allowEnterKey: false,
      heightAuto: false,
      onOpen: () => {
        if (swal.getInput()) {
          swal.getInput().focus();
        } else {
          swal.getConfirmButton().focus();
        }
      },
      ...config
    }),

  success: config =>
    swal({
      type: 'success',
      showCancelButton: false,
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: true,
      allowEnterKey: false,
      heightAuto: false,
      onOpen: () => {
        swal.getConfirmButton().focus();
      },
      ...config
    }),

  warning: config =>
    swal({
      type: 'warning',
      showCancelButton: false,
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: true,
      allowEnterKey: false,
      heightAuto: false,
      onOpen: () => {
        swal.getConfirmButton().focus();
      },
      ...config
    }),

  info: config =>
    swal({
      type: 'info',
      showCancelButton: false,
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: true,
      allowEnterKey: false,
      heightAuto: false,
      onOpen: () => {
        swal.getConfirmButton().focus();
      },
      ...config
    })
};

export default Alert;
