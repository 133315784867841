import React from 'react';

type Props = {
  children: React.ReactNode;
};
const Actions: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div className="btn-actions">
      <div className="btn-actions-inner">{children}</div>
    </div>
  );
};

export default Actions;
