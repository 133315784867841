import classnames from 'classnames';
import React from 'react';

type Props = {
  color?: 'module-color' | 'positive' | 'negative' | 'warning' | 'neutral';
  value: String;
  letter?: String;
};

const ExtraInfo: React.FC<Props> = ({
  color = 'module-color',
  value,
  letter
}) => {
  const className: string = letter ? 'extra-info split-letter' : 'extra-info';

  const conteinerClassName = classnames(className, {
    [color]: true,
    'bg-gray-50': color === 'neutral',
    'bg-yellow-60': color === 'warning'
  });

  return (
    <span className="td-content inline-flex">
      <div className={conteinerClassName} data-letter={letter}>
        <p>{value}</p>
      </div>
    </span>
  );
};

export default ExtraInfo;
