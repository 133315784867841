import { ArraySchema, TestContext, ValidationError } from 'yup';

import { Yup, formatUtils } from '../';
import {
  Alternativa,
  Campo,
  CampoTipoEnum,
  GrupoResposta,
  Resposta,
  TipoValidacaoEnum,
  Validacao
} from '../type';

export type FlatResposta = {
  descricao: string;
  campoDependente: string;
  tipoCampo?: CampoTipoEnum;
  campoAtivo?: boolean;
  valor?: string;
  alternativa?: Alternativa;
  alternativaAtiva?: boolean;
};

export type WithRespostaAgrupada = {
  respostasAgrupadas: GrupoResposta[];
};

export type ValidationResult = ValidationError | undefined;

type DynamicFieldValidator = (
  testContext: TestContext,
  resposta: Resposta,
  validacao: Validacao
) => ValidationResult;

type InnerValidator = (innerValidatorParams: {
  resposta: FlatResposta;
  respostaBase: FlatResposta;
  validacao: Validacao;
  testContext: TestContext;
}) => ValidationResult;

type ValidatorBuilder = (
  innerValidator: InnerValidator
) => (
  testContext: TestContext,
  resposta: Resposta,
  validacao: Validacao
) => ValidationResult;

export const getRespostaDinamica = <T extends WithRespostaAgrupada>(
  context: T,
  nomeCampo: string
): FlatResposta | undefined => {
  return context.respostasAgrupadas.reduce<FlatResposta | undefined>(
    (result, currentGrupo) => {
      if (result) {
        return result;
      }
      const match = currentGrupo.respostas.find(
        resposta => resposta.campo.identificador === nomeCampo
      );
      return match ? respostaToFlat(match) : undefined;
    },
    undefined
  );
};

export const respostaToFlat = (resposta: Resposta): FlatResposta => ({
  descricao: resposta.campo.descricao,
  campoDependente: resposta.campo.identificador,
  valor: resposta.valor,
  alternativa: resposta.alternativa,
  alternativaAtiva: resposta.alternativa?.ativo || false,
  tipoCampo: resposta.campo.tipo,
  campoAtivo: resposta.campo.ativo
});

const validatorBuilder: ValidatorBuilder = (innerValidator: InnerValidator) => (
  testContext: TestContext,
  resposta: Resposta,
  validacao: Validacao
) => {
  const context = testContext.options.context! as any;
  const respostaDependente = getRespostaDinamica(
    context,
    validacao.campoDependente
  );

  if (!respostaDependente?.campoAtivo) {
    return;
  }

  return innerValidator({
    resposta: respostaToFlat(resposta),
    respostaBase: respostaDependente,
    validacao,
    testContext
  });
};

export const validadoresGrupoResposta: {
  [k in TipoValidacaoEnum]: DynamicFieldValidator;
} = {
  OBRIGATORIO_SE_AUSENTE: validatorBuilder(
    ({ resposta, respostaBase, testContext }) => {
      if (
        !resposta.valor &&
        !resposta.alternativa &&
        !respostaBase.valor &&
        (!respostaBase.alternativa || !respostaBase.alternativaAtiva)
      ) {
        return testContext.createError({
          path: `${testContext.path}.${getFieldPath(resposta)}`,
          message: `O campo '${resposta.descricao}' deve estar respondido se o campo '${respostaBase.descricao}' não estiver.`
        });
      }
    }
  ),
  PROIBIDO_SE_AUSENTE: validatorBuilder(
    ({ resposta, respostaBase, testContext }) => {
      if (
        (resposta.valor || resposta.alternativa) &&
        !respostaBase.valor &&
        (!respostaBase.alternativa || !respostaBase.alternativaAtiva)
      ) {
        return testContext.createError({
          path: `${testContext.path}.${getFieldPath(resposta)}`,
          message: `O campo '${resposta.descricao}' não deve estar respondido se o campo '${respostaBase.descricao}' não estiver.`
        });
      }
    }
  ),
  OBRIGATORIO_SE_PRESENTE: validatorBuilder(
    ({ resposta, respostaBase, testContext }) => {
      if (
        !resposta.valor &&
        !resposta.alternativa &&
        (respostaBase.valor ||
          (respostaBase.alternativaAtiva && respostaBase.alternativa))
      ) {
        return testContext.createError({
          path: `${testContext.path}.${getFieldPath(resposta)}`,
          message: `O campo '${resposta.descricao}' deve estar respondido se o campo '${respostaBase.descricao}' estiver.`
        });
      }
    }
  ),
  PROIBIDO_SE_PRESENTE: validatorBuilder(
    ({ resposta, respostaBase, testContext }) => {
      if (
        (resposta.valor || resposta.alternativa) &&
        (respostaBase.valor ||
          (respostaBase.alternativaAtiva && respostaBase.alternativa))
      ) {
        return testContext.createError({
          path: `${testContext.path}.${getFieldPath(resposta)}`,
          message: `O campo '${resposta.descricao}' não pode estar respondido se o campo '${respostaBase.descricao}' estiver.`
        });
      }
    }
  ),
  OBRIGATORIO_SE_IGUAL: validatorBuilder(
    ({ resposta, respostaBase, validacao, testContext }) => {
      const valorParaValidacao = respostaBase.valor
        ? respostaBase.valor
        : String(respostaBase?.alternativa?.codigo);
      if (
        !resposta.valor &&
        !resposta.alternativa &&
        ((respostaBase.alternativa?.id && respostaBase.alternativaAtiva) ||
          !respostaBase.alternativa?.id) &&
        `${validacao.valor}` === `${valorParaValidacao}`
      ) {
        return testContext.createError({
          path: `${testContext.path}.${getFieldPath(resposta)}`,
          message: `O campo '${resposta.descricao}' é obrigatório se o campo '${
            respostaBase.descricao
          }' for igual a ${formatValor(respostaBase, validacao)}.`
        });
      }
    }
  ),
  PROIBIDO_SE_IGUAL: validatorBuilder(
    ({ resposta, respostaBase, validacao, testContext }) => {
      const valorParaValidacao = respostaBase.valor
        ? respostaBase.valor
        : String(respostaBase?.alternativa?.codigo);
      if (
        (resposta.valor || resposta.alternativa) &&
        ((respostaBase.alternativa?.id && respostaBase.alternativaAtiva) ||
          !respostaBase.alternativa?.id) &&
        `${validacao.valor}` === `${valorParaValidacao}`
      ) {
        return testContext.createError({
          path: `${testContext.path}.${getFieldPath(resposta)}`,
          message: `O campo '${resposta.descricao}' é proibido se o campo '${
            respostaBase.descricao
          }' for igual a ${formatValor(respostaBase, validacao)}.`
        });
      }
    }
  )
};

const formatValor = (respostaBase: FlatResposta, validacao: Validacao) => {
  if (respostaBase.tipoCampo === CampoTipoEnum.DATA) {
    return formatUtils.formatStringToDisplayDate(validacao.valor!);
  }
  return validacao.valor;
};

const getFieldPath = (resposta: FlatResposta) => {
  if (resposta.tipoCampo === CampoTipoEnum.MULTIPLA_ESCOLHA) {
    return 'alternativa.id';
  }
  return 'valor';
};

const isCampoObrigatorioValorSemResposta = (resposta: Resposta) => {
  return (
    resposta.campo.tipo !== CampoTipoEnum.MULTIPLA_ESCOLHA &&
    resposta.campo.obrigatorio &&
    !resposta.valor
  );
};

const isCampoObrigatorioMultiplaEscolhaSemResposta = (resposta: Resposta) => {
  return (
    resposta.campo.tipo === CampoTipoEnum.MULTIPLA_ESCOLHA &&
    resposta.campo.obrigatorio &&
    !resposta.alternativa
  );
};

const getMessageCampoObrigatorio = (campo: Campo) => {
  return `${campo.descricao} é obrigatório`;
};

export const suffixNameElementCampoValor = 'valor';
export const suffixNameElementCampoMultiplaEscolha = 'alternativa.id';

export const respostasCamposDinamicosValidator = (
  fieldNameResposta: string
) => {
  const respostasCamposDinamicosYup: {
    [nome: string]: ArraySchema<any>;
  } = {
    [fieldNameResposta]: Yup.array().of(
      Yup.object().shape({
        respostas: Yup.array().of(
          Yup.object().test('respostas', '', function(
            this: Yup.TestContext,
            resposta: Resposta
          ) {
            if (isCampoObrigatorioValorSemResposta(resposta)) {
              return this.createError({
                path: `${this.path}.${suffixNameElementCampoValor}`,
                message: getMessageCampoObrigatorio(resposta.campo)
              });
            } else if (isCampoObrigatorioMultiplaEscolhaSemResposta(resposta)) {
              return this.createError({
                path: `${this.path}.${suffixNameElementCampoMultiplaEscolha}`,
                message: getMessageCampoObrigatorio(resposta.campo)
              });
            }

            if (resposta.campo.validacoes?.length > 0) {
              const validationError = resposta.campo.validacoes.reduce<
                ValidationResult
              >((error, validation) => {
                return (
                  error ??
                  validadoresGrupoResposta[validation.tipo](
                    this,
                    resposta,
                    validation
                  )
                );
              }, undefined);

              if (validationError) {
                return validationError;
              }
            }
            return true;
          })
        )
      })
    )
  };
  return Yup.object().shape(respostasCamposDinamicosYup);
};
