import React from 'react';

type Props = {
  title: string;
};

const FeatureSection: React.FC<Props> = ({ title }) => (
  <li className="features-section">{title}</li>
);

export default FeatureSection;
