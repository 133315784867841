import { useCallback } from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import * as IdentityProviderUtils from '../auth/IdentityProviderUtils';
import { ApplicationContext } from '../providers/ApplicationProvider';
import {
  KeycloakContext,
  RoleUsuarioDTO,
  UserInfo,
  UserProfile
} from '../providers/KeycloakProvider';
import AuthService from '../service/AuthService';
import * as UrlUtils from '../utils/UrlUtils';

const createAccountUrl = () => {
  return UrlUtils.getUrl(UrlUtils.Urls.MinhaConta);
};

const accountManagement = () => {
  window.location.href = createAccountUrl();
};

const logout = () =>
  AuthService.logout({
    redirectUri: IdentityProviderUtils.getLogoutUrl()
  });

export interface UseAuthData {
  hasRole: (role: string) => boolean;
  userInfo: UserInfo;
  userProfile: UserProfile;
  userPermissions: RoleUsuarioDTO;
  user: any;
  createAccountUrl: () => string;
  accountManagement: () => void;
  logout: () => Promise<void>;
  tenantIdentifier: string;
}

const useAuth = (): UseAuthData => {
  const gatewayUser = useSelector((state: any) => state.user?.currentUser);
  const { userInfo, userProfile } = useContext(KeycloakContext);
  const {
    userPermissions,
    userProfileStandalone,
    tenantIdentifier
  } = useContext(ApplicationContext);

  const hasRole = useCallback(
    (role: string) => userPermissions.clientRoles.some(r => role === r.name),
    [userPermissions]
  );

  return {
    hasRole,
    userInfo,
    userProfile: userProfileStandalone ?? userProfile,
    userPermissions,
    user: gatewayUser,
    createAccountUrl,
    accountManagement,
    logout,
    tenantIdentifier: tenantIdentifier ?? UrlUtils.getTenantIdentifier()
  };
};

export default useAuth;
