import axios, { AxiosPromise } from 'axios';

import { NotificationNotCountDto, PagedResponse } from '../type';

const EloNotificationService = {
  getNotifications: (): AxiosPromise<PagedResponse<any>> =>
    axios.get(`/painel-api/api/notifications`, {
      params: {
        size: 5,
        page: 0,
        sort: 'createOn,desc'
      }
    }),
  getUnreadCount: (): AxiosPromise<NotificationNotCountDto> =>
    axios.get(`/painel-api/api/notifications/count-not-readed`),

  markAsRead: (ids: string[]): AxiosPromise<void> =>
    axios.put(`/painel-api/api/notifications`, ids),

  getWebsocketURL: undefined
};

export default EloNotificationService;
