import { PageRequest, PagedResponse, Sort } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import {
  CadastroGeralCidadaoFiltroDTO,
  CadastroGeralCidadaoMobileDTO
} from '../type';

const defaultSort = { sort: 'cadastroGeral' };

export const findPropriedadesByProprietario = (
  cpfCnpj: string,
  searchParams: string,
  page: any
): AxiosPromise<any> =>
  axios.get(`/api/consulta-cadastral/cadastrosSearch?cpf-cnpj=${cpfCnpj}`, {
    params: { ...defaultSort, ...page, search: searchParams }
  });

export const findCadastrosByProprietario = (
  filtro: CadastroGeralCidadaoFiltroDTO,
  pagination?: PageRequest,
  sort?: Sort
): AxiosPromise<PagedResponse<CadastroGeralCidadaoMobileDTO>> =>
  axios.post(`/api/consulta-cadastral/cadastros/cidadao`, filtro, {
    params: { ...pagination, ...sort }
  });

export const getPropriedade = (
  tipoCadastro: string,
  cadastroGeral: string
): AxiosPromise<any> =>
  axios.get(
    `/api/consulta-cadastral/cadastro?tipo-cadastro=${tipoCadastro}&cadastro-geral=${cadastroGeral}`
  );
