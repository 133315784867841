import { ActionButton, FAB } from '@elotech/components';
import React from 'react';

export const PedidoActionButton = ({
  pedido,
  icon,
  title,
  onClick,
  fab,
  small = true
}) => {
  if (fab) {
    return (
      <FAB
        icon={icon}
        title={title}
        onClick={() => onClick(pedido)}
        small={small}
      />
    );
  }
  return (
    <ActionButton icon={icon} label={title} onClick={() => onClick(pedido)} />
  );
};

export default PedidoActionButton;
