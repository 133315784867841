import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import FAB from './Fab';

class FabSpeedDial extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string,
    verticalOptions: PropTypes.bool,
    showDestaque: PropTypes.bool,
    titleDestaque: PropTypes.node,
    infoDestaque: PropTypes.node
  };

  state = {
    showActions: false,
    destacarBotao: this.props.showDestaque
  };

  toggleActions = () => {
    this.setState(state => {
      return {
        showActions: !state.showActions
      };
    });
  };

  handleMouseEnter = () => {
    if (window.innerWidth > 768) {
      this.setState({
        showActions: true
      });
    }
  };

  handleMouseLeave = () => {
    if (window.innerWidth > 768) {
      this.setState({
        showActions: false
      });
    }
  };

  handleClick = () => {
    if (window.innerWidth < 769) {
      this.toggleActions();
    }

    this.setState({ destacarBotao: false });
  };

  mainButton = () => {
    const { showActions } = this.state;
    const { icon, title } = this.props;

    if (window.innerWidth > 768 || (window.innerWidth < 769 && !showActions)) {
      return (
        <FAB
          data-test-id="showActions"
          icon={icon}
          title={title}
          onClick={() => {}}
          hideHintOnMobile
        />
      );
    } else if (window.innerWidth < 769 && showActions) {
      return (
        <FAB
          data-test-id="hideActions"
          typeButton="negative"
          title="Fechar"
          onClick={() => {}}
          hideHintOnMobile
        />
      );
    } else {
      console.warn(
        `Não renderizou. innerWidth=${window.innerWidth} showActions=${showActions}`
      );
    }
  };

  render() {
    const { showActions, destacarBotao } = this.state;
    const { children, titleDestaque, infoDestaque } = this.props;

    const childrenFab = React.Children.toArray(children).filter(c => !!c);

    if (childrenFab.length === 0) {
      return null;
    }

    if (childrenFab.length === 1) {
      return (
        <div className="btn-save">
          {childrenFab.map(child =>
            React.cloneElement(child, { small: false })
          )}
        </div>
      );
    }

    const btnsClasses = classNames('btn-save', 'vertical-options', {
      hover: showActions,
      show: destacarBotao,
      'has-details': destacarBotao
    });

    return (
      <div
        className={btnsClasses}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClick}
      >
        <div className="options">
          {childrenFab.map(child => React.cloneElement(child, { small: true }))}
        </div>
        <div className="details">
          <h1>{titleDestaque}</h1>
          <p className="mt-xs">{infoDestaque}</p>
        </div>
        {this.mainButton()}
      </div>
    );
  }
}

FabSpeedDial.defaultProps = {
  verticalOptions: false
};

export default FabSpeedDial;
