import axios, { AxiosPromise } from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuth } from '../hooks';
import { FirebaseNotifications } from '../service/FirebaseService';
import { Tenant } from '../type';
import { getErrorMessage } from '../utils/ErrorMessageUtils';

export type NotificationContextProps = {
  error?: string;
  countNaoLidas: number;
  notifications: any[];
  hasMore: boolean;
  read(idNotification: string): void;
  readAll(): void;
  loadMore(): void;
  loading: boolean;
  disableNotification?: boolean;
};

export type NotificationProviderProps = {
  children: React.ReactNode;
  notificationService: FirebaseNotifications;
  gatewayResource: string;
  tenant?: Tenant;
  getToken?: () => AxiosPromise<string>;
};

export const NotificationContext = React.createContext<
  NotificationContextProps
>({
  error: undefined,
  countNaoLidas: 0,
  notifications: [],
  hasMore: false,
  loading: false,
  read: () => {},
  readAll: () => {},
  loadMore: () => {},
  disableNotification: false
});

const SIZE_NOTIFICATIONS = 3;

const defaultGetToken = (gatewayResource: string) => () =>
  axios.get(`${gatewayResource}/auth/firebase-token`);

const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
  notificationService,
  gatewayResource,
  tenant,
  getToken
}) => {
  const { userInfo } = useAuth();
  const [countNaoLidas, setCountNaoLidas] = useState(0);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [lastNotificationId, setLastNotificationId] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const getTokenFn = useMemo(() => {
    return getToken ?? defaultGetToken(gatewayResource);
  }, [getToken, gatewayResource]);

  useEffect(() => {
    getTokenFn()
      .then(response => notificationService.login(response.data))
      .catch(async error => {
        const errorMessage = await getErrorMessage(error);
        setError(`Erro na autenticação do Firebase: ${errorMessage}`);
      });
    return () => notificationService.logout();
  }, [tenant]);

  useEffect(() => {
    if (!tenant) {
      return;
    }
    notificationService.getUnreadCount(
      userInfo.id,
      tenant.id,
      (quantidadeNaoLidas: number) => {
        const size = Math.max(SIZE_NOTIFICATIONS, notifications.length);

        setCountNaoLidas(quantidadeNaoLidas);

        notificationService.loadNotifications(
          size,
          userInfo.id,
          tenant.id,
          (notifications: any) => {
            setNotifications(notifications);
            setLastNotificationId(notifications[notifications.length - 1]?.id);
            setHasMore(notifications.length === size);
          }
        );
      }
    );
  }, [userInfo.id, tenant]);

  const loadMore = useCallback(() => {
    if (!tenant) {
      return;
    }
    setLoading(true);

    notificationService
      .loadMoreNotifications(
        SIZE_NOTIFICATIONS,
        userInfo.id,
        lastNotificationId,
        tenant.id
      )
      .then((moreNotifications: any[]) => {
        setNotifications(prevState => [...prevState, ...moreNotifications]);
        setHasMore(moreNotifications.length === SIZE_NOTIFICATIONS);
        setLoading(false);
      });
  }, [notificationService, userInfo.id, lastNotificationId, tenant]);

  const read = useCallback(
    (idNotification: string) => {
      notificationService.readNotification(idNotification, userInfo.id);
    },
    [notificationService, userInfo.id]
  );

  const readAll = useCallback(() => {
    notificationService.readAllNotifications(userInfo.id);
  }, [notificationService, userInfo.id]);

  return (
    <NotificationContext.Provider
      value={{
        countNaoLidas,
        error,
        notifications,
        hasMore,
        read,
        readAll,
        loadMore,
        loading
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
