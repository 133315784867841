import React, { ReactNode } from 'react';

type Props = {
  title: ReactNode;
  children: ReactNode;
};

const Item: React.FC<Props> = ({ title, children }) => {
  return (
    <>
      <div className="autocomplete-item-text">
        <h3 className="autocomplete-item-title">{title}</h3>
        <article className="autocomplete-item-description">{children}</article>
      </div>
    </>
  );
};

export default Item;
