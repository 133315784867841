import React, { forwardRef } from 'react';

export type InputDateProps = React.InputHTMLAttributes<HTMLInputElement>;

const InputDateTime = forwardRef<HTMLInputElement, InputDateProps>(
  (props, ref) => (
    <input
      ref={ref}
      type="datetime-local"
      max="9999-12-31T00:00:00"
      autoComplete="off"
      {...props}
    />
  )
);

InputDateTime.displayName = 'InputDateTime';
export { InputDateTime };
