import { ActionButton, ActionsGroup } from '@elotech/components';
import React from 'react';

const renderPrintButton = (documento, { onPrintDocumento }, props) => (
  <ActionButton
    key="print-button"
    icon="print"
    label="Imprimir"
    onClick={() => onPrintDocumento(documento)}
    {...props}
  />
);

const renderDetailButton = (documento, { onShowDetail }, props) => (
  <ActionButton
    key="view-button"
    customClass="circle"
    icon="eye"
    label="Ver detalhes"
    onClick={() => onShowDetail(documento)}
    {...props}
  />
);

const actionButtons = [renderPrintButton, renderDetailButton];

export const renderActionButtons = (documento, actions, options = {}) => {
  return (
    <ActionsGroup>
      {actionButtons
        .map(renderFunction => renderFunction(documento, actions, options))
        .filter(item => !!item)}
    </ActionsGroup>
  );
};
