export const buildFilter = (fields: string[], op: string = 'or') => (
  value: string
): string => {
  const res = fields
    .map((field: string) => `(${field}=='*${value}*')`)
    .join(` ${op} `);
  return `(${res})`;
};

export const buildNumberAndStringFilter = (
  numerFields: string[],
  stringFields: string[],
  numberOp: string = 'or',
  stringOp: string = 'or'
) => (value: string): string => {
  if (!value) {
    return `((${stringFields[0]}=='**'))`;
  }
  const match = value.trim().match(/^\d+$/);
  const res: string[] = [];

  if (match) {
    res.push(
      numerFields
        .map((field: string) => `(${field}==${value.trim()})`)
        .join(` ${numberOp} `)
    );
  }

  res.push(
    stringFields
      .map((field: string) => `(${field}=='*${value}*')`)
      .join(` ${stringOp} `)
  );

  return `(${res.join(' or ')})`;
};

export const buildDefaultFilter = (
  numberField: string = 'id',
  textField: string = 'descricao'
) => (value: string): string => {
  const match = value.match(/^\d+$/);
  const textFilter = `${textField}=='*${value}*'`;
  return match ? `(${numberField}==${value} or ${textFilter})` : textFilter;
};

export const buildRangeNumericFilter = (
  fieldName: string,
  n: number,
  multiplier: number
) => {
  const nFrom = n * multiplier;
  const nTo = nFrom + (multiplier - 1);
  return n > 0 ? `(${fieldName}>=${nFrom} and ${fieldName}<=${nTo})` : '';
};

export const buildNameFilter = (numberField: string, textField: string) => (
  value: string
): string => {
  const match = value.match(/^\d+$/);
  const textFilter = `${textField}=='*${value}*'`;
  return match ? `(${numberField}==${value} or ${textFilter})` : textFilter;
};
