import { ErrorText, Loading, Masks } from '@elotech/components';
import { CepService } from 'itbi-common/service';
import { Constantes } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

const errorMessages = {
  logradouro: 'O endereço é obrigatório.',
  cidade: 'A cidade é obrigatória.',
  bairro: 'O bairro é obrigatório',
  cep: 'O CEP está inválido.',
  numero: 'O número é obrigatório.',
  numeroLength: 'Número deve ter no máximo 10 caracteres.',
  uf: 'O estado é obrigatório.'
};

class EnderecoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingCep: false
    };
  }

  onChangeCepValue = event => {
    const { onChangeField, onChangeFieldMask } = this.props;
    const cep = event.target.value.replace(/\D/g, '');

    onChangeFieldMask(event);

    if (cep.length === 0) {
      this.onClearCep();
    } else if (cep.length >= 8) {
      this.setState({ loadingCep: true }, () => {
        CepService.buscaCep(cep)
          .then(response => response.data)
          .then(data => {
            this.setState(prevState => ({ ...prevState, loadingCep: false }));

            const fields = {
              cep: data.cep.replace(/\D/g, ''),
              logradouro: data.logradouro,
              complemento: data.complemento,
              bairro: data.bairro,
              cidade: data.cidade,
              ibge: data.ibge,
              uf: data.uf
            };

            Object.keys(fields).forEach(k => {
              onChangeField({
                target: { name: k, value: fields[k] }
              });
            });
          })
          .catch(() => {
            onChangeField({
              target: { name: 'ibge', value: undefined }
            });
            this.setState({
              loadingCep: false
            });
          });
      });
    }
  };

  onClearCep = () => {
    const { onChangeField } = this.props;

    const fields = {
      cep: '',
      logradouro: '',
      complemento: '',
      bairro: '',
      cidade: '',
      ibge: '',
      uf: '',
      numero: ''
    };

    Object.keys(fields).forEach(k => {
      onChangeField({
        target: { name: k, value: fields[k] }
      });
    });
  };

  render() {
    const { endereco = {}, onChangeField, errors } = this.props;
    const { loadingCep } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="form-group col-sm-2">
            <label className="label" htmlFor="inputCep">
              CEP
            </label>
            <Loading loading={loadingCep} />
            <MaskedInput
              id="inputCep"
              data-test-id="inputCep"
              type="text"
              name="cep"
              className={errors.cep ? 'error' : ''}
              onChange={this.onChangeCepValue}
              value={endereco.cep || ''}
              mask={Masks.MASK_CEP}
            />
            {errors.cep && <ErrorText>{errorMessages.cep}</ErrorText>}
          </div>
          <div className="form-group col-sm-5">
            <label className="label" htmlFor="inputEndereco">
              Endereço
            </label>
            <input
              id="inputEndereco"
              type="text"
              className={errors.logradouro ? 'error' : ''}
              name="logradouro"
              onChange={onChangeField}
              value={endereco.logradouro || ''}
            />
            {errors.logradouro && (
              <ErrorText>{errorMessages.logradouro}</ErrorText>
            )}
          </div>
          <div className="form-group col-sm-2">
            <label className="label" htmlFor="inputNumero">
              Número
            </label>
            <input
              id="inputNumero"
              data-test-id="inputNumero"
              type="text"
              className={errors.numero || errors.numeroLength ? 'error' : ''}
              name="numero"
              onChange={onChangeField}
              value={endereco.numero || ''}
            />
            {errors.numero && <ErrorText>{errorMessages.numero}</ErrorText>}
            {errors.numeroLength && (
              <ErrorText>{errorMessages.numeroLength}</ErrorText>
            )}
          </div>
          <div className="form-group col-sm-3">
            <label className="label">
              Complemento <span className="optional">Opcional</span>
            </label>
            <input
              type="text"
              name="complemento"
              onChange={onChangeField}
              value={endereco.complemento || ''}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-5">
            <label className="label" htmlFor="inputBairro">
              Bairro
            </label>
            <input
              id="inputBairro"
              type="text"
              className={errors.bairro ? 'error' : ''}
              name="bairro"
              onChange={onChangeField}
              value={endereco.bairro || ''}
            />
            {errors.bairro && <ErrorText>{errorMessages.bairro}</ErrorText>}
          </div>
          <div className="form-group col-sm-5">
            <label className="label" htmlFor="inputCidade">
              Cidade
            </label>
            <input
              id="inputCidade"
              type="text"
              className={errors.cidade ? 'error' : ''}
              name="cidade"
              onChange={onChangeField}
              value={endereco.cidade || ''}
            />
            {errors.cidade && <ErrorText>{errorMessages.cidade}</ErrorText>}
          </div>
          <div className="form-group col-sm-2">
            <label className="label" htmlFor="selectEstado">
              Estado
            </label>
            <select
              id="selectEstado"
              name="uf"
              className={errors.uf ? 'error' : ''}
              value={endereco.uf || ''}
              onChange={onChangeField}
            >
              <option value="">Selecione</option>
              {Constantes.optionsEstados.map(item => (
                <option key={item.key} value={item.key}>
                  {item.label}
                </option>
              ))}
            </select>
            {errors.uf && <ErrorText>{errorMessages.uf}</ErrorText>}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

EnderecoForm.propTypes = {
  onChangeField: PropTypes.func.isRequired,
  onChangeFieldMask: PropTypes.func.isRequired
};

export default EnderecoForm;
