import React, { useEffect, useRef } from 'react';

export default function useListener(
  target: HTMLElement | Window | Document,
  event: string,
  callback: (event: Event) => void,
  ...args: any
) {
  const ref = useRef(callback);

  useEffect(() => {
    ref.current = callback;
  });

  useEffect(() => {
    const fn = (event: Event) => ref.current(event);

    target.addEventListener(event, fn, ...args);

    return () => target.removeEventListener(event, fn, ...args);
  }, []);
}
