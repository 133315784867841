import classNames from 'classnames';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import {
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
  useHistory,
  useLocation,
  withRouter
} from 'react-router';
import { Link } from 'react-router-dom';

import { Panel } from '../index';
import { getTabItemClassName, shouldRenderBadge } from './TabUtils';

type RouteTabProps = {
  path: string;
  header: ReactNode;
  children?: RouteProps['children'];
  badgeCount?: number;
  component?: React.ComponentType<any>;
};

const RouterTab: React.FC<RouteTabProps> = () => null;

type Props = {
  replace?: boolean;
};

type ComponentWithTab = React.FC<Props> & { Tab: typeof RouterTab };
const RouterTabs: ComponentWithTab = props => {
  const location = useLocation();
  const history = useHistory();

  const goToRoute = (routeProps: RouteTabProps) => {
    props.replace
      ? history.replace(routeProps.path)
      : history.push(routeProps.path);
  };
  const [activeTab, setActiveTab] = useState(0);

  const childrenArray = React.Children.toArray(
    props.children as ReactElement<RouteTabProps>
  );

  useEffect(() => {
    const newIndex = childrenArray.findIndex(
      child => child.props.path === location.pathname
    );
    if (newIndex === -1) {
      goToRoute(childrenArray[0].props);
      setActiveTab(0);
      return;
    }
    setActiveTab(newIndex);
  }, [location.pathname]);

  return (
    <Panel>
      <div className="panel-tab">
        <div className="panel-tab-selector">
          {childrenArray.map((child, index) => (
            <div
              className={getTabItemClassName(
                child.props,
                child.props.path === location.pathname
              )}
              data-position={index}
              key={index}
              onClick={() => goToRoute(child.props)}
            >
              {child.props.header}
              {shouldRenderBadge(child.props.badgeCount) && (
                <div className="badge">{child.props.badgeCount}</div>
              )}
            </div>
          ))}
        </div>
        <div className="panel-tab-content">
          <div className={`tab-item active`}>
            <Switch>
              {childrenArray.map((child, index) => (
                <Route
                  path={child.props.path}
                  component={child.props.component}
                  children={child.props.children}
                  exact
                  key={index}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </Panel>
  );
};

RouterTabs.Tab = RouterTab;

export default RouterTabs;
