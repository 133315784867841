import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useAuth, useLocalStorage } from '../hooks';

export type SidebarToggleContextType = {
  toggledSidebar: boolean;
  fullScreen: boolean;
  toggleSideBar: () => void;
  toggleFullScreen: () => void;
  currentUser: any;
  userExists: any;
};

export type SidebarToggleProps = {
  children: (props: SidebarToggleContextType) => React.ReactChildren;
};

export const SidebarToggleContext = React.createContext<
  SidebarToggleContextType
>({
  toggledSidebar: false,
  fullScreen: false,
  toggleSideBar: () => {},
  toggleFullScreen: () => {},
  userExists: undefined,
  currentUser: undefined
});

export const SidebarToggle: React.FC<SidebarToggleProps> = ({ children }) => {
  const [toggledSidebar, setToggledSidebar] = useLocalStorage(
    'toggledSidebar',
    true
  );

  const [fullScreen, setFullscreen] = useState(false);

  const userExists = useSelector((state: any) => state.user?.userExists);
  const { user } = useAuth();

  const toggleSideBar = () => {
    setToggledSidebar((prevState: any) => !prevState);
  };

  const toggleFullScreen = () => {
    setFullscreen((prevState: any) => !prevState);
  };

  const value = {
    userExists: userExists?.userExists,
    toggledSidebar,
    toggleSideBar,
    fullScreen,
    toggleFullScreen,
    currentUser: user
  };

  return (
    <SidebarToggleContext.Provider value={value}>
      {children(value)}
    </SidebarToggleContext.Provider>
  );
};
