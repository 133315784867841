import React from 'react';

import ActionButton from '../buttons/ActionButtons';
import ActionsGroup from '../buttons/Actions';

type Props = {
  className: string;
  renderHeader(): React.ReactNode;
  buttonLabel: string;
  children: React.ReactNode;
};

type State = {
  open: boolean;
};

export default class CollapseRow extends React.Component<Props, State> {
  state = {
    open: false
  };

  onToggle = () => {
    this.setState(state => ({
      open: !state.open
    }));
  };

  render() {
    const { className, renderHeader, children, buttonLabel } = this.props;
    const { open } = this.state;
    return (
      <React.Fragment>
        <tr className={className}>
          {renderHeader()}
          <td style={{ borderLeft: 'none' }}>
            <ActionsGroup>
              <ActionButton
                key="view-button"
                icon={open ? 'chevron-up' : 'chevron-down'}
                label={buttonLabel}
                onClick={this.onToggle}
              />
            </ActionsGroup>
          </td>
        </tr>
        {open && children}
      </React.Fragment>
    );
  }
}
