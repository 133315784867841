import { BasicInput, Col, FormikTextArea } from '@elotech/components';
import { Formik } from 'formik';
import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { FormGroup } from 'react-bootstrap';

import { TramiteProtocolo } from '../../types';

type Props = {
  tramite: TramiteProtocolo;
  saveParecerResposta: (tramiteComNovoParecer: TramiteProtocolo) => void;
  cancelEditTramite: (tramite: TramiteProtocolo) => void;
};

const ProcessoViewPageParecerInnerForm: React.FC<Props> = ({
  tramite,
  saveParecerResposta,
  cancelEditTramite
}) => (
  <Formik
    enableReinitialize
    initialValues={{
      parecer: tramite.parecer,
      parecerResposta: tramite.parecerResposta,
      dataParecerResposta: tramite.dataParecerResposta
    }}
    onSubmit={values =>
      saveParecerResposta({
        ...tramite,
        parecerResposta: values.parecerResposta
      })
    }
  >
    {formProps => (
      <>
        <Col md={6} sm={6}>
          <Row>
            <FormikTextArea
              name="parecerResposta"
              label="Parecer do Requerente"
              type="text"
              size={12}
              rows={4}
            />
          </Row>
          <Row>
            <BasicInput
              size={4}
              label="Data/Hora"
              name="dataParecerResposta"
              disabled
              render={({ field }) => (
                <input
                  {...field}
                  type="datetime-local"
                  max="9999-12-31T00:00:00"
                />
              )}
            />
          </Row>
          <Row className="mt-xs">
            <Col md={12} sm={12}>
              <FormGroup>
                <Button
                  className="inline icon-left module-color"
                  onClick={() => formProps.submitForm()}
                >
                  <i className="fa fa-check" />
                  Atualizar
                </Button>
                <Button
                  type="button"
                  className="inline icon-left outlined negative"
                  onClick={() => cancelEditTramite(tramite)}
                >
                  <i className="fa fa-times" />
                  Cancelar
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={6}>
          <Row>
            <FormikTextArea
              name="parecer"
              label="Parecer"
              size={12}
              rows={4}
              disabled
            />
          </Row>
        </Col>
      </>
    )}
  </Formik>
);

export { ProcessoViewPageParecerInnerForm };
