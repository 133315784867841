import { ErrorText } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

const LocalizacaoImovel = props => {
  const {
    permiteBuscaPorInscricaoCadastral,
    cadastroImobiliario,
    imovelNotFoundByCadastro,
    imovelNotFoundByInscricaoCadastral,
    onChangeField,
    onKeyDownCadastroImobiliario,
    onBlurCadastroImobiliario,
    error,
    errorMessages,
    loading,
    isServidor
  } = props;
  return (
    <div>
      <div className="row">
        <div className="form-group col-md-3">
          <label htmlFor="cadastroImobiliarioInput">Cadastro Imobiliário</label>
          <NumberFormat
            id="cadastroImobiliarioInput"
            data-cy="inputCadastroImobiliario"
            name="cadastro"
            value={cadastroImobiliario.cadastro}
            onChange={onChangeField}
            onKeyDown={onKeyDownCadastroImobiliario}
            onBlur={onBlurCadastroImobiliario}
            maxLength={10}
            decimalScale={0}
            allowNegative={false}
            placeholder="Digite o cadastro municipal"
            autoComplete="off"
            className={
              loading
                ? 'loading'
                : imovelNotFoundByCadastro ||
                  error.cadastro ||
                  error.isCadastroInadimplente
                ? 'error'
                : ''
            }
          />
          {loading && <i className="fa fa-spinner spinner" />}
          {imovelNotFoundByCadastro && (
            <div>
              <ErrorText>Não foi possível localizar o imóvel!</ErrorText>
            </div>
          )}
          {!imovelNotFoundByCadastro && error.cadastro && (
            <ErrorText>{errorMessages.cadastro}</ErrorText>
          )}
          {error.cadastroInadimplente && (
            <ErrorText>{errorMessages.cadastroInadimplente}</ErrorText>
          )}
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inscricaoInput">
            Inscrição Cadastral
            {permiteBuscaPorInscricaoCadastral && (
              <div className="hint inline clean module-color fa-exclamation-circle mobile sm">
                <div className="hint-content">
                  Necessário informar a inscrição sem utilizar pontuações
                </div>
              </div>
            )}
          </label>
          <input
            id="inscricaoInput"
            type="text"
            name="inscricaoCadastral"
            value={cadastroImobiliario.inscricaoCadastral || ''}
            placeholder={
              permiteBuscaPorInscricaoCadastral &&
              'Digite a inscrição cadastral'
            }
            disabled={!permiteBuscaPorInscricaoCadastral}
            maxLength={100}
            onChange={onChangeField}
            onBlur={onBlurCadastroImobiliario}
            onKeyDown={onKeyDownCadastroImobiliario}
            className={
              loading
                ? 'loading'
                : imovelNotFoundByInscricaoCadastral || error.inscricaoCadastral
                ? 'error'
                : ''
            }
          />
          {loading && <i className="fa fa-spinner spinner" />}
          {imovelNotFoundByInscricaoCadastral && (
            <div>
              <ErrorText>Não foi possível localizar o imóvel!</ErrorText>
            </div>
          )}
          {!imovelNotFoundByInscricaoCadastral && error.inscricaoCadastral && (
            <ErrorText>{errorMessages.inscricaoCadastral}</ErrorText>
          )}
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="loteInput">Data/Lote</label>
          <input
            id="loteInput"
            type="text"
            name="lote"
            value={cadastroImobiliario.lote}
            disabled
          />
        </div>
        <div className="form-group col-md-1">
          <label htmlFor="quadraInput">Quadra</label>
          <input
            id="quadraInput"
            type="text"
            name="quadra"
            value={cadastroImobiliario.quadra}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <label htmlFor="loteInputComplemento">Complemento do Lote</label>
          <input
            id="loteInputComplemento"
            type="text"
            name="complementoLote"
            value={cadastroImobiliario.complementoLote || ''}
            disabled
          />
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="areaTerrenoInput">Área Terreno</label>
          <input
            id="areaTerrenoInput"
            type="text"
            name="areaTerreno"
            value={cadastroImobiliario.areaTerreno}
            disabled
          />
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="areaConstruidaInput">Área Construída</label>
          <input
            id="areaConstruidaInput"
            type="text"
            name="areaConstruida"
            value={cadastroImobiliario.areaConstruida}
            disabled
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-2">
          <label htmlFor="cepInput">CEP</label>
          <input
            id="cepInput"
            type="text"
            name="cep"
            value={cadastroImobiliario.cep}
            disabled
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="enderecoInput">Endereço</label>
          <input
            id="enderecoInput"
            type="text"
            name="endereco"
            value={cadastroImobiliario.endereco}
            disabled
          />
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="numeroInput">Número</label>
          <input
            id="numeroInput"
            type="text"
            name="numero"
            value={cadastroImobiliario.numero}
            onChange={onChangeField}
            disabled={!isServidor}
            className={error.numeroEndereco ? 'error' : ''}
          />
          {error.numeroEndereco && (
            <ErrorText>{errorMessages.numeroEnderecoLength}</ErrorText>
          )}
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="complementoInput">Complemento</label>
          <input
            id="complementoInput"
            type="text"
            name="complemento"
            value={cadastroImobiliario.complemento || ''}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4">
          <label htmlFor="bairroInput">Bairro</label>
          <input
            id="bairroInput"
            type="text"
            name="bairro"
            value={cadastroImobiliario.bairro || ''}
            disabled
          />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="cidadeInput">Cidade</label>
          <input
            id="cidadeInput"
            type="text"
            name="cidade"
            value={cadastroImobiliario.cidade}
            disabled
          />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="estadoInput">Estado</label>
          <input
            id="estadoInput"
            type="text"
            name="estado"
            value={cadastroImobiliario.estado}
            disabled
          />
        </div>

        <div className="form-group col-md-12">
          <label htmlFor="condominioInput">Condomínio</label>
          <input
            id="condominioInput"
            type="text"
            name="condominio"
            value={
              cadastroImobiliario.condominio
                ? `${cadastroImobiliario.condominio} - ${cadastroImobiliario.descricaoCondominio}`
                : ''
            }
            disabled
          />
        </div>
      </div>
    </div>
  );
};

LocalizacaoImovel.propTypes = {
  imovelNotFoundByCadastro: PropTypes.bool,
  imovelNotFoundByInscricaoCadastral: PropTypes.bool,
  cadastroImobiliario: PropTypes.object.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onKeyDownCadastroImobiliario: PropTypes.func.isRequired,
  onBlurCadastroImobiliario: PropTypes.func.isRequired,
  error: PropTypes.object,
  isServidor: PropTypes.bool
};

export default LocalizacaoImovel;
