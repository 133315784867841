import { AxiosPromise } from 'axios';
import { FormikProps } from 'formik';
import React from 'react';

import { EditableAutoCompleteProps } from '../../editableInput/EditableAutoComplete';
import { EditableAutoComplete, FormikEditableInput } from '../..';
import { FormikEditableInputProps } from '../FormikEditableInput/FormikEditableInput';

export type FormikEditableAutocompleteProps<T> = FormikEditableInputProps<T> &
  Partial<EditableAutoCompleteProps<T>> & {
    name: string;
    onSearch(params: string): AxiosPromise<any>;
    onItemSelected?: (form: FormikProps<any>, value: any) => void;
  };

const FormikEditableAutocomplete = <T extends {}>(
  props: FormikEditableAutocompleteProps<T>
) => {
  const onSelect = (form: FormikProps<any>, name: string, value: any) => {
    form.setFieldValue(name, value || '');
    form.setFieldTouched(name);
    props.onItemSelected && props.onItemSelected(form, value);
  };

  return (
    <FormikEditableInput
      {...props}
      render={({ field, form }) => (
        <EditableAutoComplete
          {...field}
          {...props}
          value={field.value}
          onSelect={(name: string, value: any) => onSelect(form, name, value)}
          renderVisualization={() => field.value}
        />
      )}
    />
  );
};

export default FormikEditableAutocomplete;
