import React from 'react';

type Props = {
  active: boolean;
  onChange(name: string, value: boolean): void;
  name: string;
  caption?: string;
  title?: string;
  labelInline?: boolean;
};
const Switch: React.FunctionComponent<Props> = ({
  active,
  onChange,
  name,
  caption,
  title,
  labelInline = false
}) => {
  const onClick = () => {
    onChange && onChange(name, !active);
  };

  return (
    <span
      title={title}
      className={`switch-caption ${labelInline ? 'nowrap' : ''}`}
      onClick={onClick}
    >
      <em className={`switch ${active && 'active'} module-color`} /> {caption}
    </span>
  );
};

export default Switch;
