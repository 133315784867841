import { Button } from '@elotech/components';
import { ConcursoPremiadoResumoPorCpf } from 'itbi-common/type';
import React from 'react';

import { ReactComponent as ImagemAdesao } from '../../assets/img/iss_premiado.svg';

type Props = {
  concurso: ConcursoPremiadoResumoPorCpf;
  onClickAceite: () => void;
  renderRegulamentoText: () => void;
};

const ConcursoPremiadoAdesaoPage: React.FC<Props> = ({
  concurso,
  onClickAceite,
  renderRegulamentoText
}) => {
  return (
    <div className={'mt-xs'} style={{ textAlign: 'center' }}>
      <div>
        <ImagemAdesao
          className="module-color"
          style={{
            width: '535px',
            maxWidth: '100%'
          }}
        />
      </div>
      <p className={'typography-size-20px'}>Sua nota vale prêmios!</p>
      <p
        className={'typography-size-30px typography-bold primary-color center'}
      >
        {concurso.premiacao}
      </p>
      <p className={'typography-size-15px typography-bold center'}>
        {' '}
        em prêmios
      </p>
      <p>{`${concurso.textoInformativo}`}</p>
      <p>
        Ao clicar em Aceito, você concorda com os termos do
        {renderRegulamentoText()}.
      </p>
      <div>
        <div style={{ display: 'inline-block' }}>
          <Button data-test-id="btn-aceite" onClick={onClickAceite}>
            Aceito
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConcursoPremiadoAdesaoPage;
