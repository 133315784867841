import axios, { AxiosPromise } from 'axios';

import { CadastroImobiliario } from '../type';

export const findCadastroImobiliario = (codigo: string): AxiosPromise<any> =>
  axios.get(`/api/imoveis/${codigo}`);

export const findByInscricaoCadastral = (
  inscricaoCadastral: string
): AxiosPromise<any> =>
  axios.get(`/api/imoveis/${inscricaoCadastral}/inscricao-cadastral`);

export const findByCadastroComValorAvaliado = (
  cadastro: string
): AxiosPromise<CadastroImobiliario> =>
  axios.get(`/api/imoveis/${cadastro}/calcular-valores-avaliacao`);
