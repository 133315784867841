import {
  FormattedCurrency,
  Hint,
  HintContent,
  HintRow,
  Panel,
  PanelFooter,
  PanelFooterItem,
  SectionTitle,
  Table
} from '@elotech/components';
import React from 'react';

import {
  DebitoEmAberto,
  DebitosEmAbertoComTotalizadorDTO,
  ParcelamentoCadastroDTO
} from '../../../types';
import { Requerente } from './ParcelamentoFormPage';
import ParcelamentoIdentificacao from './ParcelamentoIdentificacao';

type Props = {
  cadastro: ParcelamentoCadastroDTO;
  requerente: Requerente;
  debitosEmAberto?: DebitosEmAbertoComTotalizadorDTO;
  loading: boolean;
};

const DebitosEmAbertoFooter: React.FC<{
  values: DebitosEmAbertoComTotalizadorDTO;
}> = ({ values }) => (
  <PanelFooter>
    <PanelFooterItem
      label="Principal"
      value={<FormattedCurrency value={values.valorPrincipal} />}
    />
    <PanelFooterItem
      label="Acréscimos"
      value={<FormattedCurrency value={values.valorAcrescimos} />}
    />
    <PanelFooterItem
      label="Desconto"
      value={<FormattedCurrency value={values.valorDescontos} />}
    />
    <PanelFooterItem
      label="Valor Total"
      value={<FormattedCurrency value={values.valorTotal} />}
    />
  </PanelFooter>
);
const DebitosStep: React.FC<Props> = ({
  cadastro,
  requerente,
  debitosEmAberto,
  loading
}) => {
  return (
    <>
      <ParcelamentoIdentificacao cadastro={cadastro} requerente={requerente} />
      <SectionTitle>Débitos em aberto</SectionTitle>
      <Panel
        isTable
        footer={
          debitosEmAberto && <DebitosEmAbertoFooter values={debitosEmAberto} />
        }
      >
        <Table
          fixed
          loading={loading}
          values={debitosEmAberto?.debitos ?? []}
          messageEmpty={'Esse cadastro não possui débitos em aberto'}
          keyExtractor={item => item.idDebito}
        >
          <Table.Column<DebitoEmAberto>
            header="Ano"
            value={item => item.exercicio}
          />
          <Table.Column<DebitoEmAberto>
            header="Dívida"
            value={item => `${item.divida} - ${item.descricaoDivida}`}
          />
          <Table.Column<DebitoEmAberto>
            header="Subdívida"
            value={item => item.subDivida}
          />
          <Table.Column<DebitoEmAberto>
            header="Valor Principal"
            headerClassName="right"
            className="right"
            value={item => (
              <FormattedCurrency hideSymbol value={item.valorPrincipal} />
            )}
          />
          <Table.Column<DebitoEmAberto>
            header="Valor Acréscimos"
            headerClassName="right"
            className="right"
            value={item => (
              <>
                <Hint
                  classes={`inline clean module-color fa-exclamation-circle mobile sm bottom-right`}
                >
                  <HintContent>
                    <HintRow>
                      Juros:
                      <FormattedCurrency value={item.valorJuros} />
                    </HintRow>
                    <HintRow>
                      Multa:
                      <FormattedCurrency value={item.valorMulta} />
                    </HintRow>
                    <HintRow>
                      Correção:
                      <FormattedCurrency value={item.valorCorrecao} />
                    </HintRow>
                  </HintContent>
                </Hint>
                <FormattedCurrency hideSymbol value={item.valorAcrescimos} />
              </>
            )}
          />
          <Table.Column<DebitoEmAberto>
            header="Valor Descontos"
            headerClassName="right"
            className="right"
            value={item => (
              <FormattedCurrency hideSymbol value={item.valorDescontos} />
            )}
          />
          <Table.Column<DebitoEmAberto>
            header="Valor Total"
            headerClassName="right"
            className="right"
            value={item => (
              <FormattedCurrency hideSymbol value={item.valorTotal} />
            )}
          />
        </Table>
      </Panel>
    </>
  );
};

export default DebitosStep;
