import React from 'react';

import { FormikInputNumber, FormikInputNumberProps } from './FormikInputNumber';

/**
 * @deprecated Será removido em versões futuras.
 */
const MaskedInput: React.FC<FormikInputNumberProps> = props => {
  console.error(
    `O component @elotech/components/MaskedInput foi depreciado e será removido em versões futuras!\n Utilizar o FormikInputNumber como substituto.
    `
  );

  return <FormikInputNumber {...props} />;
};

export default MaskedInput;
