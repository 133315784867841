import {
  ActionButton,
  ActionsGroup,
  Alert,
  Chip,
  Container,
  Panel,
  SearchPagination,
  Table,
  useAuth,
  usePagedQuery
} from '@elotech/components';
import { PropriedadeService } from 'itbi-common/service';
import {
  CadastroGeralCidadaoFiltroDTO,
  CadastroGeralCidadaoMobileDTO
} from 'itbi-common/type';
import React, { useEffect, useState } from 'react';

import CadastrosFilter from '../propriedade/CadastrosFilter';
import CarneInnerComponentPage from './CarneInnerComponentPage';

const CarneListPage: React.FC = () => {
  const { user } = useAuth();
  const initialFilter: CadastroGeralCidadaoFiltroDTO = {
    cpfCnpj: user.cpfCnpj
  };
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);

  const {
    loading,
    values,
    pagination,
    doSearch,
    doSortChange,
    doPagedSearch
  } = usePagedQuery<
    CadastroGeralCidadaoMobileDTO,
    CadastroGeralCidadaoFiltroDTO
  >({
    search: PropriedadeService.findCadastrosByProprietario,
    onError: (error: any) => {
      Alert.error(
        {
          title: 'Não foi possível consultar os cadastros'
        },
        error
      );
    }
  });

  const onExpandIndex = (_: any, index: number) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes([]);
    } else {
      setExpandedIndexes([index]);
    }
  };

  const renderInnerComponent = (item: any, index: number) => {
    if (expandedIndexes.length > 0 && expandedIndexes.includes(index)) {
      return <CarneInnerComponentPage item={item} />;
    }
    return null;
  };

  useEffect(
    () => {
      doSearch(initialFilter);
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Container breadcrumb>
      <Panel isTable>
        <CadastrosFilter
          filtro={initialFilter}
          onSearch={doSearch}
          isFiltroCarne
        />
        <Table
          values={values}
          loading={loading}
          renderInnerComponent={(item: any, index: number) =>
            renderInnerComponent(item, index)
          }
          sortOnHeaderColumnClick={doSortChange}
        >
          <Table.Column<CadastroGeralCidadaoMobileDTO>
            header="Tipo Cadastro"
            sortable
            name="descricaotipocadastro"
            value={item => item.descricaoTipoCadastro}
          />
          <Table.Column<CadastroGeralCidadaoMobileDTO>
            header="Cadastro"
            sortable
            name="cadastrogeral"
            value={item => item.cadastroGeral}
          />
          <Table.Column<CadastroGeralCidadaoMobileDTO>
            header="Nome"
            sortable
            name="nome"
            value={item => item.nome}
          />
          <Table.Column<CadastroGeralCidadaoMobileDTO>
            header="Endereço"
            sortable
            name="endereco"
            value={item => item.endereco}
          />
          <Table.Column<CadastroGeralCidadaoMobileDTO>
            header="Possui carnê ?"
            sortable
            name="existecarne"
            value={item => (
              <Chip
                className="center"
                value={item.temCarne}
                color={item.existeCarne ? 'positive' : 'negative'}
              />
            )}
          />
          <Table.Column<CadastroGeralCidadaoMobileDTO>
            header=""
            value={(item, index) =>
              item.existeCarne && (
                <ActionsGroup>
                  <ActionButton
                    key={`expand-${item.tipoCadastro}-${item.cadastroGeral}`}
                    icon="eye"
                    label="Visualizar carnês"
                    onClick={() => onExpandIndex(item, index)}
                  />
                </ActionsGroup>
              )
            }
          />
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
    </Container>
  );
};

export default CarneListPage;
