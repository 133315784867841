import {
  FormattedDate,
  Loading,
  SearchFilter,
  SearchPagination,
  Table,
  buildFilterRsql,
  parseFilterUrl
} from '@elotech/components';
import {
  DocumentoGeradoService,
  DocumentoTipoService,
  TipoCadastroService,
  withService
} from 'itbi-common/service';
import PropTypes from 'prop-types';
import React from 'react';

import { renderActionButtons } from './DocumentoEmitidoActions';
import { getFields } from './DocumentoEmitidoSearchField';

class DocumentoEmitidoList extends React.Component {
  static propTypes = {
    usuario: PropTypes.object,
    onPrintDocumento: PropTypes.func.isRequired,
    onShowDetail: PropTypes.func.isRequired,
    documentoGeradoService: PropTypes.shape({
      loadDocumentosByCpfCnpj: PropTypes.func.isRequired
    }).isRequired,
    documentoTipoService: PropTypes.shape({
      loadDocumentoTipo: PropTypes.func.isRequired
    }).isRequired,
    tipoCadastroService: PropTypes.shape({
      loadTipoCadastro: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    loading: false,
    documentosPage: {},
    searchParams: '',
    searchFields: [],
    tiposCadastro: [],
    tiposDocumento: []
  };

  componentDidMount() {
    this.loadTipoCadastro();
    this.loadDocumentoTipo();
    this.updateSearchFields();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.usuario !== this.props.usuario && this.props.usuario) {
      this.updateSearchFields();

      this.findDocumentos(
        buildFilterRsql(
          parseFilterUrl(this.state.searchFields, this.props.location)
        )
      );
    }
  }

  updateSearchFields = () => {
    const { tiposCadastro, tiposDocumento } = this.state;
    this.setState({
      searchFields: getFields({ tiposCadastro, tiposDocumento })
    });
  };

  loadTipoCadastro = () => {
    this.setState({ loading: true });
    this.props.tipoCadastroService
      .loadTipoCadastro()
      .then(this.onGetTipoCadastro)
      .finally(() => this.setState({ loading: false }));
  };

  onGetTipoCadastro = response => {
    const tiposCadastro = response.data.map(tipoCadastro => ({
      name: tipoCadastro.id.toString(),
      descricao: tipoCadastro.descricao
    }));
    this.setState({ tiposCadastro });
  };

  loadDocumentoTipo = () => {
    this.setState({ loading: true });
    this.props.documentoTipoService
      .loadDocumentoTipo()
      .then(this.onGetDocumentoTipo)
      .finally(() => this.setState({ loading: false }));
  };

  onGetDocumentoTipo = response => {
    this.setState({ tiposDocumento: response.data });
  };

  findDocumentos = (searchParams = '', page = {}) => {
    this.setState({ loading: true }, () => {
      this.props.documentoGeradoService
        .loadDocumentosByCpfCnpj(this.props.usuario.cpfCnpj, searchParams, page)
        .then(response =>
          this.setState({
            documentosPage: response.data,
            searchParams
          })
        )
        .finally(() => this.setState({ loading: false }));
    });
  };

  searchWithPage = page => {
    this.findDocumentos(this.state.searchParams, page);
  };

  render() {
    const { onShowDetail, onPrintDocumento, usuario } = this.props;
    const { documentosPage = {}, loading, searchFields } = this.state;

    if (!usuario) {
      return 'Selecione um Usuário';
    }

    const actions = {
      onShowDetail: onShowDetail,
      onPrintDocumento: onPrintDocumento
    };

    return (
      <div className="panel table table-responsive">
        <Loading loading={loading} />
        <div className="panel-body">
          {usuario && (
            <SearchFilter fields={searchFields} search={this.findDocumentos} />
          )}
          <Table values={documentosPage.content || []}>
            <Table.Column
              header="Tipo"
              value={item => item.descricaoTipoDocumento}
            />
            <Table.Column header="Documento" value={item => item.documento} />
            <Table.Column
              header="Validade"
              value={item =>
                item.dataValidade ? (
                  <FormattedDate value={item.dataValidade} timeZone={'UTC'} />
                ) : null
              }
            />
            <Table.Column
              header="Emissão"
              value={item => (
                <FormattedDate value={item.dataEmissao} timeZone={'UTC'} />
              )}
            />
            <Table.Column
              header="Tipo Cadastro"
              value={item =>
                item.tipoCadastro ? item.tipoCadastro.descricao : ''
              }
            />
            <Table.Column
              header="Cadastro"
              value={item => item.cadastroGeral}
            />
            <Table.Column
              header=""
              value={item => renderActionButtons(item, actions)}
            />
          </Table>
          {documentosPage && (
            <SearchPagination
              page={documentosPage}
              searchWithPage={this.searchWithPage}
            />
          )}
        </div>
      </div>
    );
  }
}

const WithServiceComponent = withService({
  documentoGeradoService: DocumentoGeradoService,
  documentoTipoService: DocumentoTipoService,
  tipoCadastroService: TipoCadastroService
})(DocumentoEmitidoList);

export { WithServiceComponent as default, DocumentoEmitidoList };
