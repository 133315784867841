import React from 'react';

import TextArea, { TextAreaProps } from '../inputs/TextArea';
import BasicInput, { Props as BasicInputProps } from './BasicInput';

export const FormikTextArea: React.FC<BasicInputProps &
  TextAreaProps> = props => (
  <BasicInput
    {...props}
    render={({ field }) => (
      <>
        <TextArea
          resize={props.resize}
          placeholder={props.placeholder}
          {...field}
          id={props.name}
          rows={props.rows}
        />
        {props.maxLength && (
          <div
            className="help-message gray-60 right col-md-12"
            data-testid="contador-textarea"
          >
            {`${field.value ? field.value.length : 0}/${props.maxLength}`}
          </div>
        )}
      </>
    )}
  />
);
