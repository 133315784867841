const getFields = ({ tiposCadastro, tiposDocumento }) => {
  return [
    {
      label: 'Tipo Documento',
      name: 'tipoDocumento.id',
      type: 'AUTOCOMPLETE',
      loadOptions: searchParameter =>
        Promise.resolve(
          tiposDocumento.filter(documento =>
            documento.descricao.includes(searchParameter)
          )
        ),
      getOptionValue: option => {
        return option.id;
      },
      getOptionLabel: option => option.descricao,
      options: tiposDocumento
    },
    {
      label: 'Documento',
      name: 'documento',
      type: 'NUMBER'
    },
    {
      label: 'Data Validade',
      name: 'dataValidade',
      type: 'DATE'
    },
    {
      label: 'Data Emissão',
      name: 'dataEmissao',
      type: 'DATE'
    },
    {
      label: 'Tipo Cadastro',
      name: 'cadastroGeral.tipoCadastro',
      type: 'ENUM',
      options: tiposCadastro
    },
    {
      label: 'Cadastro Geral',
      name: 'cadastroGeral.cadastroGeral',
      type: 'NUMBER'
    }
  ];
};

export { getFields };
