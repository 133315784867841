import PropTypes from 'prop-types';
import React from 'react';

const PageItem = ({ active, item, onClick }) => {
  const classActive = active ? 'active' : '';

  return (
    <li
      key={`page-item-${item}`}
      data-testid={`${item}`}
      className={`page-item ${classActive}`}
      onClick={onClick}
    >
      <a className="page-link" href={`#${item}`}>
        {item}
      </a>
    </li>
  );
};

const MAX_PAGES = 5;

class SearchPagination extends React.Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired,
    showTotalRegistro: PropTypes.bool,
    registrosSelecionados: PropTypes.array,
    splitIntoTwoRows: PropTypes.bool,
    loading: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.itemsPagina = React.createRef();
  }

  handleSelectNumeroItems = () => {
    if (!this.props.loading)
      this.props.searchWithPage({
        page: 0,
        size: +this.itemsPagina.current.value
      });
  };

  loadFirstPage = event => {
    event.preventDefault();
    const {
      page: { size },
      searchWithPage
    } = this.props;
    if (!this.props.loading) searchWithPage({ page: 0, size });
  };

  loadPreviousPage = event => {
    event.preventDefault();
    const {
      page: { size, number },
      searchWithPage
    } = this.props;
    if (!this.props.loading) searchWithPage({ page: number - 1, size });
  };

  loadThisPage = (event, page) => {
    event.preventDefault();
    const {
      page: { size },
      searchWithPage
    } = this.props;
    if (!this.props.loading) searchWithPage({ page, size });
  };

  loadNextPage = event => {
    event.preventDefault();
    const {
      page: { size, number },
      searchWithPage
    } = this.props;
    if (!this.props.loading) searchWithPage({ page: number + 1, size });
  };

  loadLastPage = event => {
    event.preventDefault();
    const {
      page: { size, totalPages },
      searchWithPage
    } = this.props;
    if (!this.props.loading) searchWithPage({ page: totalPages - 1, size });
  };

  renderNumberPages = () => {
    const { page } = this.props;

    let start = page.number + 1 - MAX_PAGES / 2;

    if (page.totalPages <= MAX_PAGES) {
      start = Math.max(1, Math.floor(start));
    } else {
      start = Math.max(1, Math.ceil(start));
    }

    const end = Math.min(page.totalPages, start + MAX_PAGES - 1);

    if (page.last) {
      start = Math.max(1, end - MAX_PAGES + 1);
    }

    const pages = [];

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    return pages.map(item => (
      <PageItem
        key={`page-item-${item}`}
        item={item}
        active={item === page.number + 1}
        onClick={event => this.loadThisPage(event, item - 1)}
      />
    ));
  };

  render() {
    const {
      page,
      showTotalRegistro,
      splitIntoTwoRows,
      registrosSelecionados
    } = this.props;
    const pageHasData =
      page && page.numberOfElements && page.numberOfElements > 0;

    if (!pageHasData) {
      return null;
    }

    return (
      <div className="panel-pagination">
        <div
          className={
            splitIntoTwoRows
              ? 'align-items-center flex justify-content-center row'
              : 'sisters'
          }
        >
          <div
            className={`${'number-itens'}${
              splitIntoTwoRows ? ' ml-none mr-none br-none' : ''
            }`}
          >
            <b>Mostrar</b>
            <select
              name="itemsPagina"
              className="form-control control-label"
              onChange={this.handleSelectNumeroItems}
              ref={this.itemsPagina}
              value={page.size}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
            <b>Itens</b>
          </div>
        </div>
        <div className={splitIntoTwoRows ? 'panel-body' : 'sisters'}>
          <ul className="panel-pagination-inner">
            {!page.first && page.totalPages > MAX_PAGES && (
              <PageItem item="«" onClick={this.loadFirstPage} />
            )}
            {!page.first && page.totalPages > MAX_PAGES && (
              <PageItem item="‹" onClick={this.loadPreviousPage} />
            )}
            {this.renderNumberPages()}
            {!page.last && page.totalPages > MAX_PAGES && (
              <PageItem item="›" onClick={this.loadNextPage} />
            )}
            {!page.last && page.totalPages > MAX_PAGES && (
              <PageItem item="»" onClick={this.loadLastPage} />
            )}
          </ul>
        </div>
        {this.props.children}
        {showTotalRegistro && (
          <div className="sisters">
            <div className="highLight">
              Total de Registros: <b>{page.totalElements}</b>
            </div>
          </div>
        )}
        {registrosSelecionados && (
          <div className="sisters">
            <div className="highLight">
              Total Selecionados: <b>{registrosSelecionados.length}</b>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SearchPagination;
