import { Col, FormattedDate, QuickView, Row } from '@elotech/components';
import { FunctionUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';

const DocumentoGeradoQuickView = ({ documentoSelecionado, onClose }) => {
  const {
    documento,
    exercicio,
    tipoDocumento,
    descricaoTipoDocumento,
    dataEmissao,
    dataValidade,
    tipoCadastro,
    cadastroGeral,
    codigoValidacao,
    finalidade,
    cpfRequerente,
    nomeRequerente,
    subTipo
  } = documentoSelecionado;

  return (
    <QuickView onClose={onClose}>
      <div className="quick-header">
        {`${tipoDocumento} - ${descricaoTipoDocumento}`}
      </div>
      <Row>
        <Col md={6}>
          <div className="form-group">
            <label className="label">Documento</label>
            {`${documento}`}
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <label className="label">Exercício</label>
            {`${exercicio}`}
          </div>
        </Col>
      </Row>
      {cadastroGeral && (
        <Row>
          <Col md={12}>
            <div className="form-group">
              <label className="label">Cadastro</label>
              {`${tipoCadastro.descricao} - ${cadastroGeral}`}
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={6}>
          <div className="form-group">
            <label className="label">Data Emissão</label>
            <FormattedDate value={dataEmissao} timeZone={'UTC'} />
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <label className="label">Data Validade</label>
            <FormattedDate value={dataValidade} timeZone={'UTC'} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="form-group">
            <label className="label">Requerente</label>
            {`${cpfRequerente} - ${nomeRequerente}`}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="form-group">
            <label className="label">Finalidade</label>
            {`${finalidade}`}
          </div>
        </Col>
      </Row>
      {subTipo && (
        <Row>
          <Col md={12}>
            <div className="form-group">
              <label className="label">Situação</label>
              {`${FunctionUtils.getSubTipoDocumento(subTipo)}`}
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12}>
          <div className="form-group">
            <label className="label">Código Autenticidade</label>
            {`${codigoValidacao}`}
          </div>
        </Col>
      </Row>
    </QuickView>
  );
};

DocumentoGeradoQuickView.propTypes = {
  documentoSelecionado: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DocumentoGeradoQuickView;
