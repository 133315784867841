import {
  Button,
  FormikInputInteger,
  FormikSelect,
  Row,
  SearchPanel
} from '@elotech/components';
import { Formik } from 'formik';
import { CadastroGeralCidadaoFiltroDTO } from 'itbi-common/type';
import React from 'react';

import { SimNaoEnum, TipoCadastro, TipoSituacaoDebitoEnum } from '../../enums';
import { EnumValue } from '../../types/EnumType';

type Props = {
  filtro: CadastroGeralCidadaoFiltroDTO;
  onSearch(filtro: CadastroGeralCidadaoFiltroDTO): void;
  isFiltroCarne: boolean;
};

const CadastrosFilter: React.FC<Props> = ({
  filtro,
  onSearch,
  isFiltroCarne
}) => {
  const onSubmit = (filtro: CadastroGeralCidadaoFiltroDTO) => {
    onSearch(filtro);
  };

  return (
    <SearchPanel>
      <Formik
        onSubmit={onSubmit}
        enableReinitialize
        initialValues={filtro}
        render={formProps => (
          <>
            <Row>
              <FormikSelect<EnumValue>
                name="tipoCadastro"
                label="Tipo Cadastro"
                size={2}
                options={TipoCadastro}
                getOptionLabel={value => value.descricao}
                getOptionValue={value => value.codigo}
              />
              <FormikInputInteger name="cadastroGeral" label="Cadastro Geral" />
              {!isFiltroCarne && (
                <FormikSelect<EnumValue>
                  name="tipoSituacaoDebito"
                  label="Débitos"
                  size={2}
                  options={TipoSituacaoDebitoEnum}
                  getOptionLabel={value => value.descricao}
                  getOptionValue={value => value.codigo}
                />
              )}
              {isFiltroCarne && (
                <FormikSelect<EnumValue>
                  name="comCarne"
                  label="Carnê"
                  size={2}
                  options={SimNaoEnum}
                  getOptionLabel={value => value.descricao}
                  getOptionValue={value => value.codigo}
                />
              )}
              <div className="col-md-2">
                <div className="form-group">
                  <label className="label" />
                  <Button type="submit" onClick={formProps.submitForm}>
                    Filtrar
                  </Button>
                </div>
              </div>
            </Row>
          </>
        )}
      />
    </SearchPanel>
  );
};

export default CadastrosFilter;
