import { selectUnit } from '@formatjs/intl-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedRelativeTime } from 'react-intl';
import styled from 'styled-components';

import CommonButton from '../buttons/Button';
import Icon from '../icons/Icon';
import { HeaderContext } from './Header';

const Actions = styled.div`
  text-align: center;
`;

const Space = styled.hr`
  margin: 5px -15px;
`;

const Button = ({ icon, iconProps, hint, onClick, className = '' }) => {
  const classes = classnames('read', 'hint', 'clean', 'center-left', className);

  return (
    <div className={classes} onClick={onClick}>
      <Icon icon={icon} {...iconProps} />
      <div className="hint-content">{hint}</div>
    </div>
  );
};

class NotificationDialog extends Component {
  state = {
    selectedNotification: undefined
  };

  selectNotification = notification => {
    this.setState({ selectedNotification: notification });
    if (!notification.read) {
      this.props.onRead(notification.id);
    }
  };

  renderNotificationsList = () => {
    const {
      notifications,
      loadMoreNotifications,
      loadingNotifications,
      showButtonMore
    } = this.props;

    return (
      <React.Fragment>
        {notifications.map(notification => (
          <li
            key={notification.id}
            className={!notification.read ? 'unread' : ''}
            onClick={() => this.selectNotification(notification)}
            title={notification.title}
          >
            <div className="datehour">
              <time dateTime={notification.time.toDate()}>
                <FormattedRelativeTime
                  value={selectUnit(notification.time.toDate()).value}
                  unit={selectUnit(notification.time.toDate()).unit}
                  timeZone={'UTC'}
                />
              </time>
            </div>
            <article className="nowrap">{notification.title}</article>
          </li>
        ))}
        {showButtonMore && (
          <CommonButton
            onClick={loadMoreNotifications}
            color="neutral"
            className="text"
          >
            {loadingNotifications ? (
              <React.Fragment>
                <Icon icon="spinner" className="spinner" pulse primary />
              </React.Fragment>
            ) : (
              <React.Fragment>
                Ver mais
                <FontAwesomeIcon icon="chevron-down" />
              </React.Fragment>
            )}
          </CommonButton>
        )}
      </React.Fragment>
    );
  };

  onViewDetail = () => {
    const { onView } = this.props;
    if (onView) {
      onView(this.state.selectedNotification);
    }
  };

  renderSelectedNotification = () => {
    const { selectedNotification: notification } = this.state;
    const { onShowView } = this.props;
    const { value, unit } = selectUnit(notification.time.toDate());
    return (
      <li key={notification.id} onClick={this.onViewDetail}>
        <div className="datehour">
          <time dateTime={notification.time.toDate()}>
            <FormattedRelativeTime value={value} unit={unit} timeZone={'UTC'} />
          </time>
        </div>
        <article>{notification.title}</article>
        <p>{notification.text}</p>
        <Space />
        {onShowView(notification) && <Actions> Visualizar </Actions>}
      </li>
    );
  };

  renderNotifications = () => {
    return this.state.selectedNotification
      ? this.renderSelectedNotification()
      : this.renderNotificationsList();
  };

  renderButtonAction = () => {
    const { selectedNotification } = this.state;

    if (selectedNotification !== undefined) {
      return (
        <Button
          icon="arrow-left"
          hint="Voltar"
          onClick={() => this.setState({ selectedNotification: undefined })}
          iconProps={{ primary: true }}
          className="module-color"
        />
      );
    }

    return this.props.count === 0 ? (
      <Button
        icon="envelope-open"
        hint="Não há novas mensagens"
        onClick={() => {}}
        iconProps={{ color: '#a5a5a5' }}
        className="neutral"
      />
    ) : (
      <Button
        icon="envelope"
        hint="Marcar todas como lida"
        className="negative"
        iconProps={{ danger: true }}
        onClick={this.props.onReadAll}
      />
    );
  };

  renderFeaturesNotification = active => {
    const { reduced, autoHeight, hasReadOptions } = this.props;

    const customFeaturesNotificationsClasses = classnames(
      'features-notifications',
      {
        active: active,
        reduced: reduced,
        'auto-height': autoHeight,
        'read-options': hasReadOptions
      }
    );

    return (
      <div className={customFeaturesNotificationsClasses}>
        <h2 className="title">
          {hasReadOptions && this.renderButtonAction()}
          Notificações
        </h2>

        <div className="features-body">
          <ul className="notification-list">{this.renderNotifications()}</ul>
        </div>
      </div>
    );
  };

  render() {
    return (
      <HeaderContext.Consumer>
        {({ activeNotification }) => {
          return this.renderFeaturesNotification(activeNotification);
        }}
      </HeaderContext.Consumer>
    );
  }
}

NotificationDialog.propTypes = {
  count: PropTypes.number,
  reduced: PropTypes.bool,
  autoHeight: PropTypes.bool,
  notifications: PropTypes.array,
  hasReadOptions: PropTypes.bool,
  onReadAll: PropTypes.func,
  onRead: PropTypes.func,
  className: PropTypes.string,
  loadMoreNotifications: PropTypes.func,
  showButtonMore: PropTypes.bool,
  onView: PropTypes.func
};

NotificationDialog.defaultProps = {
  className: '',
  count: 0,
  reduced: false,
  autoHeight: false,
  notifications: [],
  hasReadOptions: false,
  onReadAll: () => {},
  onRead: () => {},
  onShowView: () => false
};

export default NotificationDialog;
