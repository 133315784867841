import React from 'react';

type Props = {
  children: React.ReactNode;
};

/**
 * Componente de painel para pesquisa para ser utilizado como cabeçalho de pesquisa na tabela
 **/
const SearchPanel: React.FC<Props> = ({ children }) => {
  return <div className="panel-table-filter">{children}</div>;
};

export default SearchPanel;
