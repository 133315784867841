// prettier-ignore
export const MASK_CPF = [
    /\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'-',/\d/,/\d/
  ]

// prettier-ignore
export const MASK_CNPJ = [
    /\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'/',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/
]

// prettier-ignore
export const MASK_TELEFONE = [
    '(', /[1-9]/, /[1-9]/, ')', ' ', /\d?/,/\d/,/\d/,/\d/,/\d/, '-', /\d/,/\d/,/\d/,/\d/
];

// prettier-ignore
export const MASK_CODCNAE = [
    /\d/,/\d/,/\d/,/\d/,'-',/\d/,'/',/\d/,/\d/
];

export const MASK_CEP = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

export const MASK_PERCENTUAL = [/\d?/, /\d/, /\d/, ',', /\d?/, /\d?/];
