import ReactGA, { EventArgs } from 'react-ga';

const initializeReactGA = (trackingId: string): void => {
  ReactGA.initialize(trackingId, {
    testMode: process.env.NODE_ENV !== 'production'
  });
  ReactGA.pageview(window.location.pathname + window.location.search);
};

const logEvent = (event: EventArgs): void => {
  ReactGA.event(event);
};

export { initializeReactGA, logEvent };
