import classnames from 'classnames';
import React from 'react';

type Props = {
  children: React.ReactNode;
  loading?: boolean;
  onClick?(event: React.MouseEvent): void;
  active?: boolean;
  clickable?: boolean;
  marginTop?: boolean;
  minHeightUnset?: boolean;
};

export const Card: React.FC<Props> = ({
  children,
  loading = false,
  onClick,
  active = false,
  clickable = false,
  marginTop = true,
  minHeightUnset = false
}) => {
  const classNames = classnames('card', {
    pointer: !!onClick && clickable,
    'placeholder-loader': loading,
    active,
    'mt-xs': marginTop,
    'mh-unset': minHeightUnset
  });

  return (
    <div className={classNames} onClick={clickable ? onClick : undefined}>
      {active && (
        <i className="fa fa-check-circle pull-right hint module-color clean active-button" />
      )}
      {loading ? <div className="loading" /> : children}
    </div>
  );
};
