import axios, { AxiosPromise } from 'axios';
import ServiceUtils from '../utils/ServiceUtils';

const defaultSort = { sort: 'numero,data,desc' };

export const autenticarDocumento = (
  codigoValidacao: string
): AxiosPromise<any> =>
  axios.get(`/api/documentos-gerados/autenticidade/${codigoValidacao}`);

export const loadDocumentosByCpfCnpj = (
  cpfCnpj: string,
  searchParams?: string,
  page?: any
) =>
  axios.get(
    `/api/documentos-gerados/cpf-cnpj/${cpfCnpj}?${ServiceUtils.buildSearchParams(
      searchParams
    )}`,
    {
      params: { ...defaultSort, ...page }
    }
  );
