import PropTypes from 'prop-types';

const TableReportSummary = ({ children }) => children;

TableReportSummary.propTypes = {
  size: PropTypes.number
};

TableReportSummary.defaultProps = {
  size: 2
};

export default TableReportSummary;
