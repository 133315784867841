import classnames from 'classnames';
import React, { useContext } from 'react';

import Icon from '../icons/Icon';
import { HeaderContext } from './Header';

const AjustesButton: React.FC = () => {
  const headerContext = useContext(HeaderContext);
  const className = classnames({
    active: headerContext.activeAjustes || headerContext.activeEntidade
  });

  return (
    <li
      className={className}
      title="Ajustes"
      onClick={headerContext.toggleAjustes}
    >
      <Icon icon="tools" className={'icon'} />
      <small className="hidden-xs">Ajustes</small>
    </li>
  );
};

export default AjustesButton;
