import axios, { AxiosPromise } from 'axios';
import { DebitosEmAbertoComTotalizadorDTO } from 'itbi-cidadao/src/types';

import { DebitoDTO } from '../type';

export const getDebitosEmAberto = (
  tipoCadastro: number,
  cadastroGeral: number
): AxiosPromise<DebitosEmAbertoComTotalizadorDTO> =>
  axios.get(`/api/debitos/abertos/${tipoCadastro}/${cadastroGeral}`);

export const getDebitosAgrupadosVencidos = (
  tipoCadastro: number,
  cadastroGeral: number
): AxiosPromise<Array<DebitoDTO>> => {
  return axios.get(
    `/api/debitos/agrupados/vencidos?tipoCadastro=${tipoCadastro}&cadastroGeral=${cadastroGeral}`
  );
};
