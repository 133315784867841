import { AxiosPromise } from 'axios';
import { FormikProps } from 'formik';
import React, { ReactNode } from 'react';

import AutoComplete from '../../inputs/AutoComplete';
import BasicInput, { Props } from '../BasicInput';

type AutoCompleteProps<T> = Props & {
  onSearch(search: string): AxiosPromise<any>;
  getOptionLabel?: (option: T) => string;
  getDetailOptionLabel?: (option: T) => ReactNode;
  getOptionValue?: (option: T) => any;
  filter?: (value: string) => string;
  onItemSelected?: (form: FormikProps<any>, value: any) => void;
  allowChange?: (form: FormikProps<any>, value: any) => boolean;
  autoFocus?: boolean;
  placeholder?: string;
  newValueLabel?: string;
  onCreateValue?: (texto: string) => AxiosPromise<T>;
};

class FormikAutocomplete<T> extends React.Component<AutoCompleteProps<T>> {
  static defaultProps = {
    getOptionLabel: (option: any) => option.descricao,
    getOptionValue: (option: any) => option.id,
    allowChange: () => true
  };

  onSelect = (form: FormikProps<any>, name: string, value: any) => {
    if (!this.props.allowChange?.(form, value)) return;
    form.setFieldValue(name, value || '');
    form.setFieldTouched(name);
    this.props.onItemSelected && this.props.onItemSelected(form, value);
  };

  render() {
    const {
      onSearch,
      getOptionLabel,
      getDetailOptionLabel,
      getOptionValue,
      filter,
      onItemSelected,
      autoFocus,
      placeholder,
      newValueLabel,
      onCreateValue,
      ...rest
    } = this.props;

    return (
      <BasicInput
        {...rest}
        render={({
          field: { onChange, disabled, value, ...fieldsProps },
          form
        }) => (
          <AutoComplete
            {...fieldsProps}
            value={value}
            onSearch={onSearch}
            onSelect={(name: string, value: T) =>
              this.onSelect(form, name, value)
            }
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            getDetailOptionLabel={getDetailOptionLabel}
            isDisabled={disabled}
            filter={filter}
            autoFocus={autoFocus}
            placeholder={placeholder}
            newValueLabel={newValueLabel}
            onCreateValue={onCreateValue}
          />
        )}
      />
    );
  }
}

export default FormikAutocomplete;
