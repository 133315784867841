import {
  Alert,
  KeycloakService,
  NotificationActions,
  StringUtils,
  ValidationUtils,
  logEvent,
  scrollToElementByName
} from '@elotech/components';
import {
  TermoResponsabilidadeService,
  UserService,
  withService
} from 'itbi-common/service';
import { UserOperations } from 'itbi-common/state/user';
import { FunctionUtils } from 'itbi-common/utils';
import { ObjectUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import MyAccountForm from './MyAccountForm';

const defaultUser = {
  cpfCnpj: '',
  nome: '',
  nomeFantasia: '',
  email: '',
  telefone: '',
  tipoPessoa: 'FISICA',
  procurador: false,
  tipoProcurador: '',
  termoResponsabilidade: false,
  aceitouUltimoTermo: false
};

class MyAccountPage extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object,
    userExists: PropTypes.object,
    userProfile: PropTypes.object,
    desabilitaEdicao: PropTypes.bool,
    getUserData: PropTypes.func.isRequired,
    loadUserProfile: PropTypes.func.isRequired,
    setUserExists: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    userService: PropTypes.shape({
      patchUser: PropTypes.func.isRequired,
      addUser: PropTypes.func.isRequired,
      resetPassword: PropTypes.func.isRequired
    }).isRequired,
    termoResponsabilidadeService: PropTypes.shape({
      getTermo: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    user: defaultUser,
    initialUser: defaultUser,
    userExists: undefined,
    userNivel: undefined,
    desabilitaEdicao: false,
    error: {
      cpfCnpj: false,
      nome: false,
      nomeFantasia: false,
      email: '',
      termoResponsabilidade: false,
      tipoProcurador: false
    },
    loadingSave: false,
    authorizedUserNotFound: false,
    loadingAuthorizedSearch: false,
    currentUserSearch: '',
    currentAuthorized: undefined,
    authorizedUsers: [],
    termoResponsabilidade: undefined,
    mostrouMensagemTermo: false
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.userExists !== undefined &&
      state.userExists === undefined &&
      !ObjectUtils.isEmptyObject(props.userExists) &&
      !ObjectUtils.isEmptyObject(props.userProfile)
    ) {
      const { userExists, ...userProps } = props.userExists;

      if (userExists) {
        return {
          userExists,
          desabilitaEdicao: props.userProfile
            ? ValidationUtils.validateCpfCnpj(props.userProfile.username)
            : false
        };
      }

      const user = ObjectUtils.createUserObjectFromProfileAndUserExistsData(
        props.userProfile,
        userProps
      );
      user.nome = user.nome === '___ ___' ? '' : user.nome;

      user.cpfCnpj = user.userName
        ? user.userName.replace(/\D/g, '')
        : user.cpfCnpj;

      user.tipoPessoa =
        user.cpfCnpj && user.cpfCnpj.length === 14 ? 'JURIDICA' : 'FISICA';

      return {
        userExists,
        initialUser: {
          ...state.user,
          ...user
        },
        user: {
          ...state.user,
          ...user
        },
        desabilitaEdicao: ValidationUtils.validateCpfCnpj(user.cpfCnpj)
      };
    }

    if (
      props.userData !== undefined &&
      state.userExists &&
      state.user === state.initialUser &&
      !ObjectUtils.isEmptyObject(props.userData)
    ) {
      const { usuariosAutorizados, ...userProps } = props.userData;

      return {
        user: {
          ...userProps
        },
        desabilitaEdicao: ValidationUtils.validateCpfCnpj(userProps.cpfCnpj),
        authorizedUsers: usuariosAutorizados || []
      };
    }

    return null;
  }

  componentDidMount() {
    const { userData: user } = this.props;
    if (user !== undefined && !ObjectUtils.isEmptyObject(user)) {
      this.setState({ user, authorizedUsers: user.usuariosAutorizados || [] });
      if (user.id) this.getUsuarioNivel(user.id);
    }

    this.getTermoResponsabilidade();
  }

  componentDidUpdate() {
    if (
      !this.state.mostrouMensagemTermo &&
      this.props.userExists?.userExists &&
      this.props.userData?.aceitouUltimoTermo === false
    ) {
      this.setState({ mostrouMensagemTermo: true });
      Alert.warning({
        title: 'O termo de responsabilidade foi atualizado',
        text: 'Você deverá aceitar o novo termo para utilizar o sistema'
      });
    }

    if (this.state.mostrouMensagemTermo && this.state.termoResponsabilidade) {
      scrollToElementByName('termoResponsabilidade');
    }
  }

  getUsuarioNivel = id => {
    UserService.findNivel(id).then(response => {
      this.setState({ userNivel: response.data });
    });
  };

  getTermoResponsabilidade = () => {
    this.props.termoResponsabilidadeService
      .getTermo()
      .then(response => {
        this.setState({ termoResponsabilidade: response.data });
      })
      .catch(() => {
        this.props.showNotification({
          level: 'warning',
          message:
            'Não foi possível encontrar o termo de responsabilidade. Entre em contato com' +
            ' os responsáveis.'
        });
      });
  };

  onChangeField = event => {
    const { name, value, checked, type } = event.target;
    this.setState(state => {
      const newValue = type === 'checkbox' ? checked : value;
      const { user, error } = state;
      const newError = error[name] ? !newValue : error[name];
      return {
        user: { ...user, [name]: newValue === '' ? null : newValue },
        error: { ...error, [name]: newError }
      };
    });
  };

  onChangeProcurador = event => {
    const { checked } = event.target;
    this.setState(state => {
      const { user } = state;
      const tipoProcurador = checked ? user.tipoProcurador : '';
      return {
        user: {
          ...user,
          procurador: checked,
          tipoProcurador
        }
      };
    });
  };

  onChangeFieldMask = event => {
    const { name, value = '' } = event.target;
    this.setState(state => {
      const newValue = value.replace(/\D/g, '');
      const { user, error } = state;
      const newError = error[name] ? !newValue : error[name];
      const newErrorCpfCnpjInvalido =
        name === 'cpfCnpj' ? newError : error.cpfCnpjInvalido;
      return {
        user: { ...user, [name]: newValue },
        error: {
          ...error,
          [name]: newError,
          cpfCnpjInvalido: newErrorCpfCnpjInvalido
        }
      };
    });
  };

  onSave = () => {
    const { user, userExists } = this.state;
    const { userService, keycloakService } = this.props;

    this.setState(
      {
        error: {},
        user: {
          ...user,
          cpfCnpj: StringUtils.somenteNumeros(user.cpfCnpj),
          telefone: StringUtils.somenteNumeros(user.telefone),
          cep: StringUtils.somenteNumeros(user.cep)
        }
      },
      () => {
        const newError = {};
        if (!user.termoResponsabilidade && !user.aceitouUltimoTermo) {
          newError.termoResponsabilidade = true;
        }

        if (!user.cpfCnpj) {
          newError.cpfCnpj = true;
        } else {
          if (
            (user.tipoPessoa === 'FISICA' && user.cpfCnpj.length !== 11) ||
            (user.tipoPessoa === 'JURIDICA' && user.cpfCnpj.length !== 14) ||
            !ValidationUtils.validateCpfCnpj(user.cpfCnpj)
          ) {
            newError.cpfCnpjInvalido = true;
          }
        }

        if (!user.nome) {
          newError.nome = true;
        }

        if (user.tipoPessoa === 'JURIDICA' && !user.nomeFantasia) {
          newError.nomeFantasia = true;
        }

        if (!user.email) {
          newError.email = true;
        }

        if (
          user.tipoPessoa === 'JURIDICA' &&
          user.procurador &&
          !user.tipoProcurador
        ) {
          newError.tipoProcurador = true;
        }

        if (!Boolean(user.telefone)) {
          newError.telefoneObrigatorio = true;
        } else if (!ValidationUtils.validateTelefone(user.telefone)) {
          newError.telefoneInvalido = true;
        }

        newError.cep = !user.cep || user.cep.length < 8 || !user.ibge;
        newError.cidade = !user.cidade;
        newError.bairro = !user.bairro;
        newError.logradouro = !user.logradouro;
        newError.uf = !user.uf;
        newError.numero = !user.numero;
        newError.numeroLength = user.numero?.length > 10;
        newError.complemento = false;

        if (Object.values(newError).includes(true)) {
          return this.setState({ error: { ...this.state.error, ...newError } });
        }

        const updateFn = userExists
          ? userService.patchUser
          : userService.addUser;
        this.setState({ loadingSave: true });

        updateFn(user)
          .then(() => {
            logEvent({
              category: 'click',
              action: userExists
                ? 'Salvar alterações da minha conta'
                : 'Salvar novo usuário',
              label: 'Minha Conta'
            });
            const {
              showNotification,
              getUserData,
              setUserExists,
              loadUserProfile
            } = this.props;
            showNotification({
              level: 'success',
              title: 'Sucesso',
              message: 'Seus dados foram atualizados com sucesso.'
            });
            setUserExists(true);
            getUserData()
              .then(() => {
                this.props.history.push('/');
              })
              .catch(() => {
                this.props.showNotification({
                  level: 'warning',
                  message: 'Não foi possível carregar os seus dados'
                });
              });

            keycloakService
              .getInstance()
              .loadUserProfile()
              .success(userProfile => {
                const { name, sub } = keycloakService.getInstance().tokenParsed;
                const user = {
                  name,
                  userid: sub,
                  ...userProfile
                };
                loadUserProfile(user);
              });
          })
          .catch(error => {
            const errorAction = userExists
              ? 'Erro ao salvar alterações da minha conta'
              : 'Erro ao salvar novo usuário';
            logEvent({
              category: 'click',
              action: errorAction,
              label: 'Minha Conta'
            });
            this.setState({ loadingSave: false });
            Alert.error(
              {
                title: errorAction
              },
              error
            );
          });
      }
    );
  };

  onSearchAuthorized = () => {
    this.setState(
      {
        loadingAuthorizedSearch: true,
        currentAuthorized: undefined
      },
      () => {
        const { currentUserSearch } = this.state;
        UserService.searchUserByCpf(currentUserSearch)
          .then(response => {
            if (response.data) {
              this.setState({ currentAuthorized: response.data });
            }
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              this.setState({ authorizedUserNotFound: true });
            } else {
              this.props.showNotification({
                level: 'warning',
                message: 'Não foi possível pesquisar o usuário.'
              });
            }
          })
          .finally(() => this.setState({ loadingAuthorizedSearch: false }));
      }
    );
  };

  onChangeAuthorizedSearch = event => {
    const { value } = event.target;
    this.setState(
      { currentUserSearch: value, authorizedUserNotFound: false },
      () => {
        const { currentUserSearch, authorizedUsers } = this.state;
        if (currentUserSearch.length === 11) {
          if (
            authorizedUsers.some(user => user.cpfCnpj === currentUserSearch)
          ) {
            return this.props.showNotification({
              level: 'warning',
              message:
                'Este usuário já está na sua lista de pessoas autorizadas.'
            });
          }
          this.onSearchAuthorized();
        }
      }
    );
  };

  onClearCurrentAuthorized = () => {
    this.setState({ currentUserSearch: '', currentAuthorized: undefined });
  };

  onAddAuthorized = () => {
    const { currentAuthorized, authorizedUsers } = this.state;
    if (!currentAuthorized) {
      return;
    }

    this.props.userService
      .adicionarUsuarioAutorizado(currentAuthorized.cpfCnpj)
      .then(() => {
        this.props.showNotification({
          level: 'success',
          message: 'Usuário autorizado com sucesso'
        });
        this.props.getUserData();
        this.setState({
          authorizedUsers: [...authorizedUsers, currentAuthorized],
          currentAuthorized: undefined,
          currentUserSearch: ''
        });
      })
      .catch(error => {
        this.props.showNotification({
          level: 'warning',
          message: error.response.data.message
        });
      });
  };

  viewHistoryAuthorization = () => {
    this.props.history.push(`/history`);
  };

  onResetPassword = () => {
    return this.props.userService
      .resetPassword()
      .then(() => {
        this.props.showNotification({
          level: 'success',
          message:
            'Foi enviado um email para o seu endereço cadastrado com um link que irá permitir a' +
            ' alteração da sua senha.'
        });
      })
      .catch(() => {
        this.props.showNotification({
          level: 'warning',
          message:
            'Não foi possível redefinir a senha, verifique o email cadastrado.'
        });
      });
  };

  removeAuthorizedUser = index => () => {
    const { authorizedUsers } = this.state;

    this.setState({ loadingSave: true });
    this.props.userService
      .removerUsuarioAutorizado(authorizedUsers[index].cpfCnpj)
      .then(() => {
        this.props.showNotification({
          level: 'success',
          message: 'Usuário removido da lista de usuário autorizados'
        });
        this.props.getUserData();
        this.setState({
          loadingSave: false,
          authorizedUsers: authorizedUsers.filter((obj, idx) => idx !== index),
          currentAuthorized: undefined
        });
      })
      .catch(error => {
        this.setState({ loadingSave: false });
        this.props.showNotification({
          level: 'warning',
          message: error.response.data.message
        });
      });
  };

  render() {
    const {
      user,
      userExists,
      userNivel,
      desabilitaEdicao,
      error,
      currentUserSearch,
      currentAuthorized,
      loadingAuthorizedSearch,
      authorizedUsers,
      authorizedUserNotFound,
      termoResponsabilidade,
      loadingSave
    } = this.state;
    if (!user || ObjectUtils.isEmptyObject(user)) {
      return null;
    }

    return (
      <MyAccountForm
        authorizedUserSearchText={currentUserSearch}
        currentAuthorizedUser={currentAuthorized}
        authorizedUsers={authorizedUsers}
        error={error}
        user={user}
        userExists={userExists}
        userNivel={userNivel}
        desabilitaEdicao={desabilitaEdicao}
        authorizedUserSearchLoading={loadingAuthorizedSearch}
        userNotFound={authorizedUserNotFound}
        onResetPassword={this.onResetPassword}
        onChangeField={this.onChangeField}
        onChangeFieldMask={this.onChangeFieldMask}
        onSave={this.onSave}
        onAddAuthorizedUser={this.onAddAuthorized}
        onChangeAuthorizedUserSearchText={this.onChangeAuthorizedSearch}
        onClearAuthorizedUser={this.onClearCurrentAuthorized}
        onRemoveAuthorizedUser={this.removeAuthorizedUser}
        termoResponsabilidade={termoResponsabilidade}
        loadingSave={loadingSave}
        viewHistoryAuthorization={this.viewHistoryAuthorization}
        onChangeProcurador={this.onChangeProcurador}
      />
    );
  }
}

const mapStateToProps = state => ({
  userExists: state.user.userExists,
  userData: state.user.currentUser,
  userProfile: state.user.profile
});

const enhancers = FunctionUtils.compose(
  withService({
    userService: UserService,
    termoResponsabilidadeService: TermoResponsabilidadeService,
    keycloakService: KeycloakService
  }),
  connect(mapStateToProps, {
    showNotification: NotificationActions.showNotification,
    getUserData: () => UserOperations.getUserData(true),
    setUserExists: UserOperations.setUserExists,
    loadUserProfile: UserOperations.loadUserProfile
  })
);

const ConnectedComponent = enhancers(MyAccountPage);

export { ConnectedComponent as default, MyAccountPage };
