import { History, Location } from 'history';
import React, { ChangeEvent } from 'react';
import { match, withRouter } from 'react-router';

import { Col, Row } from '../../grid';
import { SearchPanel } from '../..';
import { buildFilterRsql } from './BuildFilterRsql';
import { buildFilterToUrl, parseFilterUrl } from './BuildFilterUrl';
import FilterItem from './FilterItem';
import OPERATORS from './Operators';
import SearchInput from './SearchInput';
import {
  Field,
  FilterItem as FilterItemType,
  Filters,
  Operator
} from './Types';

type Props = {
  fields: Field[];
  search(search: string): void;
  fieldSelectSize?: number;
  operatorSelectSize?: number;
  valueSelectSize?: number;
  renderLeftComponent?: () => React.ReactNode;
  superiorComponent?: React.ReactNode;
};

type InnerSearchFilterProps = Props & {
  location: Location;
  history: History;
  match: match;
};

type State = {
  selectedField: Field;
  selectedOperator: Operator;
  filters: Filters;
};

export class InnerSearchFilter extends React.Component<
  InnerSearchFilterProps,
  State
> {
  searchValueRef = React.createRef<any>();

  constructor(props: InnerSearchFilterProps) {
    super(props);
    const { fields } = this.props;

    if (fields.length > 0) {
      const firstField = fields[0];
      const operators = OPERATORS[firstField.type];

      this.state = {
        selectedField: firstField,
        selectedOperator: operators[0],
        filters: {}
      };
    }
  }

  componentDidMount() {
    this.buildFiltersUrl();
  }

  componentDidUpdate(oldProps: InnerSearchFilterProps) {
    if (oldProps.fields !== this.props.fields) {
      const firstField = this.props.fields[0];
      const operators = OPERATORS[firstField.type];

      const filterItemThatExistsOnNewFields = Object.entries(
        this.state.filters
      ).filter(([filter]) =>
        this.props.fields.some(field => field.name === filter.split('_')[0])
      );

      this.setState({
        selectedField: firstField,
        selectedOperator: operators[0],
        filters: Object.fromEntries(filterItemThatExistsOnNewFields)
      });
    }

    const oldFilter = new URLSearchParams(oldProps.location.search);
    const newFilter = new URLSearchParams(this.props.location.search);
    if (oldFilter.get('filters') !== newFilter.get('filters')) {
      this.buildFiltersUrl();
    }
  }

  buildFiltersUrl = (): void => {
    const { fields, location, search } = this.props;

    const filters = parseFilterUrl(fields, location);

    this.setState({ filters });
    search(buildFilterRsql(filters));
  };

  getValue = () => {
    // para conseguir testar, foi criado essa verificação a mais
    // TODO: remover esse if, para que consiga testar com código de produção
    // https://reactjs.org/blog/2016/11/16/react-v15.4.0.html#mocking-refs-for-snapshot-testing
    // ou colocar o valor do component SearchInput no SearchFilter
    if (this.searchValueRef.current.test) {
      return this.searchValueRef.current.test.getValue();
    }
    return this.searchValueRef.current.getValue();
  };

  onAddFilter = () => {
    const { history, location } = this.props;
    const { selectedField, selectedOperator, filters } = this.state;

    const value = this.getValue();
    if (!!value && (typeof value !== 'string' || value.length > 0)) {
      const newFilter = {
        field: selectedField,
        operator: selectedOperator,
        value
      };

      const newFilters = {
        ...filters,
        [`${selectedField.name}_${selectedOperator.name}`]: newFilter
      };

      history &&
        history.push({
          pathname: location.pathname,
          search: buildFilterToUrl(newFilters, location.search)
        });

      this.setState({
        filters: newFilters
      });

      // para conseguir testar, foi criado essa verificação a mais
      // TODO: remover esse if, para que consiga testar com código de produção
      // https://reactjs.org/blog/2016/11/16/react-v15.4.0.html#mocking-refs-for-snapshot-testing
      // ou colocar o valor do component SearchInput no SearchFilter
      if (this.searchValueRef.current.test) {
        this.searchValueRef.current.test.cleanValue();
      } else {
        this.searchValueRef.current.cleanValue();
      }
    }
  };

  onRemoveFilter = (filter: FilterItemType) => {
    const { history, location } = this.props;
    const { filters } = this.state;

    const key: any = `${filter.field.name}_${filter.operator.name}`;

    const newFitros = {
      ...filters
    };

    delete newFitros[key];

    history &&
      history.push({
        pathname: location.pathname,
        search: buildFilterToUrl(newFitros, location.search)
      });

    this.setState({
      filters: newFitros
    });
  };

  handleFieldsChange = (event: ChangeEvent<any>) => {
    const selectedField = this.props.fields.find(
      f => f.name === event.target.value
    );
    const fieldOperators = OPERATORS[selectedField!.type];

    this.setState(
      {
        selectedField: selectedField!,
        selectedOperator: fieldOperators[0]
      },
      () => {
        this.searchValueRef.current && this.searchValueRef.current.focus();
      }
    );
  };

  handleOperatorsChange = (event: ChangeEvent<any>) => {
    const fieldOperators = OPERATORS[this.state.selectedField!.type];
    const selectedOperator = fieldOperators.find(
      op => op.name === event.target.value
    );

    this.setState({ selectedOperator: selectedOperator! }, () => {
      this.searchValueRef.current && this.searchValueRef.current.focus();
    });
  };

  render() {
    const {
      fields,
      fieldSelectSize = 3,
      operatorSelectSize = 2,
      valueSelectSize = 7,
      renderLeftComponent,
      superiorComponent = null
    } = this.props;
    const { filters, selectedField, selectedOperator } = this.state;

    return (
      <SearchPanel>
        {superiorComponent}
        <Row>
          {renderLeftComponent && renderLeftComponent()}
          <Col sm={fieldSelectSize} className="form-group">
            <select
              data-test-id="selectField"
              name="filter-fields"
              style={{ height: 28 }}
              onChange={this.handleFieldsChange}
            >
              {fields.map(field => (
                <option key={field.label} value={field.name}>
                  {field.label}
                </option>
              ))}
            </select>
          </Col>
          <Col sm={operatorSelectSize} className="form-group">
            <select
              data-test-id="selectOperator"
              name="filter-operators"
              placeholder="Filtros"
              style={{ height: 28 }}
              onChange={this.handleOperatorsChange}
              value={selectedOperator && selectedOperator.name}
            >
              {selectedField &&
                selectedField.type &&
                OPERATORS[selectedField!.type].map(operator => (
                  <option key={operator.name} value={operator.name}>
                    {operator.name}
                  </option>
                ))}
            </select>
          </Col>
          <Col sm={valueSelectSize} className="form-group">
            <SearchInput
              data-test-id="SearchInput"
              ref={this.searchValueRef}
              onEnter={this.onAddFilter}
              field={selectedField!}
              operator={selectedOperator!}
            />
            <button
              data-test-id="buttonAdicionarFiltro"
              className="btn filter"
              type="button"
              onClick={this.onAddFilter}
            >
              Filtrar
            </button>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="panel-filter-tags">
              {Object.keys(filters).map((key: any) => (
                <FilterItem
                  key={key}
                  filter={filters[key]}
                  removeFilter={this.onRemoveFilter}
                />
              ))}
            </div>
          </Col>
        </Row>
      </SearchPanel>
    );
  }
}

const SerchFilterRouter = withRouter<
  InnerSearchFilterProps,
  typeof InnerSearchFilter
>(InnerSearchFilter);

const SearchFilter: React.FC<Props> = props => <SerchFilterRouter {...props} />;

export default SearchFilter;
