import {
  Alert,
  BasicInput,
  Col,
  Container,
  DragDropFiles,
  FAB,
  FormikAutocomplete,
  FormikTextArea,
  Loading,
  Row,
  SectionTitle
} from '@elotech/components';
import { Formik } from 'formik';
import { ProcessoService } from 'itbi-common/service';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  AssuntoProtocolo,
  DocumentoProtocolo,
  ProcessoProtocolo,
  TipoProcessoProtocolo
} from '../../types';
import DocumentosExigidos from './DocumentosExigidosList';
import { initialValues, validationSchema } from './ProcessoUtils';

const ProcessoForm = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formikRef = useRef<Formik<ProcessoProtocolo>>(null);
  const usuario = useSelector((state: any) => state.user?.currentUser);

  const onSubmit = (values: ProcessoProtocolo) => {
    setLoading(true);

    const processo = {
      ...values,
      pessoa: {
        cnpjCpf: usuario.cnpjCpf,
        tipoPessoa: usuario.tipoPessoa === 'FISICA' ? 'F' : 'J',
        nome: usuario.nomeFantasia || usuario.nome,
        telefone: usuario.telefone,
        email: usuario.email,
        ativo: true
      }
    };

    return ProcessoService.save(processo)
      .then(({ data }) => {
        return values.arquivos.map(file => {
          const formData = new FormData();

          formData.append('files', file);

          if (file.documento) {
            return ProcessoService.salvarArquivoComDocumento(
              data.id!,
              file.documento.id!,
              formData
            );
          }

          return ProcessoService.salvarArquivo(`${data.id}`, formData);
        });
      })
      .then(promises =>
        Promise.all(promises).catch(error => {
          Alert.error(
            { title: 'Ocorreu um erro ao salvar os arquivos do processo' },
            error
          );
        })
      )
      .then(() => history.push('/processos'))
      .catch(error => {
        Alert.error({ title: 'Ocorreu um erro ao salvar o processo' }, error);
      })
      .finally(() => setLoading(false));
  };

  const addFile = (file: File[]) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('arquivos', [
        ...formikRef.current.state.values.arquivos,
        ...file
      ]);
    }
  };

  const removeFile = (index: number) => {
    if (formikRef.current) {
      const { arquivos } = formikRef.current.state.values;

      const newFiles = [
        ...arquivos.slice(0, index),
        ...arquivos.slice(index + 1)
      ];

      formikRef.current.setFieldValue('arquivos', newFiles);
    }
  };

  const handleChangeDocumentoExigido = (
    file: File,
    documento: DocumentoProtocolo
  ) => {
    if (file && formikRef.current) {
      let newFiles = [...formikRef.current.state.values.arquivos];
      const newFile = Object.assign(file, { documento });
      const index = newFiles.findIndex(a => a.documento?.id === documento?.id);

      newFiles = [...newFiles, newFile];

      if (index >= 0) {
        newFiles = [...newFiles.slice(0, index), ...newFiles.slice(index + 1)];
      }

      formikRef.current.setFieldValue('arquivos', newFiles);
    }
  };

  return (
    <Container title="Processos" icon="list">
      <Loading loading={loading} />
      <Formik
        ref={formikRef}
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {formProps => {
          const { arquivos, assunto } = formProps.values;

          return (
            <>
              <SectionTitle marginTop="0px">Dados do Processo</SectionTitle>
              <Row>
                <FormikAutocomplete
                  size={4}
                  name="tipo"
                  label="Tipo Processo"
                  getOptionLabel={(tipo: TipoProcessoProtocolo) =>
                    `${tipo.id} - ${tipo.descricao}`
                  }
                  onSearch={ProcessoService.getTipos}
                />
                <FormikAutocomplete
                  size={4}
                  name="assunto"
                  label="Assunto"
                  getOptionLabel={(assunto: AssuntoProtocolo) =>
                    `${assunto.id} - ${assunto.descricao}`
                  }
                  onSearch={ProcessoService.getAssuntos}
                />
                <BasicInput
                  size={4}
                  name="complementoAssunto"
                  label="Complemento Assunto"
                />
              </Row>
              <Row>
                <FormikTextArea
                  size={6}
                  name="digitacao"
                  maxLength={4000}
                  label="Requerimento"
                />
                <FormikTextArea size={6} name="observacao" label="Observação" />
              </Row>
              <SectionTitle>Dados do Imóvel</SectionTitle>
              <Row>
                <BasicInput size={3} name="zona" label="Zona" maxLength={30} />
                <BasicInput
                  size={3}
                  name="quadra"
                  label="Quadra"
                  maxLength={30}
                />
                <BasicInput size={3} name="lote" label="Lote" maxLength={30} />
                <BasicInput
                  size={3}
                  name="cadastro"
                  label="Cadastro"
                  maxLength={30}
                />
              </Row>
              <Row>
                {!!assunto?.assuntoDocumento?.length && (
                  <Col md={5}>
                    <SectionTitle>Documentos Exigidos</SectionTitle>
                    <DocumentosExigidos
                      arquivos={arquivos}
                      documentos={assunto?.assuntoDocumento!}
                      onChangeArquivo={handleChangeDocumentoExigido}
                      hasError={
                        formProps.submitCount > 0 &&
                        !!formProps.errors?.arquivos?.length
                      }
                    />
                  </Col>
                )}
                <Col md={!!assunto?.assuntoDocumento?.length ? 7 : 12}>
                  <SectionTitle>Arquivos</SectionTitle>
                  <DragDropFiles
                    files={arquivos}
                    onAddFile={addFile}
                    onRemoveFile={removeFile}
                  />
                </Col>
              </Row>
              <div className="btn-save">
                <FAB
                  icon="check"
                  title="Salvar"
                  onClick={formProps.submitForm}
                />
              </div>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ProcessoForm;
