import { AxiosError } from 'axios';
import { KeycloakProfile } from 'keycloak-js';
import React, { useEffect, useState } from 'react';

import ErrorPage from '../pages/ErrorPage';
import KeycloakService from '../service/KeycloakService';
import { getErrorMessage } from '../utils/ErrorMessageUtils';

interface KeycloaTokenParsed {
  name: string;
  sub: string;
}

export type UserExistsResponse = {
  payload: {
    userExists: boolean;
  };
};

type Props = {
  setKeycloakLoaded(): any;
  checkUserExists?(): Promise<UserExistsResponse>;
  getUserData?(): any;
  getCidadeLogada?(): any;
  loadUserProfile(user: KeycloakProfile): any;
  onUserNotAuthenticated?(): any;
  isKeycloakLoaded: boolean;
  keycloakService: typeof KeycloakService;
  idpHint?: string;
  children: React.ReactNode;
  fastInitialization?: boolean;
};

type RoleDTO = {
  name: string;
};

export type RoleUsuarioDTO = {
  clientRoles: RoleDTO[];
  realmRoles: RoleDTO[];
};

export type UserInfo = {
  groups?: string[];
  email?: string;
  family_name?: string;
  given_name?: string;
  nome: string;
  picture?: string;
  username: string;
  id: string;
  sub: string;
  name: string;
};

export type UserProfile = {
  name: string;
  userid: string;
  attributes?: KeyCloakAttributes;
} & KeycloakProfile;

export type KeyCloakAttributes = {
  [key: string]: [string];
};

export type KeycloakContextType = {
  userProfile: UserProfile;
  userInfo: UserInfo;
};

const initialContext: KeycloakContextType = {
  userProfile: {
    name: '',
    userid: '',
    attributes: {
      usuario_aise: ['']
    }
  },
  userInfo: {
    nome: '',
    username: '',
    id: '',
    name: '',
    sub: ''
  }
};

export const KeycloakContext = React.createContext(initialContext);

const defaultCheckUserExists = () =>
  Promise.resolve({ payload: { userExists: true } });

const KeycloakProvider: React.FC<Props> = props => {
  const {
    setKeycloakLoaded,
    checkUserExists = defaultCheckUserExists,
    getUserData,
    getCidadeLogada,
    loadUserProfile,
    keycloakService,
    idpHint,
    onUserNotAuthenticated,
    fastInitialization = true,
    isKeycloakLoaded,
    children
  } = props;

  const [checkUserExistsError, setCheckUserExistsError] = useState<
    AxiosError
  >();
  const [errorParams, setErrorParams] = useState<any>(undefined);
  const [userProfile, setUserProfile] = useState(initialContext.userProfile);
  const [userInfo, setUserInfo] = useState(initialContext.userInfo);

  const initKeycloak = () => {
    return new Promise((resolve, reject) => {
      keycloakService
        .getInstance()
        .init({
          onLoad: 'check-sso',
          useNonce: false,
          checkLoginIframe: false
        })
        .success((authenticated: boolean) => {
          if (authenticated) {
            checkUserExists?.()
              .then(action => {
                if (action && action.payload.userExists) {
                  getUserData?.();
                  getCidadeLogada?.();
                }
              })
              .catch((error: AxiosError) => {
                console.error('Erro no checkUserExists', error);
                setCheckUserExistsError(error);
              });

            keycloakService
              .getInstance()
              .loadUserInfo()
              .success((userInfo: any) => {
                setUserInfo({
                  ...userInfo,
                  id: userInfo.sub,
                  username: userInfo.preferred_username,
                  nome: userInfo.name
                });
              });

            keycloakService
              .getInstance()
              .loadUserProfile()
              .success((keycloakProfile: KeycloakProfile) => {
                const { name, sub } = keycloakService.getInstance()
                  .tokenParsed! as KeycloaTokenParsed;
                const userProfile: UserProfile = {
                  name,
                  userid: sub,
                  ...keycloakProfile
                };
                setUserProfile(userProfile);
                loadUserProfile(userProfile);
                setKeycloakLoaded();
              });

            resolve(true);
          } else {
            onUserNotAuthenticated?.();

            keycloakService
              .getInstance()
              .login({ idpHint })
              .success(() => resolve(true))
              .error(error => {
                console.error('Keycloak login error', error);
                reject(error);
              });
          }
        })
        .error(error => {
          console.error('Keycloak error', error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    if (fastInitialization) {
      initKeycloak()
        .then(() => console.log('Keycloak inicializado'))
        .catch(error => {
          console.error(
            'Erro ao inicializar Keycloak. Tentar novamente',
            error
          );
          setTimeout(initKeycloak, 0);
        });
    }
  }, []);

  useEffect(() => {
    if (checkUserExistsError) {
      getErrorMessage(checkUserExistsError).then(message =>
        setErrorParams({
          message,
          status: checkUserExistsError.response?.status ?? 500
        })
      );
    }
  }, [checkUserExistsError]);

  if (!isKeycloakLoaded) {
    return null;
  }

  if (errorParams) {
    return (
      <ErrorPage status={errorParams.status} message={errorParams.message} />
    );
  }

  return (
    <KeycloakContext.Provider value={{ userProfile, userInfo }}>
      {children}
    </KeycloakContext.Provider>
  );
};

export default KeycloakProvider;
