import React from 'react';

import { InputDateTime } from '../inputs/InputDateTime';
import BasicInput, { Props as BasicInputProps } from './BasicInput';

export const FormikInputDateTime: React.FC<BasicInputProps> = props => (
  <BasicInput
    {...props}
    render={({ field }) => <InputDateTime {...field} id={field.name} />}
  />
);
