import {
  ActionButton,
  ActionsGroup,
  ArrayUtils,
  Button,
  SectionTitle,
  useShowForm
} from '@elotech/components';
import React, { useEffect } from 'react';

import { Laudo, TipoImovel } from '../../type';
import LaudoPropriedadeList from './LaudoPropriedadeList';
import PropriedadeForm from './PropriedadeForm';

type Props = {
  propriedades: Laudo[];
  isOrigemServidor?: boolean;
  permiteItbiRural?: boolean;
  openFirstPropriedadeForm?: boolean;
  onChange: (values: Laudo[]) => void;
};

const PropriedadeSection: React.FC<Props> = ({
  propriedades,
  isOrigemServidor = false,
  permiteItbiRural = false,
  openFirstPropriedadeForm = false,
  onChange
}) => {
  const { isOpen, openForm, closeForm, editForm, editingIndex } = useShowForm();

  const onRemove = (index: number) =>
    onChange([
      ...propriedades.slice(0, index),
      ...propriedades.slice(index + 1)
    ]);

  const onConfirm = (value: Laudo) => {
    const newPropriedades = ArrayUtils.addOrReplace(
      propriedades,
      value,
      (_, index) => editingIndex === index
    );
    onChange(newPropriedades);
    openForm();
  };

  const checkDuplicados = (
    numeroCadastro: string,
    tipoImovel: TipoImovel
  ): boolean => {
    return propriedades.some(
      propriedade =>
        String(propriedade.cadastro) === String(numeroCadastro) &&
        propriedade.tipoImovel === tipoImovel &&
        (editingIndex === undefined ||
          (String(propriedades[editingIndex].cadastro) !==
            String(numeroCadastro) &&
            String(propriedades[editingIndex].tipoImovel) !== tipoImovel))
    );
  };

  useEffect(() => {
    if (openFirstPropriedadeForm) {
      editForm(0);
    }
  }, [openFirstPropriedadeForm]);

  return (
    <React.Fragment>
      <SectionTitle hasButton>
        Propriedades
        {!isOpen && (
          <Button
            data-test-id="show-form-pessoa-button"
            onClick={openForm}
            iconPosition="left"
          >
            <i className="fa fa-plus" />
            Novo
          </Button>
        )}
      </SectionTitle>

      {isOpen && (
        <PropriedadeForm
          isOrigemServidor={isOrigemServidor}
          permiteItbiRural={permiteItbiRural}
          editingValue={
            editingIndex === undefined ? undefined : propriedades[editingIndex]
          }
          onCheckDuplicados={checkDuplicados}
          onConfirm={onConfirm}
          onCancel={closeForm}
        />
      )}

      <LaudoPropriedadeList
        propriedades={propriedades}
        renderButton={(item, index) => (
          <ActionsGroup>
            <ActionButton
              key="editar"
              icon="pencil-alt"
              label="Editar"
              onClick={() => editForm(index)}
            />
            <ActionButton
              key="excluir"
              icon="trash-alt"
              label="Excluir"
              onClick={() => onRemove(index)}
            />
          </ActionsGroup>
        )}
      />
    </React.Fragment>
  );
};

export default PropriedadeSection;
