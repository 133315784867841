import { getDate, getMonth, getYear, startOfMonth, subMonths } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import Icon from '../icons/Icon';
import { abreviacaoSemana, mesesAno } from '../utils/DateUtils';
import * as DatePickerUtils from './DatePickerUtils';
import { ArrayUtils } from '..';

export type DatePickerOptions = {
  label: string;
  id: 'white' | 'red' | 'blue' | 'black';
  color: 'neutral' | 'negative' | 'selected' | 'outline';
  canSelect: boolean;
};

export type DatePickerDateConfig = {
  data: Date;
  marked?: boolean;
  option?: DatePickerOptions;
};

type State = {
  loading: boolean;
  dataAtual: Date;
  dataInicialDrag: Date;
  dataFinalDrag: Date;
  nomeMes: string[];
  listaDias: InternalDatePickerDateConfig[];
};

type DatePickerProps = {
  options?: DatePickerOptions[];
  sm?: number;
  dataDefault?: Date;
  onChangeDate?: (mes: number, ano: number) => Promise<DatePickerDateConfig[]>;
  onClick?(event: React.MouseEvent): void;
  onSelect?(listaDias: DatePickerDateConfig[]): void;
  listaDiasConfig?: DatePickerDateConfig[];
  detalheMesComponent?: React.ReactElement;
};

export type InternalDatePickerDateConfig = {
  available: boolean;
  dragging?: boolean;
  interval?: boolean;
  initial?: boolean;
  final?: boolean;
} & DatePickerDateConfig;

const CenterIcon = styled(Icon)`
  margin: 20% 45%;
`;

const optionsDefault = (props: DatePickerProps): DatePickerOptions[] => [
  {
    label: 'Disponível',
    color: 'outline',
    id: 'white',
    canSelect: true
  },
  {
    label: 'Marcado',
    color: 'selected',
    id: 'blue',
    canSelect: true
  },
  ...(props.options || [])
];

class DatePicker extends React.Component<DatePickerProps, State> {
  state: State = {
    dataAtual: new Date(0),
    nomeMes: mesesAno,
    listaDias: [],
    loading: false,
    dataInicialDrag: new Date(0),
    dataFinalDrag: new Date(0)
  };

  componentDidMount() {
    const { dataDefault } = this.props;

    this.setState(
      {
        dataAtual: dataDefault
          ? subMonths(startOfMonth(dataDefault), 1)
          : startOfMonth(Date.now())
      },
      () => {
        const { dataAtual } = this.state;

        const listaDiasComConfiguracaoEspecifica =
          this.props.listaDiasConfig && this.props.onChangeDate
            ? this.props.listaDiasConfig
            : [];

        this.setState({
          listaDias: DatePickerUtils.getDiasCalendario(
            dataAtual,
            listaDiasComConfiguracaoEspecifica
          )
        });
      }
    );
  }

  componentDidUpdate(oldProps: DatePickerProps) {
    const { dataAtual } = this.state;

    if (
      oldProps.onChangeDate &&
      oldProps.listaDiasConfig !== this.props.listaDiasConfig
    ) {
      this.setState({
        listaDias: DatePickerUtils.getDiasCalendario(
          dataAtual,
          this.props.listaDiasConfig
        )
      });
    }
  }

  executeOnChangeDate = async (dataAtual: Date) => {
    if (this.props.onChangeDate) {
      this.setState({ loading: true });
      return await this.props
        .onChangeDate(getMonth(dataAtual), getYear(dataAtual))
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  atualizaListaDias = async (novaData: Date) => {
    const novaListaDias = DatePickerUtils.getListaDiasConfig(novaData);

    this.setState({
      dataAtual: novaData,
      listaDias: DatePickerUtils.getDiasCalendario(novaData, novaListaDias)
    });

    this.executeOnChangeDate(novaData);
  };

  onClickChangeMonthYear = (operacao: DatePickerUtils.ENUM_OPERACAO) => {
    const { dataAtual } = this.state;

    switch (operacao) {
      case 'INCREMENTA_MES':
        this.atualizaListaDias(DatePickerUtils.incrementaMes(dataAtual));
        break;
      case 'DECREMENTA_MES':
        this.atualizaListaDias(DatePickerUtils.decrementaMes(dataAtual));
        break;
      case 'INCREMENTA_ANO':
        this.atualizaListaDias(DatePickerUtils.incrementaAno(dataAtual));
        break;
      case 'DECREMENTA_ANO':
        this.atualizaListaDias(DatePickerUtils.decrementaAno(dataAtual));
        break;
    }
  };

  onClickCalendarDate = (event: React.SyntheticEvent<HTMLLIElement>) => {
    const { listaDias } = this.state;
    const item = DatePickerUtils.getConfigDiaSelecionado(
      event.currentTarget.dataset.value,
      listaDias
    );

    item &&
      this.setState(
        (prevState: State) => ({
          listaDias: DatePickerUtils.getClickCalendarDateList(
            item,
            prevState.listaDias
          )
        }),
        () => {
          const canMarked =
            item.interval ||
            (!item.marked && !item.interval) ||
            item.initial ||
            item.final;

          this.updateConfigDay(true, {
            ...item,
            marked: canMarked,
            dragging: false,
            interval: false,
            initial: false,
            final: false
          });
        }
      );
  };

  updateConfigDay = (
    callSelect: boolean,
    config: InternalDatePickerDateConfig
  ) => {
    this.setState(
      (prevState: State) => ({
        listaDias: ArrayUtils.addOrReplace(
          prevState.listaDias,
          config,
          item => item.available && item.data === config.data
        )
      }),
      () => {
        callSelect
          ? this.props.onSelect && this.props.onSelect(this.state.listaDias)
          : null;
      }
    );
  };

  onDragEnter = (event: React.SyntheticEvent<HTMLLIElement>) => {
    const { listaDias } = this.state;
    const config = DatePickerUtils.getConfigDiaSelecionado(
      event.currentTarget.dataset.value,
      listaDias
    );

    config &&
      this.setState({ dataFinalDrag: config.data }, () => {
        const { listaDias, dataInicialDrag } = this.state;
        config.dragging = config.data >= dataInicialDrag;
        listaDias
          .filter(item => item.available)
          .forEach(element => {
            if (
              !element.initial &&
              (!element.option || element.option!.canSelect)
            ) {
              const isMarked =
                element.data > dataInicialDrag && element.data < config.data;

              element.marked = isMarked;
              element.interval = isMarked;

              this.updateConfigDay(false, { ...element });
            } else {
              this.updateConfigDay(false, {
                ...config,
                interval: false
              });
            }
          });
      });
  };

  onDragLeave = (event: React.SyntheticEvent<HTMLLIElement>) => {
    const { listaDias } = this.state;
    const item = DatePickerUtils.getConfigDiaSelecionado(
      event.currentTarget.dataset.value,
      listaDias
    );

    item &&
      this.updateConfigDay(false, {
        ...item,
        dragging: false
      });
  };

  onDragStart = (event: React.SyntheticEvent<HTMLLIElement>) => {
    const { listaDias } = this.state;
    const item = DatePickerUtils.getConfigDiaSelecionado(
      event.currentTarget.dataset.value,
      listaDias
    );

    if (item) {
      this.setState(
        (prevState: State) => ({
          dataInicialDrag: item.data,
          listaDias: DatePickerUtils.getListaLimpa(prevState.listaDias)
        }),
        () => {
          this.updateConfigDay(false, {
            ...item,
            marked: true,
            initial: true,
            final: false
          });
        }
      );
    } else {
      this.setState({
        dataInicialDrag: new Date(0),
        dataFinalDrag: new Date(0)
      });
    }
  };

  onDragEnd = () => {
    const { dataAtual, dataInicialDrag, dataFinalDrag, listaDias } = this.state;
    const item = DatePickerUtils.getConfigDiaSelecionado(
      dataFinalDrag.toString(),
      listaDias
    );

    if (item) {
      if (dataInicialDrag < dataAtual || dataInicialDrag > dataFinalDrag) {
        this.setState((prevState: State) => ({
          listaDias: DatePickerUtils.getListaLimpa(prevState.listaDias)
        }));
      } else {
        this.updateConfigDay(true, {
          ...item,
          initial: dataInicialDrag > dataFinalDrag,
          final: dataInicialDrag < dataFinalDrag,
          marked: true
        });
      }
    }
  };

  render() {
    const { dataAtual, nomeMes, listaDias, loading } = this.state;
    const { sm = 4, detalheMesComponent } = this.props;
    return (
      <>
        <div className={`col-md-${sm} mb-xs`}>
          <div className="datepicker visible">
            <div className="datepicker-header">
              <div className="datepicker-header-year">
                <em
                  onClick={() => this.onClickChangeMonthYear('DECREMENTA_ANO')}
                  className="fa fa-chevron-left"
                  data-test-id="button-decrementaAno"
                />
                <b className="year-number" data-test-id="test-ano">
                  {getYear(dataAtual)}
                </b>

                <em
                  onClick={() => this.onClickChangeMonthYear('INCREMENTA_ANO')}
                  className="fa fa-chevron-right"
                  data-test-id="button-incrementaAno"
                />
              </div>
              <div className="datepicker-header-month">
                <em
                  onClick={() => this.onClickChangeMonthYear('DECREMENTA_MES')}
                  className="fa fa-chevron-left"
                  data-test-id="button-decrementaMes"
                />

                <b className="month-name" data-test-id="test-mes">
                  {nomeMes[getMonth(dataAtual)]}
                </b>

                <em
                  onClick={() => this.onClickChangeMonthYear('INCREMENTA_MES')}
                  className="fa fa-chevron-right"
                  data-test-id="button-incrementaMes"
                />
              </div>
              {detalheMesComponent}
            </div>
            <ul className="datepicker-calendar open">
              <ul className="datepicker-calendar-header">
                {abreviacaoSemana.map(diaSemana => (
                  <li className="item" key={diaSemana}>
                    {diaSemana}
                  </li>
                ))}
              </ul>

              {loading ? (
                <CenterIcon size="2x" icon="spinner" spin primary />
              ) : (
                listaDias.map((dia, index) => {
                  return (
                    <li
                      data-value={dia.data}
                      onClick={event =>
                        dia.available ? this.onClickCalendarDate(event) : {}
                      }
                      onDragEnter={this.onDragEnter}
                      onDragLeave={this.onDragLeave}
                      onDragEnd={this.onDragEnd}
                      onDragStart={this.onDragStart}
                      key={index}
                      className={`item ${DatePickerUtils.getClassName(dia)}`}
                      draggable={true}
                      data-test-id="click-selectedDay"
                    >
                      {getDate(dia.data)}
                    </li>
                  );
                })
              )}
            </ul>
            )
            <div className="subtitles">
              {optionsDefault(this.props).map(
                (option: DatePickerOptions, index: number) => {
                  return (
                    <div
                      key={index}
                      className={`subtitle large ${option.color}`}
                    >
                      {option.label}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DatePicker;
