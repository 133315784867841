import {
  ActionButton,
  ActionsGroup,
  Alert,
  Col,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  DragDropFiles,
  FormattedDate,
  Loading,
  Panel,
  Row,
  SectionTitle,
  Table,
  TableChild
} from '@elotech/components';
import FileSaver from 'file-saver';
import { ProcessoService, TramiteService } from 'itbi-common/service';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  ArquivoProtocolo,
  ProcessoProtocolo,
  TramiteProtocolo
} from '../../types';
import DocumentosExigidos from './DocumentosExigidosList';
import { ProcessoViewPageParecerInnerForm } from './ProcessoViewPageParecerInnerForm';

type Params = {
  id: string;
};

const initialProcesso: ProcessoProtocolo = {
  tipo: undefined,
  assunto: undefined,
  pessoa: undefined,
  complementoAssunto: '',
  digitacao: '',
  observacao: '',
  zona: '',
  quadra: '',
  lote: '',
  cadastro: '',
  arquivos: [],
  tramites: []
};

export const ProcessoViewPage = () => {
  const { id } = useParams<Params>();
  const [loading, setLoading] = useState(false);
  const [processo, setProcesso] = useState(initialProcesso);
  const [expandedTramiteSequencias, setExpandedTramiteSequencias] = useState<
    number[]
  >([]);

  useEffect(() => {
    setLoading(true);

    ProcessoService.getProcesso(id)
      .then(({ data }) => setProcesso(data))
      .catch(error => {
        Alert.error({ title: 'Ocorreu um erro ao buscar o processo' }, error);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const arquivos = processo.arquivos.map(arquivo => ({
    ...arquivo,
    name: arquivo.nome
  }));

  const getArquivo = (arquivo: ArquivoProtocolo) => {
    return ProcessoService.downloadArquivo(
      arquivo.identificador!,
      arquivo.nome!
    ).then(({ data }) => {
      return new Blob([data], { type: data.type });
    });
  };

  const previewArquivo = (arquivo: ArquivoProtocolo) => {
    getArquivo(arquivo).then(blob => window.open(URL.createObjectURL(blob)));
  };

  const downloadArquivo = (arquivo: ArquivoProtocolo) => {
    getArquivo(arquivo).then(blob => {
      FileSaver.saveAs(URL.createObjectURL(blob), arquivo.nome);
    });
  };

  const addArquivo = (arquivos: File[]) => {
    setLoading(true);
    let promises = arquivos.map(arquivo => {
      const formData = new FormData();
      formData.append('files', arquivo);
      return ProcessoService.salvarArquivo(processo.id!, formData);
    });
    Promise.all(promises)
      .then(() => {
        ProcessoService.getProcesso(id).then(({ data }) =>
          setProcesso(prev => {
            return {
              ...prev,
              arquivos: data.arquivos
            };
          })
        );
      })
      .catch(error => {
        Alert.error({ title: 'Ocorreu um erro ao salvar o arquivo' }, error);
      })
      .finally(() => setLoading(false));
  };

  const removeFromExpandedSequencia = (
    prevState: number[],
    sequencia: number
  ) => prevState.filter(s => s !== sequencia);

  const removeExpandedTramite = (tramite: TramiteProtocolo) =>
    setExpandedTramiteSequencias(prevState =>
      removeFromExpandedSequencia(prevState, tramite.sequencia!)
    );

  const saveParecerResposta = (tramiteComNovoParecer: TramiteProtocolo) => {
    setLoading(true);

    return TramiteService.updateParecerRequerente(id, tramiteComNovoParecer)
      .then(({ data }) => {
        setProcesso(prevState => {
          const tramiteIndex = prevState.tramites.findIndex(
            t => t.sequencia === data.sequencia
          );

          const tramiteAtualizado = {
            ...tramiteComNovoParecer,
            dataParecerResposta: data.dataParecerResposta
          };

          const processoComTramiteAtualizado: ProcessoProtocolo = {
            ...prevState,
            tramites: [
              ...prevState.tramites.slice(0, tramiteIndex),
              tramiteAtualizado,
              ...prevState.tramites.slice(tramiteIndex + 1)
            ]
          };

          return processoComTramiteAtualizado;
        });
        removeExpandedTramite(data);
      })
      .catch(error => {
        Alert.error(
          { title: 'Ocorreu um erro ao atualizar o parecer do requerente' },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const openTramiteInnerComponent = (tramite: TramiteProtocolo) =>
    setExpandedTramiteSequencias(prevState => {
      const newExpandedValues = prevState.includes(tramite.sequencia!)
        ? removeFromExpandedSequencia(prevState, tramite.sequencia!)
        : [...prevState, tramite.sequencia!];

      return newExpandedValues;
    });

  const renderTramiteActionButtons = (
    tramite: TramiteProtocolo,
    index: number
  ) => (
    <ActionsGroup>
      <ActionButton
        key={`pareceres-btn-${index}`}
        icon="list"
        label="Pareceres"
        onClick={() => openTramiteInnerComponent(tramite)}
      />
    </ActionsGroup>
  );

  const renderTramiteInnerComponent = (tramite: TramiteProtocolo) => {
    if (
      expandedTramiteSequencias.length > 0 &&
      expandedTramiteSequencias.includes(tramite.sequencia!)
    ) {
      return (
        <TableChild>
          <Row>
            <Col sm={12} md={12}>
              <ProcessoViewPageParecerInnerForm
                tramite={tramite}
                saveParecerResposta={saveParecerResposta}
                cancelEditTramite={removeExpandedTramite}
              />
            </Col>
          </Row>
        </TableChild>
      );
    }

    return null;
  };

  return (
    <Container title="Visualização do Processo" icon="file-alt">
      <Loading loading={loading} />
      <SectionTitle marginTop="0px">Dados do Processo</SectionTitle>
      <DisplayDataGrid>
        <Row>
          <DisplayDataItem title="Número/Ano" sm={3}>
            {processo.numero}/{processo.ano}
          </DisplayDataItem>
          <DisplayDataItem title="Tipo" sm={3}>
            {processo.tipo?.id} - {processo.tipo?.descricao}
          </DisplayDataItem>
          <DisplayDataItem title="Assunto" sm={3}>
            {processo.assunto?.id} - {processo.assunto?.descricao}
          </DisplayDataItem>
          <DisplayDataItem title="Data Processo" sm={3}>
            <FormattedDate value={processo.dataProcesso} />
          </DisplayDataItem>
        </Row>
        <Row>
          <DisplayDataItem title="Requerimento" sm={12}>
            {processo.digitacao}
          </DisplayDataItem>
        </Row>
        <Row>
          <DisplayDataItem title="Observação" sm={12}>
            {processo.observacao}
          </DisplayDataItem>
        </Row>
        <Row>
          <DisplayDataItem title="Zona" sm={3}>
            {processo.zona}
          </DisplayDataItem>
          <DisplayDataItem title="Quadra" sm={3}>
            {processo.quadra}
          </DisplayDataItem>
          <DisplayDataItem title="Lote" sm={3}>
            {processo.lote}
          </DisplayDataItem>
          <DisplayDataItem title="Cadastro" sm={3}>
            {processo.cadastro}
          </DisplayDataItem>
        </Row>
      </DisplayDataGrid>

      <SectionTitle>Trâmites</SectionTitle>
      <Panel isTable>
        <Table
          reduced={false}
          values={processo.tramites.sort((a, b) => b.sequencia! - a.sequencia!)}
          keyExtractor={(tramite: TramiteProtocolo) => `${tramite.sequencia}`}
          renderInnerComponent={renderTramiteInnerComponent}
        >
          <Table.Column
            header="Ordem"
            value={(tramite: TramiteProtocolo) => tramite.sequencia}
          />
          <Table.Column
            header="Situação"
            value={(tramite: TramiteProtocolo) => tramite.situacao?.descricao}
          />
          <Table.Column
            header="Status"
            value={(tramite: TramiteProtocolo) => tramite.funcao}
          />
          <Table.Column
            header="Data Trâmite"
            value={(tramite: TramiteProtocolo) => (
              <FormattedDate value={tramite.data} />
            )}
          />
          <Table.Column
            header="Hora"
            value={(tramite: TramiteProtocolo) => tramite.hora}
          />
          <Table.Column
            header="Local"
            value={(tramite: TramiteProtocolo) => tramite.local?.descricao}
          />
          <Table.Column
            header="Caixa"
            value={(tramite: TramiteProtocolo) => tramite.caixa?.descricao}
          />
          <Table.Column
            header="Dias no Local"
            value={(tramite: TramiteProtocolo) => tramite.quantidadeDiasLocal}
          />
          <Table.Column header="" value={renderTramiteActionButtons} />
        </Table>
      </Panel>

      <Row>
        <Col md={5}>
          <SectionTitle>Documentos Exigidos</SectionTitle>
          <DocumentosExigidos
            onlyView
            arquivos={processo.arquivos}
            documentos={processo.assunto?.assuntoDocumento!}
          />
        </Col>

        <Col md={7}>
          <SectionTitle>Arquivos</SectionTitle>
          <DragDropFiles
            files={arquivos}
            canDelete={false}
            onAddFile={addArquivo}
            onPreview={previewArquivo}
            onDownload={downloadArquivo}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ProcessoViewPage;
