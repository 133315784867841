import axios, { AxiosPromise } from 'axios';

import { CadastroRural } from '../type';

export const buscarCadastro = (cadastro: string): AxiosPromise<any> =>
  axios.get(`/api/rural/${cadastro}`);

export const findByCadastroComValorAvaliado = (
  cadastro: string
): AxiosPromise<CadastroRural> =>
  axios.get(`/api/rural/${cadastro}/calcular-valores-avaliacao`);
