import { formatUtils } from '@elotech/components';

import { Laudo, TIPO_IMOVEL_RURAL } from '../type';

export const buildEnderecoCadastro = (laudo: Laudo) => {
  return laudo.tipoImovel === TIPO_IMOVEL_RURAL
    ? `${laudo.cadastroRural?.enderecoDescrito ?? ''}`
    : `${laudo.cadastroImobiliario?.endereco ?? ''}, ${laudo.cadastroImobiliario
        ?.numero ?? ''}, ${formatUtils.formatCep(
        laudo.cadastroImobiliario?.cep
      )}, ${laudo.cadastroImobiliario?.cidade ?? ''}, ${laudo
        .cadastroImobiliario?.estado ?? ''}`;
};
