import {
  Props as FAIconProps,
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import React from 'react';
import styled, {
  CSSObject,
  StyledComponent,
  ThemedStyledProps
} from 'styled-components/macro';

import { Theme } from '../type';

const getColor = (props: ThemedStyledProps<Props, Theme>): CSSObject => {
  const {
    theme,
    lightPrimary,
    primary,
    darkPrimary,
    lightPositive,
    positive,
    darkPositive,
    lightWarning,
    warning,
    darkWarning,
    lightDanger,
    danger,
    darkDanger,
    color
  } = props;

  const conditions = {
    primary,
    lightPrimary,
    darkPrimary,
    positive,
    lightPositive,
    darkPositive,
    warning,
    lightWarning,
    darkWarning,
    danger,
    lightDanger,
    darkDanger
  };

  const activeCondition = Object.entries(conditions).reduce(
    (result, [key, value]) =>
      result === 'defaultIcon' && value ? key : result,
    'defaultIcon'
  );

  if (activeCondition !== 'defaultIcon' || color === undefined) {
    return { color: theme[`${activeCondition}Color` as keyof Theme] };
  }
  return { color };
};

const getHoverColor = (props: ThemedStyledProps<Props, Theme>): CSSObject => {
  const { hoverColor } = props;
  return hoverColor ? { color: hoverColor } : getColor(props);
};

type Props = {
  primary?: boolean; //used
  lightPrimary?: boolean;
  darkPrimary?: boolean; //used
  positive?: boolean; //used
  lightPositive?: boolean;
  darkPositive?: boolean;
  warning?: boolean; //used
  lightWarning?: boolean;
  darkWarning?: boolean;
  danger?: boolean; //used
  lightDanger?: boolean;
  darkDanger?: boolean;
  color?: string;
  hoverColor?: string;
  hoverParent?: StyledComponent<any, any>;
};

const InnerIconComponent: React.FC<Props & FAIconProps> = ({
  primary,
  lightPrimary,
  darkPrimary,
  positive,
  lightPositive,
  darkPositive,
  warning,
  lightWarning,
  darkWarning,
  danger,
  lightDanger,
  darkDanger,
  hoverParent,
  hoverColor,
  ...props
}) => <FontAwesomeIcon {...props} />;

const Icon = styled(InnerIconComponent)`
  
  ${getColor}
  * {
    ${getColor};
  }
  
  ${(props: Props) => props.hoverParent || '&'}:hover & {
    * {
      ${getHoverColor}
    }
  
  
`;

Icon.displayName = 'Icon';

export default Icon;
