import PropTypes from 'prop-types';
import React from 'react';

const stylesByType = {
  info: { icon: '', buttonColor: 'neutral' },
  primary: { icon: 'fa-info-circle', buttonColor: 'module-color' },
  success: { icon: 'fa-check-circle', buttonColor: 'positive' },
  negative: { icon: 'fa-times-circle', buttonColor: 'negative' },
  warning: { icon: 'fa-exclamation-triangle', buttonColor: 'warning' }
};

export default class Info extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.string,
    buttonLabel: PropTypes.string,
    onButtonClick: PropTypes.func,
    beaty: PropTypes.bool,
    type: PropTypes.oneOfType(
      'info' | 'primary' | 'success' | 'negative' | 'warning'
    )
  };

  static defaultProps = {
    classes: '',
    type: 'info'
  };

  render() {
    const {
      classes,
      type,
      children,
      buttonLabel,
      onButtonClick,
      beaty
    } = this.props;

    const styles = stylesByType[type];

    if (!beaty) {
      return <div className={`info ${classes}`}> {children} </div>;
    }

    return (
      <div className={`mb-xs info icon ${type}`}>
        <div class="icon">
          <em class={`fa ${styles.icon}`}></em>
        </div>
        <article>{children}</article>
        {buttonLabel && (
          <button
            class={`btn right ${styles.buttonColor}`}
            onClick={onButtonClick}
          >
            {' '}
            {buttonLabel}
          </button>
        )}
      </div>
    );
  }
}
