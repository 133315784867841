export const isSituacaoDeArbitramento = (situacaoITBI: any) => {
  const situacoesArbitramento = ['AGUARDANDO_ACEITE', 'EM_CONTESTACAO'];
  return situacoesArbitramento.some(situacao => situacao === situacaoITBI.name);
};

export const getChipColorBySituacao = (situacaoITBI: any) => {
  let color = 'neutral';

  if (isSituacaoDeArbitramento(situacaoITBI)) {
    color = 'warning';
  }

  return color;
};
