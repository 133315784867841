import classnames from 'classnames';
import React from 'react';

const TableReportRows = ({ children }) => {
  const countChild = React.Children.count(children);
  const classUl = classnames('group-itens', { scroll: countChild > 1 });
  return <ul className={classUl}>{children}</ul>;
};

export default TableReportRows;
