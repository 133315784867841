export function isDocumentElement(el: HTMLElement) {
  return [document.documentElement, document.body, window].indexOf(el) > -1;
}

export function scrollTo(el: HTMLElement, top: number): void {
  // with a scroll distance, we perform scroll on the element
  if (isDocumentElement(el)) {
    window.scrollTo(0, top);
    return;
  }

  el.scrollTop = top;
}

export function scrollIntoView(
  parentElement: HTMLElement,
  childFocusedElement: HTMLElement
): void {
  const parentRect = parentElement.getBoundingClientRect();
  const focusedRect = childFocusedElement.getBoundingClientRect();
  const overScroll = childFocusedElement.offsetHeight / 3;

  if (focusedRect.bottom + overScroll > parentRect.bottom) {
    scrollTo(
      parentElement,
      Math.min(
        childFocusedElement.offsetTop +
          childFocusedElement.clientHeight -
          parentElement.offsetHeight +
          overScroll,
        parentElement.scrollHeight
      )
    );
  } else if (focusedRect.top - overScroll < parentRect.top) {
    scrollTo(
      parentElement,
      Math.max(childFocusedElement.offsetTop - overScroll, 0)
    );
  }
}
