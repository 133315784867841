import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

import Icon from '../icons/Icon';
import { Exclusive } from '../types';

const StyledButton = styled.button<{ iconColor: string }>`
  * {
    color: ${props => props.iconColor};
  }
  &.btn {
    font-size: 20px;
  }
`;

const StyledLink = styled(Link)`
  &.btn {
    font-size: 20px;
  }
`;

type FABButtonProps = {
  icon: IconProp;
  iconColor?: string;
  title?: string;
  loading?: boolean;
  onClick: React.MouseEventHandler;
  typeButton?: 'module-color' | 'negative' | 'positive';
  small?: boolean;
  hideHintOnMobile?: boolean;
  showHintOnFocus?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

type FABLinkProps = {
  icon: IconProp;
  title?: string;
  typeButton?: 'module-color' | 'negative' | 'positive';
  small?: boolean;
  hideHintOnMobile?: boolean;
  showHintOnFocus?: boolean;
  path: LinkProps['to'];
};

type Props = Exclusive<FABButtonProps, FABLinkProps>;

const FABButton = React.forwardRef(
  (
    {
      icon,
      title = '',
      loading = false,
      onClick,
      typeButton = 'module-color',
      small = false,
      hideHintOnMobile = false,
      showHintOnFocus = false,
      iconColor = 'white',
      ...buttonProps
    }: FABButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const buttonClassNames = classnames({
      'btn hint clean': true,
      [typeButton]: true,
      lg: small,
      'show-hint-on-focus': showHintOnFocus,
      hideHintOnMobile: hideHintOnMobile
    });
    const iconSize = small ? 'xs' : 'lg';

    return (
      <StyledButton
        data-testid="fab-button"
        type="button"
        ref={ref}
        iconColor={iconColor}
        {...buttonProps}
        className={buttonClassNames}
        onClick={event => (loading ? undefined : onClick(event))}
      >
        {loading ? (
          <Icon data-testid="fab-button-load" icon="spinner" spin />
        ) : (
          <React.Fragment>
            <FontAwesomeIcon
              data-testid="fab-button-icon"
              size={iconSize}
              icon={icon}
            />
            {title && <div className="hint-content">{title}</div>}
          </React.Fragment>
        )}
      </StyledButton>
    );
  }
) as React.FunctionComponent<Props>;

const FABLink = React.forwardRef(
  (
    {
      icon,
      title = '',
      typeButton = 'module-color',
      small = false,
      hideHintOnMobile = false,
      showHintOnFocus = false,
      path,
      ...linkProps
    }: FABLinkProps,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const buttonClassNames = classnames({
      'btn hint clean': true,
      [typeButton]: true,
      lg: small,
      'show-hint-on-focus': showHintOnFocus,
      hideHintOnMobile: hideHintOnMobile
    });
    const iconSize = small ? 'xs' : 'lg';

    return (
      <StyledLink
        data-testid="fab-link"
        to={path}
        type="button"
        innerRef={ref}
        {...linkProps}
        className={buttonClassNames}
      >
        <React.Fragment>
          <FontAwesomeIcon
            data-testid="fab-link-icon"
            size={iconSize}
            icon={icon}
          />
          {title && <div className="hint-content">{title}</div>}
        </React.Fragment>
      </StyledLink>
    );
  }
) as React.FunctionComponent<Props>;

const isFABButton = (props: Props): props is FABButtonProps =>
  (props as FABLinkProps).path === undefined;

const FAB: React.FunctionComponent<Props> = props => {
  if (isFABButton(props)) {
    return <FABButton {...props} />;
  }
  return <FABLink {...props} />;
};

FAB.displayName = 'FAB';

export default FAB;
