import { EmptyRow, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

import DocumentosAvulsosListItem from './DocumentosAvulsosListItem';

const DocumentosAvulsosList = ({
  documentos,
  showVisibleColumn,
  onRemove,
  onDownload,
  customSectionTitle,
  filter
}) => {
  return (
    <React.Fragment>
      <SectionTitle>{customSectionTitle || 'Documentos Avulsos'}</SectionTitle>
      <div className="panel table table-responsive">
        <div className="panel-body">
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Descrição</th>
                  {showVisibleColumn && <th>Visivel para o cidadão</th>}
                  <th />
                </tr>
              </thead>
              <tbody>
                {renderDocumentos(
                  documentos,
                  onRemove,
                  onDownload,
                  showVisibleColumn,
                  filter
                )}
                <EmptyRow show={documentos.length === 0} colSpan={3}>
                  Nenhum documento adicionado
                </EmptyRow>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

DocumentosAvulsosList.propTypes = {
  documentos: PropTypes.array.isRequired,
  showVisibleColumn: PropTypes.bool,
  onRemove: PropTypes.func,
  onDownload: PropTypes.func,
  customSectionTitle: PropTypes.string
};

DocumentosAvulsosList.defaultProps = {
  showVisibleColumn: false
};

const renderDocumentos = (
  documentos,
  onRemove,
  onDownload,
  showVisibleColumn,
  filter
) => {
  let documentosToShow = [...documentos];
  if (filter) {
    documentosToShow = documentos.filter(filter);
  }

  return documentosToShow.map(doc => (
    <DocumentosAvulsosListItem
      key={doc.id}
      documento={doc}
      onRemove={onRemove}
      onDownload={onDownload}
      showVisibleColumn={showVisibleColumn}
    />
  ));
};

export default DocumentosAvulsosList;
