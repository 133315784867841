import { CampoTipoEnum, TipoValidacaoEnum } from './enum';

export type FormikEmptyValue = '';

export type Campo = {
  id?: number;
  ordem: number;
  identificador: string;
  codigo?: number;
  descricao: string;
  tipo: CampoTipoEnum;
  obrigatorio: boolean;
  alternativas: Alternativa[];
  validacoes: Validacao[];
  tabelaResposta?: string;
  ativo: boolean;
  origemCampo?: OrigemCampo;
};

export type GrupoResposta = {
  id: number;
  descricao: string;
  ativo: boolean;
  respostas: Resposta[];
};

export type Alternativa = {
  id: number;
  descricao: string;
  valor?: number | FormikEmptyValue;
  codigo: number | FormikEmptyValue;
  ativo: boolean;
};

export type Validacao = {
  id?: number;
  tipo: TipoValidacaoEnum;
  campoDependente: string;
  dinamico: boolean;
  valor?: string;
};

export type Resposta = {
  id: number | string;
  campo: Campo;
  referencia?: number;
  valor: string;
  alternativa?: Alternativa;
};

export enum OrigemCampo {
  CADASTRO = 'CADASTRO',
  SITUACAO = 'SITUACAO'
}
