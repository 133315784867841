import { History, Location } from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';

type Props = {
  message: string;
  history: History;
  location: Location;
  match: match;
};
export const NotFoundPage: React.FunctionComponent<Props> = ({
  message,
  history
}) => {
  return (
    <div className="not-found" style={{ maxWidth: '500px' }}>
      <h1>
        <em className="far fa-frown" />
      </h1>
      <h2>Desculpe, não conseguimos encontrar o que você está procurando.</h2>
      <p>{message}</p>
      <button
        data-test-id="btn-notFound"
        className="btn module-color icon-right center mt-xs"
        type="button"
        onClick={() => history.push('/')}
      >
        Volte ao Início <em className="fa fa-home" />
      </button>
    </div>
  );
};

export default withRouter(NotFoundPage);
