import {
  Col,
  DisplayData,
  FormattedCurrency,
  FormattedDate,
  Row,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const PointerInput = styled.input`
  cursor: pointer;
`;

class ContestacaoSectionForm extends React.Component {
  static propTypes = {
    contestacao: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired,
    isVisibleAllInformations: PropTypes.bool.isRequired
  };

  render() {
    const { contestacao, onDownload, isVisibleAllInformations } = this.props;

    return (
      <React.Fragment>
        <SectionTitle>Dados da Contestação</SectionTitle>
        <div className="display-data border small mt-xs">
          <Row>
            <Col sm={12}>
              <DisplayData title="Motivo Contestação">
                {`${contestacao?.motivo || ''}`}
              </DisplayData>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <DisplayData title="Valor proposto pelo contribuinte">
                <FormattedCurrency
                  value={`${contestacao?.valorProposto || ''}`}
                />
              </DisplayData>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <DisplayData title="Data Contestação">
                <FormattedDate
                  value={`${contestacao?.dataContestacao || ''}`}
                  timeZone={'UTC'}
                />
              </DisplayData>
            </Col>
          </Row>
          <Row>
            <Col className="form-group" sm={12}>
              <DisplayData title="Documento contestação">
                <PointerInput
                  data-id="urlArquivo"
                  name=""
                  placeholder={contestacao?.urlArquivo ?? 'Nenhum'}
                  data-title={contestacao?.urlArquivo}
                  onClick={() => onDownload(contestacao?.urlArquivo)}
                  readOnly
                  disabled={!contestacao?.urlArquivo}
                />
                {contestacao?.urlArquivo && (
                  <i
                    aria-hidden="true"
                    onClick={() => onDownload(contestacao?.urlArquivo)}
                    className="fa fa-download input-icon"
                  />
                )}
              </DisplayData>
            </Col>
          </Row>
          {isVisibleAllInformations && (
            <>
              <Row>
                <Col sm={12}>
                  <DisplayData title="Parecer">
                    {`${contestacao?.parecer || ''}`}
                  </DisplayData>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <DisplayData title="Data Resposta">
                    <FormattedDate
                      value={`${contestacao?.dataResposta || ''}`}
                      timeZone={'UTC'}
                    />
                  </DisplayData>
                </Col>
                <Col sm={6}>
                  <DisplayData title="Situação Contestação">
                    {`${contestacao?.situacao?.descricao || ''}`}
                  </DisplayData>
                </Col>
              </Row>
              <Row>
                <Col className="form-group" sm={12}>
                  <DisplayData title="Documento parecer">
                    <PointerInput
                      data-id="urlArquivo"
                      name=""
                      placeholder={contestacao?.urlArquivoParecer ?? 'Nenhum'}
                      data-title={contestacao?.urlArquivoParecer}
                      onClick={() => onDownload(contestacao?.urlArquivoParecer)}
                      readOnly
                      disabled={!contestacao?.urlArquivoParecer}
                    />
                    {contestacao?.urlArquivoParecer && (
                      <i
                        aria-hidden="true"
                        onClick={() =>
                          onDownload(contestacao?.urlArquivoParecer)
                        }
                        className="fa fa-download input-icon"
                      />
                    )}
                  </DisplayData>
                </Col>
              </Row>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ContestacaoSectionForm;
