import {
  Container,
  EmptyRow,
  FormattedDate,
  Loading,
  NotificationActions
} from '@elotech/components';
import { UserService, withService } from 'itbi-common/service';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

export class AuthorizationHistory extends React.Component {
  static propTypes = {
    userService: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired
  };
  state = {
    loading: false,
    listUserAuthorized: []
  };

  componentDidMount() {
    this.getHistoryAuthorizantion();
  }

  getHistorySuccess = response => {
    this.setState({ listUserAuthorized: response.data, loading: false });
  };

  getHistoryError = () => {
    this.setState({ loading: false });
    this.props.showNotification({
      level: 'warning',
      message: 'Não foi possível pesquisar o histórico de usuários autorizados'
    });
  };

  getHistoryAuthorizantion = () => {
    this.setState({ loading: true });
    this.props.userService
      .getUsuarioAutorizadoById()
      .then(this.getHistorySuccess)
      .catch(this.getHistoryError);
  };

  render() {
    const { listUserAuthorized, loading } = this.state;
    return (
      <Container title="Histórico de Autorizações" icon="history">
        <Loading loading={loading} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <thead>
                  <tr>
                    <th>CPF</th>
                    <th>Nome</th>
                    <th>Data de Autorização</th>
                    <th>Data de Exclusão</th>
                  </tr>
                </thead>
                <tbody>
                  {listUserAuthorized.length > 0 ? (
                    listUserAuthorized.map((user, index) => (
                      <tr className="reduced" key={user.id}>
                        <td>{user.usuarioAutorizado.cpfCnpj}</td>
                        <td>{user.usuarioAutorizado.nome}</td>
                        <td>
                          {user.dataAutorizacao ? (
                            <FormattedDate
                              value={user.dataAutorizacao}
                              timeZone={'UTC'}
                            />
                          ) : (
                            ''
                          )}
                        </td>
                        <td>
                          {user.dataExclusao ? (
                            <FormattedDate
                              value={user.dataExclusao}
                              timeZone={'UTC'}
                            />
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <EmptyRow colSpan={7} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  userService: UserService
})(AuthorizationHistory);

const connectComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(ComponentWithService);

export default connectComponent;
