import LogoFooter from '@elotech/arc/src/assets/img/common/logo-footer.svg';
import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ShepherdTourContext } from 'react-shepherd';

import FormattedDateTime from '../formatted/FormattedDateTime';
import { useAuth } from '../hooks';
import Icon from '../icons/Icon';
import { ActuatorInfo, EnvironmentUtils } from '../index';
import { ApplicationContext } from '../providers/ApplicationProvider';
import EntidadeContent from './EntidadeContent';
import FeatureSection from './FeatureSection';
import { HeaderContext } from './Header';

export type AjustesContentProps = {
  onClickFaq?: any;
  onContactUs?: any;
  onMyAccount?: any;
  onLogout?: any;
  hideAccount?: boolean;
  showNovidadesVersao?: boolean;
  onClickNovidadesVersao?: any;
};

const AjustesContent: React.FC<AjustesContentProps> = props => {
  const { userInfo, logout, accountManagement } = useAuth();
  const {
    activeAjustes,
    toggleAjustes,
    activeEntidade,
    toggleEntidade,
    activeAjustesSobreSistema,
    toggleAjustesSobreSistema
  } = useContext(HeaderContext);
  const {
    entidade,
    onSearchEntidades,
    onGetVersaoSobreSistema,
    onGetTotalAcessosSobreSistema
  } = useContext(ApplicationContext);
  const tour = useContext(ShepherdTourContext);
  const [versaoBuild, setVersaoBuild] = useState<string | undefined>(undefined);
  const [dataBuild, setDataBuild] = useState<string | undefined>(undefined);
  const [gitData, setGitData] = useState<ActuatorInfo['git'] | undefined>(
    undefined
  );
  const [totalAcessos, setTotalAcessos] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (activeAjustesSobreSistema) {
      onGetVersaoSobreSistema?.().then(response => {
        setVersaoBuild(response.data.build.version);
        setDataBuild(response.data.build.time);
        setGitData(response.data.git);
      });

      onGetTotalAcessosSobreSistema?.().then(response => {
        setTotalAcessos(response.data);
      });
    } else {
      setVersaoBuild(undefined);
      setDataBuild(undefined);
      setTotalAcessos(undefined);
    }
  }, [activeAjustesSobreSistema]);

  const isProduction = useMemo(
    () => EnvironmentUtils.isEnviromentProduction(window.location.hostname),
    []
  );

  if (!activeAjustes && !activeEntidade && !activeAjustesSobreSistema) {
    return null;
  }

  const isVersaoDefined = onGetVersaoSobreSistema != undefined;
  const isTotalAcessosDefined = onGetTotalAcessosSobreSistema != undefined;

  const {
    onClickFaq,
    onContactUs,
    onMyAccount,
    onLogout = logout,
    hideAccount = false,
    showNovidadesVersao = false
  } = props;

  const className = classNames('features-user auto-height overflow-visible', {
    active: activeAjustes || activeEntidade || activeAjustesSobreSistema
  });

  const handleMyAccount = () => {
    toggleAjustes();
    if (onMyAccount) {
      onMyAccount();
    } else {
      accountManagement();
    }
  };

  const handleAction = (fn: any) => () => {
    toggleAjustes();
    fn();
  };

  if (activeEntidade) {
    return (
      <div className={className}>
        <EntidadeContent />
      </div>
    );
  }

  if (activeAjustesSobreSistema) {
    return (
      <div className={className}>
        <h2 className="title">
          <div
            className="read hint clean center-left neutral"
            onClick={toggleAjustes}
          >
            <Icon icon="chevron-left" />
            <div className="hint-content">Voltar</div>
          </div>
          Sobre o sistema
        </h2>
        <ul className="user-actions">
          <div className="col-md-12 form-group mb-xs">
            {isVersaoDefined && (
              <article>
                <label>Versão</label>
                <p>{versaoBuild ? versaoBuild : 'Carregando...'}</p>
                <label>Data</label>
                <p>
                  {dataBuild ? (
                    <FormattedDateTime value={dataBuild} />
                  ) : (
                    'Carregando...'
                  )}
                </p>
                {gitData && !isProduction ? (
                  <>
                    <label>Branch</label>
                    <p>{gitData.branch}</p>
                    <label>Commit</label>
                    <p>{gitData.commit.id}</p>
                  </>
                ) : null}
              </article>
            )}
            {isTotalAcessosDefined && (
              <article>
                <label>Total de Acessos</label>
                <p>{totalAcessos ? totalAcessos : 'Carregando...'}</p>
              </article>
            )}
          </div>
          {showNovidadesVersao && (
            <li className="form-group" onClick={props.onClickNovidadesVersao}>
              <Icon icon="info-circle" className="icon" />
              Confira as novidades da versão
            </li>
          )}
        </ul>
        <div className="footer-oxy-logo hidden-sm hidden-xs">
          <img src={LogoFooter} />
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      <h2 className="title">{userInfo.nome}</h2>
      <ul className="user-actions">
        {!hideAccount && (
          <>
            <FeatureSection title="Minha Conta" />
            <li onClick={handleMyAccount}>
              <Icon icon="user" className="icon" />
              <article>
                <label>Usuário</label>
                {userInfo.nome}
              </article>
            </li>
          </>
        )}

        {tour ? (
          <li className="no-link" onClick={tour.start}>
            <Icon icon="plane-departure" className="icon" />
            Fazer o tour novamente.
          </li>
        ) : null}

        {onSearchEntidades && (
          <>
            <FeatureSection title="Parâmetros" />
            <li onClick={toggleEntidade}>
              <Icon icon="building" className="icon" />
              <article>
                <label>Entidade</label>
                {entidade?.nome || 'Nenhuma entidade selecionada'}
              </article>
            </li>
          </>
        )}
        {onClickFaq || onContactUs ? (
          <FeatureSection title="Ajuda" />
        ) : (
          undefined
        )}
        {onClickFaq && (
          <li onClick={handleAction(onClickFaq)}>
            <Icon icon="question" className="icon" />
            FAQ
          </li>
        )}
        {onContactUs && (
          <li onClick={handleAction(onContactUs)}>
            <Icon icon="comment" className="icon" />
            Fale Conosco
          </li>
        )}
        {onGetVersaoSobreSistema && (
          <li onClick={toggleAjustesSobreSistema}>
            <Icon icon="info-circle" className="icon" />
            Sobre o sistema
          </li>
        )}
        <li className="no-link logout" onClick={onLogout}>
          <Icon icon="sign-out-alt" className="icon" />
          Sair
        </li>
      </ul>
    </div>
  );
};

export default AjustesContent;
