import { Yup } from '@elotech/components';

import {
  ArquivoProtocolo,
  AssuntoProtocolo,
  ProcessoProtocolo
} from '../../types';

export const validationSchema = Yup.object().shape({
  tipo: Yup.object().required('Tipo é obrigatório'),
  assunto: Yup.object().required('Assunto é obrigatório'),
  complementoAssunto: Yup.string()
    .trim()
    .max(250, 'O complemento do assunto deve possuir no máximo 250 caracteres'),
  digitacao: Yup.string()
    .required('Requerimento é obrigatório')
    .trim()
    .max(4000, 'O requerimento deve possuir no máximo 4000 caracteres'),
  quadra: Yup.string()
    .trim()
    .max(30, 'A quadra deve possuir no máximo 30 caracteres'),
  zona: Yup.string()
    .trim()
    .max(30, 'A zona deve possuir no máximo 30 caracteres'),
  lote: Yup.string()
    .trim()
    .max(30, 'O lote deve possuir no máximo 30 caracteres'),
  cadastro: Yup.string()
    .trim()
    .max(30, 'O cadastro deve possuir no máximo 30 caracteres'),
  arquivos: Yup.array().test(
    'arquivos',
    'É necessário adicionar um arquivo para cada documento exigido',
    function(value) {
      const assunto: AssuntoProtocolo = this.resolve(Yup.ref('assunto'));

      if (value && assunto && assunto.obrigaArquivosAssuntoExterno) {
        const idsDocsInseridos = value
          .filter((a: ArquivoProtocolo) => a.documento)
          .map((a: ArquivoProtocolo) => a.documento?.id);
        const idsDocsExigidos = assunto?.assuntoDocumento?.map(
          ad => ad.documento?.id!
        );

        return idsDocsExigidos.every(id => idsDocsInseridos.includes(id));
      }

      return true;
    }
  )
});

export const initialValues: ProcessoProtocolo = {
  tipo: undefined,
  assunto: undefined,
  pessoa: undefined,
  complementoAssunto: '',
  digitacao: '',
  observacao: '',
  zona: '',
  quadra: '',
  lote: '',
  cadastro: '',
  arquivos: [],
  tramites: []
};
