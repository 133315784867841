import * as Types from './types';

const handleShowNotification = (state, action) => [...state, action.payload];
const handleRemoveNotification = (state, action) => {
  const filtered = state.filter(
    notification => notification.uid !== action.payload
  );
  return [...filtered, { remove: action.payload }];
};

const handleNotifications = (state, action) =>
  state.filter(notification => !action.payload.includes(notification.uid));

const handleClearNotification = () => [{ clear: true }];

const handlers = {
  [Types.SHOW_NOTIFICATION]: handleShowNotification,
  [Types.REMOVE_NOTIFICATION]: handleRemoveNotification,
  [Types.HANDLE_NOTIFICATIONS]: handleNotifications,
  [Types.CLEAR_NOTIFICATIONS]: handleClearNotification
};

const NotificationReducer = (state = [], action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};

export default NotificationReducer;
