import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { useHover, useWindowWidth } from '../hooks';
import MenuItem, { canShow } from './MenuItem';
import { Consumer } from './Sidebar';

const InnerMenuGroup = ({
  icon,
  title,
  children,
  searchValue,
  activeMenu,
  setActiveMenu,
  ...props
}) => {
  const width = useWindowWidth();
  const [collapsed, setCollapsed] = useState(
    'collapsed' in props ? props.collapsed : true
  );
  const [refHover, isHover] = useHover();
  const refClick = useRef(null);
  const [ulStyle, setUlStyle] = useState({});

  useLayoutEffect(() => {
    const node = refHover.current;
    if (node) {
      let child = node.querySelector('ul');
      if (child) {
        let childHeight = child.offsetHeight;
        let bounding = node.getBoundingClientRect();
        let offsetTop = bounding.y;
        let windowHeight = window.innerHeight;
        if (childHeight > windowHeight) {
          childHeight = windowHeight - 310;
        }
        if (offsetTop + childHeight > windowHeight) {
          offsetTop = offsetTop - (offsetTop + childHeight - windowHeight) - 5;
        }

        setUlStyle({
          left: bounding.x + bounding.width + 'px',
          maxHeight: childHeight ? `${childHeight}px` : undefined,
          top: offsetTop + 'px'
        });
      }
    }
  }, [refHover.current, isHover]);

  const onClickMenu = useCallback(
    event => {
      if (
        event.target === refClick.current ||
        refClick.current?.contains(event.target)
      ) {
        event.preventDefault();
        event.stopPropagation();
        setCollapsed(prevState => !prevState);
      } else if (refHover.current && refHover.current.contains(event.target)) {
        setActiveMenu(title);
      }
    },
    [refClick.current]
  );

  const renderItems = searchValue => {
    return React.Children.map(children, item => {
      if (
        item !== null &&
        item.type === MenuItem &&
        canShow(item.props.title, searchValue, item.props.visible)
      ) {
        return item;
      }
      return null;
    }).filter(item => !!item);
  };

  const sidebarCanFloat = width >= 768 && width < 1024;

  const items = renderItems(searchValue);

  if (items.length === 0) {
    return null;
  }

  const showMenuItems = !collapsed || searchValue;

  const classesli = classnames({
    'dropdown-arrow': true,
    'top open': showMenuItems
  });

  const classesliNewTheme = classnames({
    on: activeMenu === title
  });

  const classesul = classnames({
    active: showMenuItems,
    'visible-block': sidebarCanFloat && showMenuItems
  });

  return (
    <li ref={refHover} className={classesliNewTheme} onClick={onClickMenu}>
      <a ref={refClick} className={classesli}>
        <i className={`father ${icon}`} />
        <b> {title} </b>
      </a>
      <ul
        attr-parent={title}
        className={classesul}
        style={sidebarCanFloat ? {} : ulStyle}
      >
        {items}
      </ul>
    </li>
  );
};

const MenuGroup = props => (
  <Consumer>
    {({ searchValue = '', activeMenu, setActiveMenu }) => (
      <InnerMenuGroup
        searchValue={searchValue}
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
        {...props}
      />
    )}
  </Consumer>
);

MenuGroup.propTypes = {
  collapsed: PropTypes.bool,
  icon: PropTypes.any,
  title: PropTypes.string
};

export default MenuGroup;
