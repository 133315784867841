import React, { useRef } from 'react';

import { useOnClickOutside } from '../hooks';

type Props = {
  onClose(): void;
  children: React.ReactNode;
  maxWidth?: string;
};

const Modal: React.FC<Props> = ({ onClose, children, maxWidth }) => {
  const divRef = useRef(null);
  useOnClickOutside(divRef, onClose);
  return (
    <div ref={divRef} className="modal open">
      <div className="container" style={{ maxWidth: maxWidth ? maxWidth : '' }}>
        <span className="fas fa-times close" onClick={onClose} />
        {children}
      </div>
    </div>
  );
};

export default Modal;
