import * as H from 'history';

type Url = {
  base: string;
  port: string;
};

type UrlEnum = Record<string, Url>;

export const Urls: UrlEnum = {
  Unico: { base: 'unico', port: '4200' },
  Tributos: { base: 'tributos', port: '3003' },
  MinhaConta: { base: 'minha-conta', port: '3050' }
};

const getURLByAppAndPort = (url: Url): string => {
  return ['localhost', 'elotech.elotech.localnet'].includes(
    window.location.hostname
  )
    ? `${window.location.protocol}//localhost:${url.port}`
    : `${window.location.origin}/${url.base}`;
};

export const getValueFromUrlSearchParams = (
  location: Location | H.Location<any>,
  key: string
): string | null => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(key);
};

export const getUrl = (url: Url, param?: string): string => {
  let baseUrl = `${getURLByAppAndPort(url)}`;

  if (param) {
    baseUrl += `/${param}`;
  }

  if (url === Urls.MinhaConta) {
    return `${baseUrl}?referrer=${encodeURIComponent(
      document.title
    )}&referrer_uri=${encodeURIComponent(window.location.href)}`;
  }

  return baseUrl;
};

export const openUrl = (url: string): Promise<any> => {
  window.open(url, '_blank');
  return Promise.resolve(undefined);
};

export const getTenantIdentifier = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('.')) {
    return hostname.substring(0, hostname.indexOf('.'));
  }
  return hostname;
};
