import LogoFooter from '@elotech/arc/src/assets/img/common/logo-footer.svg';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';

import { AreasAgrupadas, KeycloakService, ModuloAreaDTO } from '../index';
import { HeaderContext } from './Header';
import Areas from './modulos/Areas';

export type ModulosContentProps = {
  getModulos?: () => Promise<ModuloAreaDTO[]>;
};

const agruparModulos = (modulos: ModuloAreaDTO[]): AreasAgrupadas => {
  return modulos.reduce((result, current) => {
    if (result[current.area]) {
      result[current.area].modulos = [...result[current.area].modulos, current];
    } else {
      result[current.area] = {
        descricao: current.descricaoArea,
        modulos: [current]
      };
    }
    return result;
  }, {});
};

const defaultModuloResolver = () => {
  return axios
    .get<ModuloAreaDTO[]>(`${KeycloakService.getUrl()}/usuario/me/modulos`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.getToken()}`,
        'x-tenant-host': window.location.host
      }
    })
    .then(response => response.data);
};

const agruparIdentificadores = (agrupados: AreasAgrupadas) =>
  Object.keys(agrupados).reduce((result, current) => {
    result[current] = true;
    return result;
  }, {});

const ModulosContent: React.FC<ModulosContentProps> = ({
  getModulos = defaultModuloResolver
}) => {
  const { activeModulos } = useContext(HeaderContext);
  const [modulosAgrupados, setModulosAgrupados] = useState<AreasAgrupadas>({});
  const [areasExpandidas, setAreasExpandidas] = useState<
    Record<string, boolean>
  >({});
  useEffect(() => {
    getModulos().then(response => {
      const agrupados = agruparModulos(response);
      setAreasExpandidas(agruparIdentificadores(agrupados));
      return setModulosAgrupados(agrupados);
    });
  }, [getModulos]);

  const handleExpand = (area: string) => {
    setAreasExpandidas(oldAreas => ({
      ...oldAreas,
      [area]: !oldAreas[area]
    }));
  };
  if (!activeModulos) {
    return null;
  }
  return (
    <div className={'features-modules active'}>
      <h2 className="title">Módulos</h2>
      <ul className="tab">
        <li className="active">
          <div className="features-body">
            <Areas
              areas={modulosAgrupados}
              onExpand={handleExpand}
              areasExpandidas={areasExpandidas}
            />
          </div>
        </li>
      </ul>
      <div className="footer-oxy-logo hidden-sm hidden-xs">
        <img src={LogoFooter} />
      </div>
    </div>
  );
};

export default ModulosContent;
