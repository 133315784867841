import { FormattedDate, Panel, SectionTitle, Table } from '@elotech/components';
import React from 'react';

import { EstornoParcelamentoRowDTO } from '../../types';

type Props = {
  estornos: EstornoParcelamentoRowDTO[];
};

const EstornosList: React.FC<Props> = ({ estornos }) => (
  <React.Fragment>
    <SectionTitle>Estornos</SectionTitle>
    <Panel isTable>
      <Table values={estornos} fixed>
        <Table.Column
          header="Data Estorno"
          value={(item: any) => (
            <FormattedDate value={item.dataEstorno} timeZone="UTC" />
          )}
        />
        <Table.Column header="Motivo" value={(item: any) => item.motivo} />
      </Table>
    </Panel>
  </React.Fragment>
);

export default EstornosList;
