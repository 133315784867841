/**
 * Essa função recebe um array, um item e uma função de comparação.
 * Caso a função de comparação retorne `true` para algum elemento do array, esse elemento
 *  é substituido pelo item.
 *
 * Caso nenhum elemento retorne `true` nas chamadas da função de comparação,
 *  o item informado é inserido no fim do array.
 *  @param arr O array a ser modificado
 *  @param value O valor a ser inserido no array
 *  @param compareFn Uma função para identificar qual elemento deve ser substituído pelo valor informado
 *  @returns O array alterado
 **/

import { somarSemCasasDecimais } from './NumberUtils';
import { FieldsOfType } from '..';

export const addOrReplace = <T>(
  arr: T[],
  value: T,
  compareFn: (item: T, index: number, array: T[]) => boolean
): T[] => {
  const index = arr.findIndex(compareFn);

  if (index < 0) {
    return [...arr, value];
  }
  return [...arr.slice(0, index), value, ...arr.slice(index + 1)];
};

export const uniqueByProperty = <T>(
  array: T[],
  fieldName: FieldsOfType<T, number | string | boolean | undefined>
) => {
  const map = array.reduce(
    (map, current) => map.set(current[fieldName!], current),
    new Map()
  );
  return [...map.values()];
};

export const uniqueByKey = <T>(
  array: T[],
  keyGenerator: (item: T) => string
) => {
  const map = array.reduce(
    (map, current) => map.set(keyGenerator(current), current),
    new Map()
  );
  return [...map.values()];
};

export const sumByField = <T>(
  array: T[],
  fieldName: FieldsOfType<T, number | string>
): number =>
  array.reduce((total, current) => total + +(current[fieldName!] ?? 0), 0);

export const sumByFieldIgnoringDecimal = <T extends unknown>(
  array: T[],
  fieldName: FieldsOfType<T, number | string>,
  precision: number = 2
): number =>
  array.reduce<number>(
    (total, current) =>
      somarSemCasasDecimais(total, Number(current[fieldName] ?? 0), precision),
    0
  );
