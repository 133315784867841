import axios, { AxiosPromise } from 'axios';

import { ParametroGeralDTO } from '../type';

const url = '/api/parametros-gerais';

export const findOne = (
  modulo: string,
  parametro: string
): AxiosPromise<ParametroGeralDTO> =>
  axios.get(`${url}?modulo=${modulo}&parametro=${parametro}`);
