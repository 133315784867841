import { Alert, Container, Info, Loading } from '@elotech/components';
import { DeclaracaoService, ParametroService } from 'itbi-common/service';
import { Parametro } from 'itbi-common/type';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

const HomePage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [parametro, setParametro] = useState<Parametro>();
  const [
    hasDeclaracaoItbiAguardandoAceite,
    setHasDeclaracaoItbiAguardandoAceite
  ] = useState<boolean>(false);
  const history = useHistory();

  const findHasDeclaracaoItbiAguardandoAceite = () => {
    DeclaracaoService.hasDeclaracaoItbiAguardandoAceite()
      .then(response => setHasDeclaracaoItbiAguardandoAceite(response.data))
      .catch(error => {
        Alert.error(
          {
            title:
              'Erro ao buscar as declarações de ITBI que estão aguardando aceite'
          },
          error
        );
      });
  };

  useEffect(() => {
    setLoading(true);
    findHasDeclaracaoItbiAguardandoAceite();
    ParametroService.loadAllParametros()
      .then(response => setParametro(response.data))
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao buscar o conteúdo da tela inicial'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container title="Início" icon="home">
      <Loading loading={loading} />
      {hasDeclaracaoItbiAguardandoAceite && (
        <Info
          beaty
          type="primary"
          buttonLabel="Visualizar"
          onButtonClick={() =>
            history.push(
              '/declaracoes-itbi?filters==situacaoItbi_Igual=AGUARDANDO_ACEITE'
            )
          }
        >
          Existem Declarações de ITBI com situação "Aguardando Aceite" que
          necessitam de sua atenção.
        </Info>
      )}
      {parametro && (
        <div
          className="typography-size-16px ql-editor"
          dangerouslySetInnerHTML={{ __html: parametro.conteudoTelaInicial }}
        />
      )}
    </Container>
  );
};

export default HomePage;
