import {
  BasicInput,
  Button,
  Col,
  ErrorText,
  FormikInputNumber,
  Row,
  SectionTitle,
  SwitchButtons,
  Yup
} from '@elotech/components';
import { validateWithContext } from '@elotech/components/src/utils/FormikUtils';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import React from 'react';

import {
  Laudo,
  TIPO_IMOVEL_RURAL,
  TIPO_IMOVEL_URBANO,
  TipoImovel,
  tiposImovel
} from '../../type';
import { porExtenso } from '../../utils/NumeroExtenso';
import CadastroInput from './CadastroInput';
import PropriedadeFormRural from './PropriedadeFormRural';
import PropriedadeFormUrbano from './PropriedadeFormUrbano';

type Props = {
  editingValue?: Laudo;
  isOrigemServidor?: boolean;
  permiteItbiRural?: boolean;
  onCancel: () => void;
  onCheckDuplicados: (cadastro: string, tipoImovel: TipoImovel) => boolean;
  onConfirm: (value: Laudo) => void;
};

const emptyValue = ({
  cadastro: '',
  proprietario: '',
  quadra: '',
  lote: '',
  cep: '',
  endereco: '',
  cidade: '',
  numero: '',
  estado: '',
  observacao: '',
  condominio: '',
  descricaoCondominio: '',
  valorEstimado: '',
  tipoImovel: TIPO_IMOVEL_URBANO
} as unknown) as Laudo;

type LaudoYupContext = {
  onCheckDuplicados: (cadastro: string, tipoImovel: TipoImovel) => boolean;
};
const validationSchema = Yup.object().shape({
  valorEstimado: Yup.number()
    .required()
    .moreThan(0)
    .label('Valor Estimado'),
  cadastro: Yup.string()
    .required()
    .label('Cadastro Municipal')
    .test('cadastroDuplicado', 'Cadastro já se encontra no laudo!', function(
      cadastro
    ) {
      const { onCheckDuplicados } = this.options.context! as LaudoYupContext;

      const result = onCheckDuplicados?.(cadastro, this.parent.tipoImovel);
      return !result ?? true;
    })
    .test('cadastroImobiliarioErro', '', function() {
      if (
        this.parent.tipoImovel === TIPO_IMOVEL_URBANO &&
        this.parent.cadastroImobiliarioErro
      ) {
        return this.createError({
          path: this.path,
          message: this.parent.cadastroImobiliarioErro
        });
      }
      return true;
    })
    .test('cadastroRuralErro', '', function() {
      if (
        this.parent.tipoImovel === TIPO_IMOVEL_RURAL &&
        this.parent.cadastroRuralErro
      ) {
        return this.createError({
          path: this.path,
          message: this.parent.cadastroRuralErro
        });
      }
      return true;
    })
});

const PropriedadeSection: React.FC<Props> = ({
  editingValue = emptyValue,
  isOrigemServidor = false,
  permiteItbiRural = false,
  onCancel,
  onConfirm,
  onCheckDuplicados
}) => {
  const closeOnEsc = (event: React.KeyboardEvent) => {
    if (event.keyCode === 27) {
      onCancel();
    }
  };

  const onChangeTipoImovel = (value: string, formProps: FormikProps<Laudo>) => {
    formProps.setFieldValue('cadastro', '', true);
    formProps.setFieldValue('cadastroImobiliario', undefined, true);
    formProps.setFieldValue('cadastroRural', undefined, true);
    formProps.setFieldValue('tipoImovel', value, true);
  };

  return (
    <Formik<Laudo>
      onSubmit={(value, formikActions) => {
        onConfirm(value);
        formikActions.resetForm(emptyValue);
      }}
      initialValues={editingValue}
      enableReinitialize
      validationSchema={validationSchema}
      validate={validateWithContext(validationSchema, { onCheckDuplicados })}
    >
      {formProps => (
        <div onKeyDown={closeOnEsc}>
          <Row>
            {isOrigemServidor && permiteItbiRural && (
              <Col md={2}>
                <div className="form-group">
                  <label className="label">Tipo do Imóvel</label>
                  <SwitchButtons
                    value={formProps.values.tipoImovel}
                    onChange={(value: string) =>
                      onChangeTipoImovel(value, formProps)
                    }
                    name={'tipoImovel'}
                    options={tiposImovel}
                  />
                </div>
              </Col>
            )}
            <Col md={3}>
              <CadastroInput
                onCheckDuplicados={onCheckDuplicados}
                isOrigemServidor={isOrigemServidor}
              />
              <ErrorMessage name={`cadastro`}>
                {msg => <ErrorText>{msg}</ErrorText>}
              </ErrorMessage>
            </Col>
            <FormikInputNumber
              name={'valorEstimado'}
              label={'Valor Estimado'}
              size={3}
              decimalScale={2}
              allowNegative={false}
            />
            <Col
              md={isOrigemServidor && permiteItbiRural ? 4 : 6}
              className="form-group"
            >
              <label className="label" />
              {formProps.values.valorEstimado &&
                porExtenso(formProps.values.valorEstimado)}
            </Col>
          </Row>
          {formProps.values.tipoImovel === TIPO_IMOVEL_RURAL ? (
            <PropriedadeFormRural />
          ) : (
            <PropriedadeFormUrbano formProps={formProps} />
          )}
          <SectionTitle>Observações</SectionTitle>
          <Row>
            <BasicInput
              name={'observacao'}
              label={'Observação sobre o cadastro'}
              size={12}
            />
          </Row>
          <Row>
            <Col md={12} className="form-group">
              <Button
                color="positive"
                className="inline mt-xs mb-xs"
                onClick={formProps.submitForm}
              >
                Salvar
              </Button>
              <Button
                color="negative"
                className="inline mt-xs mb-xs"
                onClick={onCancel}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Formik>
  );
};

export default PropriedadeSection;
