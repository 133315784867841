import React from 'react';

const Widget = ({ title, icon, children }) => {
  return (
    <div className="panel widget widget-same-height">
      <span className="widget-icon">
        <em className={`${icon}`} />
      </span>
      <h2 className="panel-title">{title}</h2>
      <div className="panel-body">{children}</div>
    </div>
  );
};

export default Widget;
