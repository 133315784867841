import React, { ReactElement, ReactNode } from 'react';

type ItemProps = {
  date?: string;
  tag?: string;
  title?: string;
  status?: 'positive' | 'warning' | 'error';
  children?: ReactNode;
  onClick?: () => void;
};

const Item: React.FC<ItemProps> = () => null;

type ChildType = ReactElement<ItemProps>;

type Props = {
  children: ChildType | ChildType[];
  onClick?: () => void;
  showMore?: boolean;
} & Omit<React.AllHTMLAttributes<HTMLDivElement>, 'children'>;

const dateFormat = new Intl.DateTimeFormat('pt-BR', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
});
const hourFormat = new Intl.DateTimeFormat('pt-BR', {
  hour: '2-digit',
  minute: '2-digit'
});

const getDate = (date: string) => {
  return dateFormat.format(new Date(date));
};

const getHour = (date: string) => {
  return hourFormat.format(new Date(date));
};

const getStatus = (status: string) => {
  switch (status) {
    case 'warning':
      return 'bg-orange-60';
    case 'error':
      return 'bg-red-60';
    case 'positive':
    default:
      return '';
  }
};

const Timeline: React.FC<Props> & { Item: typeof Item } = ({
  children,
  onClick,
  showMore
}: Props) => {
  return (
    <div className="timeline-container">
      <ul className="timeline type2">
        {React.Children.map(children, (child, index) => (
          <>
            <li>
              <div className="datetime">
                {child.props.date ? (
                  <>
                    <b>{getDate(child.props.date!)}</b>
                    <small>{getHour(child.props.date!)}</small>
                  </>
                ) : (
                  <small>Sem data</small>
                )}
              </div>

              <div className="info">
                <div className="head">
                  <div
                    id={'timeLineTag' + index}
                    className={'tag ' + getStatus(child.props.status!)}
                  >
                    {child.props.tag ?? 'Sem tag'}
                  </div>
                  <div className="title">
                    {child.props.title ?? 'Sem título'}
                  </div>
                </div>
                <div className="description">{child.props.children}</div>
                {child.props.onClick && (
                  <div className="link" onClick={child.props.onClick}>
                    <i className="fa fa-chevron-right hint clean module-color top-right">
                      <div className="hint-content">Detalhes</div>
                    </i>
                  </div>
                )}
              </div>
            </li>
          </>
        ))}
      </ul>
      {showMore && (
        <div className="button-loader-top" onClick={onClick}>
          <div className="hint fa fa-plus-circle clean top-left module-color">
            <div className="hint-content">Carregar mais dados</div>
          </div>
        </div>
      )}
    </div>
  );
};

Timeline.Item = Item;

export default Timeline;
