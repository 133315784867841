import { ErrorText, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

const MountFields = ({ documento, onChangeField }) =>
  documento.campos.map(field => {
    if (field.tipoCampo) {
      const isNumber = field.tipoCampo.name === 'NUMBER';
      const TypeInput = isNumber ? NumberFormat : 'input';
      const inputProps = isNumber
        ? { allowNegative: false, decimalSeparator: ',' }
        : {};
      return (
        <div className="form-group col-md-3" key={field.id}>
          <label htmlFor={field.id}>{field.label}</label>

          <TypeInput
            id={field.id}
            type={field.tipoCampo.type}
            name={field.id}
            size={field.size}
            className={
              field.error ? 'error' : field.className ? field.className : ''
            }
            data-id={documento.id}
            value={field.value || ''}
            files={field.files}
            onChange={onChangeField}
            {...inputProps}
          />

          {field.error && <ErrorText>{field.errorMessage}</ErrorText>}
        </div>
      );
    }
    return null;
  });

const MountDocuments = ({ documentos, onChangeField, onChangeUploadFile }) =>
  documentos.map(documento => {
    return (
      <div className="row" key={documento.id}>
        <div className="form-group col-md-6">
          <label htmlFor={documento.id}>
            {documento.nome}
            {documento.instrucao && (
              <div className="hint inline clean module-color fa-exclamation-circle mobile sm">
                <div className="hint-content">{documento.instrucao}</div>
              </div>
            )}
          </label>

          <div className="file-uploader">
            <input
              type="file"
              data-id={documento.id}
              className={`file-uploader-input${
                documento.error ? ' error' : ''
              }`}
              id={documento.id}
              title="Clique ou arraste para anexar"
              onChange={onChangeUploadFile}
            />

            <label
              htmlFor={documento.id}
              className="input"
              data-title={
                documento.value && documento.value.length > 0
                  ? documento.value[0].name
                  : 'Clique ou arraste para anexar'
              }
            />

            <label htmlFor={documento.id} className="file-uploader-icon" />
            {documento.error && <ErrorText>{documento.errorMessage}</ErrorText>}
          </div>
        </div>
        <div>
          <MountFields documento={documento} onChangeField={onChangeField} />
        </div>
      </div>
    );
  });

const Documento = props => {
  const { onChangeField, onChangeUploadFile, documentos } = props;
  if (documentos.length > 0) {
    return (
      <div>
        <SectionTitle>Documentos do Imóvel</SectionTitle>
        <MountDocuments
          documentos={documentos}
          onChangeField={onChangeField}
          onChangeUploadFile={onChangeUploadFile}
        />
      </div>
    );
  }
  return null;
};

Documento.propTypes = {
  onChangeField: PropTypes.func.isRequired,
  onChangeUploadFile: PropTypes.func.isRequired,
  documentos: PropTypes.array
};

export default Documento;
