import React from 'react';

import { AreasAgrupadas } from '../../type';
import AreaItem from './AreaItem';

type Props = {
  areas: AreasAgrupadas;
  areasExpandidas: Record<string, boolean>;
  onExpand: (area: string) => void;
};

const Areas: React.FC<Props> = ({ areas, areasExpandidas, onExpand }) => {
  const { COMUM: comum, ...outrasAreas } = areas;

  return (
    <>
      {Object.entries(outrasAreas).map(([identificadorArea, area]) => {
        return (
          <AreaItem
            key={identificadorArea}
            onClick={onExpand}
            area={area}
            identificador={identificadorArea}
            open={areasExpandidas[identificadorArea]}
          />
        );
      })}
      {comum && (
        <AreaItem
          onClick={onExpand}
          area={comum}
          identificador={'COMUM'}
          open={areasExpandidas['COMUM']}
          hideTitle
        />
      )}
    </>
  );
};

export default Areas;
