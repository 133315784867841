import { FormikProps, validateYupSchema, yupToFormErrors } from 'formik';

import { Yup } from '../index';

export const validateWithContext = (schema: Yup.Schema<any>, context?: any) => (
  value: any
) => {
  return validateYupSchema(value, schema, false, context).catch(error => {
    if (error.name === 'ValidationError') {
      throw yupToFormErrors(error);
    }
    throw error;
  });
};

export const getErrorCount = (
  formProps: FormikProps<any>,
  schema: Yup.Schema<any>
): number => {
  const { fields } = schema.describe();

  return Object.keys(fields).filter(
    field =>
      (formProps.submitCount > 0 || formProps.touched[field]) &&
      Boolean(formProps.errors[field])
  ).length;
};

type MetaDataTab = {
  tabPath: string;
};

export const getMetaData = (
  field: string,
  schema: Yup.Schema<any>
): MetaDataTab | undefined => {
  const { fields } = schema.describe();

  if (fields[field]) {
    return fields[field].meta;
  }
};
