import {
  Checkbox,
  FormattedCurrency,
  Hint,
  HintContent,
  HintRow,
  Info,
  Panel,
  PanelFooter,
  PanelFooterItem,
  SectionTitle,
  Table
} from '@elotech/components';
import React from 'react';

import {
  DebitoPassivelParcelamentoDTO,
  ParametroParcelamento,
  ParcelamentoCadastroDTO
} from '../../../types';
import { Requerente } from './ParcelamentoFormPage';
import ParcelamentoIdentificacao from './ParcelamentoIdentificacao';

type Props = {
  cadastro: ParcelamentoCadastroDTO;
  requerente: Requerente;
  leis: ParametroParcelamento[];
  idLeiSelecionada?: number;
  idDebitosSelecionados: number[];
  debitosPassiveisParcelamento: Record<string, DebitoPassivelParcelamentoDTO[]>;
  onSelect: (lei: ParametroParcelamento, idDebitos: number[]) => void;
  valorTotalDebitosAbertos?: number;
};

type FooterLeiProps = {
  valorTotal: number;
  valorSelecionado: number;
  valorTotalDebitosAbertos: number;
};
const FooterLei: React.FC<FooterLeiProps> = ({
  valorTotal,
  valorSelecionado,
  valorTotalDebitosAbertos
}) => (
  <PanelFooter>
    <PanelFooterItem
      label="Valor Total Devido"
      value={<FormattedCurrency value={valorTotalDebitosAbertos} />}
    />
    <PanelFooterItem
      label="Valor Parcelável Pela Lei"
      value={<FormattedCurrency value={valorTotal} />}
    />
    <PanelFooterItem
      label="Valor Selecionado"
      value={<FormattedCurrency value={valorSelecionado} />}
    />
  </PanelFooter>
);

const getValorSelecionado = (
  debitos: DebitoPassivelParcelamentoDTO[],
  debitosSelecionados: Record<number, boolean>
) => {
  return getValorTotal(
    debitos.filter(debito => debitosSelecionados[debito.iddebito])
  );
};

const getValorTotal = (debitos: DebitoPassivelParcelamentoDTO[]) =>
  debitos.reduce((result, current) => result + current.valordebito, 0);

const getConcedeDescontoNoPagamento = (
  debitos: DebitoPassivelParcelamentoDTO[]
) => {
  return (
    debitos.findIndex(debito => debito.concedeDescontoNoPagamento === true) >= 0
  );
};

const LeisStep: React.FC<Props> = ({
  cadastro,
  requerente,
  leis,
  debitosPassiveisParcelamento,
  onSelect,
  idLeiSelecionada,
  idDebitosSelecionados,
  valorTotalDebitosAbertos
}) => {
  const debitosSelecionadosAgrupados: Record<
    number,
    boolean
  > = idDebitosSelecionados.reduce((result, current) => {
    result[current] = true;
    return result;
  }, {});

  const checkAll = (
    event: React.ChangeEvent<HTMLInputElement>,
    lei: ParametroParcelamento
  ) => {
    const { checked } = event.target;
    if (checked) {
      return onSelect(
        lei,
        (debitosPassiveisParcelamento[lei.id] ?? []).map(
          debito => debito.iddebito
        )
      );
    }
    if (lei.id === idLeiSelecionada) {
      return onSelect(lei, []);
    }
  };

  const checkItem = (
    event: React.ChangeEvent<HTMLInputElement>,
    lei: ParametroParcelamento,
    debito: DebitoPassivelParcelamentoDTO
  ) => {
    const { checked } = event.target;

    if (lei.id !== idLeiSelecionada) {
      return onSelect(lei, [debito.iddebito]);
    }

    const newSelectedItens = checked
      ? [...idDebitosSelecionados, debito.iddebito]
      : idDebitosSelecionados.filter(iddebito => iddebito !== debito.iddebito);

    onSelect(lei, newSelectedItens);
  };

  return (
    <>
      <ParcelamentoIdentificacao cadastro={cadastro} requerente={requerente} />
      {leis.length > 1 && (
        <Info classes={'mb-xs'}>
          É permitido utilizar apenas uma lei por parcelamento. Clique sobre a
          lei para expandir os débitos passíveis de parcelamento.
        </Info>
      )}
      <SectionTitle>Leis disponíveis para parcelamento</SectionTitle>
      {leis.map(lei => {
        const isLeiSelecionada =
          idLeiSelecionada === lei.id || leis.length === 1;
        const debitosPassiveis = debitosPassiveisParcelamento[lei.id] ?? [];
        const valorSelecionado = isLeiSelecionada
          ? getValorSelecionado(debitosPassiveis, debitosSelecionadosAgrupados)
          : 0;
        const valorTotal = getValorTotal(debitosPassiveis);
        const concedeDescontoNoPagamento = getConcedeDescontoNoPagamento(
          debitosPassiveis
        );
        return (
          <Panel
            collapsed={!isLeiSelecionada}
            expansible
            isTable
            title={lei.descricao}
            key={lei.id}
            highlight={isLeiSelecionada}
            onClickPanel={() => onSelect(lei, [])}
            footer={
              debitosPassiveis.length > 0 && (
                <FooterLei
                  valorTotalDebitosAbertos={
                    concedeDescontoNoPagamento
                      ? valorTotal
                      : valorTotalDebitosAbertos ?? 0
                  }
                  valorTotal={valorTotal}
                  valorSelecionado={valorSelecionado}
                />
              )
            }
          >
            <Table
              fixed
              values={debitosPassiveis}
              messageEmpty={
                'Esse cadastro não possui débitos que podem ser parcelados por essa lei.'
              }
              keyExtractor={item => item.iddebito}
            >
              <Table.Column<DebitoPassivelParcelamentoDTO>
                headerClassName="column-checkbox no-print"
                className="column-checkbox no-print"
                name="select-all-debitos"
                header={
                  <div className="hidden-xs">
                    <Checkbox
                      id={`checkAll-${lei.id}`}
                      data-testid={`checkAll-${lei.id}`}
                      checked={
                        isLeiSelecionada &&
                        debitosPassiveis.length > 0 &&
                        debitosPassiveis.every(
                          debito =>
                            debitosSelecionadosAgrupados[debito.iddebito]
                        )
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        checkAll(e, lei)
                      }
                    />
                  </div>
                }
                value={item => (
                  <Checkbox
                    id={`checkbox-${lei.id}-${item.iddebito}`}
                    data-testid={`checkbox-${lei.id}-${item.iddebito}`}
                    checked={
                      isLeiSelecionada &&
                      debitosSelecionadosAgrupados[item.iddebito]
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      checkItem(e, lei, item)
                    }
                  />
                )}
              />
              <Table.Column<DebitoPassivelParcelamentoDTO>
                header={'Ano'}
                value={item => item.exercicio}
              />
              <Table.Column<DebitoPassivelParcelamentoDTO>
                header="Dívida"
                value={item =>
                  `${item.guiarecolhimento} - ${item.guiarecolhimento_descr}`
                }
              />
              <Table.Column<DebitoPassivelParcelamentoDTO>
                header="Subdívida"
                value={item => item.subdivida}
              />
              <Table.Column<DebitoPassivelParcelamentoDTO>
                header="Valor Principal"
                headerClassName="right"
                className="right"
                value={item => (
                  <FormattedCurrency hideSymbol value={item.valorPrincipal} />
                )}
              />
              <Table.Column<DebitoPassivelParcelamentoDTO>
                header="Valor Acréscimos"
                headerClassName="right"
                className="right"
                value={item => (
                  <>
                    <Hint
                      classes={`inline clean module-color fa-exclamation-circle mobile sm bottom-right`}
                    >
                      <HintContent>
                        <HintRow>
                          Juros:
                          <FormattedCurrency value={item.valorJuros} />
                        </HintRow>
                        <HintRow>
                          Multa:
                          <FormattedCurrency value={item.valorMulta} />
                        </HintRow>
                        <HintRow>
                          Correção:
                          <FormattedCurrency value={item.valorCorrecao} />
                        </HintRow>
                      </HintContent>
                    </Hint>
                    <FormattedCurrency
                      hideSymbol
                      value={item.valorAcrescimos}
                    />
                  </>
                )}
              />
              <Table.Column<DebitoPassivelParcelamentoDTO>
                header="Valor Descontos"
                headerClassName="right"
                className="right"
                value={item => (
                  <FormattedCurrency hideSymbol value={item.valorDesconto} />
                )}
              />
              <Table.Column<DebitoPassivelParcelamentoDTO>
                header="Valor Total"
                headerClassName="right"
                className="right"
                value={item => (
                  <FormattedCurrency hideSymbol value={item.valordebito} />
                )}
              />
            </Table>
          </Panel>
        );
      })}
    </>
  );
};

export default LeisStep;
