import React from 'react';

type Props = {
  message?: string;
};

const ForbiddenPage: React.FunctionComponent<Props> = ({ message }) => {
  return (
    <div className="not-found" style={{ maxWidth: '500px' }}>
      <h1>
        <em className="fa fa-lock" />
      </h1>
      <h2>Você não possui permissão para acessar esta página!</h2>
      <p>{message}</p>
      <a href={document.baseURI}>
        <button
          className="btn module-color icon-right center mt-xs"
          type="button"
        >
          Volte ao Início <em className="fa fa-home" />
        </button>
      </a>
    </div>
  );
};

export default ForbiddenPage;
