import React from 'react';
import styled from 'styled-components';

type Props = {
  maxHeight: number;
  children: React.ReactNode;
};

const FixedHeightDiv = styled.div<{ maxHeight: number }>`
  max-height: ${props => props.maxHeight}vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const QuickViewFixedHeight: React.FC<Props> = ({ maxHeight, children }) => {
  return <FixedHeightDiv maxHeight={maxHeight}>{children}</FixedHeightDiv>;
};

export default QuickViewFixedHeight;
