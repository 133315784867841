import { SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const Observacao = props => {
  const { observacao, onChangeField } = props;
  return (
    <div>
      <SectionTitle>Observações</SectionTitle>
      <div className="form-group">
        <label htmlFor="observacaoInput">Observação sobre o requerimento</label>
        <input
          id="observacaoInput"
          data-cy="inputObservacao"
          type="textarea"
          name="observacaoCidadao"
          value={observacao}
          onChange={onChangeField}
        />
      </div>
    </div>
  );
};

Observacao.propTypes = {
  observacao: PropTypes.string,
  onChangeField: PropTypes.func.isRequired
};

export default Observacao;
