import {
  Alert,
  ExtraInfo,
  Loading,
  Panel,
  SearchPagination,
  SectionTitle,
  Switch,
  Table,
  useAuth,
  usePagedQuery
} from '@elotech/components';
import { ConcursoPremiadoService } from 'itbi-common/service';
import {
  ConcursoPremiadoResumoPorCpf,
  CuponsParticipante
} from 'itbi-common/type';
import React, { useEffect, useState } from 'react';
type Props = {
  concurso: ConcursoPremiadoResumoPorCpf;
};

export type CuponsParticipanteFilter = {
  idConcurso: number;
  cpf: number;
  somenteSorteados: boolean;
  cidade: string;
};

const ConcursoPremiadoCuponsPage: React.FC<Props> = ({ concurso }) => {
  const { user, tenantIdentifier } = useAuth();

  const [somenteSorteados, setSomenteSorteados] = useState<boolean>(false);

  const concursoNaoEncerrado =
    concurso.situacao === 'CUPONS_GERADOS' || concurso.situacao === 'PUBLICADO';

  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<CuponsParticipante, CuponsParticipanteFilter>({
    search: ConcursoPremiadoService.getCupons,
    onError: error => {
      Alert.error({ title: 'Erro ao buscar os dados de cupom.' }, error);
    }
  });

  useEffect(
    () => {
      doSearch({
        idConcurso: concurso.idConcurso,
        cpf: user.cpfCnpj,
        somenteSorteados: somenteSorteados,
        cidade: tenantIdentifier
      });
    },
    [concurso.idConcurso, user.cpfCnpj, somenteSorteados, tenantIdentifier] // eslint-disable-line react-hooks/exhaustive-deps
    /*Foi colocado o comentario  do lint pois não foi possivel resolver o loop de dependencia,
     * pois como esta usando um objeto de filtro ao inves de uma string, toda vez que passa nesse metodo ele altera a referencia,
     * mesmo passando os mesmos dados para o filtro, o que causa o doSearch do hook usePagedQuery sempre ser alterado pois ele
     * utiliza o ultimo filtro pesquisado como dependencia.*/
  );

  const onHandlePremiados = () => {
    setSomenteSorteados(!somenteSorteados);
  };

  return (
    <div>
      <Loading loading={loading} />
      <SectionTitle marginTop={'0px'}>Cupons</SectionTitle>
      <Panel isTable>
        <Table<CuponsParticipante>
          values={values}
          keyExtractor={item => `${item.id}`}
          messageEmpty={'Não há cupons para listar'}
        >
          <Table.Column<CuponsParticipante>
            header="Número"
            value={item => (
              <>
                <ExtraInfo
                  color={
                    concursoNaoEncerrado
                      ? 'neutral'
                      : item.premiado
                      ? 'positive'
                      : 'negative'
                  }
                  value={
                    concursoNaoEncerrado
                      ? 'Cupom em Fase de Apuração'
                      : item.premiado
                      ? 'Cupom Premiado'
                      : 'Não foi dessa vez'
                  }
                  letter={
                    concursoNaoEncerrado ? 'G' : item.premiado ? 'P' : 'N'
                  }
                />
                {item.numero}
              </>
            )}
          />
          <Table.Column<CuponsParticipante>
            header="Observação"
            value={item =>
              `${item.observacao !== undefined ? item.observacao : ''}`
            }
          />
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch}>
            {!concursoNaoEncerrado && (
              <div className="sisters">
                <label className="label">Premiados</label>
                <Switch
                  data-test-id="premiados-switch-button"
                  name="premiados"
                  active={somenteSorteados}
                  onChange={onHandlePremiados}
                />
              </div>
            )}
          </SearchPagination>
        )}
      </Panel>
    </div>
  );
};

export default ConcursoPremiadoCuponsPage;
