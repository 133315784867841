import classnames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';

import Button from '../buttons/Button';
import { Col, Row } from '../grid';
import { usePreviousState } from '../hooks';
import Icon from '../icons/Icon';
import { HeaderContext } from './Header';

export type ZoomOperation = 'INCREMENT' | 'DECREMENT' | 'RESET';
export type ContrastType =
  | 'NORMAL'
  | 'ESCURO'
  | 'PROTANOPIA'
  | 'DEUTERANOPIA'
  | 'TRITANOPIA';

const ContrastLabel: Record<ContrastType, string> = {
  NORMAL: 'Normal',
  ESCURO: 'Escuro',
  PROTANOPIA: 'Protanopia',
  DEUTERANOPIA: 'Deuteranopia',
  TRITANOPIA: 'Tritanopia'
};

const ContrastClasses: Record<ContrastType, string> = {
  NORMAL: 'normal',
  ESCURO: 'invert',
  PROTANOPIA: 'protanopia',
  DEUTERANOPIA: 'deuteranopia',
  TRITANOPIA: 'tritanopia'
};

type AccessibilityProps = {
  scaleIncrement?: number;
  zoomIncrement?: number;
  minZoomLevel?: number;
  maxZoomLevel?: number;
  reduced?: boolean;
  autoHeight?: boolean;
  hasReadOptions?: boolean;
};

type ContrastSwitchProps = {
  type: ContrastType;
  onClick: (type: ContrastType) => void;
  currentType: ContrastType;
};

const ContrastSwitch: React.FC<ContrastSwitchProps> = ({
  type,
  currentType,
  onClick
}) => {
  const switchClass = classnames('switch module-color', {
    active: type === currentType
  });
  return (
    <div className="switch-container form-group" onClick={() => onClick(type)}>
      <em className={switchClass} />
      <span className="switch-caption">{ContrastLabel[type]}</span>
    </div>
  );
};

const AccessibilityContent: React.FC<AccessibilityProps> = ({
  reduced = false,
  autoHeight = false,
  hasReadOptions = false,
  scaleIncrement = 0.03,
  zoomIncrement = 5,
  minZoomLevel = -3,
  maxZoomLevel = 3
}) => {
  const { activeAcessibilidade } = useContext(HeaderContext);
  const [zoomLevel, setZoomLevel] = useState<number>(0);
  const [contrasteOpen, setContrasteOpen] = useState<boolean>(false);
  const [
    currentContrastType,
    setCurrentContrastType,
    previousContrastType
  ] = usePreviousState<ContrastType>('NORMAL');

  const customFeaturesUserClasses = classnames('features-user accessibility', {
    active: activeAcessibilidade,
    reduced: reduced,
    'auto-height': autoHeight,
    'read-options': hasReadOptions
  });

  useEffect(() => {
    const newScale = 1 + zoomLevel * scaleIncrement;
    const newZoom = 100 + zoomLevel * zoomIncrement;
    if (zoomLevel === 0) {
      document.body.style['-moz-transform'] = '';
      document.body.style['-moz-transform-origin'] = '';
      document.body.style.zoom = '100%';
    } else {
      document.body.style['-moz-transform'] = `scale(${newScale})`;
      document.body.style['-moz-transform-origin'] = 'top center';
      document.body.style.zoom = `${newZoom}%`;
    }
  }, [zoomLevel]);

  useEffect(() => {
    document.body.classList.remove(ContrastClasses[previousContrastType]);
    document.body.classList.add(ContrastClasses[currentContrastType]);
  }, [currentContrastType]);

  const applyZoom = (increment: number) => {
    setZoomLevel(oldZoom => {
      const newZoom = oldZoom + increment;
      return newZoom >= minZoomLevel && newZoom <= maxZoomLevel
        ? newZoom
        : oldZoom;
    });
  };

  const onChangeZoom = (action: ZoomOperation) => {
    switch (action) {
      case 'INCREMENT':
        return applyZoom(1);
      case 'DECREMENT':
        return applyZoom(-1);
      case 'RESET':
        return setZoomLevel(0);
    }
  };

  if (contrasteOpen) {
    return (
      <div className={customFeaturesUserClasses}>
        <h2 className="title">
          <div
            className="read hint clean center-left neutral"
            onClick={() => setContrasteOpen(false)}
          >
            <Icon icon={'chevron-left'} />
            <div className="hint-content">Voltar</div>
          </div>
          Contraste
        </h2>
        <div className="features-group-container">
          <Row>
            <Col md={12}>
              <ContrastSwitch
                type={'NORMAL'}
                onClick={() => setCurrentContrastType('NORMAL')}
                currentType={currentContrastType}
              />
              <ContrastSwitch
                type={'ESCURO'}
                onClick={() => setCurrentContrastType('ESCURO')}
                currentType={currentContrastType}
              />
              <ContrastSwitch
                type={'PROTANOPIA'}
                onClick={() => setCurrentContrastType('PROTANOPIA')}
                currentType={currentContrastType}
              />
              <ContrastSwitch
                type={'DEUTERANOPIA'}
                onClick={() => setCurrentContrastType('DEUTERANOPIA')}
                currentType={currentContrastType}
              />
              <ContrastSwitch
                type={'TRITANOPIA'}
                onClick={() => setCurrentContrastType('TRITANOPIA')}
                currentType={currentContrastType}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="form-group">
              <Button
                color={'neutral'}
                position={'right'}
                text
                onClick={() => setCurrentContrastType('NORMAL')}
              >
                Restaurar Definições
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  return (
    <div className={customFeaturesUserClasses}>
      <h2 className="title">Acessibilidade</h2>
      <ul className="user-actions">
        <li className="no-link zoom">
          <Icon icon="search" className="icon" />
          Zoom
          <div className="btn-group right">
            <button
              className="btn neutral pt-none pb-none fa fa-plus"
              onClick={() => onChangeZoom('INCREMENT')}
            />
            <button
              className="btn neutral pt-none pb-none fa fa-minus"
              onClick={() => onChangeZoom('DECREMENT')}
            />
            <button
              className="btn neutral pt-none pb-none fa fa-expand"
              onClick={() => onChangeZoom('RESET')}
            />
          </div>
        </li>
        <li onClick={() => setContrasteOpen(true)}>
          <Icon icon="adjust" className="icon" />
          Contraste
        </li>
      </ul>
    </div>
  );
};

export default AccessibilityContent;
