import React from 'react';

type Props = {
  label: string;
  text: React.ReactNode;
};

const SimpleText: React.FC<Props> = props => {
  const { label, text } = props;
  return (
    <div className="form-group" style={{ padding: '0' }}>
      <label className="label">{label}</label>
      <p>{text}</p>
    </div>
  );
};

export default SimpleText;
