import axios, { AxiosPromise } from 'axios';

import { DebitoDTO } from '../type';

export const findExtratoDebitoByPropriedade = (
  tipoCadastro: string,
  cadastroGeral: string,
  searchParams: string,
  page: object
) =>
  axios.get(
    `/api/debitos/extrato?tipoCadastro=${tipoCadastro}&cadastroGeral=${cadastroGeral}&${searchParams}`,
    {
      params: { ...page }
    }
  );

export const search = (
  tipoCadastro: string,
  cadastroGeral: string
): AxiosPromise<DebitoDTO[]> => {
  return axios.get(
    `/api/debitos/agrupados/cidadao/?tipoCadastro=${tipoCadastro}&cadastroGeral=${cadastroGeral}`
  );
};
