import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';

import { useOnClickOutside } from '../hooks';
import Icon from '../icons/Icon';
import MenuGroup from './MenuGroup';
import MenuItem from './MenuItem';
import { SidebarToggleContext } from './SidebarToggle';

export const { Provider, Consumer } = React.createContext({ searchValue: '' });

const Sidebar = ({
  imgModuleMain,
  imgModule,
  imgModuleNewTheme,
  title,
  children
}) => {
  const { toggledSidebar, toggleSideBar } = useContext(SidebarToggleContext);
  const [searchValue, setSearchValue] = useState('');
  const [focusedSearchInput, setFocusedSearchInput] = useState(false);
  const [isHover, setHover] = useState(false);
  const [activeMenu, setActiveMenu] = useState('');
  const asideRef = useRef(null);
  const searchRef = useRef(null);
  useOnClickOutside(asideRef, () => setFocusedSearchInput(false));

  const onChange = event => setSearchValue(event.target.value);

  const classes = classnames('', {
    sidebar: true,
    'float-type': toggledSidebar,
    new: true,
    'sidebar-toggle': toggledSidebar,
    hover: isHover,
    'focused-search': focusedSearchInput
  });

  return (
    <Provider
      value={{
        activeMenu,
        setActiveMenu,
        searchValue: searchValue,
        toggleSideBar: toggleSideBar,
        toggledSidebar: toggledSidebar,
        setHover,
        focusedSearchInput,
        setFocusedSearchInput
      }}
    >
      <aside ref={asideRef} className={classes}>
        <header className="sidebar-header">
          <div className="sidebar-module">
            <img
              src={imgModuleNewTheme}
              aria-hidden
              className="module-color"
              style={{ width: '40px' }}
              alt={`Módulo ${title}`}
            />
          </div>
          <div className="sidebar-title">{title}</div>
        </header>
        <div className="sidebar-search">
          <input
            ref={searchRef}
            id="sidebar-search-input"
            aria-label="input-search"
            placeholder="Buscar em Menu.."
            onFocus={() => setFocusedSearchInput(true)}
            onChange={onChange}
            autoComplete="off"
          />
          <i
            className="fa fa-search"
            aria-hidden="true"
            onClick={() => searchRef.current?.focus()}
          />
        </div>
        <ul className="sidebar-itens">
          <div>{children}</div>
        </ul>
        <button
          className="header-toggle-sidebar toggle-control hidden-xs hidden-sm"
          aria-label="Botão para expandir o menu"
          onClick={toggleSideBar}
        >
          <Icon
            className="mr-xs"
            icon={toggledSidebar ? 'angle-double-right' : 'angle-double-left'}
          />
          {toggledSidebar ? '' : 'Recolher Menu'}
        </button>
      </aside>
    </Provider>
  );
};

Sidebar.Group = MenuGroup;
Sidebar.Item = MenuItem;

Sidebar.propTypes = {
  imgModuleMain: PropTypes.any,
  imgModule: PropTypes.any,
  imgModuleNewTheme: PropTypes.any,
  title: PropTypes.string,
  showBadge: PropTypes.bool
};

export default Sidebar;
