import React from 'react';
import styled from 'styled-components';

type Props = {
  width: number;
  onIsListPage?: boolean;
  className: string;
  onClose(): void;
  children: React.ReactNode;
};

const DivWidthAndTop = styled.div<{ width: number; top?: number }>`
  &.quick-view {
    width: ${props => props.width}px;
  }
  &.quick-view.fixed {
    top: ${props => props.top}px;
  }
`;

const TOP_QUICKVIEW_FIXED = 129;

class QuickView extends React.Component<Props> {
  static defaultProps: Partial<Props> = {
    width: 450,
    className: '',
    onIsListPage: false
  };

  divRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      this.props.onClose();
    }
  };

  handleClick = (event: MouseEvent) => {
    if (
      this.divRef.current &&
      !this.divRef.current.contains(event.target as HTMLDivElement)
    ) {
      this.props.onClose();
    }
  };

  render() {
    const { onClose, className, children, width, onIsListPage } = this.props;
    return (
      <DivWidthAndTop
        width={width}
        top={onIsListPage ? TOP_QUICKVIEW_FIXED : undefined}
        className={`quick-view open ${className}`}
        ref={this.divRef}
      >
        <i className="fas fa-times no-print" onClick={onClose} />
        {children}
      </DivWidthAndTop>
    );
  }
}

export default QuickView;
