import { SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

import IdentificacaoSectionList from './IdentificacaoSectionList';

const Identificacao = ({
  dadosIdentificacao,
  adicionarComprador,
  editarComprador,
  deletarComprador,
  adicionarVendedor,
  editarVendedor,
  deletarVendedor,
  adicionarAnuente,
  editarAnuente,
  deletarAnuente,
  user,
  requerente,
  anuencia,
  vinculos,
  onViewPessoa,
  isServidor,
  validaRgComprador
}) => {
  return (
    <div>
      <SectionTitle>Identificação</SectionTitle>

      <IdentificacaoSectionList
        list={dadosIdentificacao.compradores}
        vendedores={dadosIdentificacao.vendedores}
        title="Comprador"
        adicionar={adicionarComprador}
        editar={editarComprador}
        onDelete={deletarComprador}
        comprador
        user={user}
        requerente={requerente}
        vinculos={vinculos}
        onViewPessoa={onViewPessoa}
        isServidor={isServidor}
        validaRgComprador={validaRgComprador}
      />
      <div className="mt-xs" />
      <IdentificacaoSectionList
        list={dadosIdentificacao.vendedores}
        compradores={dadosIdentificacao.compradores}
        title="Vendedor"
        adicionar={adicionarVendedor}
        editar={editarVendedor}
        onDelete={deletarVendedor}
        vendedor
        vinculos={vinculos}
        onViewPessoa={onViewPessoa}
        isServidor={isServidor}
      />
      <div className="mt-xs" />
      {anuencia && (
        <IdentificacaoSectionList
          list={dadosIdentificacao.anuentes}
          vendedores={dadosIdentificacao.vendedores}
          compradores={dadosIdentificacao.compradores}
          title="Anuente/Cedente"
          adicionar={adicionarAnuente}
          editar={editarAnuente}
          onDelete={deletarAnuente}
          anuente
          vinculos={vinculos}
          onViewPessoa={onViewPessoa}
          isServidor={isServidor}
        />
      )}
    </div>
  );
};

Identificacao.propTypes = {
  dadosIdentificacao: PropTypes.object.isRequired,
  adicionarComprador: PropTypes.func.isRequired,
  editarComprador: PropTypes.func.isRequired,
  deletarComprador: PropTypes.func.isRequired,
  adicionarVendedor: PropTypes.func.isRequired,
  editarVendedor: PropTypes.func.isRequired,
  deletarVendedor: PropTypes.func.isRequired,
  adicionarAnuente: PropTypes.func.isRequired,
  editarAnuente: PropTypes.func.isRequired,
  deletarAnuente: PropTypes.func.isRequired,
  validaRgComprador: PropTypes.bool.isRequired,
  anuencia: PropTypes.bool,
  vinculos: PropTypes.array.isRequired
};

export default Identificacao;
