import { UserService } from 'itbi-common/service';

import * as UserActions from './actions';

export const getUserData = (directly = false) => dispatch => {
  const callFunction = directly
    ? UserService.getUserDataDirectly()
    : UserService.getUserData();
  return callFunction.then(response =>
    dispatch(UserActions.getUserDataSuccess(response.data))
  );
};

export const checkUserExists = () => dispatch => {
  return UserService.userExists().then(response =>
    dispatch(UserActions.userExistsVerification(response.data))
  );
};

export const setUserExists = userExists =>
  UserActions.userExistsVerification({ userExists });

export const loadUserProfile = userProfile => dispatch => {
  return dispatch(UserActions.loadUserProfile(userProfile));
};
