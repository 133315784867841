import React from 'react';

type Props = {
  label: string;
  content: React.ReactNode;
  hideHorizontalRule?: boolean;
};

const WidgetDisplay: React.FC<Props> = ({
  label,
  content,
  hideHorizontalRule = false
}) => {
  return (
    <React.Fragment>
      {!hideHorizontalRule && <hr className="mt-xs mb-xs" />}
      <b>{label}: </b>
      {content}
    </React.Fragment>
  );
};

export default WidgetDisplay;
