import styled from 'styled-components/macro';

export type TextAreaProps = {
  resize?: 'none' | 'both' | 'horizontal' | 'vertical';
  placeholder?: string;
  rows?: number;
};

const TextArea = styled.textarea<TextAreaProps>`
  resize: ${props => props.resize || 'none'};
  ${({ rows }) => rows && 'height: unset'}
`;

export default TextArea;
