import {
  ActionButton,
  ActionsGroup,
  Field,
  Loading,
  PageRequest,
  Panel,
  RadioButton,
  SearchFilter,
  SearchPagination,
  SectionTitle,
  Table,
  TableChild,
  formatUtils
} from '@elotech/components';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ParcelamentoCadastroDTO } from '../../../types';

const searchFields: Field[] = [
  {
    label: 'Tipo Cadastro',
    name: 'tipoCadastro',
    type: 'ENUM',
    options: [
      {
        name: '',
        descricao: 'Todos'
      },
      {
        name: '1',
        descricao: 'Imobiliário'
      },
      {
        name: '2',
        descricao: 'Mobiliário'
      },
      {
        name: '3',
        descricao: 'Rural'
      },
      {
        name: '4',
        descricao: 'Cemitério'
      },
      {
        name: '9',
        descricao: 'Avulso'
      }
    ]
  },
  {
    label: 'Cadastro',
    name: 'cadastroGeral',
    type: 'NUMBER'
  },
  {
    label: 'Nome',
    name: 'pessoaNome',
    type: 'STRING'
  },
  {
    label: 'Débito vencido',
    name: 'vencido',
    type: 'BOOLEAN'
  },
  {
    label: 'Titularidade',
    name: 'tipoVinculo',
    type: 'ENUM',
    options: [
      { name: 'PRINCIPAL', descricao: 'Meus Cadastros' },
      { name: 'CORESPONSAVEL', descricao: 'Transferidos a Terceiros' }
    ]
  }
];

type Props = {
  loading: boolean;
  cadastros: ParcelamentoCadastroDTO[];
  select(cadastro: ParcelamentoCadastroDTO): void;
  cadastroSelecionado?: ParcelamentoCadastroDTO;
  searchCadastros(searchParam: string, page?: PageRequest): void;
  pagination?: any;
  paginationSearch(page: PageRequest): void;
};

type State = {
  searchParam: string;
  expandedValuesIndex: number[];
  expandedEndereco: boolean;
};

class CadastrosRequerenteTable extends React.Component<Props, State> {
  state: State = {
    searchParam: '',
    expandedValuesIndex: [],
    expandedEndereco: false
  };

  onViewEnderecos = (indexes: number) => {
    this.setState(oldState => {
      const newExpandedValues = oldState.expandedValuesIndex.includes(indexes)
        ? oldState.expandedValuesIndex.filter(value => value !== indexes)
        : [...oldState.expandedValuesIndex, indexes];

      return { expandedValuesIndex: newExpandedValues };
    });
  };

  onViewAllEnderecos = () => {
    const { cadastros } = this.props;
    this.setState(prevState => {
      if (
        prevState.expandedEndereco &&
        prevState.expandedValuesIndex.length > 0
      ) {
        return {
          expandedEndereco: false,
          expandedValuesIndex: []
        };
      }

      const newIndexes = cadastros.map((_, index) => index);
      return {
        expandedEndereco: true,
        expandedValuesIndex: newIndexes
      };
    });
  };

  renderEnderecos = (cadastro: ParcelamentoCadastroDTO, index: number) => {
    const { expandedValuesIndex } = this.state;

    if (!expandedValuesIndex.includes(index)) {
      return null;
    }
    return (
      <TableChild>
        <Panel isTable>
          <Table
            values={[cadastro]}
            keyExtractor={(item: ParcelamentoCadastroDTO) =>
              `${item.cep}-${item.setor}-${item.quadra}`
            }
          >
            <Table.Column<ParcelamentoCadastroDTO>
              header="Endereço"
              value={item => item.enderecoCompleto}
            />
            <Table.Column<ParcelamentoCadastroDTO>
              header="CEP"
              value={item => item.cep}
            />
            <Table.Column<ParcelamentoCadastroDTO>
              header="Cidade/UF"
              value={item =>
                `${item.cidade ?? ''}/${item.unidadeFederacao ?? ''}`
              }
            />
            <Table.Column<ParcelamentoCadastroDTO>
              data-test-id="setor"
              header={<FormattedMessage id="setor" defaultMessage="{setor}" />}
              headerClassName="right"
              className="right"
              value={item => item.setor}
              defaultHidden={cadastro.tipoCadastro !== 1}
            />
            <Table.Column<ParcelamentoCadastroDTO>
              data-test-id="quadra"
              header={
                <FormattedMessage id="quadra" defaultMessage="{quadra}" />
              }
              headerClassName="right"
              className="right"
              value={item => item.quadra}
              defaultHidden={cadastro.tipoCadastro !== 1}
            />
            <Table.Column<ParcelamentoCadastroDTO>
              data-test-id="lote"
              header={<FormattedMessage id="lote" defaultMessage="{lote}" />}
              headerClassName="right"
              className="right"
              value={item => item.lote}
              defaultHidden={cadastro.tipoCadastro !== 1}
            />
          </Table>
        </Panel>
      </TableChild>
    );
  };

  render() {
    const {
      loading,
      select,
      cadastros,
      cadastroSelecionado,
      searchCadastros,
      pagination,
      paginationSearch
    } = this.props;

    return (
      <React.Fragment>
        <Loading loading={loading} />
        <SectionTitle>Cadastros</SectionTitle>
        <Panel isTable>
          <SearchFilter fields={searchFields} search={searchCadastros} />
          <Table<ParcelamentoCadastroDTO>
            values={cadastros}
            striped={false}
            rowSelected={item => !!item.checked}
            keyExtractor={item => `${item.cadastroGeral}-${item.tipoCadastro}`}
            renderInnerComponent={this.renderEnderecos}
          >
            <Table.Column<ParcelamentoCadastroDTO>
              name="select-cadastros"
              header=""
              value={item => (
                <>
                  <RadioButton
                    options={item}
                    selectedValue={cadastroSelecionado?.id === item.id}
                    name="atendimento-select-cadastros"
                    id={`${item.tipoCadastro}-${item.cadastroGeral}`}
                    onChange={() => select(item)}
                  />
                </>
              )}
            />
            <Table.Column<ParcelamentoCadastroDTO>
              header="Cadastro"
              value={item => item.cadastroGeral}
            />
            <Table.Column<ParcelamentoCadastroDTO>
              header="Tipo Cadastro"
              value={item => item.tipoCadastroDescricao}
            />
            <Table.Column<ParcelamentoCadastroDTO>
              header="CPF/CNPJ"
              value={item => formatUtils.formatCpfCnpj(item.cnpjCpf)}
            />
            <Table.Column<ParcelamentoCadastroDTO>
              header="Nome"
              value={item => item.pessoaNome}
            />
            <Table.Column<ParcelamentoCadastroDTO>
              header="Débito"
              value={item => (item.vencido ? 'Vencido' : 'Não Vencido')}
            />
            <Table.Column<ParcelamentoCadastroDTO>
              name="actions-buttons"
              header={
                <ActionsGroup>
                  <ActionButton
                    key="all-local-button"
                    icon="map-marker-alt"
                    label="Todos Endereços"
                    onClick={this.onViewAllEnderecos}
                  />
                </ActionsGroup>
              }
              value={(_, index) => (
                <ActionsGroup>
                  <ActionButton
                    key="local-button"
                    icon="map-marker-alt"
                    label="Endereço"
                    onClick={() => this.onViewEnderecos(index)}
                  />
                </ActionsGroup>
              )}
            />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={paginationSearch}
            />
          )}
        </Panel>
      </React.Fragment>
    );
  }
}

export { CadastrosRequerenteTable as default };
