import PropTypes from 'prop-types';
import React from 'react';

const RadioButton = props => {
  return (
    <>
      {props.options instanceof Array ? (
        <>
          {props.options.map(option => (
            <React.Fragment key={option.key}>
              <input
                className="input-radio"
                id={`${option.key}-${props.name}-${props.id}`}
                type="radio"
                disabled={props.disabled}
                value={option.value}
                checked={option.value === props.selectedValue}
                onChange={props.onChange}
                name={props.name}
              />
              <label
                data-cy={`label${option.label}${props.id}`}
                className="mr-xs"
                htmlFor={`${option.key}-${props.name}-${props.id}`}
              >
                {option.label}
              </label>
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          <input
            type="radio"
            name={props.name}
            id={`${props.name}-${props.id}`}
            className="input-radio"
            defaultChecked={props.selectedValue}
            onChange={props.onChange}
            value={props.value}
            checked={props.selectedValue}
          />
          <label
            className="radio-button-item-label"
            htmlFor={`${props.name}-${props.id}`}
          >
            {props.label}
          </label>
        </>
      )}
    </>
  );
};

RadioButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  options: PropTypes.any,
  selectedValue: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

RadioButton.defaultProps = {
  label: '',
  disabled: false
};

export default RadioButton;
