import { FormikSelect, Row } from '@elotech/components';
import React from 'react';

import { useRequerentesComPermissao } from '../../state/user/hooks';
import { Usuario } from '../../type';

type Props = {
  onSelect?: (value: Usuario) => void;
};

const SeletorRequerenteCidadao: React.FC<Props> = ({ onSelect }) => {
  const requerentes = useRequerentesComPermissao();

  return (
    <Row>
      <FormikSelect
        size={6}
        name="requerente.cpfCnpj"
        label="Requerente"
        options={requerentes}
        getOptionLabel={value => value.nome}
        getOptionValue={value => value.cpfCnpj}
        onSelect={onSelect}
        fast={false}
      />
    </Row>
  );
};

export default SeletorRequerenteCidadao;
