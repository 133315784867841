import React from 'react';

type Props = {
  value: any;
  onChange(value: any): void;
  name: string;
  options: { key: string; label: string; value: any; disabled?: boolean }[];
  className?: string;
};
const SwitchButtons: React.FunctionComponent<Props> = ({
  value,
  onChange,
  options,
  name,
  className = ''
}) => {
  return (
    <div>
      <div className={`switch-buttons flat ${className}`}>
        {options.map(option => {
          return (
            <button
              key={option.key}
              type="button"
              className={`btn ${value === option.value ? 'active' : ''}`}
              onClick={() => onChange(option.value)}
              disabled={option.disabled}
            >
              {option.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default SwitchButtons;
