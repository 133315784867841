import PropTypes from 'prop-types';
import React from 'react';

const Step = props => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

Step.propTypes = {
  stepId: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
  showExitButton: PropTypes.bool,
  showPreviousButton: PropTypes.bool,
  showNextButton: PropTypes.bool,
  showFinishButton: PropTypes.bool,
  visible: PropTypes.bool,
  valid: PropTypes.bool,
  errorMessage: PropTypes.string,
  labelExitButton: PropTypes.string,
  labelPreviousButton: PropTypes.string,
  labelNextButton: PropTypes.string,
  labelFinishButton: PropTypes.string,
  children: PropTypes.element
};

Step.defaultProps = {
  showExitButton: false,
  showPreviousButton: true,
  showNextButton: true,
  showFinishButton: true,
  valid: true,
  visible: true,
  icon: ''
};

export default Step;
