import { EnumValue } from '../types/EnumType';

export const TipoCadastroEnum = {
  Todos: 0,
  Imobiliario: 1,
  Mobiliario: 2,
  Rural: 3,
  Cemitério: 4,
  ÁguaEsgoto: 5,
  Avulso: 9
};

export const TipoCadastroDescricaoEnum = {
  Imobiliario: 'Imobiliário',
  Mobiliario: 'Mobiliário',
  Rural: 'Rural',
  Cemitério: 'Cemitério',
  Avulso: 'Avulso'
};

type TipoCadastro = EnumValue & {
  issAvailable: boolean;
};

const TipoCadastroBase: TipoCadastro[] = [
  { codigo: 1, descricao: 'Imobiliário', issAvailable: false },
  { codigo: 2, descricao: 'Mobiliário', issAvailable: true },
  { codigo: 3, descricao: 'Rural', issAvailable: false }
];

export const TipoCadastro: TipoCadastro[] = [
  ...TipoCadastroBase,
  { codigo: 4, descricao: 'Cemitério', issAvailable: false },
  { codigo: 5, descricao: 'Água / Esgoto', issAvailable: false },
  { codigo: 9, descricao: 'Avulso', issAvailable: true }
];

export const TipoCadastroIndexacao: TipoCadastro[] = [
  { codigo: 0, descricao: 'Todos', issAvailable: false },
  ...TipoCadastroBase,
  { codigo: 9, descricao: 'Avulso', issAvailable: true }
];

export const TipoCadastroSemAgua: TipoCadastro[] = [
  ...TipoCadastroBase,
  { codigo: 4, descricao: 'Cemitério', issAvailable: false },
  { codigo: 9, descricao: 'Avulso', issAvailable: true }
];

export const getOptionLabelTiposCadastroCidadao = (id: number) => {
  switch (id) {
    case TipoCadastroEnum.Imobiliario:
      return 'Imóveis';
    case TipoCadastroEnum.Mobiliario:
      return 'Empresas/Autônomo';
    case TipoCadastroEnum.Rural:
      return 'Rural';
  }
};
