import React from 'react';

import Button from './Button';

type Props = {
  onAdd?(event: React.MouseEvent): void;
  onCancel?(event: React.MouseEvent): void;
  disabledAdd?: boolean;
  labelAdd?: string;
  labelCancel?: string;
};

const ButtonsAddCancel: React.FunctionComponent<Props> = ({
  onAdd,
  onCancel,
  disabledAdd = false,
  labelAdd = 'Adicionar',
  labelCancel = 'Cancelar'
}) => (
  <React.Fragment>
    <Button
      id="elo-button-add"
      data-test-id="elo-button-add"
      className="inline"
      iconPosition="left"
      onClick={onAdd}
      type="submit"
      disabled={disabledAdd}
    >
      <i className="fa fa-plus" />
      {labelAdd}
    </Button>
    <Button
      id="elo-button-cancel"
      data-test-id="elo-button-cancel"
      className="inline"
      iconPosition="left"
      onClick={onCancel}
      color="neutral"
    >
      <i className="fa fa-times" />
      {labelCancel}
    </Button>
  </React.Fragment>
);

export default ButtonsAddCancel;
