import {
  Alert,
  FAB,
  FormattedCurrency,
  FormattedDate,
  Widget,
  WidgetDisplay
} from '@elotech/components';
import { ParcelamentoService } from 'itbi-common/service';
import React from 'react';

import { DocumentoAutenticado } from '../../types';

type Props = {
  documento: DocumentoAutenticado;
  onPrintLoading(loading: boolean): void;
};

const InformacaoParcelamento: React.FC<Props> = ({
  documento,
  onPrintLoading
}) => {
  const onPrint = (idParcelamento: number) => {
    onPrintLoading(true);

    return ParcelamentoService.imprimirContrato(idParcelamento)
      .then(response => {
        window.open(response.data!.urlContrato, '_blank');
      })
      .catch(error => {
        Alert.error(
          { title: `Erro ao imprimir contrato de parcelamento` },
          error
        );
      })
      .finally(() => onPrintLoading(false));
  };

  return (
    <React.Fragment>
      <Widget title="Informações do Documento" icon="fab fa-wpforms">
        <article>
          <WidgetDisplay
            label="Tipo do documento"
            content={documento.descricao}
            hideHorizontalRule
          />
          <WidgetDisplay
            label="Nº Contrato/Ano"
            content={`${documento.numeroContrato}/${documento.anoContrato}`}
          />
          <WidgetDisplay
            label="Desc. Parâmetro"
            content={documento.descricaoParametro}
          />
          <WidgetDisplay label="Nº de Parcelas" content={documento.parcelas} />
          {documento.dataEntrada! && (
            <WidgetDisplay
              label="Data Entrada"
              content={
                <FormattedDate value={documento.dataEntrada} timeZone={'UTC'} />
              }
            />
          )}
          {documento.valorEntrada! > 0 && (
            <WidgetDisplay
              label="Valor Entrada"
              content={<FormattedCurrency value={documento.valorEntrada} />}
            />
          )}
          {documento.valorTotalParcelamento! > 0 && (
            <WidgetDisplay
              label="Total Parcelamento"
              content={
                <FormattedCurrency value={documento.valorTotalParcelamento} />
              }
            />
          )}
          {documento.dataParcelamento! && (
            <WidgetDisplay
              label="Data Parcelamento"
              content={
                <FormattedDate
                  value={documento.dataParcelamento}
                  timeZone={'UTC'}
                />
              }
            />
          )}
          <WidgetDisplay
            label="Situação"
            content={documento.descricaoSituacao}
          />
        </article>
      </Widget>
      <div className="btn-save">
        <FAB
          data-test-id="print-parcelamento"
          icon="print"
          iconColor="white"
          title="Imprimir"
          onClick={() => onPrint(documento.idParcelamento)}
        />
      </div>
    </React.Fragment>
  );
};

export default InformacaoParcelamento;
