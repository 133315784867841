import {
  Col,
  ErrorText,
  Masks,
  RadioButton,
  Row,
  SectionTitle
} from '@elotech/components';
import { Constantes } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';

class RequerenteForm extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    currentPerson: PropTypes.object.isRequired,
    isProprietarioAise: PropTypes.bool,
    onChangeInputValue: PropTypes.func.isRequired,
    onChangeInputCpfCnpj: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    errorMessages: PropTypes.object.isRequired,
    isServidor: PropTypes.bool
  };

  render() {
    const {
      title,
      currentPerson,
      errors,
      isProprietarioAise,
      onChangeInputValue,
      onChangeInputCpfCnpj,
      errorMessages,
      isServidor
    } = this.props;

    return (
      <>
        <SectionTitle>Dados do {title}</SectionTitle>
        <RadioButton
          label="Tipo"
          name="tipoPessoa"
          options={Constantes.optionsTipoPessoa}
          selectedValue={currentPerson.tipoPessoa}
          onChange={onChangeInputValue}
          disabled={isProprietarioAise}
        />
        <Row>
          <Col sm={2}>
            <label className="label">
              {currentPerson.tipoPessoa === 'FISICA' ? 'CPF' : 'CNPJ'}
            </label>
            <MaskedInput
              data-test-id="inputCpfCnpj"
              type="text"
              autoFocus
              name="cpfCnpj"
              mask={
                currentPerson.tipoPessoa === 'FISICA'
                  ? Masks.MASK_CPF
                  : Masks.MASK_CNPJ
              }
              showMask={Boolean(currentPerson.cpfCnpj)}
              onChange={onChangeInputCpfCnpj}
              value={currentPerson.cpfCnpj || ''}
              className={errors.cpfCnpj ? 'error' : ''}
              maxLength={currentPerson.tipoPessoa === 'FISICA' ? 15 : 19}
              disabled={isProprietarioAise}
            />
            {errors.cpfCnpj && <ErrorText>{errorMessages.cpfCnpj}</ErrorText>}
          </Col>
          <Col sm={4}>
            <label className="label">Nome</label>
            <input
              type="text"
              name="nome"
              className={errors.nome || errors.nomeLength ? 'error' : ''}
              onChange={onChangeInputValue}
              value={currentPerson.nome}
              disabled={isProprietarioAise}
              autoComplete="off"
            />
            {errors.nome && <ErrorText>{errorMessages.nome}</ErrorText>}
            {errors.nomeLength && (
              <ErrorText>{errorMessages.nomeLength}</ErrorText>
            )}
          </Col>
          <Col sm={4}>
            <label className="label">E-mail</label>
            <input
              data-test-id="inputEmail"
              type="email"
              name="email"
              className={!isServidor && errors.email ? 'error' : ''}
              onChange={onChangeInputValue}
              value={currentPerson.email}
              disabled={isProprietarioAise}
              autoComplete="off"
            />
            {!isServidor && errors.email && (
              <ErrorText>{errorMessages.email}</ErrorText>
            )}
          </Col>
          <Col sm={2}>
            <label className="label">Telefone</label>
            <NumberFormat
              format="(##) #####-####"
              name="telefone"
              className={errors.telefone ? 'error' : ''}
              onChange={onChangeInputValue}
              value={currentPerson.telefone}
              disabled={isProprietarioAise}
            />
            {errors.telefone && <ErrorText>{errorMessages.telefone}</ErrorText>}
          </Col>
        </Row>
      </>
    );
  }
}

export default RequerenteForm;
