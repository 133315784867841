import React, { CSSProperties, ReactNode } from 'react';

export type Props<T> = {
  value(value: T, index: number): ReactNode;
  header: ReactNode;
  className?: string;
  defaultHidden?: boolean;
  headerClassName?: string;
  style?: CSSProperties;
  name?: string;
  sortable?: boolean;
  headerOnly?: boolean;
  align?: 'left' | 'center' | 'right';
};

class Column<T> extends React.Component<Props<T>> {
  render() {
    return null;
  }
}

export default Column;
