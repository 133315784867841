import React from 'react';

import FormattedDate from '../../formatted/FormattedDate';
import FormattedDateTime from '../../formatted/FormattedDateTime';
import { FilterItem as FilterItemType } from './Types';

type Props = {
  removeFilter(filter: FilterItemType): void;
  filter: FilterItemType;
};

class FilterItem extends React.Component<Props> {
  onRemoveFilter = () => {
    const { removeFilter, filter } = this.props;

    removeFilter(filter);
  };

  getValue = () => {
    const { filter } = this.props;

    if (filter.field.type === 'ENUM') {
      const option = filter.field.options!.find(f => filter.value === f.name);
      if (option) {
        return option.descricao;
      }
    }

    if (filter.field.type === 'DATE' || filter.field.type === 'DATE_TRUNC') {
      return <FormattedDate value={filter.value} timeZone="UTC" />;
    }

    if (filter.field.type === 'DATETIME') {
      return <FormattedDateTime value={filter.value} />;
    }

    if (filter.field.type === 'AUTOCOMPLETE') {
      return filter.field.getOptionLabel!(filter.value) || filter.value;
    }

    if (filter.field.type === 'BOOLEAN') {
      return JSON.parse(filter.value) ? 'Sim' : 'Não';
    }

    return filter.value;
  };

  render() {
    const { filter } = this.props;

    return (
      <span key={filter.field.label} className="filter-tag">
        {`${filter.field.label} ${filter.operator.name.toLocaleLowerCase()} `}
        {this.getValue()}
        <i
          data-test-id="remove-button"
          className="fa fa-times remove"
          onClick={this.onRemoveFilter}
        />
      </span>
    );
  }
}

export default FilterItem;
