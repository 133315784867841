import { History, Location } from 'history';
import React, { useContext, useEffect } from 'react';
import { match, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { Icon } from '../index';
import { BreadCrumbStatus } from '../type';
import { BreadCrumbContext } from './BreadcrumbProvider';

type Props = {
  match: match<any>;
  history: History;
  location: Location;
  status?: BreadCrumbStatus;
};

const icons = {
  new: 'plus',
  edit: 'pencil-alt'
};
const names = {
  new: 'Novo',
  edit: 'Edição'
};

type InicioProps = {
  location: Location;
};
const Inicio: React.FC<InicioProps> = ({ location }) => (
  <li key="/">
    <Link to="/" aria-current={location.pathname === '/' ? 'page' : 'false'}>
      <Icon primary icon="home" />
      <span>Início</span>
    </Link>
  </li>
);

const BreadCrumb: React.FC<Props> = ({
  match,
  status = 'notype',
  history,
  location,
  children
}) => {
  const breadCrumbContext = useContext(BreadCrumbContext);
  useEffect(() => {
    breadCrumbContext.changeRoute(match, history, location);
  }, [match, history, location]);

  return (
    <nav aria-label={'Breadcrumbs'} className={'nav-breadcrumbs'}>
      <ol>
        <Inicio location={location} />
        {breadCrumbContext.activeRoutes.map((route, index) => {
          const isLastItem =
            index === breadCrumbContext.activeRoutes.length - 1;
          const useRouteValues = status === 'notype' || !isLastItem;

          return (
            <li key={route.path}>
              <Link
                to={route.matchedURL || route.path}
                aria-current={isLastItem ? 'page' : 'false'}
              >
                <Icon
                  primary
                  icon={useRouteValues ? route.icon : icons[status]}
                />
                <span>{useRouteValues ? route.title : names[status]}</span>
              </Link>
            </li>
          );
        })}
      </ol>
      <div className="nav-buttons" role="group">
        {children}
      </div>
    </nav>
  );
};

const withRouterComponent = withRouter<Props, typeof BreadCrumb>(BreadCrumb);
export { withRouterComponent as default, BreadCrumb };
