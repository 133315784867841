import axios, { AxiosPromise } from 'axios';
import {
  CertidaoParamDTO,
  DocumentoGenericoDTO,
  ImpressaoDTO
} from 'itbi-common/type';

const url = '/api/certidoes';

export const geraCertidaoContribuinte = (
  certidao: CertidaoParamDTO
): AxiosPromise<ImpressaoDTO> =>
  axios.post('${url}/gera-certidao-contribuinte', certidao);

export const geraCertidaoCadastro = (
  certidao: CertidaoParamDTO
): AxiosPromise<ImpressaoDTO> =>
  axios.post('${url}/gera-certidao-cadastro', certidao);

export const gerarCndPorTipo = (
  certidao: CertidaoParamDTO,
  tipoDocumento: number
): AxiosPromise<ImpressaoDTO> =>
  axios.post(`${url}/${tipoDocumento}/gerar-cnd`, certidao);

export const gerarDocumentoPorTipo = (
  documento: DocumentoGenericoDTO,
  tipoDocumento: number
): AxiosPromise<ImpressaoDTO> =>
  axios.post(`${url}/${tipoDocumento}/gerar-documento`, documento);
