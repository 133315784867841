import React, { ChangeEvent, useState } from 'react';
import { useEffect } from 'react';

const filterValidRegEx: RegExp = new RegExp(/^(\d+-\d+|\d+)$/);
const filterBetweenRegEx: RegExp = new RegExp(/^(\d+-\d+)$/);
const invalidCharactersRegEx: RegExp = new RegExp(/[^\d|,|-]/);

export type InputIntegerMultiValueValidate = {
  isValid: boolean;
  message: string;
};
export type InputIntegerMultiValueProps = {
  onChange?: (event: ChangeEvent<any>) => void;
  name: string;
  value?: string;
};

const findInvalidFilter = (filter: string): string => {
  return filter
    .split(',')
    .map(item => item.trim())
    .filter(item => !filterValidRegEx.test(item))
    .join(',');
};

export const validateFilter = (
  filter: string
): InputIntegerMultiValueValidate => {
  const invalidFilter: string = findInvalidFilter(filter);

  return {
    isValid: !invalidFilter,
    message: invalidFilter ? `Filtro(s) inválido(s) ${invalidFilter}` : ''
  };
};

const isSearchBetween = (value: string): boolean =>
  filterBetweenRegEx.test(value);

const mountFilterBase = (value: string): string[] => {
  return value
    .split(',')
    .map(item => item.trim())
    .filter(item => filterValidRegEx.test(item));
};

const mountFilterRsql = (value: string, field: string): string => {
  if (isSearchBetween(value)) {
    const searchBetween = value.split('-');
    return `${field}>=${searchBetween[0]};${field}<=${searchBetween[1]}`;
  }
  return `${field}==${value}`;
};

export const generateFilterRsql = (value: string, field: string): string => {
  const filters: string[] = mountFilterBase(value);
  return filters.map(item => mountFilterRsql(item, field)).join(',');
};

const InputIntegerMultiValue: React.FC<InputIntegerMultiValueProps> = props => {
  const { value, onChange, ...otherProps } = props;
  const [valueInput, setValueInput] = useState('');

  useEffect(() => {
    if (value !== valueInput) setValueInput(value !== undefined ? value : '');
  }, [value]);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const newValue: string = event.target.value.replace(
      invalidCharactersRegEx,
      ''
    );
    setValueInput(newValue);
    onChange &&
      onChange({ target: { name, value: newValue } } as ChangeEvent<
        HTMLInputElement
      >);
  };

  return (
    <input
      id={otherProps.name}
      type="text"
      autoComplete="off"
      value={valueInput}
      onChange={handleChange}
      {...otherProps}
    />
  );
};

InputIntegerMultiValue.displayName = 'InputIntegerMultiValue';

export { InputIntegerMultiValue };
