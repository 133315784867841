const getFields = listRequerentes => {
  return [
    {
      label: 'Número do Pedido',
      name: 'numero',
      type: 'NUMBER'
    },
    {
      label: 'Número do ITBI',
      name: 'codigoItbi',
      type: 'NUMBER'
    },
    {
      label: 'Ano do ITBI',
      name: 'exercicio',
      type: 'NUMBER'
    },
    {
      label: 'Número do Cadastro',
      name: 'cadastroImobiliario.cadastro',
      type: 'NUMBER'
    },
    {
      label: 'Data Lançamento',
      name: 'data',
      type: 'DATE'
    },
    {
      label: 'Valor Transação',
      name: 'valorTransacao',
      type: 'NUMBER'
    },
    {
      label: 'Valor Financiado',
      name: 'valorFinanciado',
      type: 'NUMBER'
    },
    {
      label: 'Situação ITBI',
      name: 'situacaoItbi',
      type: 'ENUM',
      options: [
        {
          name: 'RASCUNHO',
          descricao: 'Rascunho'
        },
        {
          name: 'AGUARDANDO_ANALISE',
          descricao: 'Aguardando Análise'
        },
        {
          name: 'ANALISANDO',
          descricao: 'Em Análise'
        },
        {
          name: 'INDEFERIDO',
          descricao: 'Indeferido'
        },
        {
          name: 'DEFERIDO',
          descricao: 'Deferido'
        },
        {
          name: 'AGUARDANDO_PAGAMENTO',
          descricao: 'Aguardando Pagamento'
        },
        {
          name: 'TRANSFERENCIA_MANUAL',
          descricao: 'Aguardando Transferência Manual'
        },
        {
          name: 'TRANSFERIDO',
          descricao: 'Transferido'
        },
        {
          name: 'AGUARDANDO_ACEITE',
          descricao: 'Aguardando Aceite'
        },
        {
          name: 'EM_CONTESTACAO',
          descricao: 'Em contestação'
        }
      ]
    },
    {
      label: 'Requerente',
      name: 'requerente',
      type: 'AUTOCOMPLETE',
      loadOptions: searchParameter => {
        const data = listRequerentes.filter(requerente =>
          requerente.nome.includes(searchParameter)
        );

        return Promise.resolve({ data });
      },
      getOptionValue: option => {
        return option.id;
      },
      getOptionLabel: option => option.nome,
      options: listRequerentes
    },
    {
      label: 'Comprador',
      name: 'compradores.pessoa.nome',
      type: 'STRING'
    },
    {
      label: 'Comprador CPF/CNPJ',
      name: 'compradores.pessoa.cpfCnpj',
      type: 'NUMBER'
    },
    {
      label: 'Vendedor',
      name: 'vendedores.pessoa.nome',
      type: 'STRING'
    },
    {
      label: 'Vendedor CPF/CNPJ',
      name: 'vendedores.pessoa.cpfCnpj',
      type: 'NUMBER'
    }
  ];
};

export { getFields };
