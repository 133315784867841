import React, { forwardRef } from 'react';

export type InputDateProps = React.InputHTMLAttributes<HTMLInputElement>;

const InputDate = forwardRef<HTMLInputElement, InputDateProps>((props, ref) => (
  <input
    ref={ref}
    id={props.name}
    type="date"
    max="9999-12-31"
    autoComplete="off"
    {...props}
  />
));

InputDate.displayName = 'InputDate';
export { InputDate };
