import React from 'react';

import useListener from './useListener';

export default function useOnClickOutside(
  ref: React.RefObject<HTMLDivElement>,
  handler: () => void
) {
  const listener = (event: Event) => {
    // Do nothing if clicking ref's element or descendent elements
    if (!ref.current || ref.current.contains(event.target as Node)) {
      return;
    }

    handler();
  };

  const listenerKeyDown = (event: Event) => {
    if (event instanceof KeyboardEvent) {
      if (event.keyCode === 27) {
        handler();
      }
    }
  };

  useListener(document, 'mousedown', listener);
  useListener(document, 'keydown', listenerKeyDown);
}
