import 'firebase/firestore';
import 'firebase/auth';

import firebase from 'firebase/app';
import chunk from 'lodash.chunk';

type DocumentData = firebase.firestore.DocumentData;

const config = {
  apiKey: 'AIzaSyBtmGZt9k-YZwfFTZjaoLH1j1-ZJG242L8',
  authDomain: 'oxy-cidadao-dev.firebaseapp.com',
  databaseURL: 'https://oxy-cidadao-dev.firebaseio.com',
  projectId: 'oxy-cidadao-dev',
  storageBucket: 'oxy-cidadao-dev.appspot.com',
  messagingSenderId: '1018599047430'
};

firebase.initializeApp(config);
const firestore = firebase.firestore();
const settings = {};
firestore.settings(settings);

const MAX_BATCH_SIZE = 500;

class FirebaseNotifications {
  collection: string;

  constructor(collection: string) {
    this.collection = collection;
  }

  login = (token: string) => {
    return firebase.auth().signInWithCustomToken(token);
  };

  logout = () => {
    firebase.auth().signOut();
  };

  loadNotificationsSnapshot = (
    size: number,
    userId: string,
    previousNotifications: () => DocumentData[],
    onNewNotifications: (data: DocumentData[]) => any,
    idCidadeGateway: number
  ) => {
    if (userId && idCidadeGateway) {
      let notifications = firestore
        .collection(`users/${userId}/${this.collection}`)
        .where('idCidade', '==', idCidadeGateway)
        .orderBy('time', 'desc')
        .limit(size);

      notifications.onSnapshot(snapshots => {
        const newNotifications = [...previousNotifications()];

        const firstSnapshot = newNotifications.length === 0;

        snapshots.docChanges().forEach(change => {
          const data = change.doc.data();
          if (change.type === 'added') {
            const index = newNotifications.findIndex(
              notification => notification.id === data.id
            );

            if (index < 0) {
              firstSnapshot
                ? newNotifications.push(data)
                : newNotifications.unshift(data);
            }
          }
          if (change.type === 'modified') {
            const index = newNotifications.findIndex(
              notification => notification.id === data.id
            );
            if (index >= 0) {
              newNotifications[index] = data;
            }
          }
        });
        onNewNotifications(newNotifications);
      });
    }
  };

  loadNotifications = async (
    size: number,
    userId: string,
    idCidadeGateway: number,
    onNewNotifications: (data: DocumentData[]) => any
  ) => {
    if (userId && idCidadeGateway) {
      let notifications = firestore
        .collection(`users/${userId}/${this.collection}`)
        .where('idCidade', '==', idCidadeGateway)
        .orderBy('time', 'desc')
        .limit(size);

      const snapshots = await notifications.get();

      onNewNotifications(snapshots.docs.map(snapshot => snapshot.data()));
    }
  };

  loadMoreNotifications = async (
    size: number,
    userId: string,
    lastNotificationId: string,
    idCidadeGateway: number
  ): Promise<DocumentData[]> => {
    return new Promise<DocumentData[]>(async resolve => {
      if (userId && idCidadeGateway) {
        const collection = firestore.collection(
          `users/${userId}/${this.collection}`
        );

        let notifications = collection
          .where('idCidade', '==', idCidadeGateway)
          .orderBy('time', 'desc')
          .limit(size);

        if (lastNotificationId) {
          const lastNotification = await collection
            .doc(lastNotificationId)
            .get();

          notifications = notifications.startAfter(lastNotification);
        }

        const snapshots = await notifications.get();

        resolve(snapshots.docs.map(snapshot => snapshot.data()));
      }
    });
  };

  getUnreadCount = (
    userId: string,
    idCidadeGateway: number,
    onCount: (count: number) => any
  ) => {
    if (userId && idCidadeGateway) {
      firestore
        .collection(`users/${userId}/${this.collection}`)
        .where('read', '==', false)
        .where('idCidade', '==', idCidadeGateway)
        .onSnapshot(snapshots => onCount(snapshots.docs.length));
    }
  };

  readNotification = (notificationId: string, userId: string) => {
    return firestore
      .collection(`users/${userId}/${this.collection}`)
      .doc(notificationId)
      .update({ read: true });
  };

  readAllNotifications = async (userId: string) => {
    const ref = firestore.collection(`users/${userId}/${this.collection}`);

    const notifications = await ref.where('read', '==', false).get();

    chunk(notifications.docs, MAX_BATCH_SIZE).map(dataRefs => {
      const batch = firestore.batch();

      dataRefs.forEach(doc => {
        batch.update(ref.doc(doc.id), { read: true });
      });

      return batch.commit();
    });
  };
}

export { FirebaseNotifications };
