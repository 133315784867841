import { FAB, FormattedDate, Widget, WidgetDisplay } from '@elotech/components';
import { FunctionUtils } from 'itbi-common/utils';
import React from 'react';

import { DocumentoAutenticado } from '../../types';

type Props = {
  documento: DocumentoAutenticado;
  onPrint(url: string): void;
};

const InformacaoDocumento: React.FC<Props> = ({ documento, onPrint }) => {
  return (
    <React.Fragment>
      <Widget title="Informações do Documento" icon="fab fa-wpforms">
        <article>
          <WidgetDisplay
            label="Tipo do documento"
            content={documento.descricao}
            hideHorizontalRule
          />
          <WidgetDisplay
            label="Número do documento"
            content={documento.documento}
          />
          {documento.dataEmissao && (
            <WidgetDisplay
              label="Data de emissão"
              content={<FormattedDate value={documento.dataEmissao} />}
            />
          )}
          {documento.dataValidade && (
            <WidgetDisplay
              label="Data de validade"
              content={<FormattedDate value={documento.dataValidade} />}
            />
          )}
          {documento.finalidade && (
            <WidgetDisplay label="Finalidade" content={documento.finalidade} />
          )}
          {documento.subTipo && (
            <WidgetDisplay
              label="Situação"
              content={FunctionUtils.getSubTipoDocumento(documento.subTipo)}
            />
          )}
        </article>
      </Widget>
      {documento.urlDocumento && (
        <div className="btn-save">
          <FAB
            icon="print"
            iconColor="white"
            title="Imprimir"
            onClick={() => onPrint(documento.urlDocumento)}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default InformacaoDocumento;
