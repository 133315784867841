import classnames from 'classnames';
import { getIn } from 'formik';
import React, { ChangeEvent } from 'react';
import { NumberFormatValues, SyntheticInputEvent } from 'react-number-format';

import { InputInteger } from '../inputs/InputInteger';
import BasicInput, { Props as BasicInputProps } from './BasicInput';
import { hasErrors } from './validate';

const normalizedChange = (onChange?: (e: React.ChangeEvent<any>) => void) => (
  { floatValue }: NumberFormatValues,
  { target: { name } }: SyntheticInputEvent
) => {
  const value = floatValue === undefined ? '' : floatValue;
  onChange && onChange({ target: { name, value: value } } as ChangeEvent<any>);
};

export const FormikInputInteger: React.FC<BasicInputProps> = props => (
  <BasicInput
    {...props}
    render={({ field: { onChange, ...fieldProps }, form }) => {
      const classes = classnames({
        readonly: props.readOnly,
        error: hasErrors(props.name, form)
      });

      return (
        <InputInteger
          {...fieldProps}
          onValueChange={normalizedChange(props.onChange || onChange)}
          id={fieldProps.name}
          readOnly={props.readOnly}
          className={classes}
        />
      );
    }}
  />
);
