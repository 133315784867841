import {
  Alert,
  Col,
  Container,
  FAB,
  FabSpeedDial,
  Loading,
  Row,
  UrlUtils
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Location } from 'history';
import { ParcelamentoService } from 'itbi-common/service';
import { DebitoBloquetoMobileDTO } from 'itbi-common/type';
import React from 'react';
import { match } from 'react-router';

import { ParcelamentoDTO } from '../../types';
import DebitosList from './DebitosList';
import EstornosList from './EstornoList';
import ParcelasList from './ParcelaList';
import ParcelamentoViewResumo from './ParcelamentoViewResumo';

type Props = {
  match: match<{ id: string }>;
  location: Location<{ showDestaque: boolean }>;
};

type State = {
  parcelamento?: ParcelamentoDTO;
  loading: boolean;
};

const SITUACAO_ESTORNADO = 2;

class ParcelamentoViewPage extends React.Component<Props, State> {
  state: State = {
    parcelamento: undefined,
    loading: false
  };

  componentDidMount() {
    this.getResumo();
  }

  getResumo() {
    const { match } = this.props;
    this.setState({ loading: true });
    ParcelamentoService.getResumo(match.params.id)
      .then((response: AxiosResponse<ParcelamentoDTO>) => {
        this.setState({ parcelamento: response.data });
      })
      .catch(error => {
        Alert.error(
          {
            title: `Erro ao carregar contrato de parcelamento ${match.params.id}`
          },
          error
        );
      })
      .finally(() => this.setState({ loading: false }));
  }

  isEstornado = (parcelamento: ParcelamentoDTO): boolean =>
    parcelamento &&
    parcelamento?.estornado &&
    parcelamento?.situacaoId === SITUACAO_ESTORNADO;

  onPrintCarne = (parcelamento: ParcelamentoDTO) => {
    this.setState({ loading: true });

    return ParcelamentoService.imprimirCarne(parcelamento.id)
      .then((response: AxiosResponse<any>) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window && window.open(fileURL);
      })
      .catch(error => {
        Alert.error({ title: `Erro ao imprimir carnê do parcelamento` }, error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  onPrintBoleto = (parcela: number) => {
    this.setState({ loading: true });

    const debitos: DebitoBloquetoMobileDTO[] = this.state.parcelamento!.debitosNovos.map(
      debito => ({
        idParcelamento: this.state.parcelamento!.id,
        idDebito: debito.idDebito,
        parcelas: [parcela]
      })
    );

    return ParcelamentoService.gerarBoletoAgrupado(debitos)
      .then((response: AxiosResponse<any>) => {
        UrlUtils.openUrl(response.data?.urlImpressao);
      })
      .catch((error: any) => {
        Alert.error({ title: 'Erro ao gerar o boleto' }, error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  onPrintContrato = (parcelamento: ParcelamentoDTO) => {
    this.setState({ loading: true });
    return ParcelamentoService.imprimirContrato(parcelamento.id)
      .then(response => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window && window.open(fileURL);
      })
      .catch(error => {
        Alert.error(
          { title: `Erro ao imprimir contrato de parcelamento` },
          error
        );
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { parcelamento, loading } = this.state;
    const isEstornado = this.isEstornado(parcelamento!);
    const { state } = this.props.location;

    return (
      <Container title="Acordo de Parcelamento" icon="file-contract">
        <Loading loading={loading} />
        {parcelamento && (
          <>
            <ParcelamentoViewResumo parcelamento={parcelamento} />
            <Row>
              <Col sm={12}>
                <ParcelasList
                  parcelas={parcelamento.parcelas}
                  onPrintBoleto={this.onPrintBoleto}
                  isEstornado={isEstornado}
                />
              </Col>
              <Col sm={6}>
                <DebitosList debitos={parcelamento.debitosOrigem} />
              </Col>
              <Col sm={6}>
                <EstornosList estornos={parcelamento.estornos} />
              </Col>
            </Row>
            <FabSpeedDial
              icon="ellipsis-v"
              title="Ações"
              showDestaque={state?.showDestaque}
              titleDestaque={
                <>
                  Parcelamento
                  <br />
                  Finalizado com Sucesso!
                </>
              }
              infoDestaque={
                'Clique aqui para Imprimir o Contrato e o Carnê para Pagamento.'
              }
            >
              {!isEstornado && (
                <FAB
                  data-test-id="btn-imprimir-contrato"
                  icon="print"
                  title="Imprimir Contrato"
                  onClick={() => this.onPrintContrato(parcelamento)}
                />
              )}
              {!isEstornado && (
                <FAB
                  data-testid="btn-imprimir-carne-boleto"
                  icon="receipt"
                  title={`Imprimir ${
                    parcelamento.parcelas.length === 1 ? 'Boleto' : 'Carnê'
                  }`}
                  onClick={() =>
                    parcelamento.parcelas.length === 1
                      ? this.onPrintBoleto(1)
                      : this.onPrintCarne(parcelamento)
                  }
                />
              )}
            </FabSpeedDial>
          </>
        )}
      </Container>
    );
  }
}

export default ParcelamentoViewPage;
