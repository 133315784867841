import React from 'react';

import ForbiddenPage from './ForbiddenPage';
import NotFoundPage from './NotFoundPage';
import UnknownErrorPage from './UnknownErrorPage';

type Props = {
  status?: number;
  message?: string;
  error?: any;
};

const ErrorPage: React.FunctionComponent<Props> = ({
  status,
  message = '',
  error = {}
}) => {
  switch (status) {
    case 401:
    case 403:
      return <ForbiddenPage message={message} />;
    case 404:
      return <NotFoundPage message={message} />;
    default:
      return <UnknownErrorPage message={message || error.message} />;
  }
};

export default ErrorPage;
