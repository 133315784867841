import { Widget, WidgetDisplay } from '@elotech/components';
import React from 'react';

import { DocumentoAutenticado } from '../../types';
import { TIPODOCUMENTO_BLOQUETO } from './AutenticarDocumento';

type Props = {
  documento: DocumentoAutenticado;
};

const isBoleto = (tipoDocumento: number) =>
  tipoDocumento === TIPODOCUMENTO_BLOQUETO;

const InformacaoCadastroRequerente: React.FC<Props> = ({ documento }) => {
  return (
    <Widget title="Informações do Cadastro/Requerente" icon="far fa-user">
      <article>
        <WidgetDisplay
          label={`Nome${
            isBoleto(documento.tipoDocumento) ? '' : ' do requerente'
          }`}
          content={documento.requerente}
          hideHorizontalRule
        />
        <WidgetDisplay
          label={`CPF/CNPJ${
            isBoleto(documento.tipoDocumento) ? '' : ' do requerente'
          }`}
          content={documento.cpfRequerente}
        />
        {documento.cadastroGeral && (
          <WidgetDisplay
            label="Cadastro"
            content={`${documento.tipoCadastro} - ${documento.cadastroGeral}`}
          />
        )}
        {documento.cnpjCpf && (
          <WidgetDisplay
            label="CPF/CNPJ Cadastro"
            content={documento.cnpjCpf}
          />
        )}
      </article>
    </Widget>
  );
};

export default InformacaoCadastroRequerente;
