import axios, { AxiosPromise } from 'axios';
import ServiceUtils from '../utils/ServiceUtils';

export const getAllFaq = (
  searchParams: string,
  page: object
): AxiosPromise<any> =>
  axios.get(`/admin/faq?${ServiceUtils.buildSearchParams(searchParams)}`, {
    params: { defaultSort: { sort: 'id' }, ...page }
  });

export const getAllList = (): AxiosPromise<any> =>
  axios.get(`/admin/faq/lista`);
