export enum CampoTipoEnum {
  TEXTO = 'TEXTO',
  INTEIRO = 'INTEIRO',
  DECIMAL = 'DECIMAL',
  DATA = 'DATA',
  MULTIPLA_ESCOLHA = 'MULTIPLA_ESCOLHA'
}

export enum TipoValidacaoEnum {
  OBRIGATORIO_SE_PRESENTE = 'OBRIGATORIO_SE_PRESENTE',
  OBRIGATORIO_SE_AUSENTE = 'OBRIGATORIO_SE_AUSENTE',
  PROIBIDO_SE_PRESENTE = 'PROIBIDO_SE_PRESENTE',
  PROIBIDO_SE_AUSENTE = 'PROIBIDO_SE_AUSENTE',
  OBRIGATORIO_SE_IGUAL = 'OBRIGATORIO_SE_IGUAL',
  PROIBIDO_SE_IGUAL = 'PROIBIDO_SE_IGUAL'
}
