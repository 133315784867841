import React, { useState } from 'react';

import useListener from './useListener';

export default function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useListener(window, 'resize', () => setWidth(window.innerWidth));

  return width;
}
