//name: key
export const permissaoCidadaoKeys = {
  faq: 'FAQ',
  history: 'HISTORY',
  declaracaoItbi: 'DECLARACAOITBI',
  laudo: 'LAUDO',
  autenticarDocumento: 'AUTENTICARDOCUMENTO',
  faleConosco: 'FALECONOSCO',
  propriedades: 'PROPRIEDADES',
  documentoEmitido: 'DOCUMENTOEMITIDO',
  parcelamento: 'PARCELAMENTO',
  processos: 'PROCESSOS',
  concursoPremiado: 'CONCURSOPREMIADO',
  certidaoNegativaDebito: 'CERTIDAONEGATIVADEBITO',
  certidaoComprobatoria: 'CERTIDAOCOMPROBATORIA',
  certidaoNumeracao: 'CERTIDAONUMERACAO'
};

export default permissaoCidadaoKeys;
