import { EnumValue } from '../types/EnumType';

type TipoPessoa = EnumValue & { sigla: string };

export const TipoPessoaEnum = {
  FISICA: 'FISICA',
  JURIDICA: 'JURIDICA'
};

export const TipoPessoa: TipoPessoa[] = [
  { codigo: 'FISICA', sigla: 'F', descricao: 'Pessoa Física' },
  { codigo: 'JURIDICA', sigla: 'J', descricao: 'Pessoa Jurídica' }
];

export const getTipoPessoaByCodigo = (codigo: string): TipoPessoa =>
  TipoPessoa.find(item => item.codigo === codigo)!;
