import Keycloak from 'keycloak-js';

let instance: Keycloak.KeycloakInstance;
const defaultValues: {} = {
  url: 'http://localhost:8080/auth',
  realm: 'OxyElotech'
};

/**
 * Adiciona as configurações de REALM e URL de acordo com os ambientes
 * de Desenvolvimento, Homologação e Produção, caso as mesmas não sejam informadas no arquivo
 * de configuração.
 *
 * @param configText As configurações do arquivo keycloak.json
 */
const customizeConfig = (
  configText: string | {},
  useKeycloakLocal: boolean = false,
  useRealmExterno: boolean = false
) => {
  const config: any = configText;
  if (typeof configText === 'string') return configText;

  if (!useKeycloakLocal) {
    const baseUrl = `/painel-api/api/auth/realm-config/${config.resource}`;
    return useRealmExterno ? `${baseUrl}?external=true` : baseUrl;
  }

  Object.keys(defaultValues).forEach(key => {
    if (!(key in config)) config[key] = defaultValues[key];
  });

  return config;
};

export const init = (
  config: string | {},
  useKeycloakLocal: boolean,
  useRealmExterno: boolean = false
): void => {
  if (instance === undefined) {
    instance = Keycloak(
      customizeConfig(config, useKeycloakLocal, useRealmExterno)
    );
    instance.onTokenExpired = () => console.debug('Token expired!');
    instance.onAuthRefreshSuccess = () => console.debug('Token refreshed!');
  }
};

export const getInstance = (): Keycloak.KeycloakInstance => instance;

export const isInitialized = (): boolean => instance !== undefined;

export const shouldNotUseToken = (url: string): boolean =>
  url.startsWith('/public');

export const getToken = () => instance.token;

export const refreshToken = ({
  minValidity = 5,
  url = '',
  idpHint = ''
}): Promise<boolean> => {
  const shouldNotRefreshToken = shouldNotUseToken(url);
  return new Promise((resolve, reject) => {
    if (shouldNotRefreshToken) {
      resolve();
    } else {
      if (instance.authenticated) {
        instance
          .updateToken(minValidity)
          .success(() => resolve(true))
          .error(error => {
            console.error('Erro ao renovar token!', error);
            instance
              .login({ idpHint })
              .success(() => resolve(true))
              .error(error => reject(error));
          });
      } else {
        instance
          .login({ idpHint })
          .success(() => resolve())
          .error(error => reject(error));
      }
    }
  });
};

/**
 * Executa o 'logout' da aplicação.
 * @param options Logout options.
 * @param options.redirectUri Especifica a URL de redirecionamento após executar o 'logout'.
 *
 * Exemplo de utilização:
 * ```
 * KeycloakService.logout({
 *   redirectUri: URL_PARA_REDIRECIONAMENTO
 * });
 * ```
 */
export const logout = (options?: any) => instance.logout(options);

export const accountManagement = () => instance.accountManagement();

export const getUrl = () =>
  `${instance.authServerUrl}/realms/${instance.realm}`;

export const hasRole = (role: string): boolean =>
  instance.hasResourceRole(role);

export default {
  customizeConfig,
  init,
  getInstance,
  shouldNotUseToken,
  getToken,
  refreshToken,
  logout,
  accountManagement,
  hasRole,
  getUrl,
  isInitialized
};
