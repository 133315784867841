import React, { ChangeEvent } from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
  SyntheticInputEvent
} from 'react-number-format';

import BasicInput from './BasicInput';

export type FormikInputNumberProps = NumberFormatProps & {
  name: string;
  label?: string;
  size?: number;
  hint?: string;
  hintClass?: string;
  fast?: boolean;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  readOnly?: boolean;
  suffixLabel?: string;
};

const normalizedChange = (onChange: (e: React.ChangeEvent<any>) => void) => (
  { floatValue }: NumberFormatValues,
  { target: { name } }: SyntheticInputEvent
) => {
  const value = floatValue === undefined ? '' : floatValue;
  onChange({ target: { name, value: value } } as ChangeEvent<any>);
};

export const FormikInputNumber: React.FC<FormikInputNumberProps> = ({
  name,
  label,
  size = 2,
  hint,
  hintClass,
  fast = true,
  decimalScale = 2,
  fixedDecimalScale = true,
  disabled = false,
  readOnly,
  suffixLabel,
  onBlur,
  ...numberFormatProps
}) => {
  return (
    <BasicInput
      name={name}
      label={label}
      size={size}
      hint={hint}
      hintClass={hintClass}
      fast={fast}
      disabled={disabled}
      suffixLabel={suffixLabel}
      render={({
        field: { onChange, className, onBlur: fieldOnBlur, ...fieldProps }
      }) => (
        <NumberFormat
          id={name}
          decimalSeparator=","
          thousandSeparator="."
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
          {...numberFormatProps}
          onValueChange={normalizedChange(onChange)}
          {...fieldProps}
          onBlur={e => {
            fieldOnBlur(e);
            onBlur?.(e);
          }}
          className={`${className}${readOnly ? ' readonly' : ''}`}
        />
      )}
    />
  );
};
