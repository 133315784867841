import { AxiosPromise } from 'axios';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import debounce from '../utils/Debounce';
import { scrollIntoView } from './functionScrollElement';
import Item from './Item';

export type AutoCompleteProps<T = any> = {
  value?: T | undefined;
  name: string;
  onSearch(params: string): AxiosPromise<any>;
  onSelect(name: string, value: T | undefined): void;
  onClear?: (name: string) => void;
  widthOptions?: number;
  className?: string;
  placeholder?: string;
  newValueLabel?: string;
  onCreateValue?: (texto: string) => AxiosPromise<T>;
  suffixIcon?: ReactNode;
} & Partial<DefaultProps<T>>;

type DefaultProps<T> = {
  getOptionLabel?: (option: T) => string;
  getDetailOptionLabel?: (option: T) => ReactNode;
  getOptionValue?: (option: T) => any;
  isDisabled?: boolean;
  loadOnFocus?: boolean;
  autoFocus?: boolean;
  /**
   * @deprecated Criar uma função no service que já faça o filtro
   */
  filter?: (value: string) => string;
};

type State<T = any> = {
  valueInput: string | undefined;
  loading: boolean;
  open: boolean;
  options: T[];
  selectedIndex?: number;
};

const TIME_WAIT = 300;

export default class AutoComplete<T> extends React.Component<
  AutoCompleteProps<T> & DefaultProps<T>,
  State
> {
  divRef = React.createRef<HTMLDivElement>();
  inputAutocomplete = React.createRef<HTMLInputElement>();
  ulRef = React.createRef<HTMLUListElement>();
  smartSearch?: (params: string) => Promise<any>;

  static Item = Item;

  static defaultProps = {
    getOptionLabel: (option: any) => option.descricao,
    getDetailOptionLabel: undefined,
    getOptionValue: (option: any) => option.id,
    isDisabled: false,
    loadOnFocus: true,
    autoFocus: false,
    filter: (search: string) => search
  };

  state: State<T> = {
    valueInput: undefined,
    loading: false,
    open: false,
    options: [],
    selectedIndex: undefined
  };

  handleClick = (event: Event) => {
    const { open } = this.state;

    if (
      open &&
      this.divRef.current &&
      !this.divRef.current.contains(event.target as Node)
    ) {
      this.setState({ open: false });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
    this.smartSearch = debounce(this.props.onSearch, TIME_WAIT);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  componentDidUpdate(oldProps: any) {
    const selected: HTMLLIElement | null =
      this.ulRef.current! && this.ulRef.current!.querySelector('li.selected');

    if (this.props.onSearch !== oldProps.onSearch) {
      this.smartSearch = debounce(this.props.onSearch, TIME_WAIT);
    }
    if (selected) {
      scrollIntoView(this.ulRef.current!, selected);
    }
  }

  loadOptions = () => {
    const { filter, isDisabled, onCreateValue } = this.props;
    const { valueInput } = this.state;

    if (!isDisabled) {
      this.setState({ loading: true, open: false });
      this.smartSearch!(filter!(valueInput || ''))
        .then(response => {
          const data = response.data.content
            ? response.data.content
            : response.data;
          this.setState({
            options: data,
            loading: false,
            open: data.length > 0 || !!onCreateValue
          });
        })
        .catch(() => this.setState({ loading: false }));
    }
  };

  clearValue = () => {
    const { name, onSelect } = this.props;
    this.setState({ valueInput: undefined, open: false });
    onSelect(name, undefined);
    this.focus();
  };

  focus = () => this.inputAutocomplete.current!.focus();

  onSelect = (option: T) => {
    const { name, onSelect: onSelectProps } = this.props;
    onSelectProps(name, option);
    this.setState({ valueInput: undefined, open: false });
  };

  onFocus = () => {
    const { loadOnFocus } = this.props;

    if (loadOnFocus) {
      this.loadOptions();
    }
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ valueInput: event.target.value }, () => this.loadOptions());
  };

  onKeyDown = (event: any) => {
    const { options, selectedIndex, open } = this.state;

    if (options.length > 0 && open) {
      if (selectedIndex !== undefined) {
        this.onKeyDownWhenSelectedIndexIsStarted(event, options, selectedIndex);
      } else {
        this.onKeyDownWhenSelectedIndexIsNotStarted(event);
      }
    }

    if (event.key === 'Escape') {
      const { value, getOptionLabel } = this.props;
      this.setState({
        open: false,
        valueInput: (value && getOptionLabel!(value)) || ''
      });
    }
  };

  onKeyDownWhenSelectedIndexIsStarted = (
    event: any,
    options: T[],
    selectedIndex: number
  ) => {
    let newIndex;
    switch (event.key) {
      case 'Tab':
        this.onSelect(options[selectedIndex]);
        break;
      case 'Enter':
        event.preventDefault();
        this.onSelect(options[selectedIndex]);
        break;
      case 'ArrowUp':
        newIndex = selectedIndex - 1;
        this.changeSelectedIndex(newIndex, options);
        break;
      case 'ArrowDown':
        newIndex = selectedIndex + 1;
        this.changeSelectedIndex(newIndex, options);
        break;
    }
  };

  changeSelectedIndex = (newIndex: number, options: T[]) => {
    if (newIndex < 0 || newIndex >= options.length) {
      newIndex = 0;
    }

    this.setState({ selectedIndex: newIndex });
  };

  onKeyDownWhenSelectedIndexIsNotStarted = (event: any) => {
    switch (event.key) {
      case 'ArrowDown':
        this.setState({ selectedIndex: 0 });
        break;
      case 'Tab':
        this.setState({ open: false });
    }
  };

  renderOption = (option: T, index: number) => {
    const { getOptionLabel, getOptionValue, getDetailOptionLabel } = this.props;
    const optionsClasses = classNames({
      'autocomplete-item': true,
      selected: index === this.state.selectedIndex
    });

    return (
      <li
        key={`option-${getOptionValue && getOptionValue(option)}`}
        className={optionsClasses}
        onClick={() => this.onSelect(option)}
      >
        {getDetailOptionLabel
          ? getDetailOptionLabel(option)
          : getOptionLabel && getOptionLabel(option)}
      </li>
    );
  };

  handleFocus = () =>
    this.inputAutocomplete.current &&
    this.inputAutocomplete.current === document.activeElement!;

  onClickNovo = () => {
    const { valueInput } = this.state;
    const { onCreateValue } = this.props;

    onCreateValue?.(valueInput!).then(response => {
      if (response && response.data) {
        this.onSelect(response.data);
      } else {
        this.clearValue();
      }
    });
  };

  renderSuffixIcon() {
    const { loading, open } = this.state;
    const { value, isDisabled, suffixIcon } = this.props;

    if (loading) {
      return (
        <div className="input-icon">
          <i className="fa fa-spinner fa-spin" />
        </div>
      );
    }

    if (!loading && value && !isDisabled) {
      return (
        <i
          title="Limpar"
          onClick={this.clearValue}
          className="fa fa-times input-icon"
        />
      );
    }

    if (suffixIcon && !open && !isDisabled) {
      return suffixIcon;
    }

    return null;
  }

  render() {
    const { valueInput, open, options } = this.state;
    const {
      className,
      value,
      name,
      isDisabled,
      placeholder = 'Digite a palavra chave',
      getOptionLabel,
      widthOptions,
      autoFocus,
      newValueLabel
    } = this.props;

    const allowCreate = !!this.props.onCreateValue;

    const inputClasses = classNames(className, {
      'has-input-icon': true,
      'autocomplete-input': true
    });

    const autocompleteClasses = classNames({
      autocomplete: true,
      open: open || allowCreate
    });

    let visibleValue = valueInput;
    if (visibleValue === undefined) {
      visibleValue = (value && getOptionLabel!(value)) || '';
    }

    const width = widthOptions ? { width: widthOptions } : {};

    return (
      <div ref={this.divRef}>
        <input
          id={name}
          ref={this.inputAutocomplete}
          className={inputClasses}
          disabled={isDisabled}
          placeholder={isDisabled ? '' : placeholder}
          autoComplete="off"
          autoCorrect="off"
          name={name}
          value={visibleValue}
          onChange={this.onChange}
          title={visibleValue}
          onKeyDown={this.onKeyDown}
          autoFocus={autoFocus}
          onFocus={this.onFocus}
        />
        {this.renderSuffixIcon()}
        {open && this.handleFocus() && (
          <ul className={autocompleteClasses} ref={this.ulRef} style={width}>
            {allowCreate ? (
              <li
                className="autocomplete-item item-new"
                onClick={this.onClickNovo}
                title="Adicionar"
              >
                <em className="fa fa-plus-circle" />
                {newValueLabel}
              </li>
            ) : null}
            {options.map((option, index) => this.renderOption(option, index))}
          </ul>
        )}
      </div>
    );
  }
}
