import { FieldProps } from 'formik';
import React, { FC } from 'react';

import { EditableInputProps } from '../../editableInput/EditableInput';
import EditableInputNumber from '../../editableInput/EditableInputNumber';
import { FormikEditableInput } from '../..';

export type FormikEditableInputProps = Partial<EditableInputProps<number>> & {
  fast?: boolean;
  decimalSeparator?: string;
  thousandSeparator?: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  allowNegative?: boolean;
  fixCursorOnFocus?: boolean;
};

const FormikEditableInputNumber: FC<FormikEditableInputProps> = props => (
  <FormikEditableInput
    {...props}
    render={({ field, form }: FieldProps) => {
      return (
        <EditableInputNumber
          {...field}
          {...props}
          initialValue={field.value}
          onConfirmChange={(value: number, name: string, fullPath: string) => {
            form.setFieldValue(fullPath, value);
            form.setFieldTouched(fullPath);
            props.onConfirmChange &&
              props.onConfirmChange(value, name, fullPath);
          }}
          decimalSeparator={props.decimalSeparator}
          thousandSeparator={props.thousandSeparator}
          decimalScale={props.decimalScale}
          fixedDecimalScale={props.fixedDecimalScale}
          allowNegative={props.allowNegative}
          defaultOpened={props.defaultOpened}
        />
      );
    }}
  />
);

export default FormikEditableInputNumber;
