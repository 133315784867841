import {
  Alert,
  BasicInput,
  FAB,
  FormikInputCpfCnpj,
  FormikInputInteger,
  FormikSelect,
  Loading,
  Panel,
  Row,
  Yup,
  useAuth
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import {
  CadastroGeralService,
  CertidaoService,
  DocumentoTipoService,
  FinalidadeService
} from 'itbi-common/service';
import { DocumentoFinalidadeDTO, DocumentoGenericoDTO } from 'itbi-common/type';
import React, { useEffect, useMemo, useState } from 'react';

import { getTipoPessoaByCodigo } from '../../enums';
import { TipoCadastroDTO } from '../../types/CadastroGeral';

type Props = {
  title: string;
  tipoDocumento: number;
};

const validationSchema = Yup.object().shape({
  tipoCadastro: Yup.number()
    .label('Tipo Cadastro')
    .required(),
  cadastroGeral: Yup.number()
    .label('Cadastro Geral')
    .required(),
  finalidade: Yup.number()
    .label('Finalidade')
    .required()
});

const DocumentoGenericoForm: React.FC<Props> = ({ title, tipoDocumento }) => {
  const [loading, setLoading] = useState(false);
  const [finalidades, setFinalidades] = useState<DocumentoFinalidadeDTO[]>([]);
  const [tiposCadastro, setTiposCadastro] = useState<TipoCadastroDTO[]>([]);
  const { user } = useAuth();
  const documento = useMemo<DocumentoGenericoDTO>(() => {
    return {
      web: true,
      usuario: 'WEB',
      pessoaNome: user.nome,
      pessoaCpfCnpj: user.cpfCnpj,
      tipoPessoa: getTipoPessoaByCodigo(user.tipoPessoa).sigla
    };
  }, [user]);

  useEffect(() => {
    FinalidadeService.getFinalidades(tipoDocumento)
      .then(response => setFinalidades(response.data))
      .catch(error =>
        Alert.error(
          { title: 'Erro ao buscar finalidade por tipo de certidão' },
          error
        )
      );
    DocumentoTipoService.findTiposCadastroByTipoDocumento(tipoDocumento)
      .then(response => setTiposCadastro(response.data))
      .catch(error =>
        Alert.error(
          { title: 'Erro ao buscar tipos de cadastro por tipo de certidão' },
          error
        )
      );
  }, [tipoDocumento]);

  const onSubmit = (documento: DocumentoGenericoDTO) => {
    setLoading(true);
    CadastroGeralService.getCadastroGeral(
      documento.tipoCadastro!,
      documento.cadastroGeral!
    )
      .then(response => {
        documento.idCadastro = response.data.idCadastro;
        CertidaoService.gerarDocumentoPorTipo(documento, tipoDocumento)
          .then(response => {
            setLoading(false);
            Alert.success({
              title: 'Certidão emitida com sucesso!',
              text: 'Confirme para visualizar o documento.'
            }).then(() => window.open(response.data.url));
          })
          .catch(error => {
            setLoading(false);
            Alert.error({ title: 'Erro ao emitir certidão' }, error);
          });
      })
      .catch(error => {
        setLoading(false);
        Alert.error(
          { title: 'Erro ao buscar cadastro ' + documento.cadastroGeral },
          error
        );
      });
  };

  return (
    <Panel title={title}>
      <Loading loading={loading} />
      <Formik
        enableReinitialize
        initialValues={documento}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<DocumentoGenericoDTO>) => (
          <>
            <Row>
              <FormikSelect
                size={6}
                fast={false}
                name="tipoCadastro"
                label="Tipo Cadastro"
                options={tiposCadastro}
                getOptionValue={value => value.id}
                getOptionLabel={value => value.descricao}
              />
              <FormikInputInteger
                size={6}
                name="cadastroGeral"
                label="Cadastro Geral"
              />
            </Row>
            <Row>
              <BasicInput
                size={6}
                disabled
                name="pessoaNome"
                label="Nome Requerente"
              />
              <FormikInputCpfCnpj
                size={6}
                disabled
                name="pessoaCpfCnpj"
                label="CPF/CNPJ Requerente"
              />
            </Row>
            <Row>
              <FormikSelect
                size={12}
                fast={false}
                name="finalidade"
                label="Finalidade"
                getOptionLabel={value => value.descricao}
                getOptionValue={value => value.id}
                options={finalidades}
              />
            </Row>
            <div className="btn-save">
              <FAB
                icon="check"
                title="Emitir Certidão"
                onClick={formikProps.submitForm}
              />
            </div>
          </>
        )}
      />
    </Panel>
  );
};

export default DocumentoGenericoForm;
