import { Notification } from 'react-notification-system';
import { Action } from 'redux';

import * as Types from './types';

export type NotificationIdType = string | number;

export type ActionType<T> = {
  type: string;
  payload: T;
};

export type HandleNotificationsType = (
  notificationIds: number | string
) => ActionType<number | string>;

export type NotificationEmitter = (
  notification: Notification
) => ActionType<Notification>;

export const showNotification: NotificationEmitter = notification => {
  notification.uid = notification.uid || new Date().toISOString();
  return {
    type: Types.SHOW_NOTIFICATION,
    payload: notification
  };
};

export const removeNotification = (notification: Notification) => ({
  type: Types.REMOVE_NOTIFICATION,
  payload: notification.uid
});

export const handleNotifications: HandleNotificationsType = notificationIds => ({
  type: Types.HANDLE_NOTIFICATIONS,
  payload: notificationIds
});

export const clearNotifications = () => ({
  type: Types.CLEAR_NOTIFICATIONS
});
