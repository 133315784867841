import { Col, QuickView, Row } from '@elotech/components';
import React from 'react';

import { TIPO_IMOVEL_RURAL } from '../../type';
import PropriedadeRuralQuickView from './PropriedadeRuralQuickView';
import PropriedadeUrbanoQuickView from './PropriedadeUrbanoQuickView';

const LotePropriedadeQuickView = ({ laudo, onClose }) => {
  if (laudo) {
    return (
      <QuickView onClose={onClose} className="fixed">
        <>
          {laudo.tipoImovel === TIPO_IMOVEL_RURAL ? (
            <PropriedadeRuralQuickView cadastroRural={laudo.cadastroRural} />
          ) : (
            <PropriedadeUrbanoQuickView
              cadastroImobiliario={laudo.cadastroImobiliario}
              desconsideraAreaConstruida={laudo.desconsideraAreaConstruida}
            />
          )}
          {laudo.observacao ? (
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <label className="label">Observações sobre o cadastro</label>
                  {laudo.observacao}
                </div>
              </Col>
            </Row>
          ) : null}
        </>
      </QuickView>
    );
  }
  return null;
};

export default LotePropriedadeQuickView;
