import { shallowEqual, useSelector } from 'react-redux';

import { Usuario } from '../../type';

const requerenteComPermissaoSelector = (state: any): Usuario[] => {
  const { currentUser } = state.user;
  const requerentes: Usuario[] = currentUser.usuariosProcurador || [];

  return currentUser.id && !requerentes.some(p => p.id === currentUser.id)
    ? [currentUser, ...requerentes]
    : [...requerentes];
};

const useRequerentesComPermissao = (): Usuario[] => {
  return useSelector(requerenteComPermissaoSelector, shallowEqual);
};

export { useRequerentesComPermissao };
