import React from 'react';
import { ReactNode } from 'react';

import BasicInput, { Props } from '../BasicInput';

type FormikSelectProps<T> = Props & {
  options: Array<T>;
  getOptionLabel: (value: T) => ReactNode;
  getOptionValue: (value: T) => number | string;
  onChange?: (event: React.ChangeEvent<any>) => void;
  onSelect?: (value: T) => void;
  noValue?: string;
};

const FormikSelect = <T extends {}>(props: FormikSelectProps<T>) => (
  <BasicInput
    {...props}
    render={({ field }) => (
      <select
        {...field}
        value={field.value}
        onChange={event => {
          (props.onChange ? props.onChange : field.onChange)(event);

          const value = props.options.find(
            (option: T) =>
              props.getOptionValue(option).toString() === event.target.value
          );

          props.onSelect && props.onSelect(value!);
        }}
        id={field.name}
      >
        <option value={props.noValue || ''}>Selecione</option>
        {props.options?.map((option, index) => (
          <option key={index} value={props.getOptionValue(option)}>
            {props.getOptionLabel(option)}
          </option>
        ))}
      </select>
    )}
  />
);

export default FormikSelect;
