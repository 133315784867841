import { useCallback, useReducer } from 'react';

type ShowFormState = {
  editingIndex?: number;
  open: boolean;
};

type OpenForm = {
  type: 'OPEN';
};

type CloseForm = {
  type: 'CLOSE';
};

type EditForm = {
  type: 'EDIT';
  index: number;
};

type ShowFormAction = OpenForm | CloseForm | EditForm;

const reducer = (
  state: ShowFormState,
  action: ShowFormAction
): ShowFormState => {
  switch (action.type) {
    case 'OPEN':
      return { open: true, editingIndex: undefined };
    case 'CLOSE':
      return { open: false, editingIndex: undefined };
    case 'EDIT':
      return { open: true, editingIndex: action.index };
  }
};

const initialState: ShowFormState = {
  open: false
};

const useShowForm = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openForm = useCallback(() => dispatch({ type: 'OPEN' }), []);
  const closeForm = useCallback(() => dispatch({ type: 'CLOSE' }), []);
  const editForm = useCallback(
    (index: number) => dispatch({ type: 'EDIT', index }),
    []
  );

  return {
    isOpen: state.open,
    editingIndex: state.editingIndex,
    openForm,
    closeForm,
    editForm
  };
};

export default useShowForm;
