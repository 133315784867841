import {
  Alert,
  Col,
  Container,
  Loading,
  Panel,
  Row
} from '@elotech/components';
import { FaqService, withService } from 'itbi-common/service';
import PropTypes from 'prop-types';
import React from 'react';

const Faq = ({ faq }) => (
  <Panel key={faq.id} insidePanel title={faq.pergunta} collapsed>
    <Row>
      <Col sm={12}>{faq.resposta}</Col>
    </Row>
  </Panel>
);

export class FaqList extends React.Component {
  static propTypes = {
    faqService: PropTypes.shape({
      getAllList: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    loading: false,
    perguntas: [],
    perguntasFiltradas: [],
    filtro: ''
  };

  componentDidMount() {
    this.loadPerguntas();
  }

  loadPerguntas = () => {
    const { faqService } = this.props;

    this.setState({ loading: true }, () => {
      faqService
        .getAllList()
        .then(response =>
          this.setState({
            perguntas: response.data,
            perguntasFiltradas: response.data,
            loading: false
          })
        )
        .catch(error => {
          Alert.error(
            { title: 'Erro ao carregar perguntas frequentes' },
            error
          );
          this.setState({ loading: false });
        });
    });
  };

  onChangeValue = ({ target: { value } }) => {
    const perguntasFiltradas = this.state.perguntas.filter(
      faq =>
        faq.pergunta.toUpperCase().includes(value.toUpperCase()) ||
        faq.resposta.toUpperCase().includes(value.toUpperCase())
    );

    this.setState({ perguntasFiltradas, filtro: value });
  };

  render() {
    const { loading, perguntasFiltradas, filtro } = this.state;

    return (
      <Container title="Perguntas Frequentes" icon="question">
        <Loading loading={loading} />
        <Row className="mb-xs">
          <Col sm={12}>
            <div className="form-group">
              <label className="label">Filtrar por perguntas e respostas</label>
              <input
                type="text"
                data-test-id="filtro"
                name="filtro"
                placeholder="Digite a palavra-chave"
                value={filtro}
                onChange={this.onChangeValue}
              />
            </div>
          </Col>
        </Row>
        {perguntasFiltradas.map(faq => (
          <Faq key={faq.id} faq={faq} />
        ))}
        {perguntasFiltradas.length === 0 && <div>Nenhuma pergunta</div>}
      </Container>
    );
  }
}

export default withService({
  faqService: FaqService
})(FaqList);
