import React from 'react';

import { Col, Row } from '../grid';

const TableReportHeader = props => {
  return (
    <Row>
      <Col md={12} xsHidden>
        <div className="group-header">{props.children}</div>
      </Col>
    </Row>
  );
};

TableReportHeader.propTypes = {};

export default TableReportHeader;
