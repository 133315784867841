import PropTypes from 'prop-types';
import React from 'react';

export default class EmptyRow extends React.Component {
  static propTypes = {
    message: PropTypes.node.isRequired,
    colSpan: PropTypes.number.isRequired,
    show: PropTypes.bool
  };

  static defaultProps = {
    message: 'Sem registros',
    colSpan: 1,
    show: true
  };

  render() {
    const { colSpan, message, show } = this.props;

    if (!show) {
      return null;
    }

    return (
      <tr>
        <td colSpan={`${colSpan}`} style={{ textAlign: 'center' }}>
          {message}
        </td>
      </tr>
    );
  }
}
