import React from 'react';
import { FormattedNumber } from 'react-intl';

const FormattedDecimal = ({ value = 0, type = 'decimal', ...props }) => (
  <FormattedNumber
    value={value}
    style={type} // eslint-disable-line
    currency="BRL"
    {...props}
  />
);

export default FormattedDecimal;
