export const formatCpfCnpj = cpfCnpj => {
  if (!cpfCnpj) {
    return '';
  }

  if (cpfCnpj.length === 11) {
    return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
  if (cpfCnpj.length === 14) {
    return cpfCnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    );
  }
  return cpfCnpj;
};

export const formatCelular = celular => {
  if (celular) {
    return celular.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3');
  }
  return '';
};

export const formatCnae = cnae => {
  if (cnae) {
    return cnae.replace(/(\d{4})(\d{1})(\d{2})/g, '$1-$2/$3');
  }
  return '';
};

export const formatServico = servico => {
  if (servico) {
    let service = servico.toString();
    return service.replace(/(\d{1,2})(\d{2})/g, '$1.$2');
  }
  return '';
};

export const formatCep = cep => {
  if (cep) {
    return cep.replace(/(\d{5})(\d{3})/g, '$1-$2');
  }
  return '';
};

export const pad = number => {
  if (number < 10) {
    return '0' + number;
  }
  return number;
};

export const getFormattedUTCDateWithZeroTime = (date, inc) => {
  if (typeof inc === 'number' && inc > 0) {
    date.setDate(date.getDate() + 1);
  }

  return `${pad(date.getUTCFullYear())}-${pad(date.getUTCMonth() + 1)}-${pad(
    date.getUTCDate()
  )}T00:00`;
};

export const round = (number, precision = 2) => {
  const factor = Math.pow(10, precision);
  const rounded = Math.round(number * factor);

  return rounded / factor;
};

export const getFormattedUTCDateWithNoTime = (date, inc) => {
  if (typeof inc === 'number' && inc > 0) {
    date.setDate(date.getDate() + 1);
  }

  return `${pad(date.getUTCFullYear())}-${pad(date.getUTCMonth() + 1)}-${pad(
    date.getUTCDate()
  )}`;
};

export const formatStringToDisplayDate = date => {
  const newDate = typeof date === 'string' ? new Date(date) : date;
  if (newDate) {
    return new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
      newDate
    );
  }
  return newDate;
};

export const isCpf = cpfCnpj => (cpfCnpj ? cpfCnpj.length === 11 : false);
