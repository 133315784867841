import { Operator } from './Types';

const STRING_OPERATORS: Operator[] = [
  { name: 'Contém', symbol: '==' },
  { name: 'Igual', symbol: '==' },
  { name: 'Diferente', symbol: '!=' }
];

const NUMBER_OPERATORS: Operator[] = [
  { name: 'Igual', symbol: '==' },
  { name: 'Diferente', symbol: '!=' },
  { name: 'Maior', symbol: '>' },
  { name: 'Menor', symbol: '<' },
  { name: 'Maior ou igual', symbol: '>=' },
  { name: 'Menor ou igual', symbol: '<=' },
  { name: 'Contido em', symbol: '=CIN=' },
  { name: 'Não contido em', symbol: '=COUT=' }
];

const DATE_OPERATORS: Operator[] = [
  { name: 'Igual', symbol: '==' },
  { name: 'Diferente', symbol: '!=' },
  { name: 'Maior', symbol: '>' },
  { name: 'Menor', symbol: '<' },
  { name: 'Maior ou igual', symbol: '>=' },
  { name: 'Menor ou igual', symbol: '<=' }
];

const DATE_TRUNC_OPERATORS: Operator[] = [
  { name: 'Igual', symbol: '=t=' },
  { name: 'Diferente', symbol: '=dt=' },
  { name: 'Maior', symbol: '>' },
  { name: 'Menor', symbol: '<' },
  { name: 'Maior ou igual', symbol: '>=' },
  { name: 'Menor ou igual', symbol: '<=' }
];

const DATETIME_OPERATORS: Operator[] = [
  { name: 'Maior', symbol: '>' },
  { name: 'Menor', symbol: '<' },
  { name: 'Maior ou igual', symbol: '>=' },
  { name: 'Menor ou igual', symbol: '<=' }
];

const ENUM_OPERATORS: Operator[] = [
  { name: 'Igual', symbol: '==' },
  { name: 'Diferente', symbol: '!=' }
];

const AUTOCOMPLETE_OPERATORS: Operator[] = [{ name: 'Igual', symbol: '==' }];

const BOOLEAN_OPERATORS: Operator[] = [{ name: 'Igual', symbol: '==' }];

const OPERATORS = {
  STRING: STRING_OPERATORS,
  NUMBER: NUMBER_OPERATORS,
  DATE: DATE_OPERATORS,
  DATE_TRUNC: DATE_TRUNC_OPERATORS,
  DATETIME: DATETIME_OPERATORS,
  ENUM: ENUM_OPERATORS,
  AUTOCOMPLETE: AUTOCOMPLETE_OPERATORS,
  BOOLEAN: BOOLEAN_OPERATORS
};

export default OPERATORS;
