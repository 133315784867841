import { SectionTitle, SwitchButtons } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

import RequerenteForm from '../RequerenteForm';
import DadosTransferencia from './DadosTransferenciaForm';
import Documento from './DocumentoForm';
import LocalizacaoImovelRural from './LocalizacaoImovelRural';
import LocalizacaoImovelUrbano from './LocalizacaoImovelUrbano';
import Observacao from './ObservacaoForm';
import Requerente from './Requerente';

const tiposImovel = [
  { key: 'URBANO', value: 'URBANO', label: 'Urbano' },
  { key: 'RURAL', value: 'RURAL', label: 'Rural' }
];
const Imovel = ({
  dadosImoveis,
  handleChangeFieldCurrency,
  handleChangeSelectTipoItbi,
  handleChangeSelectRequerente,
  handleChangeCadastroImobiliario,
  handleClearCadastroImobiliario,
  handleKeyDownCadastroImobiliario,
  handleBlurCadastroImobiliario,
  handleChangeFieldDocument,
  handleChangeField,
  errorMessages,
  handleChangeUploadFile,
  listRequerentes,
  isServidor,
  onChangeInputValue,
  onChangeInputCpfCnpj,
  permiteItbiRural,
  permiteBuscaPorInscricaoCadastral,
  handleChangeCadastroRural,
  handleSearchCadastroRural
}) => {
  return (
    <div>
      {isServidor ? (
        <RequerenteForm
          title="Requerente"
          errors={dadosImoveis.error.requerente}
          currentPerson={dadosImoveis.requerente}
          isProprietarioAise={false}
          errorMessages={errorMessages.requerente}
          onChangeInputValue={onChangeInputValue}
          onChangeInputCpfCnpj={onChangeInputCpfCnpj}
          isServidor={isServidor}
        />
      ) : (
        <Requerente
          requerente={dadosImoveis.requerente}
          listRequerentes={listRequerentes}
          onChangeSelect={handleChangeSelectRequerente}
          error={dadosImoveis.error}
          errorMessages={errorMessages}
        />
      )}
      <SectionTitle>Dados da Transferência</SectionTitle>
      <DadosTransferencia
        tipoItbi={dadosImoveis.tipoItbi}
        valorTransacao={dadosImoveis.valorTransacao}
        valorFinanciado={dadosImoveis.valorFinanciado}
        listTipoItbi={dadosImoveis.listTipoItbi}
        onChange={handleChangeFieldCurrency}
        onChangeSelect={handleChangeSelectTipoItbi}
        error={dadosImoveis.error}
        errorMessages={errorMessages}
      />
      <SectionTitle>Dados do Imóvel</SectionTitle>
      {permiteItbiRural && (
        <>
          <label className="label">Tipo do Imóvel</label>
          <SwitchButtons
            value={dadosImoveis.tipoImovel}
            onChange={value =>
              handleChangeField({ target: { value, name: 'tipoImovel' } })
            }
            name="tipoImovel"
            options={tiposImovel}
          />
        </>
      )}

      {dadosImoveis.tipoImovel === 'URBANO' ? (
        <LocalizacaoImovelUrbano
          permiteBuscaPorInscricaoCadastral={permiteBuscaPorInscricaoCadastral}
          cadastroImobiliario={dadosImoveis.cadastroImobiliario}
          onChangeField={handleChangeCadastroImobiliario}
          onClearCadastroImobiliario={handleClearCadastroImobiliario}
          onKeyDownCadastroImobiliario={handleKeyDownCadastroImobiliario}
          onBlurCadastroImobiliario={handleBlurCadastroImobiliario}
          imovelNotFoundByCadastro={dadosImoveis.imovelNotFoundByCadastro}
          imovelNotFoundByInscricaoCadastral={
            dadosImoveis.imovelNotFoundByInscricaoCadastral
          }
          error={dadosImoveis.error}
          loading={dadosImoveis.cadastroImobiliarioLoading}
          errorMessages={errorMessages}
          isServidor={isServidor}
        />
      ) : (
        <LocalizacaoImovelRural
          cadastro={dadosImoveis.cadastroRural}
          onSearch={handleSearchCadastroRural}
          onChangeCadastro={handleChangeCadastroRural}
          loading={dadosImoveis.cadastroRuralLoading}
          errorMessages={errorMessages}
          error={dadosImoveis.error}
          notFound={dadosImoveis.ruralNotFound}
        />
      )}

      <Documento
        documentos={dadosImoveis.documentos}
        onChangeField={handleChangeFieldDocument}
        onChangeUploadFile={handleChangeUploadFile}
      />

      <Observacao
        observacao={dadosImoveis.observacaoCidadao}
        onChangeField={handleChangeField}
      />
    </div>
  );
};

Imovel.propTypes = {
  dadosImoveis: PropTypes.object.isRequired,
  handleChangeFieldCurrency: PropTypes.func.isRequired,
  handleChangeSelectTipoItbi: PropTypes.func.isRequired,
  handleChangeSelectRequerente: PropTypes.func.isRequired,
  handleChangeCadastroImobiliario: PropTypes.func.isRequired,
  handleClearCadastroImobiliario: PropTypes.func.isRequired,
  handleKeyDownCadastroImobiliario: PropTypes.func.isRequired,
  handleBlurCadastroImobiliario: PropTypes.func.isRequired,
  handleChangeFieldDocument: PropTypes.func.isRequired,
  handleChangeField: PropTypes.func.isRequired,
  handleChangeUploadFile: PropTypes.func.isRequired,
  isServidor: PropTypes.bool,
  onChangeInputValue: PropTypes.func.isRequired,
  onChangeInputCpfCnpj: PropTypes.func.isRequired,
  listRequerentes: PropTypes.array
};

export default Imovel;
