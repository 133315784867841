import {
  Alert,
  BasicInput,
  FAB,
  FormikInputCpfCnpj,
  FormikInputInteger,
  FormikSelect,
  Loading,
  Panel,
  RadioButton,
  Row,
  Yup,
  useAuth
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import {
  CertidaoService,
  DocumentoTipoService,
  FinalidadeService,
  ParametroGeralService
} from 'itbi-common/service';
import { CertidaoParamDTO, DocumentoFinalidadeDTO } from 'itbi-common/type';
import React, { useEffect, useMemo, useState } from 'react';

import {
  TipoCadastroEnum,
  TipoCertidaoEnum,
  TipoPessoaEnum,
  getOptionLabelTiposCadastroCidadao,
  getTipoCertidaoByCodigo,
  getTipoCertidaoByDescricao
} from '../../enums';
import { TipoCadastroDTO } from '../../types/CadastroGeral';

type Props = {};

const tipoCertidaoCadastro = getTipoCertidaoByCodigo(
  TipoCertidaoEnum.certidaoCadastroMunicipal
);
const tipoCertidaoContribuinte = getTipoCertidaoByCodigo(
  TipoCertidaoEnum.certidaoContribuinte
);

const moduloTributos = '05';

const tiposCertidao = [
  {
    key: tipoCertidaoCadastro.descricao,
    value: tipoCertidaoCadastro.descricao,
    label: 'Certidão Negativa de Débitos pelo Cadastro Municipal'
  },
  {
    key: tipoCertidaoContribuinte.descricao,
    value: tipoCertidaoContribuinte.descricao,
    label: 'Certidão Negativa de Débitos pelo Contribuinte (CPF/CNPJ)'
  }
];

const validationSchema = Yup.object().shape({
  tipoCadastro: Yup.number().test(
    'tipoCadastro',
    'Tipo Cadastro é obrigatório',
    function(tipoCadastro) {
      if (this.parent.tipoCertidao === tipoCertidaoCadastro.descricao) {
        return tipoCadastro;
      }
      return true;
    }
  ),
  cadastroGeral: Yup.number().test(
    'cadastroGeral',
    'Cadastro Geral é obrigatório',
    function(cadastroGeral) {
      if (this.parent.tipoCertidao === tipoCertidaoCadastro.descricao) {
        return cadastroGeral;
      }
      return true;
    }
  ),
  finalidade: Yup.number()
    .label('Finalidade')
    .required(),
  cpfCnpj: Yup.string().test('cpfCnpj', 'CPF/CNPJ é obrigatório', function(
    cpfCnpj
  ) {
    if (this.parent.tipoCertidao === tipoCertidaoContribuinte.descricao) {
      return cpfCnpj;
    }
    return true;
  })
});

const CertidaoNegativaDebitoForm: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [finalidades, setFinalidades] = useState<DocumentoFinalidadeDTO[]>([]);
  const [tiposCadastro, setTiposCadastro] = useState<TipoCadastroDTO[]>([]);
  const [emiteCadMobiliario, setEmiteCadMobiliario] = useState(false);
  const [emiteCadRural, setEmiteCadRural] = useState(false);
  const { user } = useAuth();
  const initialValues = useMemo<CertidaoParamDTO>(
    () => ({
      tipoPessoa: TipoPessoaEnum.FISICA,
      tipoCertidao: tipoCertidaoCadastro.descricao,
      requerente: user.nome,
      cpfCnpjRequerente: user.cpfCnpj,
      tipoPessoaRequerente: user.tipoPessoa
    }),
    [user]
  );
  const tiposCadastroFiltrado = useMemo(
    () =>
      tiposCadastro.filter(
        value =>
          value.id === TipoCadastroEnum.Imobiliario ||
          (emiteCadMobiliario && value.id === TipoCadastroEnum.Mobiliario) ||
          (emiteCadRural && value.id === TipoCadastroEnum.Rural)
      ),
    [tiposCadastro, emiteCadMobiliario, emiteCadRural]
  );

  useEffect(() => {
    validarParametrosGerais();
    searchFinalidades(tipoCertidaoCadastro.codigo);
    searchTiposCadastro(tipoCertidaoCadastro.codigo);
  }, []);

  const searchFinalidades = (tipoCertidaoId: number) => {
    FinalidadeService.getFinalidades(tipoCertidaoId)
      .then(response => setFinalidades(response.data))
      .catch(error =>
        Alert.error(
          { title: 'Erro ao buscar finalidades por tipo de certidão' },
          error
        )
      );
  };

  const searchTiposCadastro = (tipoCertidaoId: number) => {
    DocumentoTipoService.findTiposCadastroByTipoDocumento(tipoCertidaoId)
      .then(response => setTiposCadastro(response.data))
      .catch(error =>
        Alert.error(
          { title: 'Erro ao buscar tipos de cadastro por tipo de certidão' },
          error
        )
      );
  };

  const validarParametrosGerais = (): Promise<[void, void]> => {
    return Promise.all([
      ParametroGeralService.findOne(
        moduloTributos,
        'EMITECERTIDAOCADMOBILIARIO'
      )
        .then(response => setEmiteCadMobiliario(response.data.valor === 'S'))
        .catch(error => {
          Alert.error(
            {
              title:
                "Erro ao verificar parâmetro geral 'EMITECERTIDAOCADMOBILIARIO'"
            },
            error
          );
        }),
      ParametroGeralService.findOne(moduloTributos, 'EMITECERTIDAOCADRURAL')
        .then(response => setEmiteCadRural(response.data.valor === 'S'))
        .catch(error => {
          Alert.error(
            {
              title: "Erro ao verificar parâmetro geral 'EMITECERTIDAOCADRURAL'"
            },
            error
          );
        })
    ]);
  };

  const searchFields = (tipoCertidaoName: string) => {
    const tipoCertidaoId = getTipoCertidaoByDescricao(tipoCertidaoName).codigo;
    searchFinalidades(tipoCertidaoId);
    tipoCertidaoId === TipoCertidaoEnum.certidaoCadastroMunicipal &&
      searchTiposCadastro(tipoCertidaoId);
  };

  const onChangeTipoCertidao = (
    event: React.ChangeEvent<HTMLInputElement>,
    formikProps: FormikProps<CertidaoParamDTO>
  ) => {
    const { value } = event.target;
    formikProps.resetForm({ ...initialValues, tipoCertidao: value });
    setFinalidades([]);
    setTiposCadastro([]);
    searchFields(value);
  };

  const getTipoPessoaByCpfCnpj = (cpfCnpj: string | undefined) => {
    if (!cpfCnpj) return undefined;
    return cpfCnpj.length === 11
      ? TipoPessoaEnum.FISICA
      : TipoPessoaEnum.JURIDICA;
  };

  const onSubmit = (certidao: CertidaoParamDTO) => {
    setLoading(true);
    certidao.tipoPessoa = getTipoPessoaByCpfCnpj(certidao.cpfCnpj);
    CertidaoService.gerarCndPorTipo(
      certidao,
      getTipoCertidaoByDescricao(certidao.tipoCertidao).codigo
    )
      .then(response => {
        setLoading(false);
        Alert.success({
          title: 'Certidão emitida com sucesso!',
          text: 'Confirme para visualizar o documento.'
        }).then(() => window.open(response.data.url));
      })
      .catch(error => {
        setLoading(false);
        Alert.error({ title: 'Erro ao emitir certidão' }, error);
      });
  };

  return (
    <Panel title="Certidão Negativa de Débitos">
      <Loading loading={loading} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={(formikProps: FormikProps<CertidaoParamDTO>) => (
          <>
            <Row>
              <BasicInput
                name="tipoCertidao"
                label="Tipo Certidão"
                size={12}
                render={({ field }) => (
                  <RadioButton
                    {...field}
                    onChange={event => onChangeTipoCertidao(event, formikProps)}
                    options={tiposCertidao}
                    selectedValue={field.value}
                  />
                )}
              />
            </Row>
            <Row>
              {formikProps.values.tipoCertidao ===
                tipoCertidaoCadastro.descricao && (
                <>
                  <FormikSelect
                    size={6}
                    fast={false}
                    name="tipoCadastro"
                    label="Tipo Cadastro"
                    options={tiposCadastroFiltrado}
                    getOptionValue={value => value.id}
                    getOptionLabel={value =>
                      getOptionLabelTiposCadastroCidadao(value.id)
                    }
                  />
                  <FormikInputInteger
                    size={6}
                    name="cadastroGeral"
                    label="Cadastro Geral"
                  />
                </>
              )}
              {formikProps.values.tipoCertidao ===
                tipoCertidaoContribuinte.descricao && (
                <FormikInputCpfCnpj size={12} name="cpfCnpj" label="CPF/CNPJ" />
              )}
            </Row>
            <Row>
              <BasicInput
                size={6}
                disabled
                name="requerente"
                label="Nome Requerente"
              />
              <FormikInputCpfCnpj
                size={6}
                disabled
                name="cpfCnpjRequerente"
                label="CPF/CNPJ Requerente"
              />
            </Row>
            <Row>
              <FormikSelect<DocumentoFinalidadeDTO>
                size={12}
                fast={false}
                name="finalidade"
                label="Finalidade"
                getOptionLabel={value => value.descricao}
                getOptionValue={value => value.sequencia}
                options={finalidades}
              />
            </Row>
            <div className="btn-save">
              <FAB
                icon="check"
                title="Emitir Certidão"
                onClick={formikProps.submitForm}
              />
            </div>
          </>
        )}
      />
    </Panel>
  );
};

export default CertidaoNegativaDebitoForm;
