import classnames from 'classnames';
import React, { useContext } from 'react';

import { useTenant } from '../hooks';
import {
  NotificationContext,
  NotificationContextProps
} from '../providers/NotificationProvider';
import { HeaderContext, HeaderContextProps } from './Header';

const NotificationButton: React.FC = () => {
  const headerContext: HeaderContextProps = useContext(HeaderContext);
  const {
    countNaoLidas,
    error,
    disableNotification
  }: NotificationContextProps = useContext(NotificationContext);

  const { tenantError } = useTenant();

  const className = classnames({
    active: headerContext.activeNotification
  });

  const customBubbleClasses = classnames('notifications', {
    active: headerContext.activeNotification
  });

  return !disableNotification ? (
    <li
      className={className}
      title="Notificações"
      onClick={headerContext.toggleNotification}
    >
      <i className="fa fa-bell" />

      {(tenantError || error) && (
        <span
          className="bubble-notification warning"
          title={tenantError || error}
        >
          0
        </span>
      )}
      {countNaoLidas > 0 && (
        <span className="bubble-notification">{countNaoLidas}</span>
      )}
      <small className="hidden-xs">Notificações</small>
    </li>
  ) : (
    <></>
  );
};

export default NotificationButton;
