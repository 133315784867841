import { FieldProps } from 'formik';
import React, { FC } from 'react';

import { EditableInputProps } from '../../editableInput/EditableInput';
import { EditableInputDate } from '../..';
import FormikEditableInput from './FormikEditableInput';

export type FormikEditableInputProps = Partial<EditableInputProps<string>> & {
  fast?: boolean;
};

const FormikEditableInputDate: FC<FormikEditableInputProps> = props => (
  <FormikEditableInput<string>
    {...props}
    render={({ field, form }: FieldProps) => (
      <EditableInputDate
        {...field}
        initialValue={field.value}
        onConfirmChange={(value: string, name: string, fullPath: string) => {
          form.setFieldValue(fullPath, value);
          form.setFieldTouched(fullPath);
          props.onConfirmChange && props.onConfirmChange(value, name, fullPath);
        }}
        defaultOpened={props.defaultOpened}
        autoFocus={props.autoFocus}
      />
    )}
  />
);

export default FormikEditableInputDate;
