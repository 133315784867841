import React from 'react';

import {
  InputIntegerMultiValue,
  validateFilter
} from '../inputs/InputIntegerMultiValue';
import BasicInput, { Props as BasicInputProps } from './BasicInput';
import { Yup } from '..';

export const validateFieldMultValueYup = function(
  this: Yup.TestContext,
  value: string = ''
) {
  const resultValidation = validateFilter(value);
  if (!resultValidation.isValid) {
    return this.createError({
      path: this.path,
      message: resultValidation.message
    });
  }
  return true;
};

export const FormikInputIntegerMultiValue: React.FC<BasicInputProps> = props => (
  <BasicInput
    hint="Separe com vírgula os intervalos de valores a serem selecionados. Exemplo: 1,2-5,6"
    {...props}
    render={({ field }) => (
      <InputIntegerMultiValue name={props.name} {...field} />
    )}
  />
);
