import { optionsTipoProcurador } from './Constantes';

export const compose = (...funcs: any[]) => (...args: any[]) =>
  funcs.reduce((result, nextFunc) => nextFunc(result(...args)));

export const getSubTipoDocumento = (subTipo: string): string => {
  switch (subTipo) {
    case 'P':
      return 'Positiva';
    case 'N':
      return 'Negativa';
    case 'X':
      return 'Positiva com efeito de negativa';
    default:
      return subTipo;
  }
};

export const getLabelPagamentoLaudo = (item: any) => {
  if (item.isento) {
    return 'Isento';
  }

  if (item.situacaoLaudo.nome === 'INDEFERIDO' && !item.pago) {
    return '';
  }

  return item.pago ? 'Pago' : 'Aguardando Pagamento';
};

export const getLabelTipoProcurador = (tipoProcurador: string) => {
  const itemProcurador = optionsTipoProcurador.find(
    item => item.key === tipoProcurador
  );
  return itemProcurador?.label;
};
