import {
  Alert,
  BasicInput,
  InputDate,
  Masks,
  RadioButton,
  Row,
  SectionTitle
} from '@elotech/components';
import { Constantes } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

import { UserService } from '../../../service';
import { validateCpfCnpj } from '../../../utils/ValidationUtils';

const StyledRadio = styled(RadioButton)`
  .radio-button-container {
    display: flex;
  }

  .radio-button-item-container {
    padding: 0 5px;
  }
`;

class DadosSectionForm extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    classError: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    exigeRg: PropTypes.bool.isRequired,
    isServidor: PropTypes.bool.isRequired
  };

  onChangeValueWithoutMask = ({ target }) => {
    const newValue = target.value.replace(/\D/g, '');

    this.props.setFieldValue(target.name, newValue);
  };

  onChangeCpfCnpj = event => {
    const { value } = event.target;
    const { isServidor } = this.props;
    this.onChangeValueWithoutMask(event);

    const valueSemMascara = value.replace(/\D/g, '');
    if (!validateCpfCnpj(valueSemMascara) || !isServidor) {
      return;
    }
    UserService.getUsuarioTributosByCpf(valueSemMascara)
      .then(response => {
        if (!response.data) {
          return;
        }
        const { setFieldValue } = this.props;
        setFieldValue('nome', response.data.nome, false);
        setFieldValue('email', response.data.email, false);
        setFieldValue('celular', response.data.telefone, false);
        setFieldValue('rg', '', false);
        setFieldValue('rgOrgaoEmissor', '', false);
        setFieldValue('rgUf', '', false);
        setFieldValue('rgDataEmissao', '', false);

        setFieldValue('cep', response.data.cep, false);
        setFieldValue('logradouro', response.data.logradouro, false);
        setFieldValue('numero', response.data.numero, false);
        setFieldValue('complemento', response.data.complemento ?? '', false);
        setFieldValue('bairro', response.data.bairro, false);
        setFieldValue('cidade', response.data.cidade, false);
        setFieldValue('uf', response.data.uf, false);
        setFieldValue('ibge', response.data.ibge, false);
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao buscar os dados da pessoa' }, error);
      });
  };

  render() {
    const { title, values, errors, touched, classError, exigeRg } = this.props;
    const isTipoPessoaFisica = values.tipoPessoa === 'FISICA';

    return (
      <React.Fragment>
        <SectionTitle>Dados do {title}</SectionTitle>
        <Row>
          <BasicInput
            name="tipoPessoa"
            render={({ field }) => (
              <StyledRadio
                id={`${title}`}
                label="Tipo"
                {...field}
                options={Constantes.optionsTipoPessoa}
                selectedValue={field.value}
              />
            )}
          />
        </Row>
        <Row>
          <BasicInput
            name="cpfCnpj"
            label={isTipoPessoaFisica ? 'CPF' : 'CNPJ'}
            render={({ field }) => (
              <MaskedInput
                id="cpfCnpj"
                {...field}
                onChange={this.onChangeCpfCnpj}
                autoFocus
                mask={isTipoPessoaFisica ? Masks.MASK_CPF : Masks.MASK_CNPJ}
                className={classError('cpfCnpj')}
                maxLength={isTipoPessoaFisica ? 15 : 19}
              />
            )}
          />
          <BasicInput
            name="nome"
            label="Nome"
            size={4}
            errors={errors}
            touched={touched}
          />
          <BasicInput
            name="email"
            label="E-mail"
            size={4}
            errors={errors}
            touched={touched}
          />
          <BasicInput
            name="celular"
            label="Celular"
            render={({ field }) => (
              <MaskedInput
                id="celular"
                {...field}
                guide
                onChange={this.onChangeValueWithoutMask}
                mask={Masks.MASK_TELEFONE}
                className={classError('celular')}
              />
            )}
          />
        </Row>
        {exigeRg && (
          <Row data-test-id="form-rg">
            <BasicInput
              name="rg"
              label="RG"
              errors={errors}
              touched={touched}
              maxLength={15}
            />
            <BasicInput
              name="rgOrgaoEmissor"
              label="Órgão Emissor"
              errors={errors}
              touched={touched}
              maxLength={15}
            />
            <BasicInput
              label="Estado"
              name="rgUf"
              size={2}
              render={({ field }) => (
                <select
                  data-cy={'selectRgEstado'}
                  className={classError('rgUf')}
                  {...field}
                  data-testid="select-rgUf"
                >
                  <option value="">Selecione</option>
                  {Constantes.optionsEstados.map(item => (
                    <option key={item.key} value={item.key}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}
            />
            <BasicInput
              name="rgDataEmissao"
              label="Data Emissão"
              size={2}
              render={({ field }) => <InputDate {...field} />}
            />
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default DadosSectionForm;
