export {
  faAdjust,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowLeft,
  faBell,
  faBroom,
  faComment,
  faCompressAlt,
  faEnvelope,
  faEnvelopeOpen,
  faFillDrip,
  faPlaneDeparture,
  faQuestion,
  faSearch,
  faSignOutAlt,
  faSpinner,
  faTools,
  faPlusCircle,
  faUniversalAccess,
  faUser,
  faTh,
  faWheelchair,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
