import { PagedResponse, formatUtils } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';
import {
  DebitoPassivelParcelamentoDTO,
  Parcelamento,
  SimulacaoDTO
} from 'itbi-cidadao/src/types';

import { DebitoBloquetoMobileDTO } from '../type';
import { ServiceUtils } from '../utils';

const defaultSort = {
  sort: 'tipocadastro,cadastrogeral'
};

export const findAll = (
  searchParams: string,
  page: object
): AxiosPromise<PagedResponse<Parcelamento>> =>
  axios.get(
    `/api/parcelamentos?${ServiceUtils.buildSearchParams(
      searchParams
    )}&fields=id,numeroContrato,anoContrato,tipo,dataParcelamento,cadastroGeralResumido,requerente.nome,situacao.descricao`,
    {
      params: { ...page }
    }
  );

export const getResumo = (idParcelamento: string) =>
  axios.get(`/api/parcelamentos/${idParcelamento}/resumo`);

export const gerarBoletoAgrupado = (
  debitos: DebitoBloquetoMobileDTO[]
): AxiosPromise<any> => {
  return axios.post('/api/parcelamentos/boleto', debitos);
};

export const imprimirCarne = (idParcelamento: number): AxiosPromise<any> =>
  axios.get(`/api/parcelamentos/${idParcelamento}/imprimir-carne`, {
    responseType: 'blob'
  });

export const imprimirContrato = (idParcelamento: number): AxiosPromise<any> =>
  axios.get(`/api/parcelamentos/${idParcelamento}/imprimir-contrato`, {
    responseType: 'blob'
  });

export const gerarParcelamento = (parcelamento: any): AxiosPromise<any> => {
  return axios.post('/api/parcelamentos', parcelamento);
};

export const loadCadastrosByCpfCnpj = (
  cpfCnpj: string,
  searchParam: string,
  page: any
) =>
  axios.get(
    `/api/parcelamentos/cpf-cnpj/${cpfCnpj}/cadastros?${ServiceUtils.buildSearchParams(
      searchParam
    )}`,
    {
      params: {
        ...defaultSort,
        ...page
      }
    }
  );

export const loadLeisComDebitos = (
  tipoCadastro: number,
  cadastroGeral: number
) =>
  axios.get('/api/parcelamentos/lei/debitos', {
    params: { tipoCadastro, cadastroGeral }
  });

export const loadLeis = () => axios.get('/api/parcelamentos/leis');

export const loadSimulacoes = (simulacao: {
  idParamParcelamento: number;
  tipoCadastro: number;
  cadastroGeral: number;
  requerente: number;
  numeroParcela?: number;
  idDebitos?: number[];
  valorTotal?: number;
}): AxiosPromise<any> => axios.post('/api/parcelamentos/simulacoes', simulacao);

export const getDebitosPassiveisParcelamento = (
  tipoCadastro: number,
  cadastroGeral: number,
  idParametroParcelamento: number
): AxiosPromise<DebitoPassivelParcelamentoDTO[]> =>
  axios.get(`/api/parcelamentos/debitos-passiveis-parcelamento`, {
    params: {
      tipoCadastro,
      cadastroGeral,
      idParametroParcelamento,
      dataCorrecao: formatUtils.getFormattedUTCDateWithNoTime(new Date())
    }
  });

type SimulacaoParam = {
  tipoCadastro: number;
  cadastroGeral: number;
  idParamParcelamento: number;
  numeroParcela: number;
  requerente: number;
  idDebitos: number[];
  valorTotal: number;
};

export const gerarSimulacao = (
  params: SimulacaoParam
): AxiosPromise<SimulacaoDTO> =>
  axios.post('/api/parcelamentos/simulacoes', params);
