import classnames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { useOnClickOutside, useWindowWidth } from '../hooks';
import { SidebarToggleContext } from '../sidebar/SidebarToggle';
import Features, {
  FEATURE_ACESSIBILIDADE,
  FEATURE_AJUSTES,
  FEATURE_AJUSTES__SOBRE_SISTEMA,
  FEATURE_ENTIDADE,
  FEATURE_MODULOS,
  FEATURE_NOTIFICATION
} from './Features';
import FeaturesContent from './FeaturesContent';

export type HeaderContextProps = {
  activeAcessibilidade: boolean;
  activeNotification: boolean;
  activeAjustes: boolean;
  activeAjustesSobreSistema: boolean;
  activeModulos: boolean;
  activeEntidade: boolean;
  activeFeature: string;
  toggleAcessibilidade: () => void;
  toggleNotification: () => void;
  toggleAjustes: () => void;
  toggleAjustesSobreSistema: () => void;
  toggleEntidade: () => void;
  toggleModulos: () => void;
  toggleFeature: (feature: string) => void;
};

export const HeaderContext = React.createContext<HeaderContextProps>({
  activeAcessibilidade: false,
  activeNotification: false,
  activeAjustes: false,
  activeAjustesSobreSistema: false,
  activeModulos: false,
  activeEntidade: false,
  activeFeature: '',
  toggleAcessibilidade: () => {},
  toggleNotification: () => {},
  toggleAjustes: () => {},
  toggleAjustesSobreSistema: () => {},
  toggleEntidade: () => {},
  toggleModulos: () => {},
  toggleFeature: () => {}
});

type HeaderProps = {
  title: string;
  children: React.ReactNode;
  showToggleButton: boolean;
  /**
   * @deprecated  Usar o atributo Logo
   * **/
  imgModule?: any;
  nomeModulo?: string;
  brasao?: any;
  logoOxy?: boolean;
  logoModulo?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  permiteTrocarEntidade?: boolean;
};

interface StaticComponents {
  Features: typeof Features;
  FeaturesContent: typeof FeaturesContent;
  HeaderContext: typeof HeaderContext;
}

const Header: React.FC<HeaderProps> & StaticComponents = ({
  title,
  children,
  showToggleButton,
  imgModule,
  nomeModulo,
  brasao,
  logoOxy,
  logoModulo: LogoModulo,
  permiteTrocarEntidade = true
}) => {
  const { toggledSidebar, toggleSideBar } = useContext(SidebarToggleContext);
  const [activeFeature, setActiveFeature] = useState('');
  const width = useWindowWidth();

  const toggleFeature = (feature: string) => {
    activeFeature === feature
      ? setActiveFeature('')
      : setActiveFeature(feature);
  };

  const toggleAcessibilidade = () => toggleFeature(FEATURE_ACESSIBILIDADE);
  const toggleNotification = () => toggleFeature(FEATURE_NOTIFICATION);
  const toggleAjustes = () => toggleFeature(FEATURE_AJUSTES);
  const toggleAjustesSobreSistema = () =>
    toggleFeature(FEATURE_AJUSTES__SOBRE_SISTEMA);
  const toggleModulos = () => toggleFeature(FEATURE_MODULOS);
  const toggleEntidade = () => toggleFeature(FEATURE_ENTIDADE);

  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    setActiveFeature('');
  });

  useEffect(() => {
    if (imgModule) {
      console.warn(
        'O atributo imgModule está deprecated. Utilizar o atributo LogoModulo'
      );
    }
  }, [imgModule]);

  useEffect(() => {
    if (!toggledSidebar && width < 1025) {
      toggleSideBar();
    }
  }, [width]);

  const classesHeader = classnames({
    flex: true,
    header: true,
    'sidebar-toggle': toggledSidebar,
    new: true
  });

  const classesButton = classnames({
    'header-toggle-sidebar': true,
    'toggle-control': true,
    'sidebar-toggle': toggledSidebar,
    'hidden-lg': true,
    'hidden-md': true
  });

  const openEntidade = () => {
    if (permiteTrocarEntidade) {
      toggleEntidade();
    }
  };

  return (
    <HeaderContext.Provider
      value={{
        activeAcessibilidade: activeFeature === FEATURE_ACESSIBILIDADE,
        activeNotification: activeFeature === FEATURE_NOTIFICATION,
        activeAjustes: activeFeature === FEATURE_AJUSTES,
        activeAjustesSobreSistema:
          activeFeature === FEATURE_AJUSTES__SOBRE_SISTEMA,
        activeModulos: activeFeature === FEATURE_MODULOS,
        activeEntidade: activeFeature === FEATURE_ENTIDADE,
        activeFeature,
        toggleAcessibilidade,
        toggleNotification,
        toggleAjustes,
        toggleAjustesSobreSistema,
        toggleModulos,
        toggleFeature,
        toggleEntidade
      }}
    >
      <header ref={ref} className={classesHeader}>
        <div className="header-content">
          <div className={`logo ${logoOxy ? 'logo-oxy' : ''}`}>
            {LogoModulo ? (
              <LogoModulo className="module-color hidden-xs" />
            ) : (
              <img
                src={imgModule}
                aria-hidden
                className="module-color hidden-xs"
                style={{ width: '40px' }}
              />
            )}
            {nomeModulo || document.title}
          </div>

          {showToggleButton && (
            <button
              className={classesButton}
              aria-label="Botão para expandir o menu"
              onClick={toggleSideBar}
            >
              <em className="fa fa-bars"></em>
            </button>
          )}

          <div className="header-title hidden-xs">
            <div className="content-title">
              {brasao && (
                <figure className="brasao">
                  <img
                    id="headerBrasao"
                    src={`data:image/png;base64, ${brasao}`}
                  />
                </figure>
              )}
              <span onClick={openEntidade}>{title}</span>
            </div>
          </div>
          {children}
        </div>
        <div className="header-content-mobile hidden-sm hidden-md hidden-lg">
          <div className="header-title">
            <div className="content-title">
              {brasao && (
                <figure className="brasao">
                  <img
                    id="headerBrasao"
                    src={`data:image/png;base64, ${brasao}`}
                  />
                </figure>
              )}
              <span onClick={openEntidade}>{title}</span>
            </div>
          </div>
        </div>
      </header>
    </HeaderContext.Provider>
  );
};

Header.Features = Features;
Header.FeaturesContent = FeaturesContent;
Header.HeaderContext = HeaderContext;

export default Header;
