import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

import BreadCrumb from '../breadcrumb/BreadCrumb';
import { Icon } from '../index';
import { BreadCrumbStatus } from '../type';

type ContainerWithTextProps = {
  icon: IconProp;
  title: string;
  titleRightComponent?: React.ReactNode;
  children: React.ReactNode;
};
type ContainerWithBreadCrumbsProps = {
  status?: BreadCrumbStatus;
  titleRightComponent?: React.ReactNode;
  children: React.ReactNode;
  breadcrumb: boolean;
  nestedRoute?: boolean;
};
type Props = ContainerWithBreadCrumbsProps | ContainerWithTextProps;

const isContainerWithText = (props: Props): props is ContainerWithTextProps =>
  (props as ContainerWithBreadCrumbsProps).breadcrumb === undefined;

const Container: React.FunctionComponent<Props> = props => {
  if (isContainerWithText(props)) {
    return (
      <>
        <header className="main-content-header">
          <nav aria-label={'Breadcrumbs'} className={'nav-breadcrumbs'}>
            <ol>
              <li>
                <a href={window.location?.pathname} aria-current="page">
                  <Icon primary icon={props.icon} />
                  <span className="page-name">{props.title}</span>
                </a>
              </li>
            </ol>
            <div className="nav-buttons" role="group">
              {props.titleRightComponent}
            </div>
          </nav>
        </header>
        <div className="content-wrapper">{props.children}</div>
      </>
    );
  }

  const headerStyle = !!props.nestedRoute ? { display: 'none' } : {};

  return (
    <>
      <header className="main-content-header" style={headerStyle}>
        <BreadCrumb status={props.status}>
          {props.titleRightComponent}
        </BreadCrumb>
      </header>
      {!!props.nestedRoute ? (
        props.children
      ) : (
        <div className="content-wrapper">{props.children}</div>
      )}
    </>
  );
};

export default Container;
