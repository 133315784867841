import { TextAlignProperty } from 'csstype';
import React, { ChangeEvent, FC } from 'react';
import { FormattedNumber } from 'react-intl';
import NumberFormat, {
  NumberFormatValues,
  SyntheticInputEvent
} from 'react-number-format';

import EditableInput, { EditableInputProps } from './EditableInput';

type Props = EditableInputProps<number> & {
  decimalSeparator?: string;
  thousandSeparator?: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  allowNegative?: boolean;
  fixCursorOnFocus?: boolean;
  align?: TextAlignProperty;
};

const normalizedChange = (onChange: (e: React.ChangeEvent<any>) => void) => (
  { floatValue }: NumberFormatValues,
  { target: { name } }: SyntheticInputEvent
) => {
  const value = floatValue === undefined ? '' : floatValue;
  onChange({ target: { name, value: value } } as ChangeEvent<any>);
};

const buildValorZerado = (
  decimalScale: number,
  decimalSeparator: string
): string => {
  if (decimalScale === 0) {
    return '0';
  }
  return `0${decimalSeparator}${'0'.repeat(decimalScale)}`;
};

const EditableInputNumber: FC<Props> = props => {
  const {
    decimalScale = 2,
    decimalSeparator = ',',
    thousandSeparator = '.',
    fixedDecimalScale = true,
    allowNegative = true,
    fixCursorOnFocus = true,
    align,
    onFocus
  } = props;

  const innerOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (fixCursorOnFocus) {
      const { target } = e;

      const cursorPosition =
        target.value === buildValorZerado(decimalScale, decimalSeparator)
          ? 1
          : 0;
      target.setSelectionRange(0, cursorPosition);
    }

    onFocus?.(e);
  };

  return (
    <EditableInput
      {...props}
      renderEditComponent={({ value, onBlur, onChange, onKeyDown }) => (
        <NumberFormat
          autoFocus={!props.defaultOpened}
          decimalSeparator={decimalSeparator}
          thousandSeparator={thousandSeparator}
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
          value={value}
          onBlur={onBlur}
          allowNegative={allowNegative}
          onValueChange={normalizedChange(onChange)}
          onKeyDown={onKeyDown}
          onFocus={innerOnFocus}
          style={align ? { textAlign: align } : {}}
        />
      )}
      renderVisualization={(value?: number | string) => {
        const newValue =
          value && parseFloat(value.toString().replace(',', '.'));

        if (newValue && props.renderVisualization) {
          return props.renderVisualization(newValue);
        }

        return (
          newValue && (
            <FormattedNumber
              value={newValue}
              minimumFractionDigits={decimalScale}
              maximumFractionDigits={decimalScale}
            />
          )
        );
      }}
    />
  );
};

export default EditableInputNumber;
