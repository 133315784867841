import classnames from 'classnames';
import React from 'react';

type Props = {
  loading: boolean;
  classname?: string;
  contain?: boolean;
};
const Loading: React.FunctionComponent<Props> = ({
  loading,
  classname = '',
  contain = false
}) => {
  const realClassName = classnames({
    'content-loader': true,
    loadOxy: true,
    hide: !loading,
    contain
  });

  return (
    <div className={`${realClassName} ${classname}`}>
      <svg
        version="1.2"
        className="content-align"
        baseProfile="tiny"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <g className="animateDraw drawText" id="Logo-Text">
            <path
              id="Caminho_129"
              data-name="Caminho 129"
              d="M206.58,240.235a19.448,19.448,0,1,0,19.448,19.448,19.448,19.448,0,0,0-19.448-19.448M208,270.5a10.909,10.909,0,1,1,9.4-9.4,10.914,10.914,0,0,1-9.4,9.4"
              transform="translate(-187.132 -240.234)"
            />
            <path
              id="Caminho_130"
              data-name="Caminho 130"
              d="M352.5,240.234v19.43a10.906,10.906,0,1,1-21.811,0V245.131l-8.54-4.9v19.43A19.448,19.448,0,0,0,352.5,275.77v3.344a10.91,10.91,0,0,1-10.911,10.9H327.216l4.9,8.543h9.474a19.45,19.45,0,0,0,19.451-19.447V245.131Z"
              transform="translate(-231.064 -240.234)"
            />
            <path
              id="Caminho_131"
              data-name="Caminho 131"
              d="M266.245,259.675l-11.96,8.825v10.615l19.154-14.132,19.155,14.132V268.5l-11.961-8.825,11.961-8.825V240.235l-19.155,14.132-19.154-14.132V250.85Z"
              transform="translate(-208.982 -240.234)"
            />
          </g>
        </defs>
        <svg className="logo_loading" viewBox="0 0 100 100">
          <use
            id="Draw-Text"
            className="drawText animateDraw"
            href="#Logo-Text"
          />
        </svg>
      </svg>
    </div>
  );
};

export default Loading;
