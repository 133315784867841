import { selectUnit } from '@formatjs/intl-utils';
import classnames from 'classnames';
import React from 'react';
import { FormattedRelativeTime } from 'react-intl';

import Icon from '../../icons/Icon';
import { EloNotification } from '../../type';
import AreaChip from './AreaChip';

type Props = {
  notification: EloNotification;
  onSelect?: (notification: EloNotification) => void;
  showDetail?: boolean;
};

const StatusIcon: React.FC<Props> = ({ notification }) => {
  let { status } = notification;
  if (status === 'STARTED') {
    return <Icon icon="spinner" spin color={'red'} />;
  }
  if (status === 'COMPLETED') {
    return <Icon icon="check" color={'green'} />;
  }
  if (status === 'FAILED') {
    return <Icon icon="times" color={'red'} />;
  }
  return <></>;
};

const NotificationItem: React.FC<Props> = ({
  notification,
  onSelect,
  showDetail = false
}) => {
  const liClass = classnames('has-icon', {
    unread: !notification.readed
  });
  const relativeTimeData = selectUnit(new Date(notification.createOn));
  return (
    <li
      key={notification.id}
      className={liClass}
      onClick={() => onSelect?.(notification)}
    >
      <AreaChip area={notification.modulo} />
      <div className="datehour">
        <time dateTime={notification.createOn}>
          <span>
            <FormattedRelativeTime
              value={relativeTimeData.value}
              unit={relativeTimeData.unit}
            />
          </span>
        </time>
      </div>
      <article>
        <p className="nowrap">{notification.message}</p>
      </article>
      <StatusIcon notification={notification} />
      {showDetail && <p>{notification.messageDetail}.</p>}
    </li>
  );
};

export default NotificationItem;
