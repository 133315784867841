import { DateUtils, Panel, SectionTitle, Table } from '@elotech/components';
import {
  ConcursoPremiadoResumoPorCpf,
  ResumoNotasParticipante
} from 'itbi-common/type';
import React from 'react';
import { FormattedNumber } from 'react-intl';

type Props = {
  concurso: ConcursoPremiadoResumoPorCpf;
};

const ConcursoPremiadoNotasPage: React.FC<Props> = ({ concurso }) => {
  return (
    <div>
      <SectionTitle marginTop={'0px'}>Notas</SectionTitle>
      <Panel isTable>
        <Table
          values={concurso?.resumoNotas ?? []}
          keyExtractor={item => `${item.mes}-${item.ano}`}
          messageEmpty={'Não há notas para listar'}
        >
          <Table.Column<ResumoNotasParticipante>
            header="Mês"
            value={item => `${DateUtils.mesesAno[item.mes - 1]}/${item.ano}`}
          />
          <Table.Column<ResumoNotasParticipante>
            header="Total de Notas"
            value={item => item.totalNotal}
          />
          <Table.Column<ResumoNotasParticipante>
            header="Valor Total"
            value={item => (
              <FormattedNumber
                style="currency" // eslint-disable-line
                currency="BRL"
                value={item?.valorNotas ?? 0}
              />
            )}
          />
        </Table>
      </Panel>
    </div>
  );
};

export default ConcursoPremiadoNotasPage;
