import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Col } from '../grid';
import Icon from '../icons/Icon';
import TableReportCol from './TableReportCol';
import TableReportHeader from './TableReportHeader';
import TableReportRow from './TableReportRow';
import TableReportRows from './TableReportRows';
import TableReportSummary from './TableReportSummary';
import TableReportTitle from './TableReportTitle';

const CenteredIcon = styled(Icon)`
  margin: 10% 45%;
`;

const CenteredDiv = styled.div`
  margin: 10% 25%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTableReport = styled.div`
  @media (min-width: 992px) {
    display: flex;
    ${props => (props.height ? `height: ${props.height}` : '')};
  }
`;
const InitialCol = styled(Col)`
  position: initial;
`;

class TableReport extends React.Component {
  extractSummary = (children, summaryIndex, empty) => {
    const normalizedChildren = React.Children.toArray(children);

    const summary = normalizedChildren.splice(summaryIndex, 1);

    const size = empty ? 12 : summary[0].props.size;
    return (
      <React.Fragment>
        <Col md={size}>{summary}</Col>
        {!empty && (
          <InitialCol md={12 - size}>{[...normalizedChildren]}</InitialCol>
        )}
      </React.Fragment>
    );
  };

  renderChildren = () => {
    const { loading, error, children, empty } = this.props;

    if (loading) {
      return (
        <CenteredDiv>
          <CenteredIcon size="4x" icon="spinner" spin primary />;
        </CenteredDiv>
      );
    }

    if (!!error) {
      return (
        <CenteredDiv>
          <Icon size="4x" icon={['far', 'frown']} primary />
          <span className="module-color">{error.message}</span>
        </CenteredDiv>
      );
    }
    const summaryIndex = React.Children.toArray(children).findIndex(
      child => child.type === TableReportSummary
    );

    if (summaryIndex > -1) {
      return this.extractSummary(children, summaryIndex, empty);
    }

    return children;
  };
  render() {
    const { height } = this.props;

    return (
      <StyledTableReport className="table-report row" height={height}>
        {this.renderChildren()}
      </StyledTableReport>
    );
  }
}

TableReport.propTypes = {
  loading: PropTypes.bool,
  height: PropTypes.any
};

TableReport.defaultProps = {
  loading: false
};

TableReport.Header = TableReportHeader;
TableReport.Col = TableReportCol;
TableReport.Row = TableReportRow;
TableReport.Rows = TableReportRows;
TableReport.Summary = TableReportSummary;
TableReport.Title = TableReportTitle;

export default TableReport;
