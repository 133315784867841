import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from 'styled-components/macro';

import Icon from '../icons/Icon';

const DivIcon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: #fff;
  padding: 6px 0;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
`;

const StyledArticle = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type TitleProps = {
  icon: IconProp;
  title: string;
  RightComponent?: React.ReactNode;
};

const Title: React.FunctionComponent<TitleProps> = ({
  icon,
  title,
  RightComponent = undefined
}) => (
  <header className="main-content-header">
    <StyledArticle className="page-name-outer">
      <div>
        <DivIcon>
          <Icon darkPrimary icon={icon || 'plus'} size="lg" />
        </DivIcon>
        <h2 className="page-name">{title}</h2>
      </div>
      {!!RightComponent && <div>{RightComponent}</div>}
    </StyledArticle>
  </header>
);

const SectionTitleContainer = styled.div<{ marginTop: string }>`
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  margin-top: ${props => (props.marginTop ? props.marginTop : '35px')};
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

const SectionTitleText = styled.h4<{ hasButton: boolean; color?: string }>`
  font-weight: bold;
  color: ${props => props.color || props.theme.sectionTitleColor};

  display: ${props => (props.hasButton ? 'flex' : '')};
  justify-content: ${props => (props.hasButton ? 'space-between' : '')};
  align-items: ${props => (props.hasButton ? 'center' : '')};
`;

type SectionTitleType = {
  hasButton?: boolean;
  marginTop?: string;
  color?: string;
  children: React.ReactNode;
};

const SectionTitle: React.FunctionComponent<SectionTitleType> = ({
  marginTop = '35px',
  hasButton = false,
  color,
  children
}) => {
  return (
    <SectionTitleContainer marginTop={marginTop}>
      <SectionTitleText hasButton={hasButton} color={color}>
        {children}
      </SectionTitleText>
    </SectionTitleContainer>
  );
};

export { Title, SectionTitle };
