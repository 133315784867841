import { formatUtils } from '@elotech/components';
import { validateCnpj, validateCpf } from 'itbi-common/utils/ValidationUtils';
import * as Yup from 'yup';

const verifySomeCpfCnpjNotEquals = (cpfCnpj, id, list) =>
  !list.some(item => item.cpfCnpj === cpfCnpj && item.id !== id);

const validaPercentual = (percentual, position, list) => {
  const percentualTotal = list
    .map(item => item.percentual)
    .filter((item, index) => index !== position)
    .reduce((acumulator, currentValue) => acumulator + currentValue, 0);

  return formatUtils.round(percentualTotal + percentual, 2) <= 100;
};

const validationEmail = (values, tipoProprietario, isServidor) => {
  if (isServidor) {
    return Yup.string();
  }

  if (tipoProprietario !== 'comprador') {
    if (!values.email) {
      return Yup.string();
    }

    return Yup.string().email('O e-mail está inválido.');
  }

  return Yup.string()
    .email('O e-mail está inválido.')
    .required('O e-mail é obrigatório.');
};

const validationCelular = (tipoProprietario, isServidor) => {
  return tipoProprietario === 'comprador' && !isServidor
    ? Yup.string().required('O número de celular é obrigatório.')
    : Yup.string();
};

const getValidationSchema = (
  values,
  list,
  vendedores,
  compradores,
  tipoProprietario,
  position,
  isServidor,
  validaRgComprador
) => {
  const isTipoPessoaFisica = values.tipoPessoa === 'FISICA';
  const cpfOrCnpj = isTipoPessoaFisica ? 'CPF' : 'CNPJ';

  const rgObrigatorio =
    tipoProprietario === 'comprador' && isTipoPessoaFisica && validaRgComprador;

  return Yup.object().shape({
    id: Yup.string(),
    tipoPessoa: Yup.string(),
    cpfCnpj: Yup.string()
      .test('cpfCnpj', 'O CPF é inválido.', value =>
        isTipoPessoaFisica ? validateCpf(value) : true
      )
      .test('cpfCnpj', 'O CNPJ é inválido.', value =>
        !isTipoPessoaFisica ? validateCnpj(value) : true
      )
      .test('cpfCnpj', `O ${cpfOrCnpj} já foi adicionado na lista`, value =>
        verifySomeCpfCnpjNotEquals(value, values.id, list)
      )
      .test(
        'cpfCnpj',
        `O ${cpfOrCnpj} já foi adicionado na lista de vendedores`,
        value =>
          tipoProprietario !== 'vendedor'
            ? verifySomeCpfCnpjNotEquals(value, values.id, vendedores)
            : true
      )
      .test(
        'cpfCnpj',
        `O ${cpfOrCnpj} já foi adicionado na lista de compradores`,
        value =>
          tipoProprietario !== 'comprador'
            ? verifySomeCpfCnpjNotEquals(value, values.id, compradores)
            : true
      )
      .required(`O ${cpfOrCnpj} é obrigatório.`),
    nome: Yup.string()
      .trim()
      .max(150, 'O nome deve conter no máximo 150 caracteres')
      .required('O nome é obrigatório.'),
    celular: validationCelular(tipoProprietario, isServidor)
      .trim()
      .max(11, 'O celular deve conter no máximo 11 caracteres'),
    logradouro: Yup.string()
      .trim()
      .max(100, 'O endereço deve conter no máximo 100 caracteres')
      .required('O endereço é obrigatório.'),
    email: validationEmail(values, tipoProprietario, isServidor),
    cidade: Yup.string()
      .trim()
      .max(100, 'A cidade deve conter no máximo 100 caracteres')
      .required('A cidade é obrigatória.'),
    bairro: Yup.string()
      .trim()
      .max(100, 'O bairro deve conter no máximo 100 caracteres')
      .required('O bairro é obrigatório.'),
    cep: Yup.string()
      .trim()
      .test(
        'cep',
        'O CEP está inválido.',
        () => values.cepValido || values.cepValido === undefined
      )
      .required('O CEP é obrigatório.'),
    numero: Yup.string()
      .trim()
      .max(10, 'O número deve conter no máximo 10 caracteres')
      .required('O número é obrigatório.'),
    uf: Yup.string()
      .trim()
      .max(100, 'O estado deve conter no máximo 2 caracteres')
      .required('O estado é obrigatório.'),
    vinculo: Yup.object().test('vinculo', 'O vínculo é obrigatório.', value =>
      isServidor && !value ? false : true
    ),
    percentual: Yup.number()
      .min(0.01, 'O valor percentual deve estar entre 0.01 e 100')
      .max(100, 'O valor percentual deve estar entre 0.01 e 100')
      .test(
        'percentual',
        'O valor total do percentual não deve exceder 100',
        value => validaPercentual(value, position, list)
      ),
    percentualVenda:
      tipoProprietario === 'vendedor'
        ? Yup.number()
            .min(
              0.01,
              'O valor percentual de venda deve estar entre 0.01 e 100'
            )
            .max(100, 'O valor percentual de venda deve estar entre 0.01 e 100')
            .test(
              'percentualVenda',
              'O valor percentual de venda não deve ser maior que o percentual.',
              value => value <= values.percentual
            )
        : Yup.number(),
    rg: Yup.string()
      .max(15, 'O RG deve conter no máximo 15 caracteres')
      .test('rgObrigatorio', 'O RG é obrigatório', function(value) {
        return rgObrigatorio ? Boolean(value) : true;
      }),
    rgOrgaoEmissor: Yup.string()
      .max(15, 'O Órgão Emissor deve conter no máximo 15 caracteres')
      .test(
        'rgOrgaoEmissorObrigatorio',
        'O Órgão Emissor é obrigatório',
        function(value) {
          return rgObrigatorio ? Boolean(value) : true;
        }
      ),
    rgUf: Yup.string().test(
      'rgUfObrigatorio',
      'O Estado é obrigatório',
      function(value) {
        return rgObrigatorio ? Boolean(value) : true;
      }
    )
  });
};

export { getValidationSchema };
