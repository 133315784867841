import { NotificationActions } from '@elotech/components';
import LaudoForm from 'itbi-common/components/laudo/LaudoForm';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class FormLaudo extends React.Component {
  static propTypes = {
    showNotification: PropTypes.func.isRequired
  };

  onSubmit = laudo => {
    this.props.showNotification({
      level: 'success',
      message: 'Laudo de Avaliação gerado com sucesso'
    });

    this.props.history.replace(`/laudos/${laudo.id}`, {
      novo: true
    });
  };
  render() {
    return <LaudoForm afterSubmit={this.onSubmit} servidor={false} />;
  }
}

const ConnectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(FormLaudo);

export { ConnectedComponent as default, FormLaudo };
