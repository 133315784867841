import classnames from 'classnames';
import React, { CSSProperties, ReactNode, useState } from 'react';

import { Direction } from '../type';

type Props = {
  name: string;
  header: ReactNode;
  className?: string;
  columnSelector?: ReactNode;
  sortable?: boolean;
  initialDirection?: Direction;
  onHeaderClick: (name: string, direction: Direction) => void;
  style?: CSSProperties;
};

const Header: React.FC<Props> = ({
  header,
  className = '',
  columnSelector,
  onHeaderClick,
  initialDirection = '',
  name,
  sortable = false,
  style
}) => {
  const [direction, setDirection] = useState(initialDirection);

  const getNextDirection = () => {
    return direction === 'asc' ? 'desc' : 'asc';
  };

  const classes = classnames(className, {
    'column-selector no-print': columnSelector,
    sortable: sortable
  });

  const onClick = () => {
    const nextDirection = getNextDirection();
    setDirection(nextDirection);
    onHeaderClick(name, nextDirection);
  };

  return (
    <th className={classes} onClick={onClick} style={style}>
      {header}
      {columnSelector}
    </th>
  );
};

export default Header;
