import { ReactComponent as AdministrativoIcon } from '@elotech/arc/src/assets/img/modules/oxy-administrativo.svg';
import { ReactComponent as ArrecadacaoIcon } from '@elotech/arc/src/assets/img/modules/oxy-arrecadacao.svg';
import { ReactComponent as ContabilIcon } from '@elotech/arc/src/assets/img/modules/oxy-contabil.svg';
import { ReactComponent as EducacionalIcon } from '@elotech/arc/src/assets/img/modules/oxy-educacional.svg';
import { ReactComponent as ComumIcon } from '@elotech/arc/src/assets/img/modules/oxy-logo.svg';
import { ReactComponent as RecursosHumanosIcon } from '@elotech/arc/src/assets/img/modules/oxy-rh.svg';
import { ReactComponent as SocialIcon } from '@elotech/arc/src/assets/img/modules/oxy-social.svg';
import React from 'react';

import Chip from '../../chip/Chip';

const AreaIcons = {
  ADMINISTRATIVO: <AdministrativoIcon className="chip-icon-left" />,
  ARRECADACAO: <ArrecadacaoIcon className="chip-icon-left" />,
  CONTABIL: <ContabilIcon className="chip-icon-left" />,
  EDUCACIONAL: <EducacionalIcon className="chip-icon-left" />,
  RECURSOS_HUMANOS: <RecursosHumanosIcon className="chip-icon-left" />,
  SOCIAL: <SocialIcon className="chip-icon-left" />,
  COMUM: <ComumIcon className="chip-icon-left" />
};

type Props = {
  area: any;
};
const AreaChip: React.FC<Props> = ({ area }) => {
  return <Chip value={area.descricao} iconLeftCustom={AreaIcons[area.area]} />;
};

export default AreaChip;
