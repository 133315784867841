import * as NumberUtils from '../../utils/NumberUtils';
import { FilterItem, Filters } from './Types';

export function buildFilterRsql(filters: Filters) {
  const getContainedInFilter = (filter: FilterItem): string => {
    const BETWEEN_REGEX = new RegExp(/^(\d+-\d+)$/);
    const UUID_REGEX = new RegExp(
      /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/
    );

    const betweenList: string[] = [];
    const inList: string[] = [];
    const isInOperator: boolean = filter.operator.name === 'Contido em';

    let retorno = '';

    filter.value.split(',').forEach((item: string) => {
      if (BETWEEN_REGEX.test(item) && !UUID_REGEX.test(item)) {
        betweenList.push(item);
      } else {
        inList.push(
          NumberUtils.isNumeric(item) || UUID_REGEX.test(item)
            ? item
            : `"${item.trim()}"`
        );
      }
    });

    if (inList.length > 0) {
      retorno += isInOperator
        ? `${filter.field.name}=in=(${inList.join(',')})`
        : `${filter.field.name}=out=(${inList.join(',')})`;
    }

    if (betweenList.length > 0) {
      betweenList.forEach(item => {
        let interval = item.split('-');

        if (retorno.length > 0) {
          retorno += isInOperator ? ' or ' : ' and ';
        }

        if (isInOperator) {
          retorno += `(${filter.field.name}>=${interval[0]} and ${filter.field.name}<=${interval[1]})`;
        } else {
          retorno += `(${filter.field.name}<${interval[0]} or ${filter.field.name}>${interval[1]})`;
        }
      });
    }

    return retorno ? `(${retorno})` : '';
  };
  let searchParams = '';

  Object.values(filters).forEach((filter: FilterItem) => {
    if (searchParams) {
      searchParams += ' and ';
    }

    let value =
      typeof filter.value === 'string'
        ? encodeURIComponent(filter.value)
        : filter.value;

    if (filter.operator.name === 'Contém') {
      value = `*${value}*`;
    }

    if (
      filter.operator.name === 'Contido em' ||
      filter.operator.name === 'Não contido em'
    ) {
      searchParams += getContainedInFilter(filter);
      return;
    }

    if (filter.field.type === 'AUTOCOMPLETE') {
      value = filter.field.getOptionValue!(value) || value;
    } else if (filter.field.type === 'STRING') {
      value = `"${value}"`;
    }

    searchParams +=
      filter.field.name + filter.operator.symbol + decodeURIComponent(value);
  });

  return searchParams;
}
