import React from 'react';
import Dropzone from 'react-dropzone';

import DragDropItem from './DragDropItem';

type Props = {
  files: Array<Object>;
  onAddFile: (file: any) => void;
  onPreview?: (file: any) => void;
  onDownload?: (file: any) => void;
  onRemoveFile?: (index: number) => void;
  maxSize?: number;
  acceptedFiles?: string; //separados por vírgula ex: '.pdf,.jpeg'
  canDelete?: boolean;
  canUpload?: boolean;
  sizeDivFile?: number;
  multiple?: boolean;
};

type State = {
  errorOnUpload: boolean;
  onDragEnter: boolean;
  errorMessage: string;
};

const defaultMessage: string = 'Clique ou arraste arquivos aqui.';

class DragDropFiles extends React.Component<Props, State> {
  state = {
    errorOnUpload: false,
    errorMessage: '',
    onDragEnter: false
  };

  static defaultProps = {
    maxSize: 20971520,
    canDelete: true,
    canUpload: true,
    sizeDivFile: 12
  };

  componentDidMount() {
    const { acceptedFiles, maxSize } = this.props;
    const bytes = this.bytesToSize(maxSize!);

    let message = 'Somente arquivos ';

    if (acceptedFiles) {
      message += 'do(s) tipo(s): ' + acceptedFiles + ' e ';
    }

    message += 'com no máximo ' + bytes;

    this.setState({
      errorMessage: message
    });
  }

  bytesToSize = (bytes: number) => {
    if (0 == bytes) return '0 Bytes';
    let mebi = 1024,
      unidadeMedida = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      menorInteiro = Math.floor(Math.log(bytes) / Math.log(mebi));
    const byteSyze = parseFloat(
      (bytes / Math.pow(mebi, menorInteiro)).toFixed(2)
    );
    return `${byteSyze}${unidadeMedida[menorInteiro]}`;
  };

  onDropAccepted = (acceptedFiles: File[]) => {
    const { onAddFile } = this.props;
    this.setState(
      () => {
        return {
          errorOnUpload: false,
          onDragEnter: false
        };
      },
      () => {
        onAddFile(acceptedFiles);
      }
    );
  };

  onDropRejected = () => {
    this.setState({
      errorOnUpload: true,
      onDragEnter: false
    });
  };

  onDragEnterEffect = () => {
    this.setState({ onDragEnter: true });
  };

  renderFiles = (file: any, i: number) => {
    const {
      canDelete,
      sizeDivFile,
      onRemoveFile,
      onPreview,
      onDownload
    } = this.props;

    return (
      <DragDropItem
        canDelete={canDelete}
        file={file}
        key={`${file.name}-${i}`}
        onPreview={onPreview}
        onRemoveFile={() => onRemoveFile?.(i)}
        onDownload={onDownload}
        sizeDivFile={sizeDivFile}
      />
    );
  };

  render() {
    const { errorMessage, errorOnUpload, onDragEnter } = this.state;
    const { maxSize, acceptedFiles, files, canUpload, multiple } = this.props;
    return (
      <div className="dragdrop">
        {canUpload && (
          <Dropzone
            maxSize={maxSize}
            accept={acceptedFiles}
            onDropAccepted={this.onDropAccepted}
            onDropRejected={this.onDropRejected}
            onDragEnter={this.onDragEnterEffect}
            multiple={multiple}
          >
            {({ getRootProps, getInputProps }) => {
              return (
                <div
                  {...getRootProps()}
                  className={`dropzone dropzone--isActive ${
                    errorOnUpload ? 'error' : ''
                  }`}
                >
                  <div
                    className={`dragdrop-space ${onDragEnter ? 'hover' : ''}`}
                    data-message={
                      errorOnUpload && errorMessage
                        ? errorMessage
                        : defaultMessage
                    }
                  >
                    <input {...getInputProps()} />
                  </div>
                </div>
              );
            }}
          </Dropzone>
        )}
        {files && files.length > 0 && <ul>{files.map(this.renderFiles)}</ul>}
      </div>
    );
  }
}

export default DragDropFiles;
