import {
  Col,
  Container,
  Loading,
  NotificationActions,
  Row
} from '@elotech/components';
import { DocumentoGeradoService, withService } from 'itbi-common/service';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import InformacaoBoleto from './InformacaoBoleto';
import InformacaoCadastroRequerente from './InformacaoCadastroRequerente';
import InformacaoDocumento from './InformacaoDocumento';
import InformacaoParcelamento from './InformacaoParcelamento';

export const TIPODOCUMENTO_BLOQUETO = 100;
export const TIPODOCUMENTO_PARCELAMENTO = 999;

class AutenticarDocumento extends React.Component {
  state = {
    documentoAutenticado: undefined,
    loading: false,
    codigoValidacao: ''
  };

  static propTypes = {
    showNotification: PropTypes.func.isRequired,
    currentUser: PropTypes.object,
    documentoGeradoService: PropTypes.object.isRequired
  };

  handleKeyDown = e => {
    if (e.keyCode === 13) {
      this.autenticar();
    }
  };

  autenticar = () => {
    const value = this.state.codigoValidacao;

    if (!value || value.length === 0) {
      return;
    }

    this.setState({ loading: true, documentoAutenticado: undefined }, () => {
      return this.props.documentoGeradoService
        .autenticarDocumento(value)
        .then(response => {
          this.setState({
            documentoAutenticado: { ...response.data },
            codigoValidacao: value
          });
        })
        .catch(error => {
          this.setState({ codigoValidacao: value });
          this.props.showNotification({
            level: 'error',
            message: `O código de autenticidade '${value}' é inválido!`
          });
        })
        .finally(() => this.setState({ loading: false }));
    });
  };

  onChangeValue = e => {
    const { value } = e.target;

    this.setState({
      documentoAutenticado: undefined,
      codigoValidacao: value
    });
  };

  onPrint = urlArquivo => {
    window.open(urlArquivo, '_blank');
  };

  onPrintLoading = loading => {
    this.setState({ loading: loading });
  };

  renderWithDocumentoType = documentoAutenticado => {
    switch (documentoAutenticado.tipoDocumento) {
      case TIPODOCUMENTO_BLOQUETO:
        return <InformacaoBoleto documento={documentoAutenticado} />;
      case TIPODOCUMENTO_PARCELAMENTO:
        return (
          <InformacaoParcelamento
            documento={documentoAutenticado}
            onPrintLoading={this.onPrintLoading}
          />
        );
      default:
        return (
          <InformacaoDocumento
            documento={documentoAutenticado}
            onPrint={this.onPrint}
          />
        );
    }
  };

  renderDocumentoAutenticado = documentoAutenticado => {
    return (
      <React.Fragment>
        <div className="widget-area">
          <Row>
            <Col md={6} sm={12}>
              {this.renderWithDocumentoType(documentoAutenticado)}
            </Col>
            <Col md={6} sm={12}>
              <InformacaoCadastroRequerente documento={documentoAutenticado} />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { loading, codigoValidacao, documentoAutenticado } = this.state;

    return (
      <Container
        title="Autenticar Documento"
        icon="check-circle"
        style={{ height: '100%' }}
      >
        <article className="info mb-xs no-print">
          Utilize este recurso para verificar a validade e autenticidade de um
          documento.
          <div className="form-group">
            <input
              type="text"
              placeholder="Digite o código de autenticidade"
              value={codigoValidacao}
              onChange={this.onChangeValue}
              onKeyDown={this.handleKeyDown}
              autoFocus
            />
            <button
              key="search"
              type="button"
              data-test-id="buttonSearch"
              className="btn filter"
              onClick={this.autenticar}
            >
              AUTENTICAR
            </button>
          </div>
        </article>

        {loading && <Loading loading={loading} />}
        {documentoAutenticado &&
          this.renderDocumentoAutenticado(documentoAutenticado)}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    user: currentUser
  };
};

const WithServiceComponent = withService({
  documentoGeradoService: DocumentoGeradoService
})(AutenticarDocumento);

const ConnectedComponent = connect(mapStateToProps, {
  showNotification: NotificationActions.showNotification
})(WithServiceComponent);

export { ConnectedComponent as default, AutenticarDocumento };
