import {
  Alert,
  BasicInput,
  Col,
  Container,
  FAB,
  FormikTextArea,
  Loading,
  RadioButton,
  Row
} from '@elotech/components';
import { Formik } from 'formik';
import { ProcessoService } from 'itbi-common/service';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { AvaliacaoProcesso } from '../../types';

type Params = {
  id: string;
};

const notas = [
  {
    key: '1',
    value: '1',
    label: 'Muito Insatisfeito'
  },
  {
    key: '2',
    value: '2',
    label: 'Insatisfeito'
  },
  {
    key: '3',
    value: '3',
    label: 'Indiferente'
  },
  {
    key: '4',
    value: '4',
    label: 'Satisfeito'
  },
  {
    key: '5',
    value: '5',
    label: 'Muito Satisfeito'
  }
];

const initialState: Partial<AvaliacaoProcesso> = {
  nota: undefined,
  notaObservacao: ''
};

const ProcessoAvaliacaoPage = () => {
  const history = useHistory();
  const { id } = useParams<Params>();
  const [loading, setLoading] = useState(false);
  const [avaliacao, setAvaliacao] = useState(initialState);

  useEffect(() => {
    setLoading(true);

    ProcessoService.getProcesso(id)
      .then(({ data }) => {
        const { nota, notaObservacao } = data;
        setAvaliacao({ nota: nota?.toString(), notaObservacao });
      })
      .catch(error => {
        Alert.error({ title: 'Ocorreu um erro ao buscar o processo' }, error);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const onSubmit = (values: AvaliacaoProcesso) => {
    setLoading(true);

    return ProcessoService.avaliarProcesso(id, values)
      .then(() => history.push('/processos'))
      .catch(error => {
        Alert.error({ title: 'Ocorreu um erro ao avaliar o processo' }, error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container title="Avaliar Processo" icon="star">
      <Loading loading={loading} />
      <Formik enableReinitialize onSubmit={onSubmit} initialValues={avaliacao}>
        {formProps => {
          return (
            <>
              <Row className="flex mb-xs">
                <BasicInput
                  noLabel
                  size={12}
                  name="nota"
                  render={({ field }) => {
                    return (
                      <Col md={12} key={field.value}>
                        <RadioButton
                          {...field}
                          options={notas}
                          selectedValue={formProps.values.nota}
                        />
                      </Col>
                    );
                  }}
                />
              </Row>
              <FormikTextArea
                size={12}
                name="notaObservacao"
                label="Observação"
              />
              <div className="btn-save">
                <FAB
                  icon="check"
                  title="Avaliar"
                  onClick={formProps.submitForm}
                />
              </div>
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ProcessoAvaliacaoPage;
