export const optionsTipoPessoa = [
  { key: 'FISICA', value: 'FISICA', label: 'Física' },
  { key: 'JURIDICA', value: 'JURIDICA', label: 'Jurídica' }
];

export const optionsEstados = [
  { key: 'AC', label: 'Acre' },
  { key: 'AL', label: 'Alagoas' },
  { key: 'AP', label: 'Amapá' },
  { key: 'AM', label: 'Amazonas' },
  { key: 'BA', label: 'Bahia' },
  { key: 'CE', label: 'Ceará' },
  { key: 'DF', label: 'Distrito Federal' },
  { key: 'ES', label: 'Espirito Santo' },
  { key: 'GO', label: 'Goiás' },
  { key: 'MA', label: 'Maranhão' },
  { key: 'MT', label: 'Mato Grosso' },
  { key: 'MS', label: 'Mato Grosso do Sul' },
  { key: 'MG', label: 'Minas Gerais' },
  { key: 'PA', label: 'Pará' },
  { key: 'PB', label: 'Paraiba' },
  { key: 'PR', label: 'Paraná' },
  { key: 'PE', label: 'Pernambuco' },
  { key: 'PI', label: 'Piauí' },
  { key: 'RJ', label: 'Rio de Janeiro' },
  { key: 'RN', label: 'Rio Grande do Norte' },
  { key: 'RS', label: 'Rio Grande do Sul' },
  { key: 'RO', label: 'Rondônia' },
  { key: 'RR', label: 'Roraima' },
  { key: 'SC', label: 'Santa Catarina' },
  { key: 'SP', label: 'São Paulo' },
  { key: 'SE', label: 'Sergipe' },
  { key: 'TO', label: 'Tocantins' }
];

export const optionsTipoParcelamento = [
  { key: 'PARCELAMENTO', value: 'P', label: 'Parcelamento' },
  { key: 'REPARCELAMENTO', value: 'R', label: 'Reparcelamento' }
];

// TODO passar parâmetros fixos para cadastro de parâmetros, quando estiver pronto
export const prazoPedido = {
  padrao: 20,
  notificacao: 10
};

export const optionsTipoProcurador = [
  { key: 'CARTORIO', label: 'Cartório' },
  { key: 'DEMAIS_PROCURADORES', label: 'Demais Procuradores' }
];

export const optionsTipoCadastro = [
  {
    key: 1,
    label: 'Imobiliário'
  },
  {
    key: 2,
    label: 'Mobiliário'
  },
  {
    key: 3,
    label: 'Rural'
  },
  {
    key: 4,
    label: 'Cemitério'
  },
  {
    key: 5,
    label: 'Água / Esgoto'
  },
  {
    key: 9,
    label: 'Avulso'
  }
];

export const tipoVinculo = {
  principal: 1,
  socio: 2,
  coResponsavel: 3,
  devedorSolidario: 4,
  outros: 5
};
export const TipoCadastroLabel = {
  1: 'Imobiliário',
  2: 'Mobiliário',
  3: 'Rural',
  4: 'Cemitério',
  5: 'Água / Esgoto',
  9: 'Avulso'
};
