import { Alert, Container, FAB, Loading } from '@elotech/components/src';
import { DeclaracaoService, ParametroService } from 'itbi-common/service';
import { Parametro } from 'itbi-common/type';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

type Props = {};

const DeclaracaoContestacaoTermoAceitePage: React.FC<Props> = () => {
  const [parametro, setParametro] = useState<Parametro>();
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    ParametroService.loadAllParametros()
      .then(response => setParametro(response.data))
      .catch(error => {
        Alert.error(
          {
            title: ''
          },
          error
        );
      })
      .finally(() => setLoading(false));
  }, [id]);

  const onSave = () => {
    setLoading(true);
    DeclaracaoService.aceitarContestacaoDeclaracao(id)
      .then(response => {
        Alert.info({
          title: 'O arbitramento da base de cálculo do ITBI foi concluído.'
        });
        setLoading(false);
        history.push(`/declaracoes-itbi/${response.data.id}/resumo`);
      })
      .catch(error => {
        Alert.error(
          {
            title:
              'Não foi possível aceitar o arbitramento da base de cálculo do ITBI'
          },
          error
        );
        setLoading(false);
      });
  };

  return (
    <>
      <Container title="Termo de Aceite de Contestação" icon="paperclip">
        <Loading loading={loading} />
        {parametro && (
          <div
            className="typography-size-16px ql-editor"
            dangerouslySetInnerHTML={{
              __html: parametro.txtTermoAceiteArbitramento
            }}
          />
        )}
        <div className="btn-save">
          <FAB
            icon="check"
            iconColor="white"
            title="Aceitar Termo"
            onClick={onSave}
          />
        </div>
      </Container>
    </>
  );
};

export default DeclaracaoContestacaoTermoAceitePage;
