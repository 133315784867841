import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { connect } from 'formik';
import { FormikContext } from 'formik/dist/types';
import React, { useState } from 'react';

import { CadastroRuralService, ImobiliarioService } from '../../service';
import {
  Laudo,
  TIPO_IMOVEL_RURAL,
  TIPO_IMOVEL_URBANO,
  TipoImovel
} from '../../type';

type Props = {
  isOrigemServidor: boolean;
  onCheckDuplicados: (cadastro: string, tipoImovel: TipoImovel) => boolean;
};

type PropsWithFormik = Props & {
  formik: FormikContext<Laudo>;
};

const STATUS_CODE_NOT_FOUND = 404;

const CadastroInput = React.forwardRef<HTMLInputElement, PropsWithFormik>(
  (props, ref) => {
    const { isOrigemServidor = false, onCheckDuplicados, formik } = props;

    const { cadastro } = formik.values;
    const [loading, setLoading] = useState<boolean>(false);

    const inputClassName = classNames({
      loading: loading,
      selected: !!cadastro,
      error: !!formik.errors.cadastro
    });

    const isImovelRural = () => formik.values.tipoImovel === TIPO_IMOVEL_RURAL;

    const onSearch = (cadastro: string) => {
      formik.setFieldValue('cadastroRuralErro', undefined);
      formik.setFieldValue('cadastroImobiliarioErro', undefined);
      if (!cadastro || onCheckDuplicados(cadastro, formik.values.tipoImovel!)) {
        return;
      }

      isImovelRural()
        ? findByCadastroRuralComValorAvaliado(cadastro)
        : isOrigemServidor
        ? findByCadastroImobiliarioComValorAvaliado(cadastro)
        : findCadastroImobiliario(cadastro);
    };

    const findCadastroImobiliario = (cadastro: string) => {
      setLoading(true);
      ImobiliarioService.findCadastroImobiliario(cadastro)
        .then(response => {
          formik.setValues({
            id: undefined,
            cadastro: response.data.cadastro,
            cadastroImobiliario: response.data,
            tipoImovel: TIPO_IMOVEL_URBANO,
            valorEstimado: formik.values.valorEstimado
          });
        })
        .catch(error =>
          setMessageError(error.response?.status, error.response?.data?.message)
        )
        .finally(() => setLoading(false));
    };

    const findByCadastroImobiliarioComValorAvaliado = (cadastro: string) => {
      setLoading(true);
      ImobiliarioService.findByCadastroComValorAvaliado(cadastro)
        .then(response => {
          formik.setValues({
            id: undefined,
            cadastro: response.data.cadastro,
            cadastroImobiliario: response.data,
            tipoImovel: TIPO_IMOVEL_URBANO,
            valorEstimado: response.data?.valorAvaliado!
          });
        })
        .catch(error =>
          setMessageError(error.response?.status, error.response?.data?.message)
        )
        .finally(() => setLoading(false));
    };

    const findByCadastroRuralComValorAvaliado = (cadastro: string) => {
      setLoading(true);
      CadastroRuralService.findByCadastroComValorAvaliado(cadastro)
        .then(response => {
          formik.setValues({
            id: undefined,
            cadastro: response.data?.cadastro,
            cadastroRural: response.data,
            tipoImovel: TIPO_IMOVEL_RURAL,
            valorEstimado: response.data?.valorAvaliado!
          });
        })
        .catch(error =>
          setMessageError(error.response?.status, error.response?.data?.message)
        )
        .finally(() => setLoading(false));
    };

    const setMessageError = (statusCode: number, message: string = '') => {
      const mensagem =
        statusCode === STATUS_CODE_NOT_FOUND
          ? 'Não foi possível localizar o cadastro!'
          : `Cadastro municipal inválido! ${message}`;
      isImovelRural()
        ? formik.setFieldValue('cadastroRuralErro', mensagem)
        : formik.setFieldValue('cadastroImobiliarioErro', mensagem);
    };

    const onBlur = () => {
      formik.setFieldTouched('cadastro', true, true);
      onSearch(`${cadastro}`);
    };

    const onKeyDown = (event: React.KeyboardEvent) => {
      if (event.keyCode === 13) {
        onSearch(`${cadastro}`);
      }
    };

    return (
      <div className="form-group">
        <label htmlFor="cadastroInput" className="label">
          {'Cadastro Municipal'}
        </label>
        <input
          id={'cadastroInput'}
          ref={ref}
          disabled={loading}
          className={inputClassName}
          value={cadastro}
          maxLength={10}
          placeholder="Digite o cadastro municipal"
          autoComplete="off"
          autoFocus
          onChange={event =>
            formik.setFieldValue('cadastro', event.target.value)
          }
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
        {loading && (
          <FontAwesomeIcon icon="spinner" className="spinner" pulse />
        )}
      </div>
    );
  }
);

export default connect<Props, Laudo>(CadastroInput);
