import React from 'react';

import { Button, Col, Row, SearchPanel, SwitchButtons } from '../..';

type Props = {
  tipoFiltro: string;
  tiposFiltroSwitch: any;
  onChangeTipoFiltro: (value: string) => void;
  onCopyPermissoes(): void;
};

const PermissionSearch: React.FC<Props> = ({
  tipoFiltro,
  tiposFiltroSwitch,
  onChangeTipoFiltro,
  onCopyPermissoes
}) => {
  return (
    <SearchPanel>
      <Row>
        <Col md={4} sm={6}>
          <label className="label">Filtro</label>
          <SwitchButtons
            data-test-id="switchButton"
            name="tipoFiltro"
            value={tipoFiltro}
            options={tiposFiltroSwitch}
            onChange={(value: string) => onChangeTipoFiltro(value)}
          />
        </Col>
        <Col md={8} sm={6}>
          <label className="label" />
          <Button
            data-test-id="buttonCopyPermissoes"
            onClick={onCopyPermissoes}
          >
            Copiar Permissões
          </Button>
        </Col>
      </Row>
    </SearchPanel>
  );
};

export default PermissionSearch;
