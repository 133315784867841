import { Col, QuickView, Row, formatUtils } from '@elotech/components';
import React from 'react';
import styled from 'styled-components/macro';

const ExtendedQuickView = styled(QuickView)`
  &.quick-view {
    width: 450px;
  }
`;
export const AlignedCol = styled(Col)`
  text-align: ${props => props.alignment};
`;

const getEndereco = pessoa => {
  const getDescricao = campo => (campo ? campo.descricao : '');

  if (pessoa.logradouroAise && pessoa.bairroAise && pessoa.tipoLogradouroAise) {
    return `${getDescricao(pessoa.tipoLogradouroAise)} ${getDescricao(
      pessoa.logradouroAise
    )}, ${pessoa.numero}, ${pessoa.complemento || ''}, ${formatUtils.formatCep(
      pessoa.cep
    )}, ${getDescricao(pessoa.bairroAise)}, ${getDescricao(
      pessoa.cidadeAise
    )} - ${pessoa.uf}`;
  }

  return `${pessoa.logradouro}, ${pessoa.numero}, ${pessoa.complemento ||
    ''}, ${formatUtils.formatCep(pessoa.cep)}, ${pessoa.bairro}, ${
    pessoa.cidade
  } - ${pessoa.uf}`;
};

const PessoasQuickView = ({ pessoa, onClose }) => {
  if (pessoa) {
    return (
      <ExtendedQuickView onClose={onClose} className="fixed">
        <React.Fragment>
          <div className="quick-header">Pessoa</div>
          <Row>
            <AlignedCol md={6} alignment="rigth">
              <div className="form-group">
                <label className="label">Nome</label>
                {pessoa.nome}
              </div>
            </AlignedCol>
            <AlignedCol md={4} alignment="rigth">
              <div className="form-group">
                <label className="label">
                  {pessoa.tipoPessoa === 'FISICA' ? 'CPF' : 'CNPJ'}
                </label>
                {formatUtils.formatCpfCnpj(pessoa.cpfCnpj)}
              </div>
            </AlignedCol>
            {pessoa.rg && (
              <React.Fragment>
                <AlignedCol md={3} alignment="rigth">
                  <div className="form-group">
                    <label className="label">RG</label>
                    {pessoa.rg}
                  </div>
                </AlignedCol>
                <AlignedCol md={3} alignment="rigth">
                  <div className="form-group">
                    <label className="label">Órgão Emissor</label>
                    {pessoa.rgOrgaoEmissor}
                  </div>
                </AlignedCol>
                <AlignedCol md={3} alignment="rigth">
                  <div className="form-group">
                    <label className="label">UF</label>
                    {pessoa.rgUf}
                  </div>
                </AlignedCol>
                <AlignedCol md={3} alignment="rigth">
                  <div className="form-group">
                    <label className="label">Data Emissão</label>
                    {pessoa.rgDataEmissao}
                  </div>
                </AlignedCol>
              </React.Fragment>
            )}
            <AlignedCol md={12} alignment="rigth">
              <div className="form-group">
                <label className="label">Endereço</label>
                {getEndereco(pessoa)}
              </div>
            </AlignedCol>
            <AlignedCol md={12} alignment="rigth">
              <div className="form-group">
                <label className="label">Email</label>
                {pessoa.email}
              </div>
            </AlignedCol>
            <AlignedCol md={12} alignment="rigth">
              <div className="form-group">
                <label className="label">Celular</label>
                {formatUtils.formatCelular(pessoa.celular || pessoa.telefone)}
              </div>
            </AlignedCol>

            {(pessoa.percentual || pessoa.percentualVenda) && (
              <>
                <AlignedCol md={4}>
                  <label className="label">Percentual</label>
                  {`${pessoa.percentual} %`}
                </AlignedCol>
                <AlignedCol md={4} alignment="right">
                  <label className="label">Percentual Venda</label>
                  {`${pessoa.percentualVenda} %`}
                </AlignedCol>
                <AlignedCol md={4} alignment="right">
                  <label className="label">Principal</label>
                  {pessoa.principal ? 'Sim' : 'Não'}
                </AlignedCol>
              </>
            )}
          </Row>
        </React.Fragment>
      </ExtendedQuickView>
    );
  }
  return null;
};

export default PessoasQuickView;
