import { History, Location } from 'history';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { match, withRouter } from 'react-router';

type Props = {
  options: object;
  location: Location;
  history: History;
  match: match;
};

const Tracker: React.FC<Props> = ({ options = {}, location }) => {
  const trackPage = (page: any) => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  useEffect(() => trackPage(location.pathname), [location.pathname]);

  return null;
};

export default withRouter<Props, typeof Tracker>(Tracker);
