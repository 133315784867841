import classNames from 'classnames';
import { FastField, FastFieldProps, Field, FormikProps, getIn } from 'formik';
import React from 'react';

import Hint from '../hint/Hint';
import { hasErrors } from './validate';
import { ErrorText } from '..';

type FieldProps = FastFieldProps['field'] & {
  id: string;
  className: string;
  disabled?: boolean;
  maxLength?: number;
  onFocus?: (event: React.FocusEvent<any>) => void;
};

type RenderProps<V = any> = {
  field: FieldProps;
  form: FormikProps<V>;
};

export type Props = {
  id?: string;
  name: string;
  type?: 'number' | 'text' | 'email' | 'password' | 'select' | 'date';
  label?: string;
  size?: number;
  render?(props: RenderProps): void;
  hint?: string;
  hintClass?: string;
  fast?: boolean;
  noLabel?: boolean;
  disabled?: boolean;
  onChange?(event: React.ChangeEvent<any>): void;
  maxLength?: number;
  onBlur?(event: React.FocusEvent<any>): void;
  readOnly?: boolean;
  onFocus?(event: React.FocusEvent<any>): void;
  loading?: boolean;
  suffixLabel?: string;
};

interface FastFieldWithType extends FastFieldProps {
  id: string;
  type: string;
  disabled: boolean;
  maxLength?: number;
  onChange?(event: React.ChangeEvent<any>): void;
  readOnly?: boolean;
  onFocus?: (event: React.FocusEvent<any>) => void;
  onBlur: (event: React.FocusEvent<any>) => void;
  suffixLabel?: string;
}

const Input: React.FunctionComponent<FastFieldWithType> = ({
  id,
  field,
  form,
  type,
  disabled,
  maxLength,
  onChange,
  readOnly,
  onFocus,
  onBlur,
  suffixLabel
}) => {
  const error: boolean = hasErrors(field.name, form);

  const className = classNames({
    error,
    readonly: readOnly,
    'suffix-input': suffixLabel
  });
  return (
    <>
      <input
        {...field}
        id={id}
        type={type}
        value={!field.value && field.value === undefined ? '' : field.value}
        className={className}
        disabled={disabled}
        maxLength={maxLength}
        onChange={onChange || field.onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {suffixLabel ? <label className="suffix-label">{suffixLabel}</label> : ''}
      {error && <ErrorText>{getIn(form.errors, field.name)}</ErrorText>}
    </>
  );
};

const BasicInput: React.FC<Props> = ({
  name,
  type = 'text',
  label,
  size = 2,
  render,
  hint,
  hintClass,
  fast = true,
  disabled = false,
  noLabel = false,
  maxLength,
  onChange,
  onBlur,
  id,
  readOnly,
  onFocus,
  loading = false,
  suffixLabel
}) => {
  if (type === 'date') {
    console.error(
      'O type date do BasicInput está obsoleto! Utilizar o FormikInputDate.'
    );
  }

  if (type === 'number') {
    console.error(
      'O type number do BasicInput está obsoleto! Utilizar o FormikInputInteger.'
    );
  }

  let renderInput = render;

  if (render) {
    renderInput = ({ field, form }: RenderProps) => {
      const error: boolean = hasErrors(field.name, form);

      const className = classNames({
        error: error,
        'suffix-input': suffixLabel
      });

      const fieldprops: FieldProps = {
        ...field,
        id: id || name,
        className: className,
        disabled,
        maxLength,
        onBlur: onBlur ?? field.onBlur,
        onFocus: onFocus
      };

      const props: RenderProps = {
        form,
        field: fieldprops
      };

      return (
        <>
          {render(props)}
          {suffixLabel ? (
            <label className="suffix-label">{suffixLabel}</label>
          ) : (
            ''
          )}
          {error && <ErrorText>{getIn(form.errors, field.name)}</ErrorText>}
        </>
      );
    };
  } else {
    renderInput = (props: RenderProps) => (
      <Input
        {...props}
        id={id || name}
        type={type}
        disabled={disabled}
        maxLength={maxLength}
        onChange={onChange}
        readOnly={readOnly}
        onFocus={onFocus}
        onBlur={onBlur ?? props.field.onBlur}
        suffixLabel={suffixLabel}
      />
    );
  }

  const FormikField = fast ? FastField : Field;

  return (
    <div className={`col-md-${size}`}>
      <div className={`form-group`}>
        {!noLabel && (
          <label className="label" htmlFor={id || name}>
            {label}
            {hint && (
              <Hint
                classes={`inline clean module-color fa-exclamation-circle mobile sm ${hintClass}`}
              >
                {hint}
              </Hint>
            )}
          </label>
        )}
        <div className={suffixLabel ? 'suffix' : ''}>
          <FormikField
            id={name}
            name={name}
            type={type}
            render={renderInput}
            disabled={disabled}
          />
        </div>
        {loading && <i className="fas fa-spinner spinner" />}
      </div>
    </div>
  );
};

export default BasicInput;
