import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  DisplayData,
  FAB,
  Info,
  Loading,
  NotificationActions,
  SectionTitle
} from '@elotech/components';
import ListPropriedadesLaudo from 'itbi-cidadao/src/pages/laudo/ListPropriedadesLaudo';
import { LotePropriedadeQuickView } from 'itbi-common/components';
import { LaudoService, withService } from 'itbi-common/service';
import { FunctionUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';

export const DisplayAPagar = ({ loteLaudo }) => (
  <div key={loteLaudo.id} className="display-data border small mb-xs">
    <div className="row">
      <div className="col-sm-12">
        <DisplayData title="Número do Pedido">
          <div className="dd-content">{loteLaudo.numero}</div>
        </DisplayData>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-6">
        <DisplayData title="Requerente">
          {loteLaudo.requerente?.nome ?? loteLaudo.requerenteNome}
        </DisplayData>
      </div>
      <div className="col-sm-6">
        <DisplayData title="Situação">
          {loteLaudo.situacaoLaudo.descricao}
        </DisplayData>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-6">
        <DisplayData title="Pagamento">
          {FunctionUtils.getLabelPagamentoLaudo(loteLaudo)}
        </DisplayData>
      </div>
      <div className="col-sm-6">
        <DisplayData title="Quantidade de Laudos">
          {loteLaudo.qtdImoveis}
        </DisplayData>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-6">
        <DisplayData title="Valor unitário">
          <FormattedNumber
            value={loteLaudo.valorUnitario}
            style="currency" // eslint-disable-line
            currency="BRL"
            currencyDisplay="symbol"
          />
        </DisplayData>
      </div>
      <div className="col-sm-6">
        <DisplayData title="Valor total">
          <FormattedNumber
            value={loteLaudo.valorTotal}
            style="currency" // eslint-disable-line
            currency="BRL"
            currencyDisplay="symbol"
          />
        </DisplayData>
      </div>
    </div>
  </div>
);

class ResumoLaudo extends React.Component {
  static propTypes = {
    showNotification: PropTypes.func.isRequired,
    laudoService: PropTypes.shape({
      getLaudo: PropTypes.func.isRequired
    }).isRequired
  };
  state = {
    loteLaudo: undefined,
    lotePropriedadeQuickView: undefined,
    loadingLaudo: false,
    novo: false
  };

  refbuttonPrintBoleto = React.createRef();

  componentDidMount() {
    if (this.props.history.location.state) {
      this.setState({ novo: true });
    }
    this.getLaudo();
  }

  componentDidUpdate(_, oldState) {
    const hadLoteLaudoAndUrl = Boolean(
      oldState.loteLaudo && oldState.loteLaudo.urlBoleto
    );
    const hasLoteLaudoAndUrl = Boolean(
      this.state.loteLaudo && this.state.loteLaudo.urlBoleto
    );
    if (
      this.refbuttonPrintBoleto.current &&
      !hadLoteLaudoAndUrl &&
      hasLoteLaudoAndUrl
    ) {
      this.refbuttonPrintBoleto.current.focus();
    }
  }

  getLaudo = () => {
    this.setState({ loadingLaudo: true });
    this.props.laudoService
      .getLaudo(this.props.match.params.id)
      .then(response => {
        this.setState({
          loteLaudo: response.data,
          loadingLaudo: false
        });
      })
      .catch(error => {
        this.setState({ loadingLaudo: false });
      });
  };

  gerarBoleto = loteLaudo => {
    return new Promise(resolve => {
      this.setState({ loadingLaudo: true });
      return this.props.laudoService
        .gerarBoleto(loteLaudo.id)
        .then(result => {
          this.setState({ loteLaudo: result.data });
          resolve(result.data);
        })
        .catch(error => {
          Alert.error({ title: 'Não foi possível gerar o boleto' }, error);
        })
        .finally(() => {
          this.setState({ loadingLaudo: false });
        });
    });
  };

  gerarDebitos = loteLaudo => {
    return new Promise(resolve => {
      this.setState({ loadingLaudo: true });
      return this.props.laudoService
        .gerarDebitos(loteLaudo.id)
        .then(result => {
          this.setState({ loteLaudo: result.data });
          resolve(result.data);
        })
        .catch(error => {
          Alert.error({ title: 'Não foi possível gerar os débitos' }, error);
        })
        .finally(() => {
          this.setState({ loadingLaudo: false });
        });
    });
  };

  renderButtons = (propriedade, loteLaudo) => {
    return (
      <ActionsGroup>
        {loteLaudo.urlDocumento ? (
          <ActionButton
            key="download-button"
            icon="print"
            label="Imprimir Laudo"
            onClick={this.onPrintClick(loteLaudo)}
          />
        ) : null}

        <ActionButton
          key="view-button"
          icon="eye"
          label="Propriedades"
          onClick={this.onViewPropriedades(loteLaudo)}
        />
      </ActionsGroup>
    );
  };

  onViewPropriedades = loteLaudo => () => {
    this.setState({
      lotePropriedadeQuickView: loteLaudo
    });
  };

  onCloseQuickView = () => {
    this.setState({
      lotePropriedadeQuickView: undefined
    });
  };

  onPrintClick = loteLaudo => () => {
    window.open(loteLaudo.urlDocumento, '_blank');
  };

  onPrintBoletoDefault = urlBoleto => {
    window.open(urlBoleto, '_blank');
  };

  onPrintBoleto = async loteLaudo => {
    if (!loteLaudo.idDebito) {
      await this.gerarDebitos(loteLaudo).then(result => {
        loteLaudo = result;
      });
    }
    if (loteLaudo.idDebito && !loteLaudo.urlBoletoServidor) {
      this.gerarBoleto(loteLaudo).then(result => {
        this.onPrintBoletoDefault(result.urlBoleto);
      });
      return;
    }

    this.onPrintBoletoDefault(loteLaudo.urlBoleto);
  };

  render() {
    const {
      loteLaudo,
      lotePropriedadeQuickView,
      loadingLaudo,
      novo
    } = this.state;

    if (!loteLaudo) {
      return <Loading loading={loadingLaudo} />;
    }

    return (
      <Container title="Laudo de Avaliação" icon="paste">
        <Loading loading={loadingLaudo} />
        {novo ? (
          <Info classes="positive mt-xs">
            {`Laudo de Avaliação gerado com sucesso. O número do pedido é: ${
              loteLaudo.numero
            } e está ${loteLaudo.pago ? 'Pago' : 'Aguardando Pagamento'}`}
          </Info>
        ) : null}
        {loteLaudo.situacaoLaudo.nome === 'INDEFERIDO' ? (
          <Info classes="warning mb-xs">
            {`Laudo indeferido, motivo: ${loteLaudo.motivoIndeferimento}`}
          </Info>
        ) : null}
        <SectionTitle>A pagar</SectionTitle>
        <DisplayAPagar loteLaudo={loteLaudo} />

        <SectionTitle>Imóveis</SectionTitle>
        <ListPropriedadesLaudo
          loteLaudo={loteLaudo}
          renderButtons={this.renderButtons}
        />
        <LotePropriedadeQuickView
          laudo={lotePropriedadeQuickView}
          onClose={this.onCloseQuickView}
        />
        <div className="btn-save">
          {!loteLaudo.pago && !loteLaudo.isento && (
            <FAB
              data-test-id="imprimir-boleto"
              icon="barcode"
              iconColor="white"
              ref={this.refbuttonPrintBoleto}
              title="Imprimir guia de pagamento"
              onClick={() => this.onPrintBoleto(loteLaudo)}
              showHintOnFocus
            />
          )}
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ComponentWithService = withService({
  laudoService: LaudoService
})(ResumoLaudo);

const ConnectedComponent = connect(
  null,
  mapDispatchToProps
)(ComponentWithService);

export { ConnectedComponent as default, ResumoLaudo };
