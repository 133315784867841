import {
  ActionButton,
  ActionsGroup,
  Container,
  FAB,
  FormattedDate,
  Hint,
  Loading,
  SearchFilter,
  SearchPagination,
  Table
} from '@elotech/components';
import classnames from 'classnames';
import { FunctionUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';

const renderViewButton = (laudo, { onViewLaudo }) => {
  return (
    <ActionButton
      key="see-button"
      icon="eye"
      label="Ver laudo"
      onClick={onViewLaudo(laudo)}
    />
  );
};

const renderPayButton = (laudo, { onDownloadBoleto }) => {
  if (!laudo.urlBoleto) {
    return null;
  }

  return (
    <ActionButton
      key="pay-button"
      icon="barcode"
      label="Imprimir guia de pagamento"
      onClick={onDownloadBoleto(laudo)}
    />
  );
};

const renderButtons = actions => laudo => {
  const actionButtons = laudo.pago
    ? [renderViewButton]
    : [renderPayButton, renderViewButton];

  return (
    <ActionsGroup>
      {(actionButtons || []).map(renderFn => renderFn(laudo, actions))}
    </ActionsGroup>
  );
};

const LaudoList = props => {
  const {
    onSearch,
    laudos,
    pagination,
    onCreate,
    onEdit,
    onViewLaudo,
    onDownloadBoleto,
    loading,
    showDetailsPrimaryButton,
    buttonRef,
    tipoPessoa,
    searchFields
  } = props;

  const actions = {
    onEdit,
    onViewLaudo,
    onDownloadBoleto
  };

  const paginationSearch = page => onSearch(undefined, page);

  return (
    <Container title="Laudos" icon="paste">
      <Loading loading={loading} />
      <div className="panel table table-responsive">
        <div className="panel-body">
          {searchFields.length > 0 && (
            <SearchFilter fields={searchFields} search={onSearch} />
          )}
          <Table values={laudos}>
            <Table.Column
              header="Data Lançamento"
              value={item => (
                <FormattedDate value={item.dataLancamento} timeZone={'UTC'} />
              )}
            />
            <Table.Column
              header="Número do Pedido"
              value={item => item.numero}
            />
            <Table.Column
              header="Qtd. de Imóveis"
              value={item => item.qtdImoveis}
            />
            <Table.Column
              header="Requerente"
              value={item => item.requerente?.nome ?? item.requerenteNome}
            />
            <Table.Column
              header="Situação"
              value={item =>
                item.situacaoLaudo && (
                  <>
                    {item.situacaoLaudo.descricao}
                    {item.situacaoLaudo.nome === 'INDEFERIDO' && (
                      <Hint
                        classes={`inline clean module-color center-left fa-question-circle mobile sm`}
                      >
                        {item.motivoIndeferimento}
                      </Hint>
                    )}
                  </>
                )
              }
            />
            <Table.Column
              header="Pagamento"
              value={FunctionUtils.getLabelPagamentoLaudo}
            />
            <Table.Column header="" value={renderButtons(actions)} />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={paginationSearch}
            />
          )}
        </div>
      </div>
      {tipoPessoa !== 'JURIDICA' && (
        <div
          className={classnames('btn-save has-details', {
            show: showDetailsPrimaryButton
          })}
        >
          <FAB
            icon="plus"
            iconColor="white"
            title="Novo Laudo"
            onClick={onCreate}
            ref={buttonRef}
            showHintOnFocus
          />
          <div className="details">
            <h1>Novo por aqui?</h1>
            <p className="mt-xs">
              Clique neste botão para solicitar seu primeiro <b>laudo</b>, é{' '}
              <b> simples </b> e <b>rápido</b>.
            </p>
          </div>
        </div>
      )}
    </Container>
  );
};

LaudoList.renderButtons = renderButtons;

LaudoList.propTypes = {
  laudos: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object,
  onSearch: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onViewLaudo: PropTypes.func.isRequired,
  onDownloadBoleto: PropTypes.func.isRequired,
  searchFields: PropTypes.array.isRequired,
  buttonRef: PropTypes.object,
  tipoPessoa: PropTypes.string
};

export default LaudoList;
