import PropTypes from 'prop-types';
import React, { Component } from 'react';

class DiffTable extends Component {
  render() {
    const { children } = this.props;
    return (
      <tr style={{ backgroundColor: '#eaf1f9' }}>
        <td colSpan="5">
          <div className="panel table" style={{ marginBottom: 0 }}>
            <div className="panel-body">
              <div className="table table-responsive">
                <div className="panel-table-outer">
                  <table className="panel-table border fancy">
                    <thead>
                      <tr>
                        <th>Alteração</th>
                        <th>Campo</th>
                        <th>Antes</th>
                        <th colSpan="2">Depois</th>
                      </tr>
                    </thead>
                    <tbody>{children}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

DiffTable.propTypes = {
  children: PropTypes.node.isRequired
};

export default DiffTable;
