import { ConcursoPremiadoResumoPorCpf } from 'itbi-common/type';
import React from 'react';

type Props = {
  concurso: ConcursoPremiadoResumoPorCpf;
  renderRegulamentoText: () => void;
};

const ConcursoPremiadoExclusaoPage: React.FC<Props> = ({
  concurso,
  renderRegulamentoText
}) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <div>{'Ops! Você não pode participar do sorteio'}</div>
      <div>{`Motivo da exclusão: ${concurso.motivoExclusao ?? ''}`}</div>
      <div>Dúvidas confira o {renderRegulamentoText()}.</div>
    </div>
  );
};

export default ConcursoPremiadoExclusaoPage;
