import axios, { AxiosPromise } from 'axios';

export const visualizarCodigoDeBarras = (
  listaDebitos: any[],
  dataReferencia: string
): AxiosPromise => {
  const requestParams = {
    resource: `/api/bloqueto/gera-por-data-pagamento`,
    body: { debitos: listaDebitos, dataReferencia: dataReferencia }
  };
  return axios.post(requestParams.resource, requestParams.body);
};
