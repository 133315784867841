import { AxiosPromise } from 'axios';
import React from 'react';

import AutoComplete, { AutoCompleteProps } from '../inputs/AutoComplete';
import EditableInput, { EditableInputProps } from './EditableInput';

export type EditableAutoCompleteProps<T> = Partial<EditableInputProps<T>> &
  Partial<AutoCompleteProps> & {
    name: string;
    onSearch(params: string): AxiosPromise<any>;
  };

const EditableAutoComplete = <T extends {}>(
  props: EditableAutoCompleteProps<T>
) => {
  const onSelect = (name: string, value: any | undefined, onBlur: any) => {
    props.onSelect && props.onSelect(name, value);
    onBlur();
  };

  return (
    <EditableInput
      {...props}
      onConfirmChange={() => {}}
      renderEditComponent={({ onBlur }) => (
        <AutoComplete
          {...props}
          onSelect={(name, value) => onSelect(name, value, onBlur)}
        />
      )}
    />
  );
};

export default EditableAutoComplete;
