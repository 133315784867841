import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  lastDayOfMonth,
  parse
} from 'date-fns';

export const getTodayDate = (): string => {
  return new Date().toISOString().split('T')[0];
};
export const getCurrentDateTime = (): string => {
  return new Date().toLocaleString('sv').replace(' ', 'T');
};

export const getCurrentMonth = (): number => {
  return new Date().getMonth() + 1;
};

export const getLastDayOfMonth = (date: string): string => {
  return lastDayOfMonth(date)
    .toISOString()
    .split('T')[0];
};

export const addMonths = (date: Date, inc: number) => {
  date.setMonth(date.getMonth() + inc);
  return date;
};

export const getTodayDateIncDays = (days: number = 0) => {
  const dataAtual = new Date();
  dataAtual.setDate(dataAtual.getDate() + days);
  return dataAtual.toISOString().split('T')[0];
};

export const getTodayDateDecDays = (days: number = 0) => {
  const dataAtual = new Date();
  dataAtual.setDate(dataAtual.getDate() - days);
  return dataAtual.toISOString().split('T')[0];
};

export const convertStringToDate = (data: string): Date | null => {
  if (!data) {
    return null;
  }
  return data.includes('T') ? new Date(data) : new Date(data + 'T00:00:00');
};

export const mesesAno = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
];

export const abreviacaoSemana = [
  'Dom',
  'Seg',
  'Ter',
  'Qua',
  'Qui',
  'Sex',
  'Sáb'
];

export const getDiferencaDatasFormatada = (
  dataInicio: string | Date,
  dataFim: string | Date
) => {
  const horas = differenceInHours(dataFim, dataInicio);
  const minutos = differenceInMinutes(dataFim, dataInicio) % 60;
  const segundos = differenceInSeconds(dataFim, dataInicio) % 60;

  const stringHoras = horas.toString().padStart(2, '0');
  const stringMinutos = minutos.toString().padStart(2, '0');
  const stringSegundos = segundos.toString().padStart(2, '0');

  return `${stringHoras}:${stringMinutos}:${stringSegundos}`;
};

export const convertDateToTimeInMilliseconds = (date: string | Date) =>
  date ? new Date(date).getTime() : 0;
