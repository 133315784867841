import { BasicInput, Row } from '@elotech/components';
import React from 'react';

type Props = {};

const PropriedadeFormRural: React.FC<Props> = () => {
  return (
    <>
      <Row>
        <BasicInput
          size={4}
          name={'cadastroRural.nome'}
          label={'Nome'}
          disabled
        />
        <BasicInput
          size={5}
          name={'cadastroRural.enderecoDescrito'}
          label={'Endereço Descrito'}
          disabled
        />
      </Row>
      <Row>
        <BasicInput
          size={4}
          name={'cadastroRural.logradouro'}
          label={'Logradouro'}
          disabled
        />
        <BasicInput
          size={2}
          name={'cadastroRural.bairro'}
          label={'Bairro'}
          disabled
        />
        <BasicInput
          size={2}
          name={'cadastroRural.complemento'}
          label={'Complemento'}
          disabled
        />
        <BasicInput
          size={2}
          name={'cadastroRural.distrito'}
          label={'Distrito'}
          disabled
        />
        <BasicInput
          size={2}
          name={'cadastroRural.setor'}
          label={'Setor'}
          disabled
        />
      </Row>
      <Row>
        <BasicInput
          size={2}
          name={'cadastroRural.numeroReceita'}
          label={'NIRF'}
          hint={'Número do Imóvel na Receita Federal'}
          disabled
        />
        <BasicInput
          size={2}
          name={'cadastroRural.numeroIncra'}
          label={'Número no INCRA'}
          disabled
        />
        <BasicInput
          size={2}
          name={'cadastroRural.alqueires'}
          label={'Qtd. Alqueires'}
          disabled
        />
        <BasicInput
          size={2}
          name={'cadastroRural.hectares'}
          label={'Qtd. Hectares'}
          disabled
        />
        <BasicInput
          size={2}
          name={'cadastroRural.areaTotal'}
          label={'Área total'}
          disabled
        />
      </Row>
    </>
  );
};

export default PropriedadeFormRural;
