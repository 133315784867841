import {
  BlockDash,
  DateUtils,
  FormattedDate,
  Row,
  SectionTitle,
  SwitchButtons
} from '@elotech/components';
import { ConcursoPremiadoResumoPorCpf } from 'itbi-common/type';
import React, { useContext, useState } from 'react';
import { FormattedNumber, IntlContext } from 'react-intl';

import ConcursoPremiadoCuponsPage from './ConcursoPremiadoCuponsPage';
import ConcursoPremiadoNotasPage from './ConcursoPremiadoNotasPage';

type Props = {
  concurso: ConcursoPremiadoResumoPorCpf;
  renderRegulamentoText: () => void;
};

const tiposVisualizar = [
  {
    key: 'CUPOM',
    value: 'CUPOM',
    label: 'Cupom'
  },
  {
    key: 'NOTA',
    value: 'NOTA',
    label: 'Nota'
  }
];

const ConcursoPremiadoContaCorrentePage: React.FC<Props> = ({
  concurso,
  renderRegulamentoText
}) => {
  const { formatNumber } = useContext(IntlContext);

  const concursoGerouCupons =
    concurso.situacao !== 'VIGENTE' && concurso.situacao !== 'APURANDO';

  const [cupomOrNota, setCupomOrNota] = useState<'CUPOM' | 'NOTA'>('NOTA');

  const jaSorteado =
    DateUtils.getTodayDate() > concurso.dataSorteio ||
    concurso.situacao === 'ENCERRADO';

  const onChangeSwitchCupomNota = (value: 'CUPOM' | 'NOTA') => {
    setCupomOrNota(value);
  };

  return (
    <>
      <Row>
        <div className={'mt-xs'} style={{ textAlign: 'center' }}>
          <p className={'typography-size-20px'}>Sua nota vale prêmios!</p>
          <p
            className={
              'typography-size-30px typography-bold primary-color center'
            }
          >
            {concurso.premiacao}
          </p>
          <p className={'typography-size-15px typography-bold center'}>
            em prêmios
          </p>
          <div>
            <div>
              {'A cada '}
              <FormattedNumber
                style="currency" // eslint-disable-line
                currency="BRL"
                value={concurso.valorCupom}
              />
              {
                ' de NFS-e de serviços você terá direito a um cupom para o sorteio.'
              }
            </div>
          </div>
          <p>Dúvidas confira o {renderRegulamentoText()}.</p>
          {concurso?.dataSorteio && (
            <p>
              {''}
              {jaSorteado ? (
                'CONCURSO FOI REALIZADO'
              ) : (
                <>
                  {'SORTEIO SERÁ REALIZADO NO DIA '}
                  <b>
                    <FormattedDate
                      value={concurso.dataSorteio}
                      timeZone={'UTC'}
                    />
                  </b>
                </>
              )}
            </p>
          )}
        </div>
        <SectionTitle hasButton={true}>
          Conta Corrente
          {concursoGerouCupons && (
            <SwitchButtons
              data-test-id="cupomosnota-switch-button"
              name="tipoFiltro"
              value={cupomOrNota}
              options={tiposVisualizar}
              onChange={onChangeSwitchCupomNota}
            />
          )}
        </SectionTitle>
        <BlockDash
          md={3}
          sm={2}
          icon="fa fa-dollar-sign"
          title={
            concurso.situacao === 'VIGENTE' ? 'SALDO PREVISTO' : 'SALDO EFETIVO'
          }
          number={formatNumber(concurso?.valorNotas ?? 0, {
            style: 'currency',
            currency: 'BRL'
          })}
        />
        <BlockDash
          md={3}
          sm={2}
          icon="fa fa-file-invoice"
          title={
            concurso.situacao === 'VIGENTE'
              ? 'CUPONS PREVISTOS'
              : 'CUPONS GERADOS'
          }
          number={concurso?.cupons ?? 0}
        />
      </Row>
      <Row>
        {cupomOrNota === 'CUPOM' && concursoGerouCupons ? (
          <ConcursoPremiadoCuponsPage concurso={concurso} />
        ) : (
          <ConcursoPremiadoNotasPage concurso={concurso} />
        )}
      </Row>
    </>
  );
};

export default ConcursoPremiadoContaCorrentePage;
