import {
  Checkbox,
  Col,
  ErrorText,
  Row,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class DocumentosAvulsosForm extends Component {
  static propTypes = {
    onAdd: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    isArbitragem: PropTypes.bool,
    customSectionTitle: PropTypes.string
  };

  state = {
    arquivo: undefined,
    descricao: '',
    visivelCidadao: false,
    loading: false,
    arquivoErrorMessage: undefined,
    descricaoErrorMessage: undefined,
    arbitragem: false
  };

  onAdd = async () => {
    const {
      arquivo,
      descricao,
      visivelCidadao,
      arquivoErrorMessage
    } = this.state;
    const {
      showNotification,
      onAdd,
      isArbitragem,
      customSectionTitle
    } = this.props;

    const invalidFile =
      !arquivo || arquivo.length !== 1 || arquivoErrorMessage !== undefined;
    const invalidDescription = !descricao;

    if (invalidFile || invalidDescription) {
      invalidFile &&
        arquivoErrorMessage === undefined &&
        this.setState({
          arquivoErrorMessage: 'O Arquivo é obrigatório.'
        });
      invalidDescription &&
        this.setState({ descricaoErrorMessage: 'A descrição é obrigatória.' });
      return showNotification({
        level: 'warning',
        message: 'Verifique os erros antes de adicionar o arquivo.'
      });
    }
    try {
      await onAdd({
        arquivo: arquivo[0],
        descricao,
        nome: arquivo[0].name,
        visivelCidadao: visivelCidadao,
        arbitragem: isArbitragem
      });
      return this.setState({
        arquivo: undefined,
        descricao: '',
        visivelCidadao: false
      });
    } catch (error) {
      if (error !== 'ALREADY_EXISTS_SAME_NAME') {
        showNotification({
          level: 'warning',
          message: 'Não foi possível salvar o arquivo. Tente novamente.'
        });
      }
    }
  };

  onUploadFile = event => {
    const { files } = event.target;

    const isValid = !(files && files[0] && files[0].size > 50000000);

    const errorObj = isValid
      ? { arquivoErrorMessage: undefined }
      : {
          arquivoErrorMessage: 'Arquivo maior que 50Mb'
        };

    this.setState({ arquivo: files, ...errorObj });
  };

  onChangeDescricao = event => {
    const { value } = event.target;

    const descricaoErrorMessage = value
      ? undefined
      : 'A descrição é obrigatória.';
    this.setState({ descricao: value, descricaoErrorMessage });
  };

  onChangeVisivelCidadao = event => {
    const { checked } = event.target;

    this.setState({ visivelCidadao: checked });
  };
  render() {
    const {
      arquivo,
      arquivoErrorMessage,
      descricaoErrorMessage,
      descricao,
      visivelCidadao
    } = this.state;
    const { customSectionTitle } = this.props;
    return (
      <div>
        <SectionTitle>
          {customSectionTitle || 'Documentos Avulsos'}
        </SectionTitle>
        <Row>
          <Col sm={3} className="form-group">
            <label htmlFor="upload-file-input">Arquivo</label>
            <div className="file-uploader">
              <input
                id="upload-file-input"
                type="file"
                className={`file-uploader-input${
                  arquivoErrorMessage ? ' error' : ''
                }`}
                title="Clique ou arraste para anexar"
                onChange={this.onUploadFile}
              />

              <label
                htmlFor="upload-file-input"
                className="input"
                data-title={
                  arquivo && arquivo.length > 0
                    ? arquivo[0].name
                    : 'Clique ou arraste para anexar'
                }
              />

              <label
                htmlFor="upload-file-input"
                className="file-uploader-icon"
              />

              {arquivoErrorMessage && (
                <ErrorText>{arquivoErrorMessage}</ErrorText>
              )}
            </div>
          </Col>
          <Col sm={3} className="form-group">
            <label htmlFor="descricao-input">Descrição</label>
            <input
              id="descricao-input"
              className={`${descricaoErrorMessage ? ' error' : ''}`}
              value={descricao}
              title="Descricao"
              onChange={this.onChangeDescricao}
            />
            {descricaoErrorMessage && (
              <ErrorText>{descricaoErrorMessage}</ErrorText>
            )}
          </Col>
          <Col sm={3}>
            <Checkbox
              className="form-group mt-xs"
              name="visivelCidadao"
              id="visivel-cidadao-checkbox"
              checked={visivelCidadao}
              onChange={this.onChangeVisivelCidadao}
              label="Visível para o cidadão"
            />
          </Col>
          <Col sm={1} className="form-group">
            <label className="label" />
            <button
              data-test-id="addButton"
              type="button"
              className="btn module-color"
              onClick={this.onAdd}
            >
              Adicionar
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DocumentosAvulsosForm;
