import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Col, Row } from '../grid';

const TableReportRow = ({ children, clickable, nowrap, error, warning }) => {
  const itemClasses = classnames('group-item', {
    clickable: clickable,
    nowrap: nowrap,
    error: error,
    warning: warning
  });

  return (
    <div className={itemClasses}>
      <Row>
        <Col md={12}>{children}</Col>
      </Row>
    </div>
  );
};

TableReportRow.propTypes = {
  nowrap: PropTypes.bool,
  clickable: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.bool
};

export default TableReportRow;
