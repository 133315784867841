import React from 'react';

import { FormattedCurrency, FormattedDate, Panel, Table } from '../';
import { CampoTipoEnum, GrupoResposta, Resposta } from '../type';

type Props = {
  grupoResposta: GrupoResposta;
};

const GrupoRespostaCampoDinamicoView: React.FC<Props> = ({ grupoResposta }) => {
  const getKey = (resposta: Resposta, indexResposta: number) =>
    `${resposta.id}-${indexResposta}`;

  const renderItemCampoDinamico = (resposta: Resposta) => {
    switch (resposta.campo.tipo) {
      case CampoTipoEnum.MULTIPLA_ESCOLHA:
        return resposta.alternativa
          ? `${resposta.alternativa.codigo} - ${resposta.alternativa.descricao}`
          : '';
      case CampoTipoEnum.DECIMAL:
        return <FormattedCurrency hideSymbol value={resposta.valor} />;
      case CampoTipoEnum.DATA:
        return <FormattedDate value={resposta.valor} />;
      default:
        return resposta.valor;
    }
  };

  const getRespostasAtivas = () =>
    grupoResposta.respostas.filter(r => r.campo.ativo);

  return (
    <>
      {grupoResposta.ativo && grupoResposta?.respostas && (
        <Panel isTable>
          <Table<Resposta>
            fixed
            values={getRespostasAtivas()}
            keyExtractor={getKey}
          >
            <Table.Column<Resposta>
              header="Código"
              value={item => item.campo.codigo}
            />
            <Table.Column<Resposta>
              header="Descrição"
              value={item => item.campo.descricao}
            />
            <Table.Column<Resposta>
              header="Valor Resposta"
              value={item => renderItemCampoDinamico(item)}
            />
          </Table>
        </Panel>
      )}
    </>
  );
};

export default GrupoRespostaCampoDinamicoView;
