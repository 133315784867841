import {
  DisplayDataGrid,
  DisplayDataItem,
  FormattedCurrency,
  Row,
  SectionTitle,
  formatUtils
} from '@elotech/components';
import React from 'react';

import {
  DebitoParcelamentoTotalizador,
  ParametroParcelamento,
  ParcelamentoCadastroDTO
} from '../../../types';
import { Requerente } from './ParcelamentoFormPage';

type Props = {
  cadastro: ParcelamentoCadastroDTO;
  requerente: Requerente;
  lei?: ParametroParcelamento;
  totalizador?: DebitoParcelamentoTotalizador;
};

const ParcelamentoIdentificacao: React.FC<Props> = ({
  cadastro,
  requerente,
  totalizador,
  lei
}) => (
  <>
    <SectionTitle marginTop="0px">Identificação</SectionTitle>
    <DisplayDataGrid className="mb-xs">
      <Row>
        <DisplayDataItem md={3} title={cadastro.tipoCadastroDescricao}>
          {cadastro.cadastroGeral}
        </DisplayDataItem>

        <DisplayDataItem md={6} title="Endereço">
          {cadastro.enderecoCompleto}
        </DisplayDataItem>
        <DisplayDataItem md={3} title="Cidade">
          {`${cadastro.cidade} - ${cadastro.unidadeFederacao}`}
        </DisplayDataItem>
      </Row>
      <Row>
        <DisplayDataItem md={9} title="Nome">
          {requerente.nome}
        </DisplayDataItem>
        <DisplayDataItem md={3} title="CPF/CNPJ">
          {formatUtils.formatCpfCnpj(requerente.cpfCnpj)}
        </DisplayDataItem>
      </Row>
      {lei && (
        <Row>
          <DisplayDataItem md={9} title={'Lei selecionada'}>
            {lei.descricao}
          </DisplayDataItem>
          <DisplayDataItem md={3} title={'Qtd. máxima parcelas'}>
            {lei.numeroParcela}
          </DisplayDataItem>
        </Row>
      )}
      {totalizador && (
        <Row>
          <DisplayDataItem md={3} title={'Qtd. de débitos selecionados'}>
            {totalizador.quantidadeDebitosSelecionados}
          </DisplayDataItem>
          <DisplayDataItem md={3} title={'Valor total selecionado'}>
            <FormattedCurrency value={totalizador.totalDebitosSelecionados} />
          </DisplayDataItem>
          <DisplayDataItem md={3} title={'Valor total pendente'}>
            <FormattedCurrency value={totalizador.totalDebitosPendentes} />
          </DisplayDataItem>
          <DisplayDataItem md={3} title={'Valor total devido'}>
            <FormattedCurrency value={totalizador.totalDebitos} />
          </DisplayDataItem>
        </Row>
      )}
    </DisplayDataGrid>
  </>
);

export default ParcelamentoIdentificacao;
