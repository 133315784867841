import { useRef } from 'react';
import { Socket, SocketOptions, io } from 'socket.io-client';
import useDeepCompareEffect from 'use-deep-compare-effect';

const authId = '1511ae76';

const useSocket = (url: string, options: SocketOptions): Socket => {
  const socketRef = useRef<Socket>();

  useDeepCompareEffect(() => {
    socketRef.current = io(url, options);
    return () => {
      console.log('close');
      socketRef.current?.close();
    };
  }, [url, options]);
  return socketRef.current!;
};

export { useSocket, authId };
