import React, { ReactNode } from 'react';

import { Col } from '../grid';

type Props = {
  title: string;
  children: ReactNode;
  sm?: number;
  md?: number;
};

const DisplayDataItem: React.FC<Props> = ({
  sm = 12,
  md = 12,
  title,
  children
}) => {
  return (
    <Col sm={sm} md={md}>
      <b className="dd-title">{title}</b>
      <div className="dd-content">{children}</div>
    </Col>
  );
};

export default DisplayDataItem;
