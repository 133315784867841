import classnames from 'classnames';
import React, { ReactNode } from 'react';

type Props = {
  title: ReactNode;
  children: ReactNode;
  isTable: boolean;
  isForm: boolean;
  collapsed: boolean;
  expansible: boolean;
  highlight?: boolean;
  mobileClose: boolean;
  insidePanel: boolean;
  onClickPanel(collapsed: boolean): void;
  style: { [attr: string]: any };
  className?: string;
  footer?: ReactNode;
  scrollHorizontal?: boolean;
  unmountWhenCollapsed?: boolean;
};

type State = {
  collapsed: boolean;
};

export default class Panel extends React.Component<Props, State> {
  static defaultProps: Partial<Props> = {
    collapsed: false,
    expansible: false,
    mobileClose: true
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      collapsed: props.collapsed
    };
  }

  componentDidMount() {
    const width = window.innerWidth;
    if (width < 768) {
      this.setState({
        collapsed: this.props.mobileClose
      });
    }
  }

  componentDidUpdate(oldProps: Props) {
    if (oldProps.collapsed !== this.props.collapsed) {
      this.setState({ collapsed: this.props.collapsed });
    }
  }

  onClick = () => {
    const { onClickPanel } = this.props;
    if (onClickPanel) {
      onClickPanel(!this.state.collapsed);
    } else {
      this.setState(state => ({ collapsed: !state.collapsed }));
    }
  };

  collapse = () => {
    this.setState({
      collapsed: true
    });
  };

  render() {
    const { collapsed } = this.state;

    const {
      title,
      isTable,
      isForm,
      expansible,
      insidePanel,
      scrollHorizontal,
      style,
      className,
      highlight,
      footer,
      children,
      unmountWhenCollapsed = false
    } = this.props;

    const childElement =
      unmountWhenCollapsed && collapsed ? undefined : children;

    const classes = classnames(className, {
      panel: true,
      expansible: expansible,
      open: !collapsed,
      highlight,
      'panel-form': isForm,
      'table table-responsive': isTable,
      'inside-panel': insidePanel
    });

    const bodyClasses = classnames('panel-body', {
      'scroll-x': scrollHorizontal
    });

    return (
      <div className={classes} style={style}>
        <span
          data-test-id="dropdown"
          className="dropdown-arrow"
          onClick={this.onClick}
          title="Clique para expandir ou minimizar"
        />
        {title && (
          <div className="panel-title" onClick={this.onClick}>
            {title}
          </div>
        )}
        <div className={bodyClasses}>{childElement}</div>
        {footer}
      </div>
    );
  }
}
