import classnames from 'classnames';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  column?: boolean;
  highlight?: boolean;
  large?: boolean;
  className?: string;
  border?: boolean;
};

const DisplayDataGrid: React.FC<Props> = ({
  children,
  column,
  highlight,
  large,
  className,
  border
}) => {
  const classesGrid = classnames(
    `display-data ${border == undefined || border == true ? 'border' : ''}`,
    className,
    {
      column,
      highlight,
      small: !large
    }
  );

  return <div className={classesGrid}>{children}</div>;
};

export default DisplayDataGrid;
