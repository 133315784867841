import axios, { AxiosPromise } from 'axios';

export const imprimeEspelhoCadastro = (
  tipoCadastro: string,
  cadastroGeral: string
): AxiosPromise<any> =>
  axios.get(
    `/api/espelho-cadastro-geral/imprime?tipo-cadastro=${tipoCadastro}&cadastro-geral=${cadastroGeral}`,
    { responseType: 'blob' }
  );
