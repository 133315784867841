import { round } from './formatUtils';

export const objectToSelectOptions = (object: object) =>
  Object.entries(object).map(([key, label]) => ({ key, value: key, label }));

export const booleanToSimNao = (value: boolean): string =>
  value ? 'Sim' : 'Não';

export const isEmptyObject = (object: object): boolean =>
  Object.keys(object).length === 0;

export const splitValues = (
  value: number,
  numberOfDivisions: number,
  precision: number = 2
): number[] => {
  const divisionValue = round(value / numberOfDivisions, precision);
  const [first, ...rest] = [...Array(numberOfDivisions)].fill(divisionValue);
  const diff = value - round(divisionValue * numberOfDivisions, precision);

  return [round(first + diff, precision), ...rest];
};

export const tipoPessoaFrom = (tipoPessoa?: string): string => {
  if (tipoPessoa === 'F') {
    return 'Física';
  }
  if (tipoPessoa === 'J') {
    return 'Jurídica';
  }
  return '';
};

export const copyObject = (src: any) => JSON.parse(JSON.stringify(src));
