import React from 'react';

type Props = {
  children: React.ReactNode;
};

/**
 * Componente de table inside para ser utilizado como tabela filha de outra tabela
 **/
const TableChild: React.FC<Props> = ({ children }) => {
  return (
    <tr className="child-table">
      <td colSpan={12}>{children}</td>
    </tr>
  );
};

export default TableChild;
