import { EnumValue } from '../types/EnumType';

type TipoCertidao = EnumValue & { codigo: number };

export const TipoCertidaoEnum = {
  certidaoCadastroMunicipal: 2,
  certidaoContribuinte: 33
};

export const TipoCertidao: TipoCertidao[] = [
  { codigo: 2, descricao: 'CERTIDAOCADASTRO' },
  { codigo: 33, descricao: 'CERTIDAOCONTRIBUINTE' }
];

export const getTipoCertidaoByCodigo = (codigo: number): TipoCertidao =>
  TipoCertidao.find(item => item.codigo === codigo)!;

export const getTipoCertidaoByDescricao = (descricao: string): TipoCertidao =>
  TipoCertidao.find(item => item.descricao === descricao)!;
