import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  .spinner {
    margin-top: -21px;
  }
`;

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.search = React.createRef();
  }
  focus = () => {
    this.search.current && this.search.current.focus();
  };

  render() {
    const { loading, selected, onClear, ...otherProps } = this.props;
    return (
      <StyledDiv>
        <input
          disabled={loading}
          className={loading ? 'loading' : selected ? 'selected' : ''}
          {...otherProps}
          ref={this.search}
        />
        {loading && (
          <FontAwesomeIcon icon="spinner" className="spinner" pulse />
        )}

        {selected && (
          <button onClick={onClear} className="clear-button">
            <FontAwesomeIcon icon="times" />
          </button>
        )}
      </StyledDiv>
    );
  }
}

export default Search;
