import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../icons/Icon';
import Step from './Step';

const clearProps = object => {
  return Object.keys(object).reduce((result, currentField) => {
    if (
      currentField !== 'children' &&
      typeof object[currentField] !== 'function'
    ) {
      result[currentField] = object[currentField];
    }
    return result;
  }, {});
};

const defaultShoudChange = (oldRoute, newRoute, steps, allowInvalidChange) => {
  if (newRoute.index < oldRoute.index) {
    return true;
  }
  const someInvalid = steps
    .slice(oldRoute.index + 1, newRoute.index)
    .some(step => step.props.visible && !step.props.valid);
  return (!someInvalid && oldRoute.valid) || allowInvalidChange;
};

class Wizard extends React.Component {
  static Step = Step;

  static propTypes = {
    allowIconNavigation: PropTypes.bool,
    allowInvalidChange: PropTypes.bool,
    shouldChange: PropTypes.func,
    onChange: PropTypes.func,
    onExit: PropTypes.func,
    onFinish: PropTypes.func,
    beforeChange: PropTypes.func,
    labelExitButton: PropTypes.string,
    labelPreviousButton: PropTypes.string,
    labelNextButton: PropTypes.string,
    labelFinishButton: PropTypes.string,
    finishButtonOnlyOnLastStep: PropTypes.bool,
    showExitButton: PropTypes.bool,
    submittingFinishButton: PropTypes.bool,
    initialStep: PropTypes.string,
    submittingNextButton: PropTypes.bool
  };

  static defaultProps = {
    allowIconNavigation: true,
    finishButtonOnlyOnLastStep: false,
    allowInvalidChange: false,
    showExitButton: false,
    submittingFinishButton: false,
    submittingNextButton: false,
    labelExitButton: 'Sair',
    labelPreviousButton: 'Anterior',
    labelNextButton: 'Próximo',
    labelFinishButton: 'Finalizar',
    onExit: () => ({}),
    onFinish: () => ({}),
    onChange: () => ({}),
    beforeChange: (oldStepData, newStepData) => ({ oldStepData, newStepData }),
    shouldChange: defaultShoudChange
  };

  state = {
    firstVisible: undefined,
    lastVisible: undefined,
    currentStepId: undefined,
    stepIndexes: {},
    showError: false
  };

  componentDidMount() {
    const children = React.Children.toArray(this.props.children);
    const stepIndexes = children.reduce((result, step, index) => {
      result[step.props.stepId] = index;
      return result;
    }, {});

    const { initialStep } = this.props;
    const { firstVisible, lastVisible } = this.getVisibleBounds(children);

    this.setState({
      currentStepId: initialStep
        ? initialStep
        : children[firstVisible].props.stepId,
      stepIndexes,
      lastVisible,
      firstVisible
    });
  }

  componentDidUpdate(oldProps) {
    const extractVisible = step => step.props.visible;

    const oldVisible = React.Children.map(oldProps.children, extractVisible);
    const newVisible = React.Children.map(this.props.children, extractVisible);

    const visibleHasChanged = oldVisible.reduce(
      (result, current, index) => result || current !== newVisible[index],
      false
    );

    if (visibleHasChanged) {
      const { firstVisible, lastVisible } = this.getVisibleBounds(
        React.Children.toArray(this.props.children)
      );
      this.setState({ firstVisible, lastVisible });
    }

    if (this.state.showError) {
      setTimeout(
        () => this.setState({ showError: !this.state.showError }),
        3000
      );
    }
  }

  getVisibleBounds = steps => {
    return steps.reduce(
      (result, current, index) => {
        return {
          firstVisible:
            current.props.visible && result.firstVisible === undefined
              ? index
              : result.firstVisible,
          lastVisible: current.props.visible ? index : result.lastVisible
        };
      },
      {
        firstVisible: undefined,
        lastVisible: undefined
      }
    );
  };

  goToStep = async newStepId => {
    const { currentStepId, stepIndexes } = this.state;
    const {
      onChange,
      shouldChange,
      children: steps,
      allowInvalidChange,
      beforeChange
    } = this.props;

    const oldStepData = {
      ...clearProps(steps[stepIndexes[currentStepId]].props),
      index: stepIndexes[currentStepId]
    };

    const newStepData = {
      ...clearProps(steps[stepIndexes[newStepId]].props),
      index: stepIndexes[newStepId]
    };
    const realSteps = await beforeChange(oldStepData, newStepData);
    const result = await shouldChange(
      realSteps.oldStepData,
      realSteps.newStepData,
      steps,
      allowInvalidChange
    );

    if (result) {
      this.setState({ currentStepId: newStepId }, () => {
        onChange(realSteps.oldStepData, realSteps.newStepData);
      });
    } else {
      this.setState({ showError: true });
    }
    return Promise.resolve(result);
  };

  onMove = (currentStepId, step) => {
    const { children: steps } = this.props;
    const { stepIndexes } = this.state;
    const newStep = steps[stepIndexes[currentStepId] + step];
    if (newStep.props.visible) {
      return this.goToStep(newStep.props.stepId);
    } else {
      return this.onMove(newStep.props.stepId, step);
    }
  };

  onExitClick = () => {
    const { currentStepId, stepIndexes } = this.state;
    this.props.onExit({
      stepId: currentStepId,
      index: stepIndexes[currentStepId]
    });
  };

  onFinishClick = () => {
    const { currentStepId, stepIndexes } = this.state;
    this.props.onFinish({
      stepId: currentStepId,
      index: stepIndexes[currentStepId]
    });
  };

  onPreviousClick = () => {
    const { currentStepId } = this.state;
    return this.onMove(currentStepId, -1);
  };

  onNextClick = () => {
    const { currentStepId } = this.state;
    return this.onMove(currentStepId, +1);
  };

  validateChildren = children => {
    if (React.Children.count(children) === 0) {
      throw new Error(
        'Wizard component children must have at least one child.'
      );
    }
    if (React.Children.toArray(children).some(child => child.type !== Step)) {
      throw new Error('All wizard component children must be Wizard.Step.');
    }
  };

  renderHeader() {
    const { allowIconNavigation } = this.props;
    const { stepIndexes, currentStepId, showError } = this.state;

    return (
      <ul className="wizard-header">
        {React.Children.map(this.props.children, (child, index) => {
          if (!child.props.visible) {
            return null;
          }
          const classStep = classnames('step', {
            active: stepIndexes[currentStepId] >= index,
            'active-mobile': stepIndexes[currentStepId] === index,
            on: !child.props.valid && showError
          });
          return (
            <li
              key={child.props.stepId}
              data-test-id={`icon-${child.props.stepId}`}
              className={classStep}
              onClick={() =>
                allowIconNavigation && this.goToStep(child.props.stepId)
              }
              data-error={!child.props.valid}
            >
              <em className={`fa ${child.props.icon}`} />
              {!child.props.valid && (
                <p className="error-content">{child.props.errorMessage}</p>
              )}
              <div className="title">{child.props.label}</div>
            </li>
          );
        })}
      </ul>
    );
  }

  renderButton = (
    buttonType,
    stepProps,
    className = 'btn',
    otherValidation = true,
    disabled = false
  ) => {
    const showButtonProp = `show${buttonType}Button`;
    const buttonLabel = `label${buttonType}Button`;
    const submitting = stepProps[`submitting${buttonType}Button`];

    if (
      otherValidation &&
      (this.props[showButtonProp] || stepProps[showButtonProp])
    ) {
      return (
        <button
          data-test-id={`${buttonType.toLowerCase()}-button`}
          data-cy={`button${buttonType}`}
          className={className}
          onClick={this[`on${buttonType}Click`]}
          disabled={submitting || disabled}
        >
          {stepProps[buttonLabel] || this.props[buttonLabel]}
          {submitting && <Icon icon="spinner" darkPrimary spin={submitting} />}
        </button>
      );
    }
    return null;
  };

  render() {
    const { children: steps, finishButtonOnlyOnLastStep } = this.props;
    const {
      currentStepId,
      stepIndexes,
      lastVisible,
      firstVisible
    } = this.state;

    this.validateChildren(this.props.children);

    const shouldRenderFinishButton =
      stepIndexes[currentStepId] === lastVisible || !finishButtonOnlyOnLastStep;

    const currentStep = React.Children.toArray(steps)[
      stepIndexes[currentStepId]
    ];

    const showExitButton =
      this.props.showExitButton ||
      (currentStep && currentStep.props['showExitButton']);

    const shouldDisabledPreviousButton =
      !stepIndexes[currentStepId] > firstVisible;

    return (
      <div className="wizard title">
        {this.renderHeader()}
        {currentStep && (
          <React.Fragment>
            <div className="wizard-content">{currentStep}</div>

            <div className="footer-wizard">
              {this.renderButton(
                'Previous',
                currentStep.props,
                'btn btn-step-backward neutral inline',
                true,
                shouldDisabledPreviousButton
              )}
              {showExitButton &&
                this.renderButton(
                  'Exit',
                  currentStep.props,
                  'btn neutral btn-step-backward inline'
                )}
              {this.renderButton(
                'Next',
                currentStep.props,
                'btn btn-step-forward module-color inline',
                stepIndexes[currentStepId] < lastVisible
              )}
              {this.renderButton(
                'Finish',
                currentStep.props,
                'btn btn-step-forward module-color inline',
                shouldRenderFinishButton
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Wizard;
