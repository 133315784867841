import classnames from 'classnames';
import React, { useContext } from 'react';

import Icon from '../icons/Icon';
import { HeaderContext } from './Header';

const ModulosButton: React.FC = () => {
  const headerContext = useContext(HeaderContext);
  const className = classnames({
    active: headerContext.activeModulos
  });

  return (
    <li
      className={className}
      title="Módulos"
      onClick={headerContext.toggleModulos}
    >
      <Icon icon="th" className={'icon'} />
      <small className="hidden-xs">Módulos</small>
    </li>
  );
};

export default ModulosButton;
