import { FormattedCurrency, Panel, Table } from '@elotech/components/src';
import React, { ReactNode } from 'react';

import { Laudo } from '../../type';
import { LaudoUtils } from '../../utils';

type Props = {
  propriedades: Laudo[];
  renderButton: (value: Laudo, index: number) => ReactNode;
};

const LaudoPropriedadeList: React.FC<Props> = ({
  propriedades,
  renderButton
}) => {
  return (
    <Panel isTable>
      <Table
        values={propriedades}
        keyExtractor={item => item.cadastro}
        messageEmpty="Laudo sem propriedades"
      >
        <Table.Column<Laudo> header="Cadastro" value={item => item.cadastro} />
        <Table.Column<Laudo>
          header="Endereço"
          value={item => LaudoUtils.buildEnderecoCadastro(item)}
        />
        <Table.Column<Laudo>
          header="Valor Estimado"
          value={item => <FormattedCurrency value={item.valorEstimado} />}
        />
        <Table.Column<Laudo> header="" value={renderButton} />
      </Table>
    </Panel>
  );
};

export default LaudoPropriedadeList;
