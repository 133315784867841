import * as Yup from 'yup';

const yupLocale: Yup.LocaleObject = {
  mixed: {
    required: '${path} é obrigatório',
    oneOf: '${path} deve ser um destes valores: ${values}',
    notOneOf: '${path} não pode ser um destes valores: ${values}'
  },
  string: {
    min: '${path} deve ter no mínimo ${min} caracteres',
    max: '${path} deve ter no máximo ${max} caracteres',
    email: '${path} deve ser um email válido',
    url: '${path} deve ser uma URL válida',
    trim: '${path} não pode ter espaços antes ou depois',
    lowercase: '${path} deve estar em letras minúsculas',
    uppercase: '${path} deve estar em letras maiúsculas'
  },
  number: {
    min: '${path} deve ser maior ou igual a ${min}',
    max: '${path} deve ser menor ou igual a ${max}',
    lessThan: '${path} deve ser menor que ${less}',
    moreThan: '${path} deve ser maior que ${more}',
    positive: '${path} deve ser número positivo',
    negative: '${path} deve ser número negativo',
    integer: '${path} deve ser um número inteiro'
  },
  date: {
    min: '${path} deve ser posterior a ${min}',
    max: '${path} deve ser anterior a ${max}'
  },
  object: {
    noUnknown: '${path} tem chaves não especificadas na descrição do objeto'
  },
  array: {
    min: '${path} deve ter ao menos ${min} itens',
    max: '${path} deve ter no máximo ${max} itens'
  },
  boolean: {}
};

Yup.setLocale(yupLocale);

export default Yup;
