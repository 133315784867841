import React from 'react';

import { BasicInput, SwitchButtons } from '../../index';
import { Omit } from '../../type';
import { Props as BasicInputProps } from '../BasicInput';

type Option = {
  key: string;
  label: string;
  value: any;
};
type Props = Omit<BasicInputProps, 'type' | 'render'> & {
  options: Option[];
  onChange?(value: any): void;
  beforeChange?: (value: any) => boolean;
};

const FormikSwitchButtons: React.FC<Props> = props => {
  return (
    <BasicInput
      {...props}
      render={({ field: { name, value }, form }) => (
        <SwitchButtons
          name={name}
          value={value}
          options={props.options}
          onChange={(value: any) => {
            if (props.beforeChange && !props.beforeChange(value)) {
              return;
            }
            form.setFieldValue(name, value);
            form.setFieldTouched(name, true);
            props.onChange && props.onChange(value);
          }}
        />
      )}
    />
  );
};

export default FormikSwitchButtons;
