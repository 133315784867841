import {
  Button,
  DisplayDataGrid,
  Loading,
  SectionTitle
} from '@elotech/components';
import { Formik } from 'formik';
import { CepService, withService } from 'itbi-common/service';
import { Constantes } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';

import DisplayIdentificacaoRequisicao from './DisplayIdentificacao';
import Form from './Form';
import { getValidationSchema } from './getValidationSchema';
import { validate } from './validate';

const SectionPessoa = ({ showButton, title, onNovo }) => (
  <SectionTitle hasButton={showButton}>
    {title}
    {showButton && (
      <Button
        data-test-id="buttonNovo"
        onClick={onNovo}
        iconPosition="left"
        data-cy={`buttton${title}`}
      >
        <i className="fa fa-plus" />
        Novo
      </Button>
    )}
  </SectionTitle>
);

export class IdentificacaoSectionList extends React.Component {
  formRef = React.createRef();

  static propTypes = {
    list: PropTypes.array.isRequired,
    compradores: PropTypes.array,
    vendedores: PropTypes.array,
    adicionar: PropTypes.func.isRequired,
    editar: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    validaRgComprador: PropTypes.boolean,
    vendedor: PropTypes.bool,
    comprador: PropTypes.bool,
    anuente: PropTypes.bool,
    vinculos: PropTypes.array.isRequired
  };

  getVinculoPrincipal = () => {
    return this.props.vinculos.find(
      item => item.tipoVinculo === Constantes.tipoVinculo.principal
    );
  };

  novo = {
    tipoPessoa: 'FISICA',
    cpfCnpj: '',
    nome: '',
    email: '',
    celular: '',
    cep: '',
    logradouro: '',
    complemento: '',
    cidade: '',
    bairro: '',
    numero: '',
    uf: '',
    vinculo: !this.props.isServidor ? this.getVinculoPrincipal() : undefined,
    percentual: 0,
    percentualVenda: 0,
    rg: '',
    rgOrgaoEmissor: '',
    rgUf: '',
    rgDataEmissao: ''
  };

  state = {
    novo: this.novo,
    loadingDoc: false,
    loadingCep: false,
    loadingDisplay: false,
    showForm: false,
    position: undefined
  };

  confirmar = formValues => {
    const { adicionar, editar } = this.props;
    const { position } = this.state;

    const values = {
      ...formValues,
      vinculo: formValues.vinculo ?? undefined
    };

    values.percentual =
      values.percentual === ''
        ? 0
        : parseFloat(`${values.percentual || '0'}`.replace(',', '.'));

    values.percentualVenda =
      values.percentualVenda === ''
        ? 0
        : parseFloat(`${values.percentualVenda || '0'}`.replace(',', '.'));

    if (position !== undefined) {
      this.setState({ loadingDisplay: true });
      return editar(values, position)
        .then(() =>
          this.setState({
            loadingDisplay: false,
            novo: this.novo,
            position: undefined,
            showForm: false
          })
        )
        .catch(() => {
          this.setState({ loadingDisplay: false });
        });
    } else {
      this.setState({ loadingDisplay: true });
      return adicionar(values)
        .then(() =>
          this.setState({
            loadingDisplay: false,
            novo: this.novo,
            position: undefined,
            showForm: false
          })
        )
        .catch(() => {
          this.setState({ loadingDisplay: false });
        });
    }
  };

  cancelar = () => {
    this.setState({
      novo: this.novo,
      showForm: !this.state.showForm
    });
  };

  onNovo = () => {
    const { comprador, list, user, requerente } = this.props;

    if (
      comprador &&
      list.length === 0 &&
      user &&
      requerente &&
      user.id === requerente.id
    ) {
      this.setState({
        novo: {
          ...this.state.novo,
          cpfCnpj: user.cpfCnpj,
          nome: user.nome,
          email: user.email,
          celular: user.telefone,
          cep: user.cep,
          logradouro: user.logradouro,
          numero: user.numero,
          complemento: user.complemento || '',
          bairro: user.bairro,
          cidade: user.cidade,
          ibge: user.ibge,
          uf: user.uf
        }
      });

      if (!user.ibge) {
        this.setState({ loadingCep: true }, () => {
          this.props.cepService
            .buscaCep(user.cep)
            .then(response => {
              this.setState(oldState => ({
                novo: { ...oldState.novo, ibge: response.data.ibge }
              }));
            })
            .catch(() =>
              this.formRef.current.setFieldValue('cepValido', false, true)
            )
            .finally(() => this.setState({ loadingCep: false }));
        });
      }
    }

    this.onChangeShowForm();
  };

  onChangeShowForm = () => {
    this.setState(oldState => ({ showForm: !oldState.showForm }));
  };

  onEdit = (pessoa, position) => {
    this.setState({ showForm: true, novo: pessoa, position });
  };

  onCopyPercent = (pessoa, position) => {
    const { editar } = this.props;

    pessoa.percentualVenda = pessoa.percentual;
    const values = {
      ...pessoa
    };

    this.setState({ loadingDisplay: true });
    return editar(values, position)
      .then(() =>
        this.setState({
          novo: this.novo,
          position: undefined
        })
      )
      .finally(() => {
        this.setState({ loadingDisplay: false });
      });
  };

  render() {
    const {
      list,
      onDelete,
      title,
      vendedor,
      onViewPessoa,
      anuente,
      vendedores,
      compradores,
      vinculos,
      cepService,
      isServidor,
      validaRgComprador
    } = this.props;

    const { position } = this.state;

    const { novo, showForm, loadingDisplay } = this.state;

    let tipoProprietario = 'comprador';
    if (vendedor) {
      tipoProprietario = 'vendedor';
    } else if (anuente) {
      tipoProprietario = 'anuente';
    }

    return (
      <div>
        <SectionPessoa
          showButton={!showForm}
          title={title}
          onNovo={this.onNovo}
        />
        <Loading loading={loadingDisplay} />
        {list.length > 0 && (
          <DisplayDataGrid className="mt-xs has-btn-actions">
            {list.map((p, index) => (
              <DisplayIdentificacaoRequisicao
                key={p.cpfCnpj}
                position={index}
                pessoa={p}
                vendedor={vendedor}
                onDelete={onDelete}
                onEdit={this.onEdit}
                onCopyPercent={this.onCopyPercent}
                showActionButtons
                vinculos={this.props.vinculos}
                onViewPessoa={onViewPessoa}
                isServidor={isServidor}
              />
            ))}
          </DisplayDataGrid>
        )}
        {novo && showForm && (
          <Formik
            ref={this.formRef}
            enableReinitialize
            initialValues={novo}
            validate={validate(
              getValidationSchema,
              list,
              vendedores,
              compradores,
              tipoProprietario,
              position,
              isServidor,
              validaRgComprador
            )}
            onSubmit={this.confirmar}
            render={props => (
              <Form
                {...props}
                cancelar={this.cancelar}
                vinculos={vinculos}
                title={title}
                tipoProprietario={tipoProprietario}
                cepService={cepService}
                isServidor={isServidor}
              />
            )}
          />
        )}
      </div>
    );
  }
}

export default withService({
  cepService: CepService
})(IdentificacaoSectionList);
