import OPERATORS from './Operators';
import { Field, Filters, Operator } from './Types';

export function parseFilterUrl(
  fields: Field[],
  location: Pick<Location, 'search'>
): Filters {
  let filtersResult: Filters = {};

  const allParams: string[] =
    (location && location.search.substr(1).split('&')) || [];

  const paramFilter: string =
    allParams.find(param => param.startsWith('filters')) || '';

  const params: string[] = paramFilter.split('==') || [];
  const filtersUrl: string = params[1];
  const filtersSplit: string[] = filtersUrl ? filtersUrl.split(',') : [];

  filtersSplit.forEach((f: string) => {
    const nameValue: string[] = f.split('=');

    const name: string = nameValue[0].split('_')[0];
    const field: Field | undefined = fields.find(f => f.name === name);
    const operatorUrl: string = decodeURIComponent(nameValue[0].split('_')[1]);
    let value: string | object = decodeURIComponent(nameValue[1]);

    if (field) {
      const operator: Operator | undefined = OPERATORS[field.type].find(
        o => o.name === operatorUrl
      );
      if (operator) {
        filtersResult = {
          ...filtersResult,
          [`${name}_${operatorUrl}`]: {
            value,
            field,
            operator
          }
        };
      }
    }
  });

  return filtersResult;
}

export function buildFilterToUrl(
  filters: Filters,
  currentSearchParams: string
): string {
  const remainingSearchParams = currentSearchParams
    .substr(currentSearchParams.startsWith('?') ? 1 : 0)
    .split('&')
    .filter(searchClause => !searchClause.startsWith('filters'))
    .join('&');

  if (Object.keys(filters).length === 0) {
    return remainingSearchParams;
  }

  const newFilter: string = Object.entries(filters)
    .map(([key, filter]: [string, any]) => {
      let value = filter.value;

      if (filter.field.type === 'AUTOCOMPLETE') {
        value =
          typeof filter.value == 'string'
            ? filter.value
            : filter.field.getOptionValue!(filter.value);
      }

      return `${key}=${encodeURIComponent(value)}`;
    })
    .join(',');

  const parsedFilter = newFilter ? `filters==${newFilter}` : '';

  return remainingSearchParams === ''
    ? parsedFilter
    : `${remainingSearchParams}&${parsedFilter}`;
}
