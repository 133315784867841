import { History, Location } from 'history';
import React, { useCallback, useMemo, useState } from 'react';
import { match } from 'react-router';

import { BreadCrumbContextType, BreadCrumbRoute } from '../type';

const BreadCrumbContext = React.createContext<BreadCrumbContextType>({
  routes: [],
  activeRoutes: [],
  changeRoute: () => {}
});

type Props = {
  routes: BreadCrumbRoute[];
};

const getParentURL = (
  currentRoute: BreadCrumbRoute,
  parentRoute: BreadCrumbRoute
): string => {
  if (!currentRoute.parentURLBuilder) {
    return parentRoute.path;
  }
  return currentRoute.parentURLBuilder(currentRoute) || parentRoute.path;
};

type IndexedRoutes = Record<string, BreadCrumbRoute>;
const groupRoutes = (routes: BreadCrumbRoute[]): IndexedRoutes =>
  routes.reduce((acc, route) => ({ ...acc, [route.path]: route }), {});

const getRootRouteFromChild = (
  currentRoute: BreadCrumbRoute,
  indexedRoutes: IndexedRoutes,
  accumulatedRoutes: BreadCrumbRoute[] = []
): BreadCrumbRoute[] => {
  if (!currentRoute.parent) {
    return [currentRoute, ...accumulatedRoutes];
  }
  const parentRoute = indexedRoutes[currentRoute.parent];
  if (parentRoute === undefined) {
    return [currentRoute, ...accumulatedRoutes];
  }
  const parentURL = getParentURL(currentRoute, parentRoute);
  const parentRouteWithMatch: BreadCrumbRoute = {
    ...parentRoute,
    matchedURL: parentURL
  };
  return getRootRouteFromChild(parentRouteWithMatch, indexedRoutes, [
    currentRoute,

    ...accumulatedRoutes
  ]);
};

const pushRoute = (
  route: BreadCrumbRoute,
  matchedURL: string,
  indexedRoutes: IndexedRoutes,
  currentActiveRoutes: BreadCrumbRoute[]
) => {
  const routeWithUrl = { ...route, matchedURL };

  if (!routeWithUrl.parent) {
    return [routeWithUrl];
  } else {
    const routesToAdd =
      currentActiveRoutes.length === 0
        ? getRootRouteFromChild(routeWithUrl, indexedRoutes)
        : [routeWithUrl];

    return [...currentActiveRoutes, ...routesToAdd];
  }
};

const BreadCrumbProvider: React.FC<Props> = ({ routes, children }) => {
  const [activeRoutes, setActiveRoutes] = useState<BreadCrumbRoute[]>([]);

  const indexedRoutes = useMemo(() => groupRoutes(routes), [routes]);

  const changeRoute = useCallback(
    (match: match<any>, history: History, location: Location<any>) => {
      if (match.path === '/') {
        return setActiveRoutes([]);
      }
      const route = indexedRoutes[match.path];

      if (!route) {
        return;
      }
      if (!match.isExact && route.onlyOnExact) {
        return;
      }
      const routeAlreadyActiveIndex = activeRoutes.findIndex(
        activeRoute => activeRoute.path === match.path
      );
      const matchedURL = `${match.url}${location.search}`;

      if (routeAlreadyActiveIndex > -1) {
        if (activeRoutes[routeAlreadyActiveIndex].matchedURL === matchedURL) {
          const newRoutes = activeRoutes.slice(0, routeAlreadyActiveIndex + 1);
          return setActiveRoutes(newRoutes);
        }
        return setActiveRoutes(
          pushRoute(
            route,
            matchedURL,
            indexedRoutes,
            activeRoutes.slice(0, routeAlreadyActiveIndex)
          )
        );
      }
      if (history.action === 'PUSH') {
        const routes = location.state?.resetBreadCrumb ? [] : activeRoutes;
        setActiveRoutes(pushRoute(route, matchedURL, indexedRoutes, routes));
      } else {
        setActiveRoutes(pushRoute(route, matchedURL, indexedRoutes, []));
      }
    },
    [pushRoute]
  );

  return (
    <BreadCrumbContext.Provider
      value={{
        routes,
        activeRoutes,
        changeRoute
      }}
    >
      {children}
    </BreadCrumbContext.Provider>
  );
};

export { BreadCrumbProvider, BreadCrumbContext };
