import React from 'react';
import MaskedInput from 'react-text-mask';

import BasicInput, { Props as BasicInputProps } from './BasicInput';
import { Masks } from '..';

const FormikInputCep: React.FC<BasicInputProps> = props => (
  <BasicInput
    name={props.name}
    label={props.label}
    type="text"
    loading={props.loading}
    disabled={props.disabled}
    render={({ field }) => (
      <MaskedInput
        {...field}
        mask={Masks.MASK_CEP}
        id={field.name}
        placeholder="Ex. 87047-000"
        onChange={props.onChange || field.onChange}
        className={`${field.className} ${props.loading ? 'loading' : ''}`}
        value={!field.value && field.value === undefined ? '' : field.value}
      />
    )}
  />
);

export default FormikInputCep;
