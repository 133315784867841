type Environment = 'prod' | 'qa' | 'dev' | 'local';

export const isEnviromentProduction = (hostname: string) =>
  hostname.includes('.oxy.elotech.com.br') ||
  hostname.includes('.iss.elotech.com.br') ||
  hostname.includes('.fiscalizacao.elotech.com.br');

export const isEnviromentQA = (hostname: string) =>
  hostname.includes('.qa.elotech.com.br');

export const isEnviromentDev = (hostname: string) =>
  hostname.includes('.dev.elotech.com.br');

export const isEnviromentLocalhost = (hostname: string) =>
  hostname === 'localhost';

export const resolveCurrentEnvironment = (
  hostname: string = window.location.hostname
): Environment => {
  switch (true) {
    case isEnviromentProduction(hostname):
      return 'prod';
    case isEnviromentQA(hostname):
      return 'qa';
    case isEnviromentDev(hostname):
      return 'dev';
    default:
      return 'local';
  }
};
