import { ErrorText } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const Requerente = props => {
  const {
    requerente = {},
    onChangeSelect,
    error,
    errorMessages,
    listRequerentes
  } = props;

  return (
    <div className="row">
      <div className="form-group col-md-6">
        <label htmlFor="requerente">Requerente</label>
        <select
          data-test-id="requerenteSelect"
          data-cy="selectRequerente"
          id="requerente"
          name="requerente"
          className={error.requerente?.id ? 'error' : ''}
          onChange={onChangeSelect}
          value={requerente.id}
        >
          <option value="">Selecione</option>
          {listRequerentes.map(item => (
            <option key={item.id} value={item.id}>
              {item.nome}
            </option>
          ))}
        </select>
        {error.requerente?.id && (
          <ErrorText>{errorMessages.requerente?.id}</ErrorText>
        )}
      </div>
    </div>
  );
};

Requerente.propTypes = {
  requerente: PropTypes.object,
  onChangeSelect: PropTypes.func.isRequired,
  error: PropTypes.object,
  listRequerentes: PropTypes.array.isRequired
};

export default Requerente;
