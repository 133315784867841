import { FormikErrors, FormikProps, FormikTouched, getIn } from 'formik';
import { ValidationError } from 'yup';

const validateFormik = <T>(validationSchema: any, ...args: any[]) => (
  values: T
) => {
  try {
    validationSchema(values, ...args).validateSync(values, {
      abortEarly: false
    });

    return {};
  } catch (error) {
    return convertErrorToFormik(error);
  }
};

const convertErrorToFormik = (validationError: ValidationError) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR]
    };
  }, {});
};

/**
 * Verifica se o campo em fieldName está com flag de erro, mesmo
 * que não conste no touched do formik, se já houve tentativa de
 * submit.
 */
const hasErrors = (fieldName: string, form: FormikProps<any>): boolean => {
  return !!(
    getIn(form.errors, fieldName) &&
    (getIn(form.touched, fieldName) || form.submitCount > 0)
  );
};

const hasVisibleErrors = (form: FormikProps<any>): boolean => {
  return Object.entries(form.errors).some(([field, errorValue]) => {
    return (
      !!errorValue && (Boolean(form.touched[field]) || form.submitCount > 0)
    );
  });
};

const buildTouched = (
  fields: string[],
  defaultValue: boolean = true
): FormikTouched<any> => {
  return fields.reduce(
    (acc, current) => ({ ...acc, [current]: defaultValue }),
    {}
  );
};

const extractErrorText = (formikProps: FormikProps<any>, fields: string[]) => {
  const result = Object.entries(formikProps.errors).find(
    ([field, errorValue]) =>
      fields.includes(field) &&
      (Boolean(formikProps.touched[field]) || formikProps.submitCount > 0) &&
      Boolean(errorValue)
  );
  return result === undefined ? '' : extractFirstError(result[1]!);
};

const extractFirstError = (
  value: string | any[] | FormikErrors<any>
): string => {
  if (typeof value === 'string') {
    return value;
  }
  if (value instanceof Array && value.length > 0) {
    return extractFirstError(value[0]);
  }
  if (typeof value === 'object') {
    return extractFirstError(Object.values(value)[0]);
  }
  return '';
};

export {
  validateFormik,
  convertErrorToFormik,
  hasErrors,
  hasVisibleErrors,
  extractErrorText,
  buildTouched,
  extractFirstError
};
