import React from 'react';
import { SectionTitle } from '@elotech/components';
import { UploadService, withService } from 'itbi-common/service';
import DocumentoRow from './DocumentoRow';

type Props = {
  documentos: any[];
  idDeclaracao: string;
  uploadService: typeof UploadService;
};
const DocumentacaoList: React.FunctionComponent<Props> = ({
  documentos,
  idDeclaracao,
  uploadService
}) => {
  if (documentos.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <SectionTitle>Documentações</SectionTitle>
      <div className="panel table table-responsive">
        <div className="panel-body">
          <div className="panel-table-outer" style={{ overflow: 'visible' }}>
            <table className="panel-table striped fancy">
              <tbody>
                {documentos.map(documento => (
                  <DocumentoRow
                    key={documento.id}
                    documento={documento}
                    idDeclaracao={idDeclaracao}
                    uploadService={uploadService}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withService({
  uploadService: UploadService
})(DocumentacaoList);
