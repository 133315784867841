import React, { FC, useContext, useState } from 'react';

import { Col, Row } from '../grid';
import Icon from '../icons/Icon';
import AutoComplete from '../inputs/AutoComplete';
import { ApplicationContext } from '../providers/ApplicationProvider';
import { Entidade } from '../type';
import { HeaderContext } from './Header';

export type EntidadeContentProps = {};

const EntidadeContent: FC<EntidadeContentProps> = () => {
  const { entidade, setEntidade, onSearchEntidades } = useContext(
    ApplicationContext
  );
  const { toggleAjustes, toggleEntidade } = useContext(HeaderContext);
  const [value, setValue] = useState(entidade);

  const handleEntidade = () => {
    toggleEntidade();
    toggleAjustes();
  };

  const define = () => {
    setEntidade(value);
    toggleEntidade();
  };

  return (
    <>
      <h2 className="title">
        <div
          className="read hint clean center-left neutral"
          onClick={handleEntidade}
        >
          <Icon icon="chevron-left" className="icon" />
          <div className="hint-content">Voltar</div>
        </div>
        Parâmetros
      </h2>
      <div className="features-group-container auto-height">
        <Row>
          <Col md={12}>
            <div className="form-group">
              <label className="label">Entidade</label>
              <AutoComplete
                name="entidade"
                value={value}
                onSelect={(_, value) => setValue(value)}
                onSearch={onSearchEntidades!}
                getOptionValue={(entidade: Entidade) => entidade.id}
                getOptionLabel={(entidade: Entidade) => entidade.nome}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="form-group">
            <button className="btn module-color right" onClick={define}>
              Definir
            </button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EntidadeContent;
