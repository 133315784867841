import { EnumValue } from '../types/EnumType';

type TipoDocumento = EnumValue & { codigo: number; title: string };

export const TipoDocumentoEnum = {
  certidaoCadMunicipal: 2,
  certidaoContribuinte: 33,
  certidaoComprobatoria: 40,
  certidaoNumeracao: 61
};

export const TipoDocumento: TipoDocumento[] = [
  {
    codigo: 2,
    descricao: 'certidaoCadMunicipal',
    title: 'Certidão por Cadastro Municipal'
  },
  {
    codigo: 33,
    descricao: 'certidaoContribuinte',
    title: 'Certidão por Contribuinte'
  },
  {
    codigo: 40,
    descricao: 'certidaoComprobatoria',
    title: 'Certidão Comprobatória'
  },
  { codigo: 61, descricao: 'certidaoNumeracao', title: 'Certidão de Numeração' }
];

export const getTipoDocumentoByCodigo = (codigo: number): TipoDocumento =>
  TipoDocumento.find(item => item.codigo === codigo)!;

export const getTipoDocumentoByDescricao = (descricao: string): TipoDocumento =>
  TipoDocumento.find(item => item.descricao === descricao)!;
