import classNames from 'classnames';
import React from 'react';

import Button from '../buttons/Button';
import AutoComplete, { AutoCompleteProps } from './AutoComplete';

type Props<T = any> = Omit<AutoCompleteProps<T>, 'value' | 'onSelect'> & {
  values?: T[];
  onRemoveItem(value: T, values: T[]): void;
  showOptionsBottom?: boolean;
  onSelect(name: string, value: T | undefined, values: T[]): void;
  getOptionLabel: (option: T) => string;
};

type State<T = any> = {
  selectedOptions: T[];
};

export default class AutoCompleteMultiple<T> extends React.Component<
  Props<T>,
  State
> {
  state: State<T> = {
    selectedOptions: this.props.values ?? []
  };

  componentDidUpdate(oldProps: Readonly<Props<T>>) {
    const { values: newValues } = this.props;

    if (
      newValues !== undefined &&
      newValues !== oldProps.values &&
      newValues !== this.state.selectedOptions
    ) {
      this.setState({ selectedOptions: newValues });
    }
  }

  innerOnSelect = (name: string, value: T | undefined): void => {
    this.setState(
      prevState => ({
        selectedOptions: [...prevState.selectedOptions, value]
      }),
      () => this.props.onSelect(name, value, this.state.selectedOptions)
    );
  };

  onRemoveSelectedValue = (value: T, index: number) => {
    const { selectedOptions } = this.state;

    this.setState(
      {
        selectedOptions: [
          ...selectedOptions.slice(0, index),
          ...selectedOptions.slice(index + 1)
        ]
      },
      () => this.props.onRemoveItem(value, this.state.selectedOptions)
    );
  };

  renderSelectedValues = () => {
    const { showOptionsBottom } = this.props;
    const { selectedOptions } = this.state;

    const buttonClassNames = classNames({
      'btn positive round interactive inline full-xs option': true,
      'mb-xs': !showOptionsBottom,
      'mt-xs': showOptionsBottom
    });

    return selectedOptions.map((item, index) => {
      return (
        <Button
          key={`btn-${index}`}
          id={`btn-${index}`}
          className={buttonClassNames}
          onClick={() => this.onRemoveSelectedValue(item, index)}
        >
          {this.props.getOptionLabel(item)}
        </Button>
      );
    });
  };

  render() {
    const { showOptionsBottom } = this.props;

    return (
      <>
        {!showOptionsBottom && (
          <div className="multiple">{this.renderSelectedValues()}</div>
        )}

        <AutoComplete {...this.props} onSelect={this.innerOnSelect} />

        {showOptionsBottom && (
          <div className="multiple">{this.renderSelectedValues()}</div>
        )}
      </>
    );
  }
}
