import React from 'react';

import {
  BasicInput,
  FormikInputDate,
  FormikInputInteger,
  FormikInputNumber,
  FormikSelect,
  Row
} from '../';
import { CampoTipoEnum, GrupoResposta, Resposta } from '../type';
import {
  suffixNameElementCampoMultiplaEscolha,
  suffixNameElementCampoValor
} from '../utils/GrupoRespostaCampoDinamicoValidatorUtils';

type Props = {
  fieldNameResposta: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  grupoResposta: GrupoResposta;
  indexGrupoResposta: number;
};

const getLabel = (resposta: Resposta) =>
  `${resposta.campo.codigo} - ${resposta.campo.descricao}`;

const getKey = (resposta: Resposta, indexResposta: number) =>
  `${resposta.id}-${indexResposta}`;

const GrupoRespostaCampoDinamicoForm: React.FC<Props> = ({
  fieldNameResposta,
  setFieldValue,
  grupoResposta,
  indexGrupoResposta
}) => {
  const getNameCampoValor = (indexResposta: number) =>
    `${fieldNameResposta}.${indexGrupoResposta}.respostas.${indexResposta}.${suffixNameElementCampoValor}`;
  const getNameCampoMultiplaEscolha = (indexResposta: number) =>
    `${fieldNameResposta}.${indexGrupoResposta}.respostas.${indexResposta}.${suffixNameElementCampoMultiplaEscolha}`;

  const renderMultiplaEscolha = (resposta: Resposta, indexResposta: number) => {
    return (
      <FormikSelect
        key={getKey(resposta, indexResposta)}
        name={getNameCampoMultiplaEscolha(indexResposta)}
        label={getLabel(resposta)}
        size={2}
        options={resposta.campo.alternativas.filter(a => a.ativo)}
        getOptionLabel={option => `${option.codigo} - ${option.descricao}`}
        getOptionValue={option => option.id}
        onChange={event => {
          setFieldValue(
            `${fieldNameResposta}.${indexGrupoResposta}.respostas.${indexResposta}.alternativa`,
            resposta.campo.alternativas.find(
              item => item.id === +event.target.value
            )
          );
        }}
      />
    );
  };

  const renderTexto = (resposta: Resposta, indexResposta: number) => {
    return (
      <BasicInput
        key={getKey(resposta, indexResposta)}
        name={getNameCampoValor(indexResposta)}
        label={getLabel(resposta)}
        size={3}
        fast={false}
      />
    );
  };

  const renderDecimal = (resposta: Resposta, indexResposta: number) => {
    return (
      <FormikInputNumber
        key={getKey(resposta, indexResposta)}
        name={getNameCampoValor(indexResposta)}
        label={getLabel(resposta)}
        size={3}
        decimalScale={2}
        isNumericString={true}
        allowNegative={false}
      />
    );
  };

  const renderData = (resposta: Resposta, indexResposta: number) => {
    return (
      <FormikInputDate
        key={getKey(resposta, indexResposta)}
        name={getNameCampoValor(indexResposta)}
        label={getLabel(resposta)}
        size={3}
      />
    );
  };

  const renderInteiro = (resposta: Resposta, indexResposta: number) => {
    return (
      <FormikInputInteger
        key={getKey(resposta, indexResposta)}
        name={getNameCampoValor(indexResposta)}
        label={getLabel(resposta)}
        size={2}
      />
    );
  };

  const renderCampoDinamico = (resposta: Resposta, indexResposta: number) => {
    if (!resposta.campo.ativo) {
      return;
    }

    switch (resposta.campo.tipo) {
      case CampoTipoEnum.MULTIPLA_ESCOLHA:
        return renderMultiplaEscolha(resposta, indexResposta);
      case CampoTipoEnum.TEXTO:
        return renderTexto(resposta, indexResposta);
      case CampoTipoEnum.DECIMAL:
        return renderDecimal(resposta, indexResposta);
      case CampoTipoEnum.DATA:
        return renderData(resposta, indexResposta);
      case CampoTipoEnum.INTEIRO:
        return renderInteiro(resposta, indexResposta);
    }
  };

  return (
    <>
      {grupoResposta.ativo && grupoResposta?.respostas && (
        <Row className={'flex wrap align-items-left mb-xs'}>
          {grupoResposta.respostas.map(
            (resposta: Resposta, indexResposta: number) =>
              renderCampoDinamico(resposta, indexResposta)
          )}
        </Row>
      )}
    </>
  );
};

export default GrupoRespostaCampoDinamicoForm;
