import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Icon from '../icons/Icon';

type ActionButtonLink = {
  label: string;
  visible?: boolean;
  onClick(event: React.MouseEvent): void;
};

type ActionLinkProps = {
  icon: IconProp;
  label: string;
  path: string;
  customClass?: string;
  tabIndex?: number;
  newTab?: boolean;
};

type ActionButtonProps = {
  icon: IconProp;
  label?: string;
  type?: 'submit' | 'reset' | 'button';
  onClick(event: React.MouseEvent): void;
  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;
  customClass?: string;
  links?: ActionButtonLink[];
  tabIndex?: number;
  disabled?: boolean;
  circle?: boolean;
};

type Props = ActionButtonProps | ActionLinkProps;

const StyledButton = styled.button``;

const StyledLink = styled(Link)``;

const renderActionButton = ({
  customClass = '',
  icon,
  label,
  type = 'button',
  links = [],
  onClick,
  tabIndex,
  disabled = false,
  circle = true,
  onMouseEnter,
  onMouseLeave
}: ActionButtonProps) => {
  const visibleLinks = links.filter(
    l => !l.hasOwnProperty('visible') || l.visible
  );
  const hasLinks = visibleLinks.length > 0;

  const names = classNames({
    btn: true,
    circle: circle,
    links: hasLinks,
    [customClass]: !!customClass
  });

  return (
    <StyledButton
      className={names}
      onMouseDown={event => event.preventDefault()}
      onClick={onClick}
      type={type}
      tabIndex={tabIndex}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Icon
        icon={icon}
        darkPrimary
        hoverColor="white"
        hoverParent={StyledButton}
      />
      {hasLinks ? (
        <div className="btn-actions-label">
          {visibleLinks.map(link => (
            <a
              key={link.label}
              href="javascript:void(0)"
              onClick={link.onClick}
            >
              {link.label}
            </a>
          ))}
        </div>
      ) : (
        label && <div className="btn-actions-label">{label}</div>
      )}
    </StyledButton>
  );
};

const renderActionLink = ({
  path,
  icon,
  label,
  customClass = '',
  tabIndex,
  newTab = false
}: ActionLinkProps) => {
  const names = classNames({
    btn: true,
    circle: true,
    links: false,
    [customClass]: !!customClass
  });

  return (
    <StyledLink
      to={path}
      className={names}
      tabIndex={tabIndex}
      target={newTab ? '_blank' : undefined}
    >
      <Icon
        icon={icon}
        darkPrimary
        hoverColor="white"
        hoverParent={StyledLink}
      />
      <div className="btn-actions-label">{label}</div>
    </StyledLink>
  );
};

const isActionButton = (props: Props): props is ActionButtonProps =>
  (props as ActionLinkProps).path === undefined;

const ActionButton: React.FunctionComponent<Props> = props => {
  if (isActionButton(props)) {
    return renderActionButton(props);
  }
  return renderActionLink(props);
};

export default ActionButton;
