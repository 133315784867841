import React from 'react';
import ReactQuill from 'react-quill';

import BasicInput from '../formik/BasicInput';

type Props = {
  name: string;
  theme?: string;
  size?: number;
  label?: string;
  onChange?(value: string): void;
  disabled?: boolean;
  fast?: boolean;
};

const FormikHtmlEditor = React.forwardRef<ReactQuill, Props>(
  (
    {
      name = '',
      theme = 'snow',
      size = 12,
      label = '',
      onChange,
      disabled = false,
      fast = true
    },
    ref
  ) => (
    <BasicInput
      name={name}
      label={label}
      size={size}
      fast={fast}
      render={({ field }) => (
        <ReactQuill
          {...field}
          id={field.name}
          ref={ref}
          theme={theme}
          value={field.value || ''}
          modules={reactQuillModules}
          formats={reactQuillFormats}
          readOnly={disabled}
          onChange={value => onChange && onChange(value)}
          onFocus={() =>
            field.onFocus?.({
              target: { name: field.name }
            } as React.FocusEvent<any>)
          }
        />
      )}
    />
  )
);

export const reactQuillModules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    ['code-block'],
    [{ align: [] }]
  ]
};

export const reactQuillFormats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'background',
  'color',
  'bullet',
  'link',
  'image',
  'code-block',
  'align'
];

export default FormikHtmlEditor;
