import axios, { AxiosPromise } from 'axios';
import download from 'downloadjs';

type UrlRequest = {
  declaracao: string;
  documento: string;
  arquivo: string;
};

export const getUrlUploadS3Declaracao = ({
  declaracao,
  documento,
  arquivo
}: UrlRequest): AxiosPromise<any> =>
  axios.get(
    `/api/uploads/url-declaracao?declaracao=${declaracao}&documento=${documento}&arquivo=${arquivo}`
  );

export const getUrlDownloadS3Declaracao = ({
  declaracao,
  documento,
  arquivo
}: UrlRequest): AxiosPromise<any> =>
  axios.get(`/api/uploads/url-download-documento?declaracao=${declaracao}
    &documento=${documento}&arquivo=${arquivo}`);

export const getUrlUploadArquivoAvulsoDeclaracao = (
  declaracao: string,
  arquivo: string
): AxiosPromise<any> =>
  axios.get(
    `/api/uploads/documento-avulso?declaracao=${declaracao}&arquivo=${arquivo}`
  );

export const getUrlDownloadArquivoAvulsoDeclaracao = (
  declaracao: string,
  arquivo: string
): AxiosPromise<any> =>
  axios.get(
    `/api/uploads/documento-avulso/download?declaracao=${declaracao}&arquivo=${arquivo}`
  );

export const getUrlUploadArquivoLaudo = (
  laudo: string,
  arquivo: string
): AxiosPromise<any> =>
  axios.get(`/api/uploads/anexo-laudo?laudo=${laudo}&arquivo=${arquivo}`);

export const getUrlDownloadArquivoLaudo = (
  laudo: string,
  arquivo: string
): AxiosPromise<any> =>
  axios.get(
    `/api/uploads/anexo-laudo/download?laudo=${laudo}&arquivo=${arquivo}`
  );

export const uploadFileS3 = (url: string, file: any): AxiosPromise<any> =>
  axios.put(url, file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

export const downloadFileS3 = (url: string): any => download(url);
