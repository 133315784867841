import { PagedResponse, Sort } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { LaudoAnexo, LoteLaudo, Usuario } from '../type';
import ServiceUtils from '../utils/ServiceUtils';

export const defaultSort = { sort: 'numero,desc' };

export const searchLaudos = (
  rsqlParam: string,
  page: object
): AxiosPromise<any> =>
  axios.get(`/api/laudos?${ServiceUtils.buildSearchParams(rsqlParam)}`, {
    params: { ...defaultSort, ...page }
  });

export const searchLaudosServidor = (
  rsqlParam: string,
  page: object,
  sort: Sort
): AxiosPromise<any> => {
  sort = sort || defaultSort;
  return axios.get(
    `/api/laudos/servidor?${ServiceUtils.buildSearchParams(rsqlParam)}`,
    {
      params: { ...sort, ...page }
    }
  );
};

export const calcularValorLaudo = (
  propriedades: any
): AxiosPromise<LoteLaudo> => axios.post(`/api/laudos/valor`, propriedades);

export const save = (propriedades: any) =>
  axios.post(`/api/laudos`, propriedades);

export const updateLaudo = (laudo: any): AxiosPromise<any> =>
  axios.put(`/api/laudos/${laudo.id}`, laudo);

export const gerarDebitos = (id: string): AxiosPromise<any> =>
  axios.post(`/api/laudos/${id}/gerar-debitos`);

export const gerarBoleto = (id: string): AxiosPromise<any> =>
  axios.post(`/api/laudos/${id}/boleto`);

export const getLaudo = (id: string): AxiosPromise<any> =>
  axios.get(`/api/laudos/${id}`);

export const getLaudoParaAnalise = (id: string): AxiosPromise<any> =>
  axios.get(`/api/laudos/${id}/analise`);

export const togglePrioritario = (
  id: string,
  motivoPrioritario: string
): AxiosPromise<any> =>
  axios.put(`/api/laudos/prioritario/${id}`, motivoPrioritario);

export const indeferir = (
  id: string,
  motivoIndeferimento: string
): AxiosPromise<any> =>
  axios.put(`/api/laudos/indeferir/${id}`, motivoIndeferimento);

export const assumirResponsabilidade = (id: string): AxiosPromise<any> =>
  axios.post(`/api/laudos/${id}/assumir`);

export const finalizarAnalise = (id: string): AxiosPromise<any> =>
  axios.put(`/api/laudos/${id}/finalizar`);

export const estornar = (id: string): AxiosPromise<any> =>
  axios.put(`/api/laudos/${id}/estornar`);

export const transferirResponsavel = (
  idLoteLaudo: string,
  responsavel: Usuario
): AxiosPromise<any> =>
  axios.put(`/api/laudos/${idLoteLaudo}/transferir-responsavel`, responsavel);

export const getHistory = (id: string): AxiosPromise<any> =>
  axios.get(`/api/laudos/${id}/history`);

export const getValorLei = (): AxiosPromise<any> =>
  axios.get('/api/laudos/valor-lei');

export const addAnexoLaudo = (
  id: string,
  documento: object
): AxiosPromise<any> => axios.post(`/api/laudosanexo/${id}`, documento);

export const findArquivosLaudoAnexo = (
  id: string,
  page?: any
): AxiosPromise<PagedResponse<LaudoAnexo>> =>
  axios.get(`/api/laudosanexo/${id}/anexos`, {
    params: { ...page, sort: 'data,desc' }
  });

export const removeArquivoLaudoAnexo = (
  idLaudo: string,
  idLaudoAnexo: string
): AxiosPromise<any> =>
  axios.delete(`/api/laudosanexo/${idLaudo}/anexo/${idLaudoAnexo}`);

export const apagarGuiaPagamento = (idLaudo: string): AxiosPromise<any> =>
  axios.delete(`/api/laudos/${idLaudo}/guiapagamento`);
