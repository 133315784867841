import { BasicInput, Row } from '@elotech/components';
import { FormikProps } from 'formik';
import React from 'react';

import { Laudo } from '../../type';

type Props = {
  formProps: FormikProps<Laudo>;
};

const PropriedadeFormUrbano: React.FC<Props> = ({ formProps }) => {
  return (
    <>
      <Row>
        <BasicInput
          size={3}
          name={'cadastroImobiliario.proprietario'}
          label={'Proprietário'}
          disabled
        />
        <BasicInput
          size={3}
          name={'cadastroImobiliario.inscricaoCadastral'}
          label={'Inscrição Cadastral'}
          disabled
        />
        <BasicInput
          size={1}
          name={'cadastroImobiliario.quadra'}
          label={'Quadra'}
          disabled
        />
        <BasicInput
          size={1}
          name={'cadastroImobiliario.lote'}
          label={'Data/Lote'}
          disabled
        />
        <BasicInput
          size={2}
          name={'cadastroImobiliario.areaTerreno'}
          label={'Área Terreno'}
          disabled
        />
        <BasicInput
          size={2}
          name={'cadastroImobiliario.areaConstruida'}
          label={'Área Construída'}
          disabled
        />
        <BasicInput
          size={12}
          name={'cadastroImobiliario.complementoLote'}
          label={'Complemento Lote'}
          disabled
        />
      </Row>
      <Row>
        <BasicInput
          size={2}
          name={'cadastroImobiliario.cep'}
          label={'CEP'}
          disabled
        />
        <BasicInput
          size={10}
          name={'cadastroImobiliario.endereco'}
          label={'Endereço'}
          disabled
        />
        <BasicInput
          size={1}
          name={'cadastroImobiliario.numero'}
          label={'Número'}
          disabled
        />
        <BasicInput
          size={5}
          name={'cadastroImobiliario.complemento'}
          label={'Complemento'}
          disabled
        />
        <BasicInput
          size={2}
          name={'cadastroImobiliario.cidade'}
          label={'Cidade'}
          disabled
        />
        <BasicInput
          size={4}
          name={'cadastroImobiliario.estado'}
          label={'Estado'}
          disabled
        />
        <div className="form-group col-md-12">
          <label htmlFor="condominioInput">Condomínio</label>
          <input
            id="condominioInput"
            type="text"
            name="cadastroImobiliario.condominio"
            value={
              formProps.values.cadastroImobiliario?.condominio
                ? `${formProps.values.cadastroImobiliario?.condominio} - ${formProps.values.cadastroImobiliario?.descricaoCondominio}`
                : ''
            }
            disabled={true}
          />
        </div>
      </Row>
    </>
  );
};

export default PropriedadeFormUrbano;
