import { queryHelpers, within } from '@testing-library/dom';
import { BoundFunctions } from '@testing-library/dom/get-queries-for-element';
import { RenderResult, wait } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import * as queries from 'testing-library__dom/queries';

export const queryByDataTestId = queryHelpers.queryByAttribute.bind(
  null,
  `data-test-id`
);

export const getTable = (wrapper: RenderResult) =>
  within(
    wrapper.getByText((content, element) => {
      return element.tagName.toLocaleLowerCase() === 'table';
    })
  );

export const getTableHeader = (wrapper: RenderResult) =>
  within(wrapper.container.querySelector('thead')!);

export const getTableBody = (wrapper: RenderResult) =>
  within(
    wrapper.getByText((content, element) => {
      return element.tagName.toLocaleLowerCase() === 'tbody';
    })
  );

export const getDisplayDataByLabel = (wrapper: RenderResult, label: string) => {
  const campo = wrapper.getByText(label);
  return within(campo.closest('div')!);
};

export const getItemFooter = (
  footer: BoundFunctions<typeof queries>,
  label: string
) => {
  return footer.getByText(label).nextElementSibling;
};

export const getDisplayDataContent = (
  wrapper: RenderResult,
  label: string,
  strictLabel?: boolean
) => {
  const field = strictLabel
    ? wrapper.getByText(
        (content, element) =>
          element.className === 'dd-title' &&
          content.startsWith(label) &&
          content.endsWith(label)
      )
    : wrapper.getByText(label);
  return field.closest('div')!.querySelector('.dd-content');
};

export const getDisplayDataContentWithPosition = (
  wrapper: RenderResult,
  label: string,
  position: number
) => {
  return wrapper
    .getAllByText(label)
    [position].closest('div')!
    .querySelector('.dd-content');
};

export const testAddRegisterAutoComplete = async (
  wrapper: any,
  link: string,
  fieldLabel: string,
  service?: any
) => {
  const autoComplete = wrapper.getByLabelText(fieldLabel);
  autoComplete.focus();

  if (service) {
    await wait(() => {
      expect(service).toHaveBeenCalledTimes(1);
    });
  }

  userEvent.click(autoComplete);

  const autoCompleteWrapper = within(
    autoComplete.closest('div')?.parentElement!
  );
  const option = await autoCompleteWrapper.findByTitle('Adicionar');
  userEvent.click(option);

  expect(window.open).toHaveBeenCalledTimes(1);
  expect(window.open).toHaveBeenCalledWith(link, '_blank');
};

export const setAutoCompleteValue = async (
  autoComplete: HTMLElement,
  valueToType: string,
  optionMatcher: string | RegExp
) => {
  autoComplete.focus();
  await userEvent.type(autoComplete, valueToType, { allAtOnce: true });
  autoComplete.focus();
  userEvent.click(autoComplete);
  const autoCompleteWrapper = within(
    autoComplete.closest('div')?.parentElement!
  );

  const option = await autoCompleteWrapper.findByText(optionMatcher);
  userEvent.click(option);
};

export const limparAutoComplete = (autoComplete: HTMLElement) => {
  const parentDiv = within(autoComplete.closest('div')!);
  userEvent.click(parentDiv.getByTitle('Limpar'));
};

export const getColumnFromRow = (
  row: BoundFunctions<typeof queries>,
  columnName: string
) => row.getByText(columnName).parentElement!.querySelector('.td-content')!;

export const getWizardActiveStep = (
  view: RenderResult
): string | null | undefined => {
  const wizard = view.container.querySelector('div.wizard');
  if (!wizard) {
    throw new Error('Não foi possível encontrar um wizard na tela.');
  }

  const activeStep = wizard.querySelectorAll('li.step.active');

  return activeStep[activeStep.length - 1]?.querySelector<HTMLElement>(
    'div.title'
  )?.textContent;
};
