import {
  ActionButton,
  ActionsGroup,
  Alert,
  FormattedDate,
  Loading,
  Panel,
  Table,
  TableChild
} from '@elotech/components';
import { ConsultaCarneService } from 'itbi-common/service';
import React, { useEffect, useState } from 'react';

import { DebitoAgrupadoPorFormaPagamentoDTO } from '../../types';

type Props = {
  item: any;
};

const CarneInnerComponentPage: React.FC<Props> = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingImprimir, setLoadingImprimir] = useState<boolean>(false);
  const [debitosCotaUnica, setDebitosCotaUnica] = useState<
    DebitoAgrupadoPorFormaPagamentoDTO[]
  >([]);

  useEffect(() => {
    setLoading(true);

    ConsultaCarneService.getDebitosFormaPagamento(
      props.item.tipoCadastro,
      props.item.cadastroGeral
    )
      .then(response => {
        if (response.data.length > 0) {
          setDebitosCotaUnica(response.data);
        } else {
          Alert.error({ title: 'Nenhum débito encontrado.' });
        }
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao buscar débitos.' }, error);
      })
      .finally(() => setLoading(false));
  }, [props]);

  const imprimirFormaPagamento = (item: any) => {
    setLoadingImprimir(true);
    ConsultaCarneService.imprimirFormaPagamento(item)
      .then(response => {
        window.open(response.data.url, '_blank');
        setLoading(false);
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao buscar boleto.' }, error);
      })
      .finally(() => setLoadingImprimir(false));
  };

  return (
    <TableChild>
      <Loading loading={loadingImprimir} />
      <Panel isTable>
        <Table values={debitosCotaUnica} loading={loading}>
          <Table.Column<DebitoAgrupadoPorFormaPagamentoDTO>
            header="Exercício"
            value={item => item.exercicio}
          />
          <Table.Column<DebitoAgrupadoPorFormaPagamentoDTO>
            header="Dívida"
            value={item => item.divida}
          />
          <Table.Column<DebitoAgrupadoPorFormaPagamentoDTO>
            header="Subdivida"
            value={item => item.subDivida}
          />
          <Table.Column<DebitoAgrupadoPorFormaPagamentoDTO>
            header="Descrição"
            value={item => item.descricaoVisualizacao}
          />

          <Table.Column<DebitoAgrupadoPorFormaPagamentoDTO>
            header="Data Vencimento"
            value={item => <FormattedDate value={item.vencimento} />}
          />
          <Table.Column<DebitoAgrupadoPorFormaPagamentoDTO>
            header="Forma Pagamento"
            value={item => item.descricaoFormaPagamento}
          />
          <Table.Column<DebitoAgrupadoPorFormaPagamentoDTO>
            header=""
            value={item => (
              <ActionsGroup>
                <ActionButton
                  key="print-button"
                  icon="print"
                  label="Imprimir Carnê"
                  onClick={() => imprimirFormaPagamento(item)}
                />
              </ActionsGroup>
            )}
          />
        </Table>
      </Panel>
    </TableChild>
  );
};

export default CarneInnerComponentPage;
